/*============================================================
[Master style sheet]

* Template : MEAL-RUSH - SaaS, App & Startup React Template 
* Author : SoftCoderes
* Version:        1.1
* Author URI : http://www.softcoders.net/ 

=========================================================*/
/*=======================================================
TABLE OF CONTENTS:

01 global Style
02 Spacing CSS

====== section style sheet ======
03 header section
04 banner section
05 about section
06 canvas section
07 service section
08 feature section
09 client section
10 counter section
11 price section
12 business section
13 testimonial section
14 blog section
15 apps section
16 footer section
====== inner page style sheet ======
17 breadcrumb page
18 blog Page
19 blog-details Page
20 faq page
21 contact page
22 team Page
23 Login-SignUp Page
====== responsive style sheet ======
24 responsive style  */


/* ============== 01 global Style ============== */

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");

html,
body {
  font-size: 16px;
  color: #424242;
  font-family: "Varela", sans-serif;
  vertical-align: baseline;
  font-weight: 400;
  overflow-x: hidden;
  line-height: 1.8;
  background: #fff;
  overflow-y: auto;
}

input,
textarea,
select {
  border: 0;
  background: transparent;
  transition: 0.4s;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

a {
  transition: 0.4s;
  text-decoration: none;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Verdana", sans-serif;
  color: #101621;
  line-height: 1.3;
}

h1 {
  font-size: 65px;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 45px;
  font-weight: 700;
}

h3 {
  font-size: 25px;
  font-weight: 500;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 15px;
  font-weight: 400;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
}

::-moz-selection {
  background: #ef9334;
  text-shadow: none;
  color: #ffffff;
}

::selection {
  background: #ef9334;
  text-shadow: none;
  color: #ffffff;
  border: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-heading-area .sub-title {
  font-size: 16px;
  line-height: 18px;
  color: #424242;
}

.blueColor {
  /*color: #7a77ff;*/
  color:#E82127;
}

.yellowColor {
  color: #cdeb45;
}

.sc-sub-title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  display: block;
  font-family: "Verdana", sans-serif;
}

.p-z-idex {
  position: relative;
  z-index: 3;
}

.margin_top {
  margin-top: -40px;
}

.white-color {
  color: #ffffff;
}

.gradiant-color {
  background: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.white-dark-color {
  color: rgba(255, 255, 255, 0.8);
}

.purple-color {
  /*color: #7215fa;*/
  color:#E82127;
}

.gray-bg {
  background: #fbf8ff;
}

.third-bg {
  background: #17975a;
}

.third_bg {
  color: #17975a;
}

.yellow-bg {
  background: #fefff5;
}

.white-bg {
  background: #fff;
}

.dark-bg-color {
  background: #101621;
}

.primary-color {
  color: #ef9334;
}

.green-color {
  color: #70ef34;
}

.darker-bg {
  background: #f8f8f8;
}

.white-gay-color {
  background: #f0f0f0;
}

.primary-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #ef9334;
}

@media screen and (max-width: 767px) {
  .primary-btn {
    padding: 10px 30px;
  }
}

.primary-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.primary-btn::before,
.primary-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.primary-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.primary-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.primary-btn:hover {
  color: #ffffff;
}

.primary-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.primary-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.primary-btn2 {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #ef9334;
  border: transparent;
}

@media screen and (max-width: 767px) {
  .primary-btn2 {
    padding: 10px 30px;
  }
}

.primary-btn2 span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.primary-btn2::before,
.primary-btn2::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.primary-btn2::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.primary-btn2::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.primary-btn2:hover {
  color: #ffffff;
}

.primary-btn2:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.primary-btn2:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.primary-btn2::before,
.primary-btn2::after {
  background-color: #ffffff;
}

.primary-btn2:hover span {
  color: #ef9334;
}

.submit-button {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  /*background: #7215fa;*/
  background: #E82127;
}

@media screen and (max-width: 767px) {
  .submit-button {
    padding: 10px 30px;
  }
}

.submit-button span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.submit-button::before,
.submit-button::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.submit-button::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.submit-button::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.submit-button:hover {
  color: #ffffff;
}

.submit-button:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.submit-button:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.submit-button::before,
.submit-button::after {
  background-color: #ef9334;
}

.submit-button input {
  color: #ffffff;
  position: relative;
  z-index: 9;
}

.sign-in-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  /*background: #7215fa;*/
  background: #E82127;
}

@media screen and (max-width: 767px) {
  .sign-in-btn {
    padding: 10px 30px;
  }
}

.sign-in-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sign-in-btn::before,
.sign-in-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sign-in-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sign-in-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sign-in-btn:hover {
  color: #ffffff;
}

.sign-in-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sign-in-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sign-in-btn::before,
.sign-in-btn::after {
  background-color: #ffffff;
}

.sign-in-btn:hover span {
  color: #ef9334;
}

.secondary-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #17975a;
  display: block;
}

@media screen and (max-width: 767px) {
  .secondary-btn {
    padding: 10px 30px;
  }
}

.secondary-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.secondary-btn::before,
.secondary-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.secondary-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.secondary-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.secondary-btn:hover {
  color: #ffffff
}

.secondary-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.secondary-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.secondary-btn::before,
.secondary-btn::after {
  background-color: #ef9334;
}

.gradiant-btn {
  background-image: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  border-radius: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding: 18px 50px;
}

.gradiant-btn span {
  color: #ffffff;
}

.gradiant-btn::before,
.gradiant-btn::after {
  background-color: 98.31deg, #eb1d36 8.58%, #b844ff 90.93%;
}

.gradiant-btn:hover span {
  color: #ffffff;
}

.sc-gradiant-btn {
  border-radius: 50px;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 18px;
  transition: 0.4s;
  padding: 18px 50px;
  border: 1px solid #e81f41;
}

.sc-gradiant-btn span {
  color: #e81f41;
}

.sc-gradiant-btn::before,
.sc-gradiant-btn::after {
  background-color: 98.31deg, #eb1d36 8.58%, #b844ff 90.93%;
}

.sc-gradiant-btn .sc-gradiant-active,
.sc-gradiant-btn:hover {
  background-image: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  border-color: transparent;
}

.sc-gradiant-btn .sc-gradiant-active span,
.sc-gradiant-btn:hover span {
  color: #ffffff;
}

.secondary-btn2 {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #17975a;
}

@media screen and (max-width: 767px) {
  .secondary-btn2 {
    padding: 10px 30px;
  }
}

.secondary-btn2 span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.secondary-btn2::before,
.secondary-btn2::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.secondary-btn2::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.secondary-btn2::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.secondary-btn2:hover {
  color: #ffffff;
}

.secondary-btn2:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.secondary-btn2:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.secondary-btn2::before,
.secondary-btn2::after {
  background-color: #ef9334;
}

.price-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #ef9334;
  display: block;
}

@media screen and (max-width: 767px) {
  .price-btn {
    padding: 10px 30px;
  }
}

.price-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.price-btn::before,
.price-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  /*background-color: #7215fa;*/
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.price-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.price-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.price-btn:hover {
  color: #ffffff;
}

.price-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.price-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.third-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #70ef34;
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .third-btn {
    padding: 10px 30px;
  }
}

.third-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.third-btn::before,
.third-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.third-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.third-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.third-btn:hover {
  color: #ffffff;
}

.third-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.third-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.third-btn::before,
.third-btn::after {
  background-color: #E82127;
}

.third-btn span {
  color: #101621;
}

.third-btn:hover span {
  color: #ffffff;
}

.sc-yellow-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #cdeb45;
  position: relative;
  z-index: 3;
  font-weight: 600;
  font-size: 18px;
  padding: 13px 33px;
  color: #353535;
  font-family: "Verdana", sans-serif;
}

@media screen and (max-width: 767px) {

  .third-transparent-btn,
  .sc-yellow-btn,
  .sc-yellow-btn-two {
    padding: 10px 30px;
  }
}


.sc-yellow-btn-two span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-yellow-btn-two::before,
.sc-yellow-btn-two::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-yellow-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-yellow-btn::before,
.sc-yellow-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-yellow-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-yellow-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-yellow-btn:hover {
  color: #ffffff;
}

.sc-yellow-btn:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-btn:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-btn::before,
.sc-yellow-btn::after {
  background-color: #7a77ff;
}

.sc-yellow-btn span {
  color: #101621;
}

.sc-yellow-btn:hover span {
  color: #ffffff;
}

.sc-yellow-btn-two {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #cdeb45;
  position: relative;
  z-index: 3;
  font-weight: 600;
  font-size: 18px;
  padding: 13px 50px;
  color: #353535;
  font-family: "Verdana", sans-serif;
}

.sc-yellow-white-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-yellow-white-btn::before,
.sc-yellow-white-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-yellow-btn-two::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-yellow-btn-two::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-yellow-btn-two:hover {
  color: #ffffff;
}

.sc-yellow-btn-two:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-btn-two:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-btn-two::before,
.sc-yellow-btn-two::after {
  background-color: #7a77ff;
}

.sc-yellow-btn-two span {
  color: #101621;
}

.sc-yellow-btn-two:hover span {
  color: #ffffff;
}

.sc-yellow-white-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #cdeb45;
  position: relative;
  z-index: 3;
  font-weight: 600;
  font-size: 18px;
  padding: 13px 33px;
  color: #353535;
  font-family: "Verdana", sans-serif;
}

@media screen and (max-width: 767px) {
  .sc-yellow-white-btn {
    padding: 10px 30px;
  }
}

.sc-yellow-white-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-yellow-white-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-yellow-white-btn:hover {
  color: #ffffff;
}

.sc-yellow-white-btn:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-white-btn:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-white-btn::before,
.sc-yellow-white-btn::after {
  background-color: #ffffff;
}

.sc-yellow-white-btn span {
  color: #101621;
}

.sc-yellow-white-btn:hover span {
  color: #101621;
}

.sc-white-transparent {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  position: relative;
  z-index: 3;
  font-weight: 600;
  font-size: 18px;
  padding: 13px 33px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-family: "Verdana", sans-serif;
}

@media screen and (max-width: 767px) {
  .sc-white-transparent {
    padding: 10px 30px;
  }
}

.sc-white-transparent span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-white-transparent::before,
.sc-white-transparent::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-white-transparent::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-white-transparent::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-white-transparent:hover {
  color: #ffffff;
}

.sc-white-transparent:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-white-transparent:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.sc-white-transparent::before,
.sc-white-transparent::after {
  background-color: #cdeb45;
}

.sc-white-transparent span {
  color: #ffffff;
}

.sc-white-transparent:hover {
  border-color: transparent;
}

.sc-white-transparent:hover span {
  color: #101621;
}

.sc-yellow-transparent {
  color: #353535;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 13px 33px;
  font-weight: 600;
  font-size: 18px;
  color: #353535;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #353535;
}

@media screen and (max-width: 767px) {
  .sc-yellow-transparent {
    padding: 10px 30px;
  }
}

.sc-yellow-transparent span {
  color: #353535;
  position: relative;
  z-index: 2;
}

.sc-yellow-transparent::before,
.sc-yellow-transparent::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #cdeb45;
  border-radius: 50%;
  color: #ffffff;
}

.sc-yellow-transparent::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-yellow-transparent::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-yellow-transparent:hover {
  color: #ffffff;
  border-color: transparent;
}

.sc-yellow-transparent:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-transparent:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-transparent2 {
  color: #353535;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 13px 33px;
  font-weight: 600;
  font-size: 18px;
  color: #353535;
  font-family: "Verdana", sans-serif;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #ffffff;
}

@media screen and (max-width: 767px) {
  .sc-yellow-transparent2 {
    padding: 10px 30px;
  }
}

.sc-yellow-transparent2 span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-yellow-transparent2::before,
.sc-yellow-transparent2::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #cdeb45;
  border-radius: 50%;
  color: #101621;
}

.sc-yellow-transparent2::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-yellow-transparent2::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-yellow-transparent2:hover {
  border-color: transparent;
}

.sc-yellow-transparent2:hover span {
  color: #101621;
  border-color: transparent;
}

.sc-yellow-transparent2:hover::before {
  animation: criss-cross-left 0.8s both;
  animation-direction: alternate;
}

.sc-yellow-transparent2:hover::after {
  animation: criss-cross-right 0.8s both;
  animation-direction: alternate;
}

.third-transparent-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #70ef34;
}

@media screen and (max-width: 767px) {
  .third-transparent-btn {
    padding: 10px 30px;
  }
}

.third-transparent-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.third-transparent-btn::before,
.third-transparent-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.third-transparent-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.third-transparent-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.third-transparent-btn:hover {
  color: #ffffff;
}

.third-transparent-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.third-transparent-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.third-transparent-btn span {
  color: #101621;
}

.third-transparent-btn::before,
.third-transparent-btn::after {
  background-color: #ffffff;
}

.third-transparent-btn:hover span {
  color: #101621;
}

.apps-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #17975a;
}

@media screen and (max-width: 767px) {
  .apps-btn {
    padding: 10px 30px;
  }
}

.apps-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.apps-btn::before,
.apps-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.apps-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.apps-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.apps-btn:hover {
  color: #ffffff;
}

.apps-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.apps-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.apps-btn::before,
.apps-btn::after {
  background-color: #ffffff;
}

.apps-btn:hover span {
  color: #ef9334;
}

.secondary-bg-btn {
  /*background: #7215fa;*/
  background: #E82127;
}

.secondary-bg-btn::before,
.secondary-bg-btn::after {
  background-color: #ffffff;
}

.secondary-bg-btn:hover span {
  color: #ef9334;
}

.apps-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #101621;
  padding: 15px 35px;
}

@media screen and (max-width: 767px) {
  .apps-btn {
    padding: 10px 30px;
  }
}

.apps-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.apps-btn::before,
.apps-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.apps-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.apps-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.apps-btn:hover {
  color: #ffffff;
}

.apps-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.apps-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.apps-btn::before,
.apps-btn::after {
  background-color: #E82127;
}

.apps-btn span {
  color: #ffffff;
}

.apps-btn i {
  margin-right: 10px;
}

.apps-btn:hover span {
  color: #ffffff;
}

.inner-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #ef9334;
}

@media screen and (max-width: 767px) {
  .inner-btn {
    padding: 10px 30px;
  }
}

.inner-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.inner-btn::before,
.inner-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.inner-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.inner-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.inner-btn:hover {
  color: #ffffff;
}

.inner-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.inner-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.inner-btn::before,
.inner-btn::after {
  background-color: #ffffff;
}

.inner-btn:hover span {
  color: #ef9334;
}

.sc-header-sticky .sign-in-btn::before,
.sc-header-sticky .sign-in-btn::after {
  background-color: #ef9334;
}

.sc-header-sticky .sign-in-btn:hover span {
  color: #ffffff;
}

.sc-header-sticky .inner-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #ef9334;
}

@media screen and (max-width: 767px) {
  .sc-header-sticky .inner-btn {
    padding: 10px 30px;
  }
}

.sc-header-sticky .inner-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-header-sticky .inner-btn::before,
.sc-header-sticky .inner-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-header-sticky .inner-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-header-sticky .inner-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-header-sticky .inner-btn:hover {
  color: #ffffff;
}

.sc-header-sticky .inner-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sc-header-sticky .inner-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sc-header-sticky .inner-btn::before,
.sc-header-sticky .inner-btn::after {
  background-color: #E82127;
}


.sc-header-section3.sc-header-sticky .sticky-btn {
  color: #ffffff;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 15px 50px;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  background: #70ef34;
}

@media screen and (max-width: 767px) {
  .sc-header-section3.sc-header-sticky .sticky-btn {
    padding: 10px 30px;
  }
}

.offcanvas-end {
  width: 350px;
}

@media screen and (max-width: 767px) {
  .sc-header-section3.sc-header-sticky .sticky-btn {
    padding: 10px 30px;
  }
}

.sc-header-section3.sc-header-sticky .sticky-btn span {
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.sc-header-section3.sc-header-sticky .sticky-btn::before,
.sc-header-section3.sc-header-sticky .sticky-btn::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E82127;
  border-radius: 50%;
  color: #ffffff;
}

.sc-header-section3.sc-header-sticky .sticky-btn::before {
  left: -20px;
  transform: translate(-50%, -50%);
}

.sc-header-section3.sc-header-sticky .sticky-btn::after {
  right: -20px;
  transform: translate(50%, -50%);
}

.sc-header-section3.sc-header-sticky .sticky-btn:hover {
  color: #ffffff;
}

.sc-header-section3.sc-header-sticky .sticky-btn:hover::before {
  -webkit-animation: criss-cross-left 0.8s both;
  animation: criss-cross-left 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sc-header-section3.sc-header-sticky .sticky-btn:hover::after {
  -webkit-animation: criss-cross-right 0.8s both;
  animation: criss-cross-right 0.8s both;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.sc-header-section3.sc-header-sticky .sticky-btn span {
  color: #101621;
}

.sc-header-section3.sc-header-sticky .sticky-btn::before,
.sc-header-section3.sc-header-sticky .sticky-btn::after {
  background-color: #E82127;
}

.sc-header-section3.sc-header-sticky .sticky-btn:hover span {
  color: #ffffff;
}


.section-bg-shape {
  position: relative;
}

.section-bg-shape .sc-section-bg .bg-image1 {
  position: absolute;
  top: 0;
  left: 0;
}

.section-bg-shape .sc-section-bg .bg-image2 {
  position: absolute;
  top: 0;
  right: 0;
}

.section-bg-shape .sc-section-bg .bg-image3 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-bg-shape .sc-section-bg .bg-image4 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.section-bg-shape1 {
  position: relative;
}

.section-bg-shape1 .sc-section-bg1 .bg-image5 {
  position: absolute;
  top: 0;
  left: 0;
}

.section-bg-shape1 .sc-section-bg1 .bg-image6 {
  position: absolute;
  top: 120px;
  right: 0;
}

.section-bg-shape1 .sc-section-bg1 .bg-image7 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-bg-shape1 .sc-section-bg1 .bg-image8 {
  position: absolute;
  bottom: -120px;
  right: 0;
}

.sc-section-bg1 .bg-image-5 {
  position: absolute;
  left: 0;
  top: 0;
}

.sc-section-bg1 .bg-image-6 {
  position: absolute;
  right: 0;
  top: 120px;
}

.sc-section-bg1 .bg-image-7 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sc-section-bg1 .bg-image-8 {
  position: absolute;
  right: 0;
  bottom: 0;
}

.section-bg-shape2 {
  position: relative;
}

.section-bg-shape2 .sc-section-bg2 .bg-image9 {
  position: absolute;
  top: 0;
  left: 0;
}

.section-bg-shape2 .sc-section-bg2 .bg-image10 {
  position: absolute;
  top: 100px;
  right: 0;
}

.section-bg-shape2 .sc-section-bg2 .bg-image11 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-bg-shape2 .sc-section-bg2 .bg-image12 {
  position: absolute;
  bottom: -195px;
  right: 0;
}

.section-bg-shape3 {
  position: relative;
}

.section-bg-shape3 .sc-section-bg3 .bg-image13 {
  position: absolute;
  bottom: -195px;
  left: 0;
}

.section-bg-shape3 .sc-section-bg3 .bg-image14 {
  position: absolute;
  bottom: -195px;
  right: 0;
}

@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

.priary-borer {
  border: 1px solid #333;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

/* -----------------------
    17. Scroll Up CSS
--------------------------*/
.customScrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  position: fixed;
  right: 20px;
  z-index: 999;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  background: #ef9334;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 40%);
  transition: 0.4s;
}

.customScrollUp i {
  font-size: 20px;
  color: #fff;
  line-height: 10px;
  position: relative;
  top: 6px;
}

.customScrollUp:hover {
  background-color: #E82127;
}

body .search-modal .modal-content {
  background: transparent;
  position: initial;
  border: 0;
}

body .search-modal .search-block input {
  height: 60px;
  line-height: 60px;
  padding: 0;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
}

body .search-modal .close {
  color: #ffffff;
  background-color: transparent;
  opacity: 1;
  outline: none;
  transition: 0.4s;
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 24px;
  z-index: -1;
  border: none;
}

body .search-modal .close:hover {
  color: #ef9334 !important;
  opacity: 1 !important;
}

body .modal-backdrop {
  opacity: 0.95 !important;
}

.shake-y {
  -webkit-animation: shakeY 5s ease-in-out infinite;
  animation: shakeY 5s ease-in-out infinite;
}

@-webkit-keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.pulse {
  -webkit-animation: pulse 3s ease-in-out infinite;
  animation: pulse 3s ease-in-out infinite;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.skew-spin {
  -webkit-animation: skewSpin 5s linear infinite;
  animation: skewSpin 5s linear infinite;
}

@-webkit-keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }

  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}

@keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }

  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}

.left-right-rotate {
  animation: rotated-style3 2s infinite alternate;
}

@keyframes rotated-style3 {
  0% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
  }
}


/* ============== 02 Spacing CSS ============== */

.sc-padding-0 {
  padding: 0;
}

.sc-margin-0 {
  margin: 0;
}

.sc-pt-0 {
  padding-top: 0;
}

.sc-pt-5 {
  padding-top: 5px;
}

.sc-pt-10 {
  padding-top: 10px;
}

.sc-pt-15 {
  padding-top: 15px;
}

.sc-pt-20 {
  padding-top: 20px;
}

.sc-pt-25 {
  padding-top: 25px;
}

.sc-pt-30 {
  padding-top: 30px;
}

.sc-pt-35 {
  padding-top: 35px;
}

.sc-pt-40 {
  padding-top: 40px;
}

.sc-pt-45 {
  padding-top: 45px;
}

.sc-pt-50 {
  padding-top: 50px;
}

.sc-pt-55 {
  padding-top: 55px;
}

.sc-pt-60 {
  padding-top: 60px;
}

.sc-pt-65 {
  padding-top: 65px;
}

.sc-pt-70 {
  padding-top: 70px;
}

.sc-pt-75 {
  padding-top: 75px;
}

.sc-pt-80 {
  padding-top: 80px;
}

.sc-pt-85 {
  padding-top: 85px;
}

.sc-pt-90 {
  padding-top: 90px;
}

.sc-pt-95 {
  padding-top: 95px;
}

.sc-pt-100 {
  padding-top: 100px;
}

.sc-pt-105 {
  padding-top: 105px;
}

.sc-pt-110 {
  padding-top: 110px;
}

.sc-pt-115 {
  padding-top: 115px;
}

.sc-pt-120 {
  padding-top: 120px;
}

.sc-pt-125 {
  padding-top: 125px;
}

.sc-pt-130 {
  padding-top: 130px;
}

.sc-pt-135 {
  padding-top: 135px;
}

.sc-pt-140 {
  padding-top: 140px;
}

.sc-pt-145 {
  padding-top: 145px;
}

.sc-pt-150 {
  padding-top: 150px;
}

.sc-pt-155 {
  padding-top: 155px;
}

.sc-pt-160 {
  padding-top: 160px;
}

.sc-pt-165 {
  padding-top: 165px;
}

.sc-pt-170 {
  padding-top: 170px;
}

.sc-pt-175 {
  padding-top: 175px;
}

.sc-pt-180 {
  padding-top: 180px;
}

.sc-pt-185 {
  padding-top: 185px;
}

.sc-pt-190 {
  padding-top: 190px;
}

.sc-pt-195 {
  padding-top: 195px;
}

.sc-pt-200 {
  padding-top: 200px;
}

.sc-pt-205 {
  padding-top: 205px;
}

.sc-pt-210 {
  padding-top: 210px;
}

.sc-pb-0 {
  padding-bottom: 0;
}

.sc-pb-5 {
  padding-bottom: 5px;
}

.sc-pb-10 {
  padding-bottom: 10px;
}

.sc-pb-15 {
  padding-bottom: 15px;
}

.sc-pb-20 {
  padding-bottom: 20px;
}

.sc-pb-25 {
  padding-bottom: 25px;
}

.sc-pb-30 {
  padding-bottom: 30px;
}

.sc-pb-35 {
  padding-bottom: 35px;
}

.sc-pb-40 {
  padding-bottom: 40px;
}

.sc-pb-45 {
  padding-bottom: 45px;
}

.sc-pb-50 {
  padding-bottom: 50px;
}

.sc-pb-55 {
  padding-bottom: 55px;
}

.sc-pb-56 {
  padding-bottom: 56px;
}

.sc-pb-57 {
  padding-bottom: 57px;
}

.sc-pb-58 {
  padding-bottom: 58px;
}

.sc-pb-60 {
  padding-bottom: 60px;
}

.sc-pb-65 {
  padding-bottom: 65px;
}

.sc-pb-66 {
  padding-bottom: 66px;
}

.sc-pb-67 {
  padding-bottom: 67px;
}

.sc-pb-68 {
  padding-bottom: 68px;
}

.sc-pb-69 {
  padding-bottom: 69px;
}

.sc-pb-70 {
  padding-bottom: 70px;
}

.sc-pb-71 {
  padding-bottom: 71px;
}

.sc-pb-72 {
  padding-bottom: 72px;
}

.sc-pb-73 {
  padding-bottom: 73px;
}

.sc-pb-74 {
  padding-bottom: 74px;
}

.sc-pb-75 {
  padding-bottom: 75px;
}

.sc-pb-76 {
  padding-bottom: 76px;
}

.sc-pb-77 {
  padding-bottom: 77px;
}

.sc-pb-78 {
  padding-bottom: 78px;
}

.sc-pb-79 {
  padding-bottom: 79px;
}

.sc-pb-80 {
  padding-bottom: 80px;
}

.sc-pb-81 {
  padding-bottom: 81px;
}

.sc-pb-82 {
  padding-bottom: 82px;
}

.sc-pb-83 {
  padding-bottom: 83px;
}

.sc-pb-84 {
  padding-bottom: 84px;
}

.sc-pb-85 {
  padding-bottom: 85px;
}

.sc-pb-86 {
  padding-bottom: 86px;
}

.sc-pb-87 {
  padding-bottom: 87px;
}

.sc-pb-88 {
  padding-bottom: 88px;
}

.sc-pb-89 {
  padding-bottom: 89px;
}

.sc-pb-90 {
  padding-bottom: 90px;
}

.sc-pb-91 {
  padding-bottom: 91px;
}

.sc-pb-92 {
  padding-bottom: 92px;
}

.sc-pb-93 {
  padding-bottom: 93px;
}

.sc-pb-94 {
  padding-bottom: 94px;
}

.sc-pb-95 {
  padding-bottom: 95px;
}

.sc-pb-96 {
  padding-bottom: 96px;
}

.sc-pb-97 {
  padding-bottom: 97px;
}

.sc-pb-98 {
  padding-bottom: 98px;
}

.sc-pb-99 {
  padding-bottom: 99px;
}

.sc-pb-100 {
  padding-bottom: 100px;
}

.sc-pb-101 {
  padding-bottom: 101px;
}

.sc-pb-102 {
  padding-bottom: 102px;
}

.sc-pb-104 {
  padding-bottom: 104px;
}

.sc-pb-105 {
  padding-bottom: 105px;
}

.sc-pb-106 {
  padding-bottom: 106px;
}

.sc-pb-107 {
  padding-bottom: 107px;
}

.sc-pb-108 {
  padding-bottom: 108px;
}

.sc-pb-109 {
  padding-bottom: 109px;
}

.sc-pb-110 {
  padding-bottom: 110px;
}

.sc-pb-111 {
  padding-bottom: 111px;
}

.sc-pb-112 {
  padding-bottom: 112px;
}

.sc-pb-113 {
  padding-bottom: 113px;
}

.sc-pb-114 {
  padding-bottom: 114px;
}

.sc-pb-115 {
  padding-bottom: 115px;
}

.sc-pb-116 {
  padding-bottom: 116px;
}

.sc-pb-117 {
  padding-bottom: 117px;
}

.sc-pb-118 {
  padding-bottom: 118px;
}

.sc-pb-119 {
  padding-bottom: 119px;
}

.sc-pb-120 {
  padding-bottom: 120px;
}

.sc-pb-121 {
  padding-bottom: 121px;
}

.sc-pb-122 {
  padding-bottom: 122px;
}

.sc-pb-123 {
  padding-bottom: 123px;
}

.sc-pb-124 {
  padding-bottom: 124px;
}

.sc-pb-125 {
  padding-bottom: 125px;
}

.sc-pb-126 {
  padding-bottom: 126px;
}

.sc-pb-127 {
  padding-bottom: 127px;
}

.sc-pb-128 {
  padding-bottom: 128px;
}

.sc-pb-129 {
  padding-bottom: 129px;
}

.sc-pb-130 {
  padding-bottom: 130px;
}

.sc-pb-131 {
  padding-bottom: 131px;
}

.sc-pb-132 {
  padding-bottom: 132px;
}

.sc-pb-133 {
  padding-bottom: 133px;
}

.sc-pb-134 {
  padding-bottom: 134px;
}

.sc-pb-135 {
  padding-bottom: 135px;
}

.sc-pb-136 {
  padding-bottom: 136px;
}

.sc-pb-137 {
  padding-bottom: 137px;
}

.sc-pb-138 {
  padding-bottom: 138px;
}

.sc-pb-139 {
  padding-bottom: 139px;
}

.sc-pb-140 {
  padding-bottom: 140px;
}

.sc-pb-141 {
  padding-bottom: 141px;
}

.sc-pb-142 {
  padding-bottom: 142px;
}

.sc-pb-143 {
  padding-bottom: 143px;
}

.sc-pb-144 {
  padding-bottom: 144px;
}

.sc-pb-145 {
  padding-bottom: 145px;
}

.sc-pb-146 {
  padding-bottom: 146px;
}

.sc-pb-147 {
  padding-bottom: 147px;
}

.sc-pb-148 {
  padding-bottom: 148px;
}

.sc-pb-149 {
  padding-bottom: 149px;
}

.sc-pb-150 {
  padding-bottom: 150px;
}

.sc-pb-151 {
  padding-bottom: 151px;
}

.sc-pb-152 {
  padding-bottom: 152px;
}

.sc-pb-153 {
  padding-bottom: 153px;
}

.sc-pb-154 {
  padding-bottom: 154px;
}

.sc-pb-155 {
  padding-bottom: 155px;
}

.sc-pb-156 {
  padding-bottom: 156px;
}

.sc-pb-157 {
  padding-bottom: 157px;
}

.sc-pb-158 {
  padding-bottom: 158px;
}

.sc-pb-159 {
  padding-bottom: 159px;
}

.sc-pb-160 {
  padding-bottom: 160px;
}

.sc-pb-161 {
  padding-bottom: 161px;
}

.sc-pb-162 {
  padding-bottom: 162px;
}

.sc-pb-163 {
  padding-bottom: 163px;
}

.sc-pb-164 {
  padding-bottom: 164px;
}

.sc-pb-165 {
  padding-bottom: 165px;
}

.sc-pb-166 {
  padding-bottom: 166px;
}

.sc-pb-167 {
  padding-bottom: 167px;
}

.sc-pb-168 {
  padding-bottom: 168px;
}

.sc-pb-169 {
  padding-bottom: 169px;
}

.sc-pb-170 {
  padding-bottom: 170px;
}

.sc-pb-171 {
  padding-bottom: 171px;
}

.sc-pb-172 {
  padding-bottom: 172px;
}

.sc-pb-173 {
  padding-bottom: 173px;
}

.sc-pb-174 {
  padding-bottom: 174px;
}

.sc-pb-175 {
  padding-bottom: 175px;
}

.sc-pb-176 {
  padding-bottom: 176px;
}

.sc-pb-177 {
  padding-bottom: 177px;
}

.sc-pb-178 {
  padding-bottom: 178px;
}

.sc-pb-179 {
  padding-bottom: 179px;
}

.sc-pb-180 {
  padding-bottom: 180px;
}

.sc-pb-181 {
  padding-bottom: 181px;
}

.sc-pb-182 {
  padding-bottom: 182px;
}

.sc-pb-183 {
  padding-bottom: 183px;
}

.sc-pb-184 {
  padding-bottom: 184px;
}

.sc-pb-185 {
  padding-bottom: 185px;
}

.sc-pb-186 {
  padding-bottom: 186px;
}

.sc-pb-187 {
  padding-bottom: 187px;
}

.sc-pb-188 {
  padding-bottom: 188px;
}

.sc-pb-189 {
  padding-bottom: 189px;
}

.sc-pb-190 {
  padding-bottom: 190px;
}

.sc-pb-191 {
  padding-bottom: 191px;
}

.sc-pb-192 {
  padding-bottom: 192px;
}

.sc-pb-193 {
  padding-bottom: 193px;
}

.sc-pb-194 {
  padding-bottom: 194px;
}

.sc-pb-195 {
  padding-bottom: 195px;
}

.sc-pb-196 {
  padding-bottom: 196px;
}

.sc-pb-197 {
  padding-bottom: 197px;
}

.sc-pb-198 {
  padding-bottom: 198px;
}

.sc-pb-199 {
  padding-bottom: 199px;
}

.sc-pb-200 {
  padding-bottom: 200px;
}

.sc-pb-210 {
  padding-bottom: 210px;
}

.sc-pb-220 {
  padding-bottom: 220px;
}

.sc-pr-0 {
  padding-right: 0;
}

.sc-pr-1 {
  padding-right: 1px;
}

.sc-pr-2 {
  padding-right: 2px;
}

.sc-pr-4 {
  padding-right: 4px;
}

.sc-pr-5 {
  padding-right: 5px;
}

.sc-pr-6 {
  padding-right: 6px;
}

.sc-pr-7 {
  padding-right: 7px;
}

.sc-pr-8 {
  padding-right: 8px;
}

.sc-pr-9 {
  padding-right: 9px;
}

.sc-pr-10 {
  padding-right: 10px;
}

.sc-pr-11 {
  padding-right: 11px;
}

.sc-pr-12 {
  padding-right: 12px;
}

.sc-pr-13 {
  padding-right: 13px;
}

.sc-pr-14 {
  padding-right: 14px;
}

.sc-pr-15 {
  padding-right: 15px;
}

.sc-pr-16 {
  padding-right: 16px;
}

.sc-pr-17 {
  padding-right: 17px;
}

.sc-pr-18 {
  padding-right: 18px;
}

.sc-pr-19 {
  padding-right: 19px;
}

.sc-pr-20 {
  padding-right: 20px;
}

.sc-pr-21 {
  padding-right: 21px;
}

.sc-pr-22 {
  padding-right: 22px;
}

.sc-pr-23 {
  padding-right: 23px;
}

.sc-pr-24 {
  padding-right: 24px;
}

.sc-pr-25 {
  padding-right: 25px;
}

.sc-pr-26 {
  padding-right: 26px;
}

.sc-pr-27 {
  padding-right: 27px;
}

.sc-pr-28 {
  padding-right: 28px;
}

.sc-pr-29 {
  padding-right: 29px;
}

.sc-pr-30 {
  padding-right: 30px;
}

.sc-pr-31 {
  padding-right: 31px;
}

.sc-pr-32 {
  padding-right: 32px;
}

.sc-pr-33 {
  padding-right: 33px;
}

.sc-pr-34 {
  padding-right: 34px;
}

.sc-pr-35 {
  padding-right: 35px;
}

.sc-pr-36 {
  padding-right: 36px;
}

.sc-pr-37 {
  padding-right: 37px;
}

.sc-pr-38 {
  padding-right: 38px;
}

.sc-pr-39 {
  padding-right: 39px;
}

.sc-pr-40 {
  padding-right: 40px;
}

.sc-pr-41 {
  padding-right: 41px;
}

.sc-pr-42 {
  padding-right: 42px;
}

.sc-pr-43 {
  padding-right: 43px;
}

.sc-pr-44 {
  padding-right: 44px;
}

.sc-pr-45 {
  padding-right: 45px;
}

.sc-pr-46 {
  padding-right: 46px;
}

.sc-pr-47 {
  padding-right: 47px;
}

.sc-pr-48 {
  padding-right: 48px;
}

.sc-pr-49 {
  padding-right: 49px;
}

.sc-pr-50 {
  padding-right: 50px;
}

.sc-pr-51 {
  padding-right: 51px;
}

.sc-pr-52 {
  padding-right: 52px;
}

.sc-pr-53 {
  padding-right: 53px;
}

.sc-pr-54 {
  padding-right: 54px;
}

.sc-pr-55 {
  padding-right: 55px;
}

.sc-pr-56 {
  padding-right: 56px;
}

.sc-pr-57 {
  padding-right: 57px;
}

.sc-pr-58 {
  padding-right: 58px;
}

.sc-pr-59 {
  padding-right: 59px;
}

.sc-pr-60 {
  padding-right: 60px;
}

.sc-pr-61 {
  padding-right: 61px;
}

.sc-pr-62 {
  padding-right: 62px;
}

.sc-pr-63 {
  padding-right: 63px;
}

.sc-pr-64 {
  padding-right: 64px;
}

.sc-pr-65 {
  padding-right: 65px;
}

.sc-pr-66 {
  padding-right: 66px;
}

.sc-pr-67 {
  padding-right: 67px;
}

.sc-pr-68 {
  padding-right: 68px;
}

.sc-pr-69 {
  padding-right: 69px;
}

.sc-pr-70 {
  padding-right: 70px;
}

.sc-pr-71 {
  padding-right: 71px;
}

.sc-pr-72 {
  padding-right: 72px;
}

.sc-pr-73 {
  padding-right: 73px;
}

.sc-pr-74 {
  padding-right: 74px;
}

.sc-pr-75 {
  padding-right: 75px;
}

.sc-pr-76 {
  padding-right: 76px;
}

.sc-pr-77 {
  padding-right: 77px;
}

.sc-pr-78 {
  padding-right: 78px;
}

.sc-pr-79 {
  padding-right: 79px;
}

.sc-pr-80 {
  padding-right: 80px;
}

.sc-pr-81 {
  padding-right: 81px;
}

.sc-pr-82 {
  padding-right: 82px;
}

.sc-pr-83 {
  padding-right: 83px;
}

.sc-pr-84 {
  padding-right: 84px;
}

.sc-pr-85 {
  padding-right: 85px;
}

.sc-pr-86 {
  padding-right: 86px;
}

.sc-pr-87 {
  padding-right: 87px;
}

.sc-pr-88 {
  padding-right: 88px;
}

.sc-pr-89 {
  padding-right: 89px;
}

.sc-pr-90 {
  padding-right: 90px;
}

.sc-pr-91 {
  padding-right: 91px;
}

.sc-pr-92 {
  padding-right: 92px;
}

.sc-pr-93 {
  padding-right: 93px;
}

.sc-pr-94 {
  padding-right: 94px;
}

.sc-pr-95 {
  padding-right: 95px;
}

.sc-pr-96 {
  padding-right: 96px;
}

.sc-pr-97 {
  padding-right: 97px;
}

.sc-pr-98 {
  padding-right: 98px;
}

.sc-pr-99 {
  padding-right: 99px;
}

.sc-pr-100 {
  padding-right: 100px;
}

.sc-pr-105 {
  padding-right: 105px;
}

.sc-pr-106 {
  padding-right: 106px;
}

.sc-pr-107 {
  padding-right: 107px;
}

.sc-pr-108 {
  padding-right: 108px;
}

.sc-pr-109 {
  padding-right: 109px;
}

.sc-pr-110 {
  padding-right: 110px;
}

.sc-pr-111 {
  padding-right: 111px;
}

.sc-pr-112 {
  padding-right: 112px;
}

.sc-pr-113 {
  padding-right: 113px;
}

.sc-pr-114 {
  padding-right: 114px;
}

.sc-pr-115 {
  padding-right: 115px;
}

.sc-pr-116 {
  padding-right: 116px;
}

.sc-pr-117 {
  padding-right: 117px;
}

.sc-pr-118 {
  padding-right: 118px;
}

.sc-pr-119 {
  padding-right: 119px;
}

.sc-pr-120 {
  padding-right: 120px;
}

.sc-pr-121 {
  padding-right: 121px;
}

.sc-pr-122 {
  padding-right: 122px;
}

.sc-pr-123 {
  padding-right: 123px;
}

.sc-pr-124 {
  padding-right: 124px;
}

.sc-pr-125 {
  padding-right: 125px;
}

.sc-pr-126 {
  padding-right: 126px;
}

.sc-pr-127 {
  padding-right: 127px;
}

.sc-pr-128 {
  padding-right: 128px;
}

.sc-pr-129 {
  padding-right: 129px;
}

.sc-pr-130 {
  padding-right: 130px;
}

.sc-pr-131 {
  padding-right: 131px;
}

.sc-pr-132 {
  padding-right: 132px;
}

.sc-pr-133 {
  padding-right: 133px;
}

.sc-pr-134 {
  padding-right: 134px;
}

.sc-pr-135 {
  padding-right: 135px;
}

.sc-pr-136 {
  padding-right: 136px;
}

.sc-pr-137 {
  padding-right: 137px;
}

.sc-pr-138 {
  padding-right: 138px;
}

.sc-pr-139 {
  padding-right: 139px;
}

.sc-pr-140 {
  padding-right: 140px;
}

.sc-pr-141 {
  padding-right: 141px;
}

.sc-pr-142 {
  padding-right: 142px;
}

.sc-pr-143 {
  padding-right: 143px;
}

.sc-pr-144 {
  padding-right: 144px;
}

.sc-pr-145 {
  padding-right: 145px;
}

.sc-pr-146 {
  padding-right: 146px;
}

.sc-pr-147 {
  padding-right: 147px;
}

.sc-pr-148 {
  padding-right: 148px;
}

.sc-pr-149 {
  padding-right: 149px;
}

.sc-pr-150 {
  padding-right: 150px;
}

.sc-pr-151 {
  padding-right: 151px;
}

.sc-pr-152 {
  padding-right: 152px;
}

.sc-pr-153 {
  padding-right: 153px;
}

.sc-pr-154 {
  padding-right: 154px;
}

.sc-pr-155 {
  padding-right: 155px;
}

.sc-pr-156 {
  padding-right: 156px;
}

.sc-pr-157 {
  padding-right: 157px;
}

.sc-pr-158 {
  padding-right: 158px;
}

.sc-pr-159 {
  padding-right: 159px;
}

.sc-pr-160 {
  padding-right: 160px;
}

.sc-pr-161 {
  padding-right: 161px;
}

.sc-pr-162 {
  padding-right: 162px;
}

.sc-pr-163 {
  padding-right: 163px;
}

.sc-pr-164 {
  padding-right: 164px;
}

.sc-pr-165 {
  padding-right: 165px;
}

.sc-pr-166 {
  padding-right: 166px;
}

.sc-pr-167 {
  padding-right: 167px;
}

.sc-pr-168 {
  padding-right: 168px;
}

.sc-pr-169 {
  padding-right: 169px;
}

.sc-pr-170 {
  padding-right: 170px;
}

.sc-pr-171 {
  padding-right: 171px;
}

.sc-pr-172 {
  padding-right: 172px;
}

.sc-pr-173 {
  padding-right: 173px;
}

.sc-pr-174 {
  padding-right: 174px;
}

.sc-pr-175 {
  padding-right: 175px;
}

.sc-pr-176 {
  padding-right: 176px;
}

.sc-pr-177 {
  padding-right: 177px;
}

.sc-pr-178 {
  padding-right: 178px;
}

.sc-pr-179 {
  padding-right: 179px;
}

.sc-pr-180 {
  padding-right: 180px;
}

.sc-pr-181 {
  padding-right: 181px;
}

.sc-pr-182 {
  padding-right: 182px;
}

.sc-pr-183 {
  padding-right: 183px;
}

.sc-pr-184 {
  padding-right: 184px;
}

.sc-pr-185 {
  padding-right: 185px;
}

.sc-pr-186 {
  padding-right: 186px;
}

.sc-pr-187 {
  padding-right: 187px;
}

.sc-pr-188 {
  padding-right: 188px;
}

.sc-pr-189 {
  padding-right: 189px;
}

.sc-pr-190 {
  padding-right: 190px;
}

.sc-pr-191 {
  padding-right: 191px;
}

.sc-pr-192 {
  padding-right: 192px;
}

.sc-pr-193 {
  padding-right: 193px;
}

.sc-pr-194 {
  padding-right: 194px;
}

.sc-pr-195 {
  padding-right: 195px;
}

.sc-pr-196 {
  padding-right: 196px;
}

.sc-pr-197 {
  padding-right: 197px;
}

.sc-pr-198 {
  padding-right: 198px;
}

.sc-pr-199 {
  padding-right: 199px;
}

.sc-pr-200 {
  padding-right: 200px;
}

.sc-pl-0 {
  padding-left: 0;
}

.sc-pl-1 {
  padding-left: 1px;
}

.sc-pl-2 {
  padding-left: 2px;
}

.sc-pb-3 {
  padding-bottom: 3px;
}

.sc-pl-4 {
  padding-left: 4px;
}

.sc-pl-5 {
  padding-left: 5px !important;
}

.sc-pl-6 {
  padding-left: 6px;
}

.sc-pl-7 {
  padding-left: 7px;
}

.sc-pl-8 {
  padding-left: 8px;
}

.sc-pl-9 {
  padding-left: 9px;
}

.sc-pl-10 {
  padding-left: 10px;
}

.sc-pl-11 {
  padding-left: 11px;
}

.sc-pl-12 {
  padding-left: 12px;
}

.sc-pl-13 {
  padding-left: 13px;
}

.sc-pl-14 {
  padding-left: 14px;
}

.sc-pl-15 {
  padding-left: 15px;
}

.sc-pl-16 {
  padding-left: 16px;
}

.sc-pl-17 {
  padding-left: 17px;
}

.sc-pl-18 {
  padding-left: 18px;
}

.sc-pl-19 {
  padding-left: 19px;
}

.sc-pl-20 {
  padding-left: 20px;
}

.sc-pl-21 {
  padding-left: 21px;
}

.sc-pl-22 {
  padding-left: 22px;
}

.sc-pl-23 {
  padding-left: 23px;
}

.sc-pl-24 {
  padding-left: 24px;
}

.sc-pl-25 {
  padding-left: 25px;
}

.sc-pl-26 {
  padding-left: 26px;
}

.sc-pl-27 {
  padding-left: 27px;
}

.sc-pl-28 {
  padding-left: 28px;
}

.sc-pl-29 {
  padding-left: 29px;
}

.sc-pl-30 {
  padding-left: 30px;
}

.sc-pl-31 {
  padding-left: 31px;
}

.sc-pl-32 {
  padding-left: 32px;
}

.sc-pl-33 {
  padding-left: 33px;
}

.sc-pl-34 {
  padding-left: 34px;
}

.sc-pl-35 {
  padding-left: 35px;
}

.sc-pl-36 {
  padding-left: 36px;
}

.sc-pl-37 {
  padding-left: 37px;
}

.sc-pl-38 {
  padding-left: 38px;
}

.sc-pl-39 {
  padding-left: 39px;
}

.sc-pl-40 {
  padding-left: 40px;
}

.sc-pl-41 {
  padding-left: 41px;
}

.sc-pl-42 {
  padding-left: 42px;
}

.sc-pl-43 {
  padding-left: 43px;
}

.sc-pl-44 {
  padding-left: 44px;
}

.sc-pl-45 {
  padding-left: 45px;
}

.sc-pl-46 {
  padding-left: 46px;
}

.sc-pl-47 {
  padding-left: 47px;
}

.sc-pl-48 {
  padding-left: 48px;
}

.sc-pl-49 {
  padding-left: 49px;
}

.sc-pl-50 {
  padding-left: 50px;
}

.sc-pl-51 {
  padding-left: 51px;
}

.sc-pl-52 {
  padding-left: 52px;
}

.sc-pl-53 {
  padding-left: 53px;
}

.sc-pl-54 {
  padding-left: 54px;
}

.sc-pl-55 {
  padding-left: 55px;
}

.sc-pl-56 {
  padding-left: 56px;
}

.sc-pl-57 {
  padding-left: 57px;
}

.sc-pl-58 {
  padding-left: 58px;
}

.sc-pl-59 {
  padding-left: 59px;
}

.sc-pl-60 {
  padding-left: 60px;
}

.sc-pl-61 {
  padding-left: 61px;
}

.sc-pl-62 {
  padding-left: 62px;
}

.sc-pl-63 {
  padding-left: 63px;
}

.sc-pl-64 {
  padding-left: 64px;
}

.sc-pl-65 {
  padding-left: 65px;
}

.sc-pl-66 {
  padding-left: 66px;
}

.sc-pl-67 {
  padding-left: 67px;
}

.sc-pl-68 {
  padding-left: 68px;
}

.sc-pl-69 {
  padding-left: 69px;
}

.sc-pl-70 {
  padding-left: 70px;
}

.sc-pl-71 {
  padding-left: 71px;
}

.sc-pl-72 {
  padding-left: 72px;
}

.sc-pl-73 {
  padding-left: 73px;
}

.sc-pl-74 {
  padding-left: 74px;
}

.sc-pl-75 {
  padding-left: 75px;
}

.sc-pl-76 {
  padding-left: 76px;
}

.sc-pl-77 {
  padding-left: 77px;
}

.sc-pl-78 {
  padding-left: 78px;
}

.sc-pl-79 {
  padding-left: 79px;
}

.sc-pl-80 {
  padding-left: 80px;
}

.sc-pl-81 {
  padding-left: 81px;
}

.sc-pl-82 {
  padding-left: 82px;
}

.sc-pl-83 {
  padding-left: 83px;
}

.sc-pl-84 {
  padding-left: 84px;
}

.sc-pl-85 {
  padding-left: 85px;
}

.sc-pl-86 {
  padding-left: 86px;
}

.sc-pl-87 {
  padding-left: 87px;
}

.sc-pl-88 {
  padding-left: 88px;
}

.sc-pl-89 {
  padding-left: 89px;
}

.sc-pl-90 {
  padding-left: 90px;
}

.sc-pl-91 {
  padding-left: 91px;
}

.sc-pl-92 {
  padding-left: 92px;
}

.sc-pl-93 {
  padding-left: 93px;
}

.sc-pl-94 {
  padding-left: 94px;
}

.sc-pl-95 {
  padding-left: 95px;
}

.sc-pl-96 {
  padding-left: 96px;
}

.sc-pl-97 {
  padding-left: 97px;
}

.sc-pl-98 {
  padding-left: 98px;
}

.sc-pl-99 {
  padding-left: 99px;
}

.sc-pl-100 {
  padding-left: 100px;
}

.sc-pl-101 {
  padding-left: 101px;
}

.sc-pl-102 {
  padding-left: 102px;
}

.sc-pb-103 {
  padding-bottom: 103px;
}

.sc-pl-104 {
  padding-left: 104px;
}

.sc-pl-105 {
  padding-left: 105px;
}

.sc-pl-106 {
  padding-left: 106px;
}

.sc-pl-107 {
  padding-left: 107px;
}

.sc-pl-108 {
  padding-left: 108px;
}

.sc-pl-109 {
  padding-left: 109px;
}

.sc-pl-110 {
  padding-left: 110px;
}

.sc-pl-111 {
  padding-left: 111px;
}

.sc-pl-112 {
  padding-left: 112px;
}

.sc-pl-113 {
  padding-left: 113px;
}

.sc-pl-114 {
  padding-left: 114px;
}

.sc-pl-115 {
  padding-left: 115px;
}

.sc-pl-116 {
  padding-left: 116px;
}

.sc-pl-117 {
  padding-left: 117px;
}

.sc-pl-118 {
  padding-left: 118px;
}

.sc-pl-119 {
  padding-left: 119px;
}

.sc-pl-120 {
  padding-left: 120px;
}

.sc-pl-121 {
  padding-left: 121px;
}

.sc-pl-122 {
  padding-left: 122px;
}

.sc-pl-123 {
  padding-left: 123px;
}

.sc-pl-124 {
  padding-left: 124px;
}

.sc-pl-125 {
  padding-left: 125px;
}

.sc-pl-126 {
  padding-left: 126px;
}

.sc-pl-127 {
  padding-left: 127px;
}

.sc-pl-128 {
  padding-left: 128px;
}

.sc-pl-129 {
  padding-left: 129px;
}

.sc-pl-130 {
  padding-left: 130px;
}

.sc-pl-131 {
  padding-left: 131px;
}

.sc-pl-132 {
  padding-left: 132px;
}

.sc-pl-133 {
  padding-left: 133px;
}

.sc-pl-134 {
  padding-left: 134px;
}

.sc-pl-135 {
  padding-left: 135px;
}

.sc-pl-136 {
  padding-left: 136px;
}

.sc-pl-137 {
  padding-left: 137px;
}

.sc-pl-138 {
  padding-left: 138px;
}

.sc-pl-139 {
  padding-left: 139px;
}

.sc-pl-140 {
  padding-left: 140px;
}

.sc-pl-141 {
  padding-left: 141px;
}

.sc-pl-142 {
  padding-left: 142px;
}

.sc-pl-143 {
  padding-left: 143px;
}

.sc-pl-144 {
  padding-left: 144px;
}

.sc-pl-145 {
  padding-left: 145px;
}

.sc-pl-146 {
  padding-left: 146px;
}

.sc-pl-147 {
  padding-left: 147px;
}

.sc-pl-148 {
  padding-left: 148px;
}

.sc-pl-149 {
  padding-left: 149px;
}

.sc-pl-150 {
  padding-left: 150px;
}

.sc-pl-151 {
  padding-left: 151px;
}

.sc-pl-152 {
  padding-left: 152px;
}

.sc-pl-153 {
  padding-left: 153px;
}

.sc-pl-154 {
  padding-left: 154px;
}

.sc-pl-155 {
  padding-left: 155px;
}

.sc-pl-156 {
  padding-left: 156px;
}

.sc-pl-157 {
  padding-left: 157px;
}

.sc-pl-158 {
  padding-left: 158px;
}

.sc-pl-159 {
  padding-left: 159px;
}

.sc-pl-160 {
  padding-left: 160px;
}

.sc-pl-161 {
  padding-left: 161px;
}

.sc-pl-162 {
  padding-left: 162px;
}

.sc-pl-163 {
  padding-left: 163px;
}

.sc-pl-164 {
  padding-left: 164px;
}

.sc-pl-165 {
  padding-left: 165px;
}

.sc-pl-166 {
  padding-left: 166px;
}

.sc-pl-167 {
  padding-left: 167px;
}

.sc-pl-168 {
  padding-left: 168px;
}

.sc-pl-169 {
  padding-left: 169px;
}

.sc-pl-170 {
  padding-left: 170px;
}

.sc-pl-171 {
  padding-left: 171px;
}

.sc-pl-172 {
  padding-left: 172px;
}

.sc-pl-173 {
  padding-left: 173px;
}

.sc-pl-174 {
  padding-left: 174px;
}

.sc-pl-175 {
  padding-left: 175px;
}

.sc-pl-176 {
  padding-left: 176px;
}

.sc-pl-177 {
  padding-left: 177px;
}

.sc-pl-178 {
  padding-left: 178px;
}

.sc-pl-179 {
  padding-left: 179px;
}

.sc-pl-180 {
  padding-left: 180px;
}

.sc-pl-181 {
  padding-left: 181px;
}

.sc-pl-182 {
  padding-left: 182px;
}

.sc-pl-183 {
  padding-left: 183px;
}

.sc-pl-184 {
  padding-left: 184px;
}

.sc-pl-185 {
  padding-left: 185px;
}

.sc-pl-186 {
  padding-left: 186px;
}

.sc-pl-187 {
  padding-left: 187px;
}

.sc-pl-188 {
  padding-left: 188px;
}

.sc-pl-189 {
  padding-left: 189px;
}

.sc-pl-190 {
  padding-left: 190px;
}

.sc-pl-191 {
  padding-left: 191px;
}

.sc-pl-192 {
  padding-left: 192px;
}

.sc-pl-193 {
  padding-left: 193px;
}

.sc-pl-194 {
  padding-left: 194px;
}

.sc-pl-195 {
  padding-left: 195px;
}

.sc-pl-196 {
  padding-left: 196px;
}

.sc-pl-197 {
  padding-left: 197px;
}

.sc-pl-198 {
  padding-left: 198px;
}

.sc-pl-199 {
  padding-left: 199px;
}

.sc-pl-200 {
  padding-left: 200px;
}

.ml--30 {
  margin-left: -30px;
}

.ml--40 {
  margin-left: -40px;
}

.sc-mt-0 {
  margin-top: 0;
}

.sc-mt-1 {
  margin-top: 1px;
}

.sc-mt-2 {
  margin-top: 2px;
}

.sc-mt-3 {
  margin-top: 3px;
}

.sc-mt-4 {
  margin-top: 4px;
}

.sc-mt-5 {
  margin-top: 5px;
}

.sc-mt-6 {
  margin-top: 6px;
}

.sc-mt-7 {
  margin-top: 7px;
}

.sc-mt-8 {
  margin-top: 8px;
}

.sc-mt-9 {
  margin-top: 9px;
}

.sc-mt-10 {
  margin-top: 10px;
}

.sc-mt-11 {
  margin-top: 11px;
}

.sc-mt-12 {
  margin-top: 12px;
}

.sc-mt-13 {
  margin-top: 13px;
}

.sc-mt-14 {
  margin-top: 14px;
}

.sc-mt-15 {
  margin-top: 15px;
}

.sc-mt-16 {
  margin-top: 16px;
}

.sc-mt-17 {
  margin-top: 17px;
}

.sc-mt-18 {
  margin-top: 18px;
}

.sc-mt-19 {
  margin-top: 19px;
}

.sc-mt-20 {
  margin-top: 20px;
}

.sc-mt-21 {
  margin-top: 21px;
}

.sc-mt-22 {
  margin-top: 22px;
}

.sc-mt-23 {
  margin-top: 23px;
}

.sc-mt-24 {
  margin-top: 24px;
}

.sc-mt-25 {
  margin-top: 25px;
}

.sc-mt-26 {
  margin-top: 26px;
}

.sc-mt-27 {
  margin-top: 27px;
}

.sc-mt-28 {
  margin-top: 28px;
}

.sc-mt-29 {
  margin-top: 29px;
}

.sc-mt-30 {
  margin-top: 30px;
}

.sc-mt-31 {
  margin-top: 31px;
}

.sc-mt-32 {
  margin-top: 32px;
}

.sc-mt-33 {
  margin-top: 33px;
}

.sc-mt-34 {
  margin-top: 34px;
}

.sc-mt-35 {
  margin-top: 35px;
}

.sc-mt-36 {
  margin-top: 36px;
}

.sc-mt-37 {
  margin-top: 37px;
}

.sc-mt-38 {
  margin-top: 38px;
}

.sc-mt-39 {
  margin-top: 39px;
}

.sc-mt-40 {
  margin-top: 40px;
}

.sc-mt-41 {
  margin-top: 41px;
}

.sc-mt-42 {
  margin-top: 42px;
}

.sc-mt-43 {
  margin-top: 43px;
}

.sc-mt-44 {
  margin-top: 44px;
}

.sc-mt-45 {
  margin-top: 45px;
}

.sc-mt-46 {
  margin-top: 46px;
}

.sc-mt-47 {
  margin-top: 47px;
}

.sc-mt-48 {
  margin-top: 48px;
}

.sc-mt-49 {
  margin-top: 49px;
}

.sc-mt-50 {
  margin-top: 50px;
}

.sc-mt-51 {
  margin-top: 51px;
}

.sc-mt-52 {
  margin-top: 52px;
}

.sc-mt-53 {
  margin-top: 53px;
}

.sc-mt-54 {
  margin-top: 54px;
}

.sc-mt-55 {
  margin-top: 55px;
}

.sc-mt-56 {
  margin-top: 56px;
}

.sc-mt-57 {
  margin-top: 57px;
}

.sc-mt-58 {
  margin-top: 58px;
}

.sc-mt-59 {
  margin-top: 59px;
}

.sc-mt-60 {
  margin-top: 60px;
}

.sc-mt-61 {
  margin-top: 61px;
}

.sc-mt-62 {
  margin-top: 62px;
}

.sc-mt-63 {
  margin-top: 63px;
}

.sc-mt-64 {
  margin-top: 64px;
}

.sc-mt-65 {
  margin-top: 65px;
}

.sc-mt-66 {
  margin-top: 66px;
}

.sc-mt-67 {
  margin-top: 67px;
}

.sc-mt-68 {
  margin-top: 68px;
}

.sc-mt-69 {
  margin-top: 69px;
}

.sc-mt-70 {
  margin-top: 70px;
}

.sc-mt-71 {
  margin-top: 71px;
}

.sc-mt-72 {
  margin-top: 72px;
}

.sc-mt-73 {
  margin-top: 73px;
}

.sc-mt-74 {
  margin-top: 74px;
}

.sc-mt-75 {
  margin-top: 75px;
}

.sc-mt-76 {
  margin-top: 76px;
}

.sc-mt-77 {
  margin-top: 77px;
}

.sc-mt-78 {
  margin-top: 78px;
}

.sc-mt-79 {
  margin-top: 79px;
}

.sc-mt-80 {
  margin-top: 80px;
}

.sc-mt-81 {
  margin-top: 81px;
}

.sc-mt-82 {
  margin-top: 82px;
}

.sc-mt-83 {
  margin-top: 83px;
}

.sc-mt-84 {
  margin-top: 84px;
}

.sc-mt-85 {
  margin-top: 85px;
}

.sc-mt-86 {
  margin-top: 86px;
}

.sc-mt-87 {
  margin-top: 87px;
}

.sc-mt-88 {
  margin-top: 88px;
}

.sc-mt-89 {
  margin-top: 89px;
}

.sc-mt-90 {
  margin-top: 90px;
}

.sc-mt-91 {
  margin-top: 91px;
}

.sc-mt-92 {
  margin-top: 92px;
}

.sc-mt-93 {
  margin-top: 93px;
}

.sc-mt-94 {
  margin-top: 94px;
}

.sc-mt-95 {
  margin-top: 95px;
}

.sc-mt-96 {
  margin-top: 96px;
}

.sc-mt-97 {
  margin-top: 97px;
}

.sc-mt-98 {
  margin-top: 98px;
}

.sc-mt-99 {
  margin-top: 99px;
}

.sc-mt-100 {
  margin-top: 100px;
}

.sc-mt-101 {
  margin-top: 101px;
}

.sc-mt-102 {
  margin-top: 102px;
}

.sc-mt-103 {
  margin-top: 103px;
}

.sc-mt-104 {
  margin-top: 104px;
}

.sc-mt-105 {
  margin-top: 105px;
}

.sc-mt-106 {
  margin-top: 106px;
}

.sc-mt-107 {
  margin-top: 107px;
}

.sc-mt-108 {
  margin-top: 108px;
}

.sc-mt-109 {
  margin-top: 109px;
}

.sc-mt-110 {
  margin-top: 110px;
}

.sc-mt-111 {
  margin-top: 111px;
}

.sc-mt-112 {
  margin-top: 112px;
}

.sc-mt-113 {
  margin-top: 113px;
}

.sc-mt-114 {
  margin-top: 114px;
}

.sc-mt-115 {
  margin-top: 115px;
}

.sc-mt-116 {
  margin-top: 116px;
}

.sc-mt-117 {
  margin-top: 117px;
}

.sc-mt-118 {
  margin-top: 118px;
}

.sc-mt-119 {
  margin-top: 119px;
}

.sc-mt-120 {
  margin-top: 120px;
}

.sc-mt-121 {
  margin-top: 121px;
}

.sc-mt-122 {
  margin-top: 122px;
}

.sc-mt-123 {
  margin-top: 123px;
}

.sc-mt-124 {
  margin-top: 124px;
}

.sc-mt-125 {
  margin-top: 125px;
}

.sc-mt-126 {
  margin-top: 126px;
}

.sc-mt-127 {
  margin-top: 127px;
}

.sc-mt-128 {
  margin-top: 128px;
}

.sc-mt-129 {
  margin-top: 129px;
}

.sc-mt-130 {
  margin-top: 130px;
}

.sc-mt-131 {
  margin-top: 131px;
}

.sc-mt-132 {
  margin-top: 132px;
}

.sc-mt-133 {
  margin-top: 133px;
}

.sc-mt-134 {
  margin-top: 134px;
}

.sc-mt-135 {
  margin-top: 135px;
}

.sc-mt-136 {
  margin-top: 136px;
}

.sc-mt-137 {
  margin-top: 137px;
}

.sc-mt-138 {
  margin-top: 138px;
}

.sc-mt-139 {
  margin-top: 139px;
}

.sc-mt-140 {
  margin-top: 140px;
}

.sc-mt-141 {
  margin-top: 141px;
}

.sc-mt-142 {
  margin-top: 142px;
}

.sc-mt-143 {
  margin-top: 143px;
}

.sc-mt-144 {
  margin-top: 144px;
}

.sc-mt-145 {
  margin-top: 145px;
}

.sc-mt-146 {
  margin-top: 146px;
}

.sc-mt-147 {
  margin-top: 147px;
}

.sc-mt-148 {
  margin-top: 148px;
}

.sc-mt-149 {
  margin-top: 149px;
}

.sc-mt-150 {
  margin-top: 150px;
}

.sc-mt-151 {
  margin-top: 151px;
}

.sc-mt-152 {
  margin-top: 152px;
}

.sc-mt-153 {
  margin-top: 153px;
}

.sc-mt-154 {
  margin-top: 154px;
}

.sc-mt-155 {
  margin-top: 155px;
}

.sc-mt-156 {
  margin-top: 156px;
}

.sc-mt-157 {
  margin-top: 157px;
}

.sc-mt-158 {
  margin-top: 158px;
}

.sc-mt-159 {
  margin-top: 159px;
}

.sc-mt-160 {
  margin-top: 160px;
}

.sc-mt-161 {
  margin-top: 161px;
}

.sc-mt-162 {
  margin-top: 162px;
}

.sc-mt-163 {
  margin-top: 163px;
}

.sc-mt-164 {
  margin-top: 164px;
}

.sc-mt-165 {
  margin-top: 165px;
}

.sc-mt-166 {
  margin-top: 166px;
}

.sc-mt-167 {
  margin-top: 167px;
}

.sc-mt-168 {
  margin-top: 168px;
}

.sc-mt-169 {
  margin-top: 169px;
}

.sc-mt-170 {
  margin-top: 170px;
}

.sc-mt-171 {
  margin-top: 171px;
}

.sc-mt-172 {
  margin-top: 172px;
}

.sc-mt-173 {
  margin-top: 173px;
}

.sc-mt-174 {
  margin-top: 174px;
}

.sc-mt-175 {
  margin-top: 175px;
}

.sc-mt-176 {
  margin-top: 176px;
}

.sc-mt-177 {
  margin-top: 177px;
}

.sc-mt-178 {
  margin-top: 178px;
}

.sc-mt-179 {
  margin-top: 179px;
}

.sc-mt-180 {
  margin-top: 180px;
}

.sc-mt-181 {
  margin-top: 181px;
}

.sc-mt-182 {
  margin-top: 182px;
}

.sc-mt-183 {
  margin-top: 183px;
}

.sc-mt-184 {
  margin-top: 184px;
}

.sc-mt-185 {
  margin-top: 185px;
}

.sc-mt-186 {
  margin-top: 186px;
}

.sc-mt-187 {
  margin-top: 187px;
}

.sc-mt-188 {
  margin-top: 188px;
}

.sc-mt-189 {
  margin-top: 189px;
}

.sc-mt-190 {
  margin-top: 190px;
}

.sc-mt-191 {
  margin-top: 191px;
}

.sc-mt-192 {
  margin-top: 192px;
}

.sc-mt-193 {
  margin-top: 193px;
}

.sc-mt-194 {
  margin-top: 194px;
}

.sc-mt-195 {
  margin-top: 195px;
}

.sc-mt-196 {
  margin-top: 196px;
}

.sc-mt-197 {
  margin-top: 197px;
}

.sc-mt-198 {
  margin-top: 198px;
}

.sc-mt-199 {
  margin-top: 199px;
}

.sc-mt-200 {
  margin-top: 200px;
}

.sc-mb-0 {
  margin-bottom: 0;
}

.sc-mb-1 {
  margin-bottom: 1px;
}

.sc-mb-2 {
  margin-bottom: 2px;
}

.sc-mb-3 {
  margin-bottom: 3px;
}

.sc-mb-4 {
  margin-bottom: 4px;
}

.sc-mb-5 {
  margin-bottom: 5px;
}

.sc-mb-6 {
  margin-bottom: 6px;
}

.sc-mb-7 {
  margin-bottom: 7px;
}

.sc-mb-8 {
  margin-bottom: 8px;
}

.sc-mb-9 {
  margin-bottom: 9px;
}

.sc-mb-10 {
  margin-bottom: 10px;
}

.sc-mb-11 {
  margin-bottom: 11px;
}

.sc-mb-12 {
  margin-bottom: 12px;
}

.sc-mb-13 {
  margin-bottom: 13px;
}

.sc-mb-14 {
  margin-bottom: 14px;
}

.sc-mb-15 {
  margin-bottom: 15px;
}

.sc-mb-16 {
  margin-bottom: 16px;
}

.sc-mb-17 {
  margin-bottom: 17px;
}

.sc-mb-18 {
  margin-bottom: 18px;
}

.sc-mb-19 {
  margin-bottom: 19px;
}

.sc-mb-20 {
  margin-bottom: 20px;
}

.sc-mb-21 {
  margin-bottom: 21px;
}

.sc-mb-22 {
  margin-bottom: 22px;
}

.sc-mb-23 {
  margin-bottom: 23px;
}

.sc-mb-24 {
  margin-bottom: 24px;
}

.sc-mb-25 {
  margin-bottom: 25px;
}

.sc-mb-26 {
  margin-bottom: 26px;
}

.sc-mb-27 {
  margin-bottom: 27px;
}

.sc-mb-28 {
  margin-bottom: 28px;
}

.sc-mb-29 {
  margin-bottom: 29px;
}

.sc-mb-30 {
  margin-bottom: 30px;
}

.sc-mb-31 {
  margin-bottom: 31px;
}

.sc-mb-32 {
  margin-bottom: 32px;
}

.sc-mb-33 {
  margin-bottom: 33px;
}

.sc-mb-34 {
  margin-bottom: 34px;
}

.sc-mb-35 {
  margin-bottom: 35px;
}

.sc-mb-36 {
  margin-bottom: 36px;
}

.sc-mb-37 {
  margin-bottom: 37px;
}

.sc-mb-38 {
  margin-bottom: 38px;
}

.sc-mb-39 {
  margin-bottom: 39px;
}

.sc-mb-40 {
  margin-bottom: 40px;
}

.sc-mb-41 {
  margin-bottom: 41px;
}

.sc-mb-42 {
  margin-bottom: 42px;
}

.sc-mb-43 {
  margin-bottom: 43px;
}

.sc-mb-44 {
  margin-bottom: 44px;
}

.sc-mb-45 {
  margin-bottom: 45px;
}

.sc-mb-46 {
  margin-bottom: 46px;
}

.sc-mb-47 {
  margin-bottom: 47px;
}

.sc-mb-48 {
  margin-bottom: 48px;
}

.sc-mb-49 {
  margin-bottom: 49px;
}

.sc-mb-50 {
  margin-bottom: 50px;
}

.sc-mb-51 {
  margin-bottom: 51px;
}

.sc-mb-52 {
  margin-bottom: 52px;
}

.sc-mb-53 {
  margin-bottom: 53px;
}

.sc-mb-54 {
  margin-bottom: 54px;
}

.sc-mb-55 {
  margin-bottom: 55px;
}

.sc-mb-56 {
  margin-bottom: 56px;
}

.sc-mb-57 {
  margin-bottom: 57px;
}

.sc-mb-58 {
  margin-bottom: 58px;
}

.sc-mb-59 {
  margin-bottom: 59px;
}

.sc-mb-60 {
  margin-bottom: 60px;
}

.sc-mb-61 {
  margin-bottom: 61px;
}

.sc-mb-62 {
  margin-bottom: 62px;
}

.sc-mb-63 {
  margin-bottom: 63px;
}

.sc-mb-64 {
  margin-bottom: 64px;
}

.sc-mb-65 {
  margin-bottom: 65px;
}

.sc-mb-66 {
  margin-bottom: 66px;
}

.sc-mb-67 {
  margin-bottom: 67px;
}

.sc-mb-68 {
  margin-bottom: 68px;
}

.sc-mb-69 {
  margin-bottom: 69px;
}

.sc-mb-70 {
  margin-bottom: 70px;
}

.sc-mb-71 {
  margin-bottom: 71px;
}

.sc-mb-72 {
  margin-bottom: 72px;
}

.sc-mb-73 {
  margin-bottom: 73px;
}

.sc-mb-74 {
  margin-bottom: 74px;
}

.sc-mb-75 {
  margin-bottom: 75px;
}

.sc-mb-76 {
  margin-bottom: 76px;
}

.sc-mb-77 {
  margin-bottom: 77px;
}

.sc-mb-78 {
  margin-bottom: 78px;
}

.sc-mb-79 {
  margin-bottom: 79px;
}

.sc-mb-80 {
  margin-bottom: 80px;
}

.sc-mb-81 {
  margin-bottom: 81px;
}

.sc-mb-82 {
  margin-bottom: 82px;
}

.sc-mb-83 {
  margin-bottom: 83px;
}

.sc-mb-84 {
  margin-bottom: 84px;
}

.sc-mb-85 {
  margin-bottom: 85px;
}

.sc-mb-86 {
  margin-bottom: 86px;
}

.sc-mb-87 {
  margin-bottom: 87px;
}

.sc-mb-88 {
  margin-bottom: 88px;
}

.sc-mb-89 {
  margin-bottom: 89px;
}

.sc-mb-90 {
  margin-bottom: 90px;
}

.sc-mb-91 {
  margin-bottom: 91px;
}

.sc-mb-92 {
  margin-bottom: 92px;
}

.sc-mb-93 {
  margin-bottom: 93px;
}

.sc-mb-94 {
  margin-bottom: 94px;
}

.sc-mb-95 {
  margin-bottom: 95px;
}

.sc-mb-96 {
  margin-bottom: 96px;
}

.sc-mb-97 {
  margin-bottom: 97px;
}

.sc-mb-98 {
  margin-bottom: 98px;
}

.sc-mb-99 {
  margin-bottom: 99px;
}

.sc-mb-100 {
  margin-bottom: 100px;
}

.sc-mb-101 {
  margin-bottom: 101px;
}

.sc-mb-102 {
  margin-bottom: 102px;
}

.sc-mb-103 {
  margin-bottom: 103px;
}

.sc-mb-104 {
  margin-bottom: 104px;
}

.sc-mb-105 {
  margin-bottom: 105px;
}

.sc-mb-106 {
  margin-bottom: 106px;
}

.sc-mb-107 {
  margin-bottom: 107px;
}

.sc-mb-108 {
  margin-bottom: 108px;
}

.sc-mb-109 {
  margin-bottom: 109px;
}

.sc-mb-110 {
  margin-bottom: 110px;
}

.sc-mb-111 {
  margin-bottom: 111px;
}

.sc-mb-112 {
  margin-bottom: 112px;
}

.sc-mb-113 {
  margin-bottom: 113px;
}

.sc-mb-114 {
  margin-bottom: 114px;
}

.sc-mb-115 {
  margin-bottom: 115px;
}

.sc-mb-116 {
  margin-bottom: 116px;
}

.sc-mb-117 {
  margin-bottom: 117px;
}

.sc-mb-118 {
  margin-bottom: 118px;
}

.sc-mb-119 {
  margin-bottom: 119px;
}

.sc-mb-120 {
  margin-bottom: 120px;
}

.sc-mb-121 {
  margin-bottom: 121px;
}

.sc-mb-122 {
  margin-bottom: 122px;
}

.sc-mb-123 {
  margin-bottom: 123px;
}

.sc-mb-124 {
  margin-bottom: 124px;
}

.sc-mb-125 {
  margin-bottom: 125px;
}

.sc-mb-126 {
  margin-bottom: 126px;
}

.sc-mb-127 {
  margin-bottom: 127px;
}

.sc-mb-128 {
  margin-bottom: 128px;
}

.sc-mb-129 {
  margin-bottom: 129px;
}

.sc-mb-130 {
  margin-bottom: 130px;
}

.sc-mb-131 {
  margin-bottom: 131px;
}

.sc-mb-132 {
  margin-bottom: 132px;
}

.sc-mb-133 {
  margin-bottom: 133px;
}

.sc-mb-134 {
  margin-bottom: 134px;
}

.sc-mb-135 {
  margin-bottom: 135px;
}

.sc-mb-136 {
  margin-bottom: 136px;
}

.sc-mb-137 {
  margin-bottom: 137px;
}

.sc-mb-138 {
  margin-bottom: 138px;
}

.sc-mb-139 {
  margin-bottom: 139px;
}

.sc-mb-140 {
  margin-bottom: 140px;
}

.sc-mb-141 {
  margin-bottom: 141px;
}

.sc-mb-142 {
  margin-bottom: 142px;
}

.sc-mb-143 {
  margin-bottom: 143px;
}

.sc-mb-144 {
  margin-bottom: 144px;
}

.sc-mb-145 {
  margin-bottom: 145px;
}

.sc-mb-146 {
  margin-bottom: 146px;
}

.sc-mb-147 {
  margin-bottom: 147px;
}

.sc-mb-148 {
  margin-bottom: 148px;
}

.sc-mb-149 {
  margin-bottom: 149px;
}

.sc-mb-150 {
  margin-bottom: 150px;
}

.sc-mb-151 {
  margin-bottom: 151px;
}

.sc-mb-152 {
  margin-bottom: 152px;
}

.sc-mb-153 {
  margin-bottom: 153px;
}

.sc-mb-154 {
  margin-bottom: 154px;
}

.sc-mb-155 {
  margin-bottom: 155px;
}

.sc-mb-156 {
  margin-bottom: 156px;
}

.sc-mb-157 {
  margin-bottom: 157px;
}

.sc-mb-158 {
  margin-bottom: 158px;
}

.sc-mb-159 {
  margin-bottom: 159px;
}

.sc-mb-160 {
  margin-bottom: 160px;
}

.sc-mb-161 {
  margin-bottom: 161px;
}

.sc-mb-162 {
  margin-bottom: 162px;
}

.sc-mb-163 {
  margin-bottom: 163px;
}

.sc-mb-164 {
  margin-bottom: 164px;
}

.sc-mb-165 {
  margin-bottom: 165px;
}

.sc-mb-166 {
  margin-bottom: 166px;
}

.sc-mb-167 {
  margin-bottom: 167px;
}

.sc-mb-168 {
  margin-bottom: 168px;
}

.sc-mb-169 {
  margin-bottom: 169px;
}

.sc-mb-170 {
  margin-bottom: 170px;
}

.sc-mb-171 {
  margin-bottom: 171px;
}

.sc-mb-172 {
  margin-bottom: 172px;
}

.sc-mb-173 {
  margin-bottom: 173px;
}

.sc-mb-174 {
  margin-bottom: 174px;
}

.sc-mb-175 {
  margin-bottom: 175px;
}

.sc-mb-176 {
  margin-bottom: 176px;
}

.sc-mb-177 {
  margin-bottom: 177px;
}

.sc-mb-178 {
  margin-bottom: 178px;
}

.sc-mb-179 {
  margin-bottom: 179px;
}

.sc-mb-180 {
  margin-bottom: 180px;
}

.sc-mb-181 {
  margin-bottom: 181px;
}

.sc-mb-182 {
  margin-bottom: 182px;
}

.sc-mb-183 {
  margin-bottom: 183px;
}

.sc-mb-184 {
  margin-bottom: 184px;
}

.sc-mb-185 {
  margin-bottom: 185px;
}

.sc-mb-186 {
  margin-bottom: 186px;
}

.sc-mb-187 {
  margin-bottom: 187px;
}

.sc-mb-188 {
  margin-bottom: 188px;
}

.sc-mb-189 {
  margin-bottom: 189px;
}

.sc-mb-190 {
  margin-bottom: 190px;
}

.sc-mb-191 {
  margin-bottom: 191px;
}

.sc-mb-192 {
  margin-bottom: 192px;
}

.sc-mb-193 {
  margin-bottom: 193px;
}

.sc-mb-194 {
  margin-bottom: 194px;
}

.sc-mb-195 {
  margin-bottom: 195px;
}

.sc-mb-196 {
  margin-bottom: 196px;
}

.sc-mb-197 {
  margin-bottom: 197px;
}

.sc-mb-198 {
  margin-bottom: 198px;
}

.sc-mb-199 {
  margin-bottom: 199px;
}

.sc-mb-200 {
  margin-bottom: 200px;
}

.sc-mr-0 {
  margin-right: 0;
}

.sc-mr-1 {
  margin-right: 1px;
}

.sc-mr-2 {
  margin-right: 2px;
}

.sc-mr-3 {
  margin-right: 3px;
}

.sc-mr-4 {
  margin-right: 4px;
}

.sc-mr-5 {
  margin-right: 5px;
}

.sc-mr-6 {
  margin-right: 6px;
}

.sc-mr-7 {
  margin-right: 7px;
}

.sc-mr-8 {
  margin-right: 8px;
}

.sc-mr-9 {
  margin-right: 9px;
}

.sc-mr-10 {
  margin-right: 10px;
}

.sc-mr-11 {
  margin-right: 11px;
}

.sc-mr-12 {
  margin-right: 12px;
}

.sc-mr-13 {
  margin-right: 13px;
}

.sc-mr-14 {
  margin-right: 14px;
}

.sc-mr-15 {
  margin-right: 15px;
}

.sc-mr-16 {
  margin-right: 16px;
}

.sc-mr-17 {
  margin-right: 17px;
}

.sc-mr-18 {
  margin-right: 18px;
}

.sc-mr-19 {
  margin-right: 19px;
}

.sc-mr-20 {
  margin-right: 20px;
}

.sc-mr-21 {
  margin-right: 21px;
}

.sc-mr-22 {
  margin-right: 22px;
}

.sc-mr-23 {
  margin-right: 23px;
}

.sc-mr-24 {
  margin-right: 24px;
}

.sc-mr-25 {
  margin-right: 25px;
}

.sc-mr-26 {
  margin-right: 26px;
}

.sc-mr-27 {
  margin-right: 27px;
}

.sc-mr-28 {
  margin-right: 28px;
}

.sc-mr-29 {
  margin-right: 29px;
}

.sc-mr-30 {
  margin-right: 30px;
}

.sc-mr-31 {
  margin-right: 31px;
}

.sc-mr-32 {
  margin-right: 32px;
}

.sc-mr-33 {
  margin-right: 33px;
}

.sc-mr-34 {
  margin-right: 34px;
}

.sc-mr-35 {
  margin-right: 35px;
}

.sc-mr-36 {
  margin-right: 36px;
}

.sc-mr-37 {
  margin-right: 37px;
}

.sc-mr-38 {
  margin-right: 38px;
}

.sc-mr-39 {
  margin-right: 39px;
}

.sc-mr-40 {
  margin-right: 40px;
}

.sc-mr-41 {
  margin-right: 41px;
}

.sc-mr-42 {
  margin-right: 42px;
}

.sc-mr-43 {
  margin-right: 43px;
}

.sc-mr-44 {
  margin-right: 44px;
}

.sc-mr-45 {
  margin-right: 45px;
}

.sc-mr-46 {
  margin-right: 46px;
}

.sc-mr-47 {
  margin-right: 47px;
}

.sc-mr-48 {
  margin-right: 48px;
}

.sc-mr-49 {
  margin-right: 49px;
}

.sc-mr-50 {
  margin-right: 50px;
}

.sc-mr-51 {
  margin-right: 51px;
}

.sc-mr-52 {
  margin-right: 52px;
}

.sc-mr-53 {
  margin-right: 53px;
}

.sc-mr-54 {
  margin-right: 54px;
}

.sc-mr-55 {
  margin-right: 55px;
}

.sc-mr-56 {
  margin-right: 56px;
}

.sc-mr-57 {
  margin-right: 57px;
}

.sc-mr-58 {
  margin-right: 58px;
}

.sc-mr-59 {
  margin-right: 59px;
}

.sc-mr-60 {
  margin-right: 60px;
}

.sc-mr-61 {
  margin-right: 61px;
}

.sc-mr-62 {
  margin-right: 62px;
}

.sc-mr-63 {
  margin-right: 63px;
}

.sc-mr-64 {
  margin-right: 64px;
}

.sc-mr-65 {
  margin-right: 65px;
}

.sc-mr-66 {
  margin-right: 66px;
}

.sc-mr-67 {
  margin-right: 67px;
}

.sc-mr-68 {
  margin-right: 68px;
}

.sc-mr-69 {
  margin-right: 69px;
}

.sc-mr-70 {
  margin-right: 70px;
}

.sc-mr-71 {
  margin-right: 71px;
}

.sc-mr-72 {
  margin-right: 72px;
}

.sc-mr-73 {
  margin-right: 73px;
}

.sc-mr-74 {
  margin-right: 74px;
}

.sc-mr-75 {
  margin-right: 75px;
}

.sc-mr-76 {
  margin-right: 76px;
}

.sc-mr-77 {
  margin-right: 77px;
}

.sc-mr-78 {
  margin-right: 78px;
}

.sc-mr-79 {
  margin-right: 79px;
}

.sc-mr-80 {
  margin-right: 80px;
}

.sc-mr-81 {
  margin-right: 81px;
}

.sc-mr-82 {
  margin-right: 82px;
}

.sc-mr-83 {
  margin-right: 83px;
}

.sc-mr-84 {
  margin-right: 84px;
}

.sc-mr-85 {
  margin-right: 85px;
}

.sc-mr-86 {
  margin-right: 86px;
}

.sc-mr-87 {
  margin-right: 87px;
}

.sc-mr-88 {
  margin-right: 88px;
}

.sc-mr-89 {
  margin-right: 89px;
}

.sc-mr-90 {
  margin-right: 90px;
}

.sc-mr-91 {
  margin-right: 91px;
}

.sc-mr-92 {
  margin-right: 92px;
}

.sc-mr-93 {
  margin-right: 93px;
}

.sc-mr-94 {
  margin-right: 94px;
}

.sc-mr-95 {
  margin-right: 95px;
}

.sc-mr-96 {
  margin-right: 96px;
}

.sc-mr-97 {
  margin-right: 97px;
}

.sc-mr-98 {
  margin-right: 98px;
}

.sc-mr-99 {
  margin-right: 99px;
}

.sc-mr-100 {
  margin-right: 100px;
}

.sc-ml-0 {
  margin-left: 0;
}

.sc-ml-1 {
  margin-left: 1px;
}

.sc-ml-2 {
  margin-left: 2px;
}

.sc-ml-3 {
  margin-left: 3px;
}

.sc-ml-4 {
  margin-left: 4px;
}

.sc-ml-5 {
  margin-left: 5px !important;
}

.sc-ml-6 {
  margin-left: 6px;
}

.sc-ml-7 {
  margin-left: 7px;
}

.sc-ml-8 {
  margin-left: 8px;
}

.sc-ml-9 {
  margin-left: 9px;
}

.sc-ml-10 {
  margin-left: 10px;
}

.sc-ml-11 {
  margin-left: 11px;
}

.sc-ml-12 {
  margin-left: 12px;
}

.sc-ml-13 {
  margin-left: 13px;
}

.sc-ml-14 {
  margin-left: 14px;
}

.sc-ml-15 {
  margin-left: 15px;
}

.sc-ml-16 {
  margin-left: 16px;
}

.sc-ml-17 {
  margin-left: 17px;
}

.sc-ml-18 {
  margin-left: 18px;
}

.sc-ml-19 {
  margin-left: 19px;
}

.sc-ml-20 {
  margin-left: 20px;
}

.sc-ml-21 {
  margin-left: 21px;
}

.sc-ml-22 {
  margin-left: 22px;
}

.sc-ml-23 {
  margin-left: 23px;
}

.sc-ml-24 {
  margin-left: 24px;
}

.sc-ml-25 {
  margin-left: 25px;
}

.sc-ml-26 {
  margin-left: 26px;
}

.sc-ml-27 {
  margin-left: 27px;
}

.sc-ml-28 {
  margin-left: 28px;
}

.sc-ml-29 {
  margin-left: 29px;
}

.sc-ml-30 {
  margin-left: 30px;
}

.sc-ml-31 {
  margin-left: 31px;
}

.sc-ml-32 {
  margin-left: 32px;
}

.sc-ml-33 {
  margin-left: 33px;
}

.sc-ml-34 {
  margin-left: 34px;
}

.sc-ml-35 {
  margin-left: 35px;
}

.sc-ml-36 {
  margin-left: 36px;
}

.sc-ml-37 {
  margin-left: 37px;
}

.sc-ml-38 {
  margin-left: 38px;
}

.sc-ml-39 {
  margin-left: 39px;
}

.sc-ml-40 {
  margin-left: 40px;
}

.sc-ml-41 {
  margin-left: 41px;
}

.sc-ml-42 {
  margin-left: 42px;
}

.sc-ml-43 {
  margin-left: 43px;
}

.sc-ml-44 {
  margin-left: 44px;
}

.sc-ml-45 {
  margin-left: 45px;
}

.sc-ml-46 {
  margin-left: 46px;
}

.sc-ml-47 {
  margin-left: 47px;
}

.sc-ml-48 {
  margin-left: 48px;
}

.sc-ml-49 {
  margin-left: 49px;
}

.sc-ml-50 {
  margin-left: 50px;
}

.sc-ml-51 {
  margin-left: 51px;
}

.sc-ml-52 {
  margin-left: 52px;
}

.sc-ml-53 {
  margin-left: 53px;
}

.sc-ml-54 {
  margin-left: 54px;
}

.sc-ml-55 {
  margin-left: 55px;
}

.sc-ml-56 {
  margin-left: 56px;
}

.sc-ml-57 {
  margin-left: 57px;
}

.sc-ml-58 {
  margin-left: 58px;
}

.sc-ml-59 {
  margin-left: 59px;
}

.sc-ml-60 {
  margin-left: 60px;
}

.sc-ml-61 {
  margin-left: 61px;
}

.sc-ml-62 {
  margin-left: 62px;
}

.sc-ml-63 {
  margin-left: 63px;
}

.sc-ml-64 {
  margin-left: 64px;
}

.sc-ml-65 {
  margin-left: 65px;
}

.sc-ml-66 {
  margin-left: 66px;
}

.sc-ml-67 {
  margin-left: 67px;
}

.sc-ml-68 {
  margin-left: 68px;
}

.sc-ml-69 {
  margin-left: 69px;
}

.sc-ml-70 {
  margin-left: 70px;
}

.sc-ml-71 {
  margin-left: 71px;
}

.sc-ml-72 {
  margin-left: 72px;
}

.sc-ml-73 {
  margin-left: 73px;
}

.sc-ml-74 {
  margin-left: 74px;
}

.sc-ml-75 {
  margin-left: 75px;
}

.sc-ml-76 {
  margin-left: 76px;
}

.sc-ml-77 {
  margin-left: 77px;
}

.sc-ml-78 {
  margin-left: 78px;
}

.sc-ml-79 {
  margin-left: 79px;
}

.sc-ml-80 {
  margin-left: 80px;
}

.sc-ml-81 {
  margin-left: 81px;
}

.sc-ml-82 {
  margin-left: 82px;
}

.sc-ml-83 {
  margin-left: 83px;
}

.sc-ml-84 {
  margin-left: 84px;
}

.sc-ml-85 {
  margin-left: 85px;
}

.sc-ml-86 {
  margin-left: 86px;
}

.sc-ml-87 {
  margin-left: 87px;
}

.sc-ml-88 {
  margin-left: 88px;
}

.sc-ml-89 {
  margin-left: 89px;
}

.sc-ml-90 {
  margin-left: 90px;
}

.sc-ml-91 {
  margin-left: 91px;
}

.sc-ml-92 {
  margin-left: 92px;
}

.sc-ml-93 {
  margin-left: 93px;
}

.sc-ml-94 {
  margin-left: 94px;
}

.sc-ml-95 {
  margin-left: 95px;
}

.sc-ml-96 {
  margin-left: 96px;
}

.sc-ml-97 {
  margin-left: 97px;
}

.sc-ml-98 {
  margin-left: 98px;
}

.sc-ml-99 {
  margin-left: 99px;
}

.sc-ml-100 {
  margin-left: 100px;
}

.sc-mt--1 {
  margin-top: -1px;
}

.sc-mt--2 {
  margin-top: -2px;
}

.sc-mt--3 {
  margin-top: -3px;
}

.sc-mt--4 {
  margin-top: -4px;
}

.sc-mt--5 {
  margin-top: -5px;
}

.sc-mt--6 {
  margin-top: -6px;
}

.sc-mt--7 {
  margin-top: -7px;
}

.sc-mt--8 {
  margin-top: -8px;
}

.sc-mt--9 {
  margin-top: -9px;
}

.sc-mt--10 {
  margin-top: -10px;
}

.sc-mt--11 {
  margin-top: -11px;
}

.sc-mt--12 {
  margin-top: -12px;
}

.sc-mt--13 {
  margin-top: -13px;
}

.sc-mt--14 {
  margin-top: -14px;
}

.sc-mt--15 {
  margin-top: -15px;
}

.sc-mt--16 {
  margin-top: -16px;
}

.sc-mt--17 {
  margin-top: -17px;
}

.sc-mt--18 {
  margin-top: -18px;
}

.sc-mt--19 {
  margin-top: -19px;
}

.sc-mt--20 {
  margin-top: -20px;
}

.sc-mt--21 {
  margin-top: -21px;
}

.sc-mt--22 {
  margin-top: -22px;
}

.sc-mt--23 {
  margin-top: -23px;
}

.sc-mt--24 {
  margin-top: -24px;
}

.sc-mt--25 {
  margin-top: -25px;
}

.sc-mt--26 {
  margin-top: -26px;
}

.sc-mt--27 {
  margin-top: -27px;
}

.sc-mt--28 {
  margin-top: -28px;
}

.sc-mt--29 {
  margin-top: -29px;
}

.sc-mt--30 {
  margin-top: -30px;
}

.sc-mt--31 {
  margin-top: -31px;
}

.sc-mt--32 {
  margin-top: -32px;
}

.sc-mt--33 {
  margin-top: -33px;
}

.sc-mt--34 {
  margin-top: -34px;
}

.sc-mt--35 {
  margin-top: -35px;
}

.sc-mt--36 {
  margin-top: -36px;
}

.sc-mt--37 {
  margin-top: -37px;
}

.sc-mt--38 {
  margin-top: -38px;
}

.sc-mt--39 {
  margin-top: -39px;
}

.sc-mt--40 {
  margin-top: -40px;
}

.sc-mt--41 {
  margin-top: -41px;
}

.sc-mt--42 {
  margin-top: -42px;
}

.sc-mt--43 {
  margin-top: -43px;
}

.sc-mt--44 {
  margin-top: -44px;
}

.sc-mt--45 {
  margin-top: -45px;
}

.sc-mt--46 {
  margin-top: -46px;
}

.sc-mt--47 {
  margin-top: -47px;
}

.sc-mt--48 {
  margin-top: -48px;
}

.sc-mt--49 {
  margin-top: -49px;
}

.sc-mt--50 {
  margin-top: -50px;
}

.sc-mt--51 {
  margin-top: -51px;
}

.sc-mt--52 {
  margin-top: -52px;
}

.sc-mt--53 {
  margin-top: -53px;
}

.sc-mt--54 {
  margin-top: -54px;
}

.sc-mt--55 {
  margin-top: -55px;
}

.sc-mt--56 {
  margin-top: -56px;
}

.sc-mt--57 {
  margin-top: -57px;
}

.sc-mt--58 {
  margin-top: -58px;
}

.sc-mt--59 {
  margin-top: -59px;
}

.sc-mt--60 {
  margin-top: -60px;
}

.sc-mt--61 {
  margin-top: -61px;
}

.sc-mt--62 {
  margin-top: -62px;
}

.sc-mt--63 {
  margin-top: -63px;
}

.sc-mt--64 {
  margin-top: -64px;
}

.sc-mt--65 {
  margin-top: -65px;
}

.sc-mt--66 {
  margin-top: -66px;
}

.sc-mt--67 {
  margin-top: -67px;
}

.sc-mt--68 {
  margin-top: -68px;
}

.sc-mt--69 {
  margin-top: -69px;
}

.sc-mt--70 {
  margin-top: -70px;
}

.sc-mt--71 {
  margin-top: -71px;
}

.sc-mt--72 {
  margin-top: -72px;
}

.sc-mt--73 {
  margin-top: -73px;
}

.sc-mt--74 {
  margin-top: -74px;
}

.sc-mt--75 {
  margin-top: -75px;
}

.sc-mt--76 {
  margin-top: -76px;
}

.sc-mt--77 {
  margin-top: -77px;
}

.sc-mt--78 {
  margin-top: -78px;
}

.sc-mt--79 {
  margin-top: -79px;
}

.sc-mt--80 {
  margin-top: -80px;
}

.sc-mt--81 {
  margin-top: -81px;
}

.sc-mt--82 {
  margin-top: -82px;
}

.sc-mt--83 {
  margin-top: -83px;
}

.sc-mt--84 {
  margin-top: -84px;
}

.sc-mt--85 {
  margin-top: -85px;
}

.sc-mt--86 {
  margin-top: -86px;
}

.sc-mt--87 {
  margin-top: -87px;
}

.sc-mt--88 {
  margin-top: -88px;
}

.sc-mt--89 {
  margin-top: -89px;
}

.sc-mt--90 {
  margin-top: -90px;
}

.sc-mt--91 {
  margin-top: -91px;
}

.sc-mt--92 {
  margin-top: -92px;
}

.sc-mt--93 {
  margin-top: -93px;
}

.sc-mt--94 {
  margin-top: -94px;
}

.sc-mt--95 {
  margin-top: -95px;
}

.sc-mt--96 {
  margin-top: -96px;
}

.sc-mt--97 {
  margin-top: -97px;
}

.sc-mt--98 {
  margin-top: -98px;
}

.sc-mt--99 {
  margin-top: -99px;
}

.sc-mt--100 {
  margin-top: -100px;
}

.sc-mt--101 {
  margin-top: -101px;
}

.sc-mt--102 {
  margin-top: -102px;
}

.sc-mt--103 {
  margin-top: -103px;
}

.sc-mt--104 {
  margin-top: -104px;
}

.sc-mt--105 {
  margin-top: -105px;
}

.sc-mt--106 {
  margin-top: -106px;
}

.sc-mt--107 {
  margin-top: -107px;
}

.sc-mt--108 {
  margin-top: -108px;
}

.sc-mt--109 {
  margin-top: -109px;
}

.sc-mt--110 {
  margin-top: -110px;
}

.sc-mt--111 {
  margin-top: -111px;
}

.sc-mt--112 {
  margin-top: -112px;
}

.sc-mt--113 {
  margin-top: -113px;
}

.sc-mt--114 {
  margin-top: -114px;
}

.sc-mt--115 {
  margin-top: -115px;
}

.sc-mt--116 {
  margin-top: -116px;
}

.sc-mt--117 {
  margin-top: -117px;
}

.sc-mt--118 {
  margin-top: -118px;
}

.sc-mt--119 {
  margin-top: -119px;
}

.sc-mt--120 {
  margin-top: -120px;
}

.sc-mt--121 {
  margin-top: -121px;
}

.sc-mt--122 {
  margin-top: -122px;
}

.sc-mt--123 {
  margin-top: -123px;
}

.sc-mt--124 {
  margin-top: -124px;
}

.sc-mt--125 {
  margin-top: -125px;
}

.sc-mt--126 {
  margin-top: -126px;
}

.sc-mt--127 {
  margin-top: -127px;
}

.sc-mt--128 {
  margin-top: -128px;
}

.sc-mt--129 {
  margin-top: -129px;
}

.sc-mt--130 {
  margin-top: -130px;
}

.sc-mt--131 {
  margin-top: -131px;
}

.sc-mt--132 {
  margin-top: -132px;
}

.sc-mt--133 {
  margin-top: -133px;
}

.sc-mt--134 {
  margin-top: -134px;
}

.sc-mt--135 {
  margin-top: -135px;
}

.sc-mt--136 {
  margin-top: -136px;
}

.sc-mt--137 {
  margin-top: -137px;
}

.sc-mt--138 {
  margin-top: -138px;
}

.sc-mt--139 {
  margin-top: -139px;
}

.sc-mt--140 {
  margin-top: -140px;
}

.sc-mt--141 {
  margin-top: -141px;
}

.sc-mt--142 {
  margin-top: -142px;
}

.sc-mt--143 {
  margin-top: -143px;
}

.sc-mt--144 {
  margin-top: -144px;
}

.sc-mt--145 {
  margin-top: -145px;
}

.sc-mt--146 {
  margin-top: -146px;
}

.sc-mt--147 {
  margin-top: -147px;
}

.sc-mt--148 {
  margin-top: -148px;
}

.sc-mt--149 {
  margin-top: -149px;
}

.sc-mt--150 {
  margin-top: -150px;
}

.sc-mt--151 {
  margin-top: -151px;
}

.sc-mt--152 {
  margin-top: -152px;
}

.sc-mt--153 {
  margin-top: -153px;
}

.sc-mt--154 {
  margin-top: -154px;
}

.sc-mt--155 {
  margin-top: -155px;
}

.sc-mt--156 {
  margin-top: -156px;
}

.sc-mt--157 {
  margin-top: -157px;
}

.sc-mt--158 {
  margin-top: -158px;
}

.sc-mt--159 {
  margin-top: -159px;
}

.sc-mt--160 {
  margin-top: -160px;
}

.sc-mt--161 {
  margin-top: -161px;
}

.sc-mt--162 {
  margin-top: -162px;
}

.sc-mt--163 {
  margin-top: -163px;
}

.sc-mt--164 {
  margin-top: -164px;
}

.sc-mt--165 {
  margin-top: -165px;
}

.sc-mt--166 {
  margin-top: -166px;
}

.sc-mt--167 {
  margin-top: -167px;
}

.sc-mt--168 {
  margin-top: -168px;
}

.sc-mt--169 {
  margin-top: -169px;
}

.sc-mt--170 {
  margin-top: -170px;
}

.sc-mt--171 {
  margin-top: -171px;
}

.sc-mt--172 {
  margin-top: -172px;
}

.sc-mt--173 {
  margin-top: -173px;
}

.sc-mt--174 {
  margin-top: -174px;
}

.sc-mt--175 {
  margin-top: -175px;
}

.sc-mt--176 {
  margin-top: -176px;
}

.sc-mt--177 {
  margin-top: -177px;
}

.sc-mt--178 {
  margin-top: -178px;
}

.sc-mt--179 {
  margin-top: -179px;
}

.sc-mt--180 {
  margin-top: -180px;
}

.sc-mt--181 {
  margin-top: -181px;
}

.sc-mt--182 {
  margin-top: -182px;
}

.sc-mt--183 {
  margin-top: -183px;
}

.sc-mt--184 {
  margin-top: -184px;
}

.sc-mt--185 {
  margin-top: -185px;
}

.sc-mt--186 {
  margin-top: -186px;
}

.sc-mt--187 {
  margin-top: -187px;
}

.sc-mt--188 {
  margin-top: -188px;
}

.sc-mt--189 {
  margin-top: -189px;
}

.sc-mt--190 {
  margin-top: -190px;
}

.sc-mt--191 {
  margin-top: -191px;
}

.sc-mt--192 {
  margin-top: -192px;
}

.sc-mt--193 {
  margin-top: -193px;
}

.sc-mt--194 {
  margin-top: -194px;
}

.sc-mt--195 {
  margin-top: -195px;
}

.sc-mt--196 {
  margin-top: -196px;
}

.sc-mt--197 {
  margin-top: -197px;
}

.sc-mt--198 {
  margin-top: -198px;
}

.sc-mt--199 {
  margin-top: -199px;
}

.sc-mt--200 {
  margin-top: -200px;
}

.sc-mb--1 {
  margin-bottom: -1px;
}

.sc-mb--2 {
  margin-bottom: -2px;
}

.sc-mb--3 {
  margin-bottom: -3px;
}

.sc-mb--4 {
  margin-bottom: -4px;
}

.sc-mb--5 {
  margin-bottom: -5px;
}

.sc-mb--6 {
  margin-bottom: -6px;
}

.sc-mb--7 {
  margin-bottom: -7px;
}

.sc-mb--8 {
  margin-bottom: -8px;
}

.sc-mb--9 {
  margin-bottom: -9px;
}

.sc-mb--10 {
  margin-bottom: -10px;
}

.sc-mb--11 {
  margin-bottom: -11px;
}

.sc-mb--12 {
  margin-bottom: -12px;
}

.sc-mb--13 {
  margin-bottom: -13px;
}

.sc-mb--14 {
  margin-bottom: -14px;
}

.sc-mb--15 {
  margin-bottom: -15px;
}

.sc-mb--16 {
  margin-bottom: -16px;
}

.sc-mb--17 {
  margin-bottom: -17px;
}

.sc-mb--18 {
  margin-bottom: -18px;
}

.sc-mb--19 {
  margin-bottom: -19px;
}

.sc-mb--20 {
  margin-bottom: -20px;
}

.sc-mb--21 {
  margin-bottom: -21px;
}

.sc-mb--22 {
  margin-bottom: -22px;
}

.sc-mb--23 {
  margin-bottom: -23px;
}

.sc-mb--24 {
  margin-bottom: -24px;
}

.sc-mb--25 {
  margin-bottom: -25px;
}

.sc-mb--26 {
  margin-bottom: -26px;
}

.sc-mb--27 {
  margin-bottom: -27px;
}

.sc-mb--28 {
  margin-bottom: -28px;
}

.sc-mb--29 {
  margin-bottom: -29px;
}

.sc-mb--30 {
  margin-bottom: -30px;
}

.sc-mb--31 {
  margin-bottom: -31px;
}

.sc-mb--32 {
  margin-bottom: -32px;
}

.sc-mb--33 {
  margin-bottom: -33px;
}

.sc-mb--34 {
  margin-bottom: -34px;
}

.sc-mb--35 {
  margin-bottom: -35px;
}

.sc-mb--36 {
  margin-bottom: -36px;
}

.sc-mb--37 {
  margin-bottom: -37px;
}

.sc-mb--38 {
  margin-bottom: -38px;
}

.sc-mb--39 {
  margin-bottom: -39px;
}

.sc-mb--40 {
  margin-bottom: -40px;
}

.sc-mb--41 {
  margin-bottom: -41px;
}

.sc-mb--42 {
  margin-bottom: -42px;
}

.sc-mb--43 {
  margin-bottom: -43px;
}

.sc-mb--44 {
  margin-bottom: -44px;
}

.sc-mb--45 {
  margin-bottom: -45px;
}

.sc-mb--46 {
  margin-bottom: -46px;
}

.sc-mb--47 {
  margin-bottom: -47px;
}

.sc-mb--48 {
  margin-bottom: -48px;
}

.sc-mb--49 {
  margin-bottom: -49px;
}

.sc-mb--50 {
  margin-bottom: -50px;
}

.sc-mb--51 {
  margin-bottom: -51px;
}

.sc-mb--52 {
  margin-bottom: -52px;
}

.sc-mb--53 {
  margin-bottom: -53px;
}

.sc-mb--54 {
  margin-bottom: -54px;
}

.sc-mb--55 {
  margin-bottom: -55px;
}

.sc-mb--56 {
  margin-bottom: -56px;
}

.sc-mb--57 {
  margin-bottom: -57px;
}

.sc-mb--58 {
  margin-bottom: -58px;
}

.sc-mb--59 {
  margin-bottom: -59px;
}

.sc-mb--60 {
  margin-bottom: -60px;
}

.sc-mb--61 {
  margin-bottom: -61px;
}

.sc-mb--62 {
  margin-bottom: -62px;
}

.sc-mb--63 {
  margin-bottom: -63px;
}

.sc-mb--64 {
  margin-bottom: -64px;
}

.sc-mb--65 {
  margin-bottom: -65px;
}

.sc-mb--66 {
  margin-bottom: -66px;
}

.sc-mb--67 {
  margin-bottom: -67px;
}

.sc-mb--68 {
  margin-bottom: -68px;
}

.sc-mb--69 {
  margin-bottom: -69px;
}

.sc-mb--70 {
  margin-bottom: -70px;
}

.sc-mb--71 {
  margin-bottom: -71px;
}

.sc-mb--72 {
  margin-bottom: -72px;
}

.sc-mb--73 {
  margin-bottom: -73px;
}

.sc-mb--74 {
  margin-bottom: -74px;
}

.sc-mb--75 {
  margin-bottom: -75px;
}

.sc-mb--76 {
  margin-bottom: -76px;
}

.sc-mb--77 {
  margin-bottom: -77px;
}

.sc-mb--78 {
  margin-bottom: -78px;
}

.sc-mb--79 {
  margin-bottom: -79px;
}

.sc-mb--80 {
  margin-bottom: -80px;
}

.sc-mb--81 {
  margin-bottom: -81px;
}

.sc-mb--82 {
  margin-bottom: -82px;
}

.sc-mb--83 {
  margin-bottom: -83px;
}

.sc-mb--84 {
  margin-bottom: -84px;
}

.sc-mb--85 {
  margin-bottom: -85px;
}

.sc-mb--86 {
  margin-bottom: -86px;
}

.sc-mb--87 {
  margin-bottom: -87px;
}

.sc-mb--88 {
  margin-bottom: -88px;
}

.sc-mb--89 {
  margin-bottom: -89px;
}

.sc-mb--90 {
  margin-bottom: -90px;
}

.sc-mb--91 {
  margin-bottom: -91px;
}

.sc-mb--92 {
  margin-bottom: -92px;
}

.sc-mb--93 {
  margin-bottom: -93px;
}

.sc-mb--94 {
  margin-bottom: -94px;
}

.sc-mb--95 {
  margin-bottom: -95px;
}

.sc-mb--96 {
  margin-bottom: -96px;
}

.sc-mb--97 {
  margin-bottom: -97px;
}

.sc-mb--98 {
  margin-bottom: -98px;
}

.sc-mb--99 {
  margin-bottom: -99px;
}

.sc-mb--100 {
  margin-bottom: -100px;
}

.sc-mb--101 {
  margin-bottom: -101px;
}

.sc-mb--102 {
  margin-bottom: -102px;
}

.sc-mb--103 {
  margin-bottom: -103px;
}

.sc-mb--104 {
  margin-bottom: -104px;
}

.sc-mb--105 {
  margin-bottom: -105px;
}

.sc-mb--106 {
  margin-bottom: -106px;
}

.sc-mb--107 {
  margin-bottom: -107px;
}

.sc-mb--108 {
  margin-bottom: -108px;
}

.sc-mb--109 {
  margin-bottom: -109px;
}

.sc-mb--110 {
  margin-bottom: -110px;
}

.sc-mb--111 {
  margin-bottom: -111px;
}

.sc-mb--112 {
  margin-bottom: -112px;
}

.sc-mb--113 {
  margin-bottom: -113px;
}

.sc-mb--114 {
  margin-bottom: -114px;
}

.sc-mb--115 {
  margin-bottom: -115px;
}

.sc-mb--116 {
  margin-bottom: -116px;
}

.sc-mb--117 {
  margin-bottom: -117px;
}

.sc-mb--118 {
  margin-bottom: -118px;
}

.sc-mb--119 {
  margin-bottom: -119px;
}

.sc-mb--120 {
  margin-bottom: -120px;
}

.sc-mb--121 {
  margin-bottom: -121px;
}

.sc-mb--122 {
  margin-bottom: -122px;
}

.sc-mb--123 {
  margin-bottom: -123px;
}

.sc-mb--124 {
  margin-bottom: -124px;
}

.sc-mb--125 {
  margin-bottom: -125px;
}

.sc-mb--126 {
  margin-bottom: -126px;
}

.sc-mb--127 {
  margin-bottom: -127px;
}

.sc-mb--128 {
  margin-bottom: -128px;
}

.sc-mb--129 {
  margin-bottom: -129px;
}

.sc-mb--130 {
  margin-bottom: -130px;
}

.sc-mb--131 {
  margin-bottom: -131px;
}

.sc-mb--132 {
  margin-bottom: -132px;
}

.sc-mb--133 {
  margin-bottom: -133px;
}

.sc-mb--134 {
  margin-bottom: -134px;
}

.sc-mb--135 {
  margin-bottom: -135px;
}

.sc-mb--136 {
  margin-bottom: -136px;
}

.sc-mb--137 {
  margin-bottom: -137px;
}

.sc-mb--138 {
  margin-bottom: -138px;
}

.sc-mb--139 {
  margin-bottom: -139px;
}

.sc-mb--140 {
  margin-bottom: -140px;
}

.sc-mb--141 {
  margin-bottom: -141px;
}

.sc-mb--142 {
  margin-bottom: -142px;
}

.sc-mb--143 {
  margin-bottom: -143px;
}

.sc-mb--144 {
  margin-bottom: -144px;
}

.sc-mb--145 {
  margin-bottom: -145px;
}

.sc-mb--146 {
  margin-bottom: -146px;
}

.sc-mb--147 {
  margin-bottom: -147px;
}

.sc-mb--148 {
  margin-bottom: -148px;
}

.sc-mb--149 {
  margin-bottom: -149px;
}

.sc-mb--150 {
  margin-bottom: -150px;
}

.sc-mb--151 {
  margin-bottom: -151px;
}

.sc-mb--152 {
  margin-bottom: -152px;
}

.sc-mb--153 {
  margin-bottom: -153px;
}

.sc-mb--154 {
  margin-bottom: -154px;
}

.sc-mb--155 {
  margin-bottom: -155px;
}

.sc-mb--156 {
  margin-bottom: -156px;
}

.sc-mb--157 {
  margin-bottom: -157px;
}

.sc-mb--158 {
  margin-bottom: -158px;
}

.sc-mb--159 {
  margin-bottom: -159px;
}

.sc-mb--160 {
  margin-bottom: -160px;
}

.sc-mb--161 {
  margin-bottom: -161px;
}

.sc-mb--162 {
  margin-bottom: -162px;
}

.sc-mb--163 {
  margin-bottom: -163px;
}

.sc-mb--164 {
  margin-bottom: -164px;
}

.sc-mb--165 {
  margin-bottom: -165px;
}

.sc-mb--166 {
  margin-bottom: -166px;
}

.sc-mb--167 {
  margin-bottom: -167px;
}

.sc-mb--168 {
  margin-bottom: -168px;
}

.sc-mb--169 {
  margin-bottom: -169px;
}

.sc-mb--170 {
  margin-bottom: -170px;
}

.sc-mb--171 {
  margin-bottom: -171px;
}

.sc-mb--172 {
  margin-bottom: -172px;
}

.sc-mb--173 {
  margin-bottom: -173px;
}

.sc-mb--174 {
  margin-bottom: -174px;
}

.sc-mb--175 {
  margin-bottom: -175px;
}

.sc-mb--176 {
  margin-bottom: -176px;
}

.sc-mb--177 {
  margin-bottom: -177px;
}

.sc-mb--178 {
  margin-bottom: -178px;
}

.sc-mb--179 {
  margin-bottom: -179px;
}

.sc-mb--180 {
  margin-bottom: -180px;
}

.sc-mb--181 {
  margin-bottom: -181px;
}

.sc-mb--182 {
  margin-bottom: -182px;
}

.sc-mb--183 {
  margin-bottom: -183px;
}

.sc-mb--184 {
  margin-bottom: -184px;
}

.sc-mb--185 {
  margin-bottom: -185px;
}

.sc-mb--186 {
  margin-bottom: -186px;
}

.sc-mb--187 {
  margin-bottom: -187px;
}

.sc-mb--188 {
  margin-bottom: -188px;
}

.sc-mb--189 {
  margin-bottom: -189px;
}

.sc-mb--190 {
  margin-bottom: -190px;
}

.sc-mb--191 {
  margin-bottom: -191px;
}

.sc-mb--192 {
  margin-bottom: -192px;
}

.sc-mb--193 {
  margin-bottom: -193px;
}

.sc-mb--194 {
  margin-bottom: -194px;
}

.sc-mb--195 {
  margin-bottom: -195px;
}

.sc-mb--196 {
  margin-bottom: -196px;
}

.sc-mb--197 {
  margin-bottom: -197px;
}

.sc-mb--198 {
  margin-bottom: -198px;
}

.sc-mb--199 {
  margin-bottom: -199px;
}

.sc-mb--200 {
  margin-bottom: -200px;
}

.sc-ml--1 {
  margin-left: -1px;
}

.sc-ml--2 {
  margin-left: -2px;
}

.sc-ml--3 {
  margin-left: -3px;
}

.sc-ml--4 {
  margin-left: -4px;
}

.sc-ml--5 {
  margin-left: -5px;
}

.sc-ml--6 {
  margin-left: -6px;
}

.sc-ml--7 {
  margin-left: -7px;
}

.sc-ml--8 {
  margin-left: -8px;
}

.sc-ml--9 {
  margin-left: -9px;
}

.sc-ml--10 {
  margin-left: -10px;
}

.sc-ml--11 {
  margin-left: -11px;
}

.sc-ml--12 {
  margin-left: -12px;
}

.sc-ml--13 {
  margin-left: -13px;
}

.sc-ml--14 {
  margin-left: -14px;
}

.sc-ml--15 {
  margin-left: -15px;
}

.sc-ml--16 {
  margin-left: -16px;
}

.sc-ml--17 {
  margin-left: -17px;
}

.sc-ml--18 {
  margin-left: -18px;
}

.sc-ml--19 {
  margin-left: -19px;
}

.sc-ml--20 {
  margin-left: -20px;
}

.sc-ml--21 {
  margin-left: -21px;
}

.sc-ml--22 {
  margin-left: -22px;
}

.sc-ml--23 {
  margin-left: -23px;
}

.sc-ml--24 {
  margin-left: -24px;
}

.sc-ml--25 {
  margin-left: -25px;
}

.sc-ml--26 {
  margin-left: -26px;
}

.sc-ml--27 {
  margin-left: -27px;
}

.sc-ml--28 {
  margin-left: -28px;
}

.sc-ml--29 {
  margin-left: -29px;
}

.sc-ml--30 {
  margin-left: -30px;
}

.sc-ml--31 {
  margin-left: -31px;
}

.sc-ml--32 {
  margin-left: -32px;
}

.sc-ml--33 {
  margin-left: -33px;
}

.sc-ml--34 {
  margin-left: -34px;
}

.sc-ml--35 {
  margin-left: -35px;
}

.sc-ml--36 {
  margin-left: -36px;
}

.sc-ml--37 {
  margin-left: -37px;
}

.sc-ml--38 {
  margin-left: -38px;
}

.sc-ml--39 {
  margin-left: -39px;
}

.sc-ml--41 {
  margin-left: -41px;
}

.sc-ml--42 {
  margin-left: -42px;
}

.sc-ml--43 {
  margin-left: -43px;
}

.sc-ml--44 {
  margin-left: -44px;
}

.sc-ml--45 {
  margin-left: -45px;
}

.sc-ml--46 {
  margin-left: -46px;
}

.sc-ml--47 {
  margin-left: -47px;
}

.sc-ml--48 {
  margin-left: -48px;
}

.sc-ml--49 {
  margin-left: -49px;
}

.sc-ml--50 {
  margin-left: -50px;
}

.sc-ml--51 {
  margin-left: -51px;
}

.sc-ml--52 {
  margin-left: -52px;
}

.sc-ml--53 {
  margin-left: -53px;
}

.sc-ml--54 {
  margin-left: -54px;
}

.sc-ml--55 {
  margin-left: -55px;
}

.sc-ml--56 {
  margin-left: -56px;
}

.sc-ml--57 {
  margin-left: -57px;
}

.sc-ml--58 {
  margin-left: -58px;
}

.sc-ml--59 {
  margin-left: -59px;
}

.sc-ml--60 {
  margin-left: -60px;
}

.sc-ml--61 {
  margin-left: -61px;
}

.sc-ml--62 {
  margin-left: -62px;
}

.sc-ml--63 {
  margin-left: -63px;
}

.sc-ml--64 {
  margin-left: -64px;
}

.sc-ml--65 {
  margin-left: -65px;
}

.sc-ml--66 {
  margin-left: -66px;
}

.sc-ml--67 {
  margin-left: -67px;
}

.sc-ml--68 {
  margin-left: -68px;
}

.sc-ml--69 {
  margin-left: -69px;
}

.sc-ml--70 {
  margin-left: -70px;
}

.sc-ml--71 {
  margin-left: -71px;
}

.sc-ml--72 {
  margin-left: -72px;
}

.sc-ml--73 {
  margin-left: -73px;
}

.sc-ml--74 {
  margin-left: -74px;
}

.sc-ml--75 {
  margin-left: -75px;
}

.sc-ml--76 {
  margin-left: -76px;
}

.sc-ml--77 {
  margin-left: -77px;
}

.sc-ml--78 {
  margin-left: -78px;
}

.sc-ml--79 {
  margin-left: -79px;
}

.sc-ml--80 {
  margin-left: -80px;
}

.sc-ml--81 {
  margin-left: -81px;
}

.sc-ml--82 {
  margin-left: -82px;
}

.sc-ml--83 {
  margin-left: -83px;
}

.sc-ml--84 {
  margin-left: -84px;
}

.sc-ml--85 {
  margin-left: -85px;
}

.sc-ml--86 {
  margin-left: -86px;
}

.sc-ml--87 {
  margin-left: -87px;
}

.sc-ml--88 {
  margin-left: -88px;
}

.sc-ml--89 {
  margin-left: -89px;
}

.sc-ml--90 {
  margin-left: -90px;
}

.sc-ml--91 {
  margin-left: -91px;
}

.sc-ml--92 {
  margin-left: -92px;
}

.sc-ml--93 {
  margin-left: -93px;
}

.sc-ml--94 {
  margin-left: -94px;
}

.sc-ml--95 {
  margin-left: -95px;
}

.sc-ml--96 {
  margin-left: -96px;
}

.sc-ml--97 {
  margin-left: -97px;
}

.sc-ml--98 {
  margin-left: -98px;
}

.sc-ml--99 {
  margin-left: -99px;
}

.sc-ml--100 {
  margin-left: -100px;
}

.sc-ml--101 {
  margin-left: -101px;
}

.sc-ml--102 {
  margin-left: -102px;
}

.sc-ml--103 {
  margin-left: -103px;
}

.sc-ml--104 {
  margin-left: -104px;
}

.sc-ml--105 {
  margin-left: -105px;
}

.sc-ml--106 {
  margin-left: -106px;
}

.sc-ml--107 {
  margin-left: -107px;
}

.sc-ml--108 {
  margin-left: -108px;
}

.sc-ml--109 {
  margin-left: -109px;
}

.sc-ml--110 {
  margin-left: -110px;
}

.sc-ml--111 {
  margin-left: -111px;
}

.sc-ml--112 {
  margin-left: -112px;
}

.sc-ml--113 {
  margin-left: -113px;
}

.sc-ml--114 {
  margin-left: -114px;
}

.sc-ml--115 {
  margin-left: -115px;
}

.sc-ml--116 {
  margin-left: -116px;
}

.sc-ml--117 {
  margin-left: -117px;
}

.sc-ml--118 {
  margin-left: -118px;
}

.sc-ml--119 {
  margin-left: -119px;
}

.sc-ml--120 {
  margin-left: -120px;
}

.sc-ml--121 {
  margin-left: -121px;
}

.sc-ml--122 {
  margin-left: -122px;
}

.sc-ml--123 {
  margin-left: -123px;
}

.sc-ml--124 {
  margin-left: -124px;
}

.sc-ml--125 {
  margin-left: -125px;
}

.sc-ml--126 {
  margin-left: -126px;
}

.sc-ml--127 {
  margin-left: -127px;
}

.sc-ml--128 {
  margin-left: -128px;
}

.sc-ml--129 {
  margin-left: -129px;
}

.sc-ml--130 {
  margin-left: -130px;
}

.sc-ml--131 {
  margin-left: -131px;
}

.sc-ml--132 {
  margin-left: -132px;
}

.sc-ml--133 {
  margin-left: -133px;
}

.sc-ml--134 {
  margin-left: -134px;
}

.sc-ml--135 {
  margin-left: -135px;
}

.sc-ml--136 {
  margin-left: -136px;
}

.sc-ml--137 {
  margin-left: -137px;
}

.sc-ml--138 {
  margin-left: -138px;
}

.sc-ml--139 {
  margin-left: -139px;
}

.sc-ml--140 {
  margin-left: -140px;
}

.sc-ml--141 {
  margin-left: -141px;
}

.sc-ml--142 {
  margin-left: -142px;
}

.sc-ml--143 {
  margin-left: -143px;
}

.sc-ml--144 {
  margin-left: -144px;
}

.sc-ml--145 {
  margin-left: -145px;
}

.sc-ml--146 {
  margin-left: -146px;
}

.sc-ml--147 {
  margin-left: -147px;
}

.sc-ml--148 {
  margin-left: -148px;
}

.sc-ml--149 {
  margin-left: -149px;
}

.sc-ml--150 {
  margin-left: -150px;
}

.sc-ml--151 {
  margin-left: -151px;
}

.sc-ml--152 {
  margin-left: -152px;
}

.sc-ml--153 {
  margin-left: -153px;
}

.sc-ml--154 {
  margin-left: -154px;
}

.sc-ml--155 {
  margin-left: -155px;
}

.sc-ml--156 {
  margin-left: -156px;
}

.sc-ml--157 {
  margin-left: -157px;
}

.sc-ml--158 {
  margin-left: -158px;
}

.sc-ml--159 {
  margin-left: -159px;
}

.sc-ml--160 {
  margin-left: -160px;
}

.sc-ml--161 {
  margin-left: -161px;
}

.sc-ml--162 {
  margin-left: -162px;
}

.sc-ml--163 {
  margin-left: -163px;
}

.sc-ml--164 {
  margin-left: -164px;
}

.sc-ml--165 {
  margin-left: -165px;
}

.sc-ml--166 {
  margin-left: -166px;
}

.sc-ml--167 {
  margin-left: -167px;
}

.sc-ml--168 {
  margin-left: -168px;
}

.sc-ml--169 {
  margin-left: -169px;
}

.sc-ml--170 {
  margin-left: -170px;
}

.sc-ml--171 {
  margin-left: -171px;
}

.sc-ml--172 {
  margin-left: -172px;
}

.sc-ml--173 {
  margin-left: -173px;
}

.sc-ml--174 {
  margin-left: -174px;
}

.sc-ml--175 {
  margin-left: -175px;
}

.sc-ml--176 {
  margin-left: -176px;
}

.sc-ml--177 {
  margin-left: -177px;
}

.sc-ml--178 {
  margin-left: -178px;
}

.sc-ml--179 {
  margin-left: -179px;
}

.sc-ml--180 {
  margin-left: -180px;
}

.sc-ml--181 {
  margin-left: -181px;
}

.sc-ml--182 {
  margin-left: -182px;
}

.sc-ml--183 {
  margin-left: -183px;
}

.sc-ml--184 {
  margin-left: -184px;
}

.sc-ml--185 {
  margin-left: -185px;
}

.sc-ml--186 {
  margin-left: -186px;
}

.sc-ml--187 {
  margin-left: -187px;
}

.sc-ml--188 {
  margin-left: -188px;
}

.sc-ml--189 {
  margin-left: -189px;
}

.sc-ml--190 {
  margin-left: -190px;
}

.sc-ml--191 {
  margin-left: -191px;
}

.sc-ml--192 {
  margin-left: -192px;
}

.sc-ml--193 {
  margin-left: -193px;
}

.sc-ml--194 {
  margin-left: -194px;
}

.sc-ml--195 {
  margin-left: -195px;
}

.sc-ml--196 {
  margin-left: -196px;
}

.sc-ml--197 {
  margin-left: -197px;
}

.sc-ml--198 {
  margin-left: -198px;
}

.sc-ml--199 {
  margin-left: -199px;
}

.sc-ml--200 {
  margin-left: -200px;
}

.sc-mr--1 {
  margin-right: -1px;
}

.sc-mr--2 {
  margin-right: -2px;
}

.sc-mr--3 {
  margin-right: -3px;
}

.sc-mr--4 {
  margin-right: -4px;
}

.sc-mr--5 {
  margin-right: -5px;
}

.sc-mr--6 {
  margin-right: -6px;
}

.sc-mr--7 {
  margin-right: -7px;
}

.sc-mr--8 {
  margin-right: -8px;
}

.sc-mr--9 {
  margin-right: -9px;
}

.sc-mr--10 {
  margin-right: -10px;
}

.sc-mr--11 {
  margin-right: -11px;
}

.sc-mr--12 {
  margin-right: -12px;
}

.sc-mr--13 {
  margin-right: -13px;
}

.sc-mr--14 {
  margin-right: -14px;
}

.sc-mr--15 {
  margin-right: -15px;
}

.sc-mr--16 {
  margin-right: -16px;
}

.sc-mr--17 {
  margin-right: -17px;
}

.sc-mr--18 {
  margin-right: -18px;
}

.sc-mr--19 {
  margin-right: -19px;
}

.sc-mr--20 {
  margin-right: -20px;
}

.sc-mr--21 {
  margin-right: -21px;
}

.sc-mr--22 {
  margin-right: -22px;
}

.sc-mr--23 {
  margin-right: -23px;
}

.sc-mr--24 {
  margin-right: -24px;
}

.sc-mr--25 {
  margin-right: -25px;
}

.sc-mr--26 {
  margin-right: -26px;
}

.sc-mr--27 {
  margin-right: -27px;
}

.sc-mr--28 {
  margin-right: -28px;
}

.sc-mr--29 {
  margin-right: -29px;
}

.sc-mr--30 {
  margin-right: -30px;
}

.sc-mr--31 {
  margin-right: -31px;
}

.sc-mr--32 {
  margin-right: -32px;
}

.sc-mr--33 {
  margin-right: -33px;
}

.sc-mr--34 {
  margin-right: -34px;
}

.sc-mr--35 {
  margin-right: -35px;
}

.sc-mr--36 {
  margin-right: -36px;
}

.sc-mr--37 {
  margin-right: -37px;
}

.sc-mr--38 {
  margin-right: -38px;
}

.sc-mr--39 {
  margin-right: -39px;
}

.sc-mr--40 {
  margin-right: -40px;
}

.sc-mr--41 {
  margin-right: -41px;
}

.sc-mr--42 {
  margin-right: -42px;
}

.sc-mr--43 {
  margin-right: -43px;
}

.sc-mr--44 {
  margin-right: -44px;
}

.sc-mr--45 {
  margin-right: -45px;
}

.sc-mr--46 {
  margin-right: -46px;
}

.sc-mr--47 {
  margin-right: -47px;
}

.sc-mr--48 {
  margin-right: -48px;
}

.sc-mr--49 {
  margin-right: -49px;
}

.sc-mr--50 {
  margin-right: -50px;
}

.sc-mr--51 {
  margin-right: -51px;
}

.sc-mr--52 {
  margin-right: -52px;
}

.sc-mr--53 {
  margin-right: -53px;
}

.sc-mr--54 {
  margin-right: -54px;
}

.sc-mr--55 {
  margin-right: -55px;
}

.sc-mr--56 {
  margin-right: -56px;
}

.sc-mr--57 {
  margin-right: -57px;
}

.sc-mr--58 {
  margin-right: -58px;
}

.sc-mr--59 {
  margin-right: -59px;
}

.sc-mr--60 {
  margin-right: -60px;
}

.sc-mr--61 {
  margin-right: -61px;
}

.sc-mr--62 {
  margin-right: -62px;
}

.sc-mr--63 {
  margin-right: -63px;
}

.sc-mr--64 {
  margin-right: -64px;
}

.sc-mr--65 {
  margin-right: -65px;
}

.sc-mr--66 {
  margin-right: -66px;
}

.sc-mr--67 {
  margin-right: -67px;
}

.sc-mr--68 {
  margin-right: -68px;
}

.sc-mr--69 {
  margin-right: -69px;
}

.sc-mr--70 {
  margin-right: -70px;
}

.sc-mr--71 {
  margin-right: -71px;
}

.sc-mr--72 {
  margin-right: -72px;
}

.sc-mr--73 {
  margin-right: -73px;
}

.sc-mr--74 {
  margin-right: -74px;
}

.sc-mr--75 {
  margin-right: -75px;
}

.sc-mr--76 {
  margin-right: -76px;
}

.sc-mr--77 {
  margin-right: -77px;
}

.sc-mr--78 {
  margin-right: -78px;
}

.sc-mr--79 {
  margin-right: -79px;
}

.sc-mr--80 {
  margin-right: -80px;
}

.sc-mr--81 {
  margin-right: -81px;
}

.sc-mr--82 {
  margin-right: -82px;
}

.sc-mr--83 {
  margin-right: -83px;
}

.sc-mr--84 {
  margin-right: -84px;
}

.sc-mr--85 {
  margin-right: -85px;
}

.sc-mr--86 {
  margin-right: -86px;
}

.sc-mr--87 {
  margin-right: -87px;
}

.sc-mr--88 {
  margin-right: -88px;
}

.sc-mr--89 {
  margin-right: -89px;
}

.sc-mr--90 {
  margin-right: -90px;
}

.sc-mr--91 {
  margin-right: -91px;
}

.sc-mr--92 {
  margin-right: -92px;
}

.sc-mr--93 {
  margin-right: -93px;
}

.sc-mr--94 {
  margin-right: -94px;
}

.sc-mr--95 {
  margin-right: -95px;
}

.sc-mr--96 {
  margin-right: -96px;
}

.sc-mr--97 {
  margin-right: -97px;
}

.sc-mr--98 {
  margin-right: -98px;
}

.sc-mr--99 {
  margin-right: -99px;
}

.sc-mr--100 {
  margin-right: -100px;
}

.sc-mr--101 {
  margin-right: -101px;
}

.sc-mr--102 {
  margin-right: -102px;
}

.sc-mr--103 {
  margin-right: -103px;
}

.sc-mr--104 {
  margin-right: -104px;
}

.sc-mr--105 {
  margin-right: -105px;
}

.sc-mr--106 {
  margin-right: -106px;
}

.sc-mr--107 {
  margin-right: -107px;
}

.sc-mr--108 {
  margin-right: -108px;
}

.sc-mr--109 {
  margin-right: -109px;
}

.sc-mr--110 {
  margin-right: -110px;
}

.sc-mr--111 {
  margin-right: -111px;
}

.sc-mr--112 {
  margin-right: -112px;
}

.sc-mr--113 {
  margin-right: -113px;
}

.sc-mr--114 {
  margin-right: -114px;
}

.sc-mr--115 {
  margin-right: -115px;
}

.sc-mr--116 {
  margin-right: -116px;
}

.sc-mr--117 {
  margin-right: -117px;
}

.sc-mr--118 {
  margin-right: -118px;
}

.sc-mr--119 {
  margin-right: -119px;
}

.sc-mr--120 {
  margin-right: -120px;
}

.sc-mr--121 {
  margin-right: -121px;
}

.sc-mr--122 {
  margin-right: -122px;
}

.sc-mr--123 {
  margin-right: -123px;
}

.sc-mr--124 {
  margin-right: -124px;
}

.sc-mr--125 {
  margin-right: -125px;
}

.sc-mr--126 {
  margin-right: -126px;
}

.sc-mr--127 {
  margin-right: -127px;
}

.sc-mr--128 {
  margin-right: -128px;
}

.sc-mr--129 {
  margin-right: -129px;
}

.sc-mr--130 {
  margin-right: -130px;
}

.sc-mr--131 {
  margin-right: -131px;
}

.sc-mr--132 {
  margin-right: -132px;
}

.sc-mr--133 {
  margin-right: -133px;
}

.sc-mr--134 {
  margin-right: -134px;
}

.sc-mr--135 {
  margin-right: -135px;
}

.sc-mr--136 {
  margin-right: -136px;
}

.sc-mr--137 {
  margin-right: -137px;
}

.sc-mr--138 {
  margin-right: -138px;
}

.sc-mr--139 {
  margin-right: -139px;
}

.sc-mr--140 {
  margin-right: -140px;
}

.sc-mr--141 {
  margin-right: -141px;
}

.sc-mr--142 {
  margin-right: -142px;
}

.sc-mr--143 {
  margin-right: -143px;
}

.sc-mr--144 {
  margin-right: -144px;
}

.sc-mr--145 {
  margin-right: -145px;
}

.sc-mr--146 {
  margin-right: -146px;
}

.sc-mr--147 {
  margin-right: -147px;
}

.sc-mr--148 {
  margin-right: -148px;
}

.sc-mr--149 {
  margin-right: -149px;
}

.sc-mr--150 {
  margin-right: -150px;
}

.sc-mr--151 {
  margin-right: -151px;
}

.sc-mr--152 {
  margin-right: -152px;
}

.sc-mr--153 {
  margin-right: -153px;
}

.sc-mr--154 {
  margin-right: -154px;
}

.sc-mr--155 {
  margin-right: -155px;
}

.sc-mr--156 {
  margin-right: -156px;
}

.sc-mr--157 {
  margin-right: -157px;
}

.sc-mr--158 {
  margin-right: -158px;
}

.sc-mr--159 {
  margin-right: -159px;
}

.sc-mr--160 {
  margin-right: -160px;
}

.sc-mr--161 {
  margin-right: -161px;
}

.sc-mr--162 {
  margin-right: -162px;
}

.sc-mr--163 {
  margin-right: -163px;
}

.sc-mr--164 {
  margin-right: -164px;
}

.sc-mr--165 {
  margin-right: -165px;
}

.sc-mr--166 {
  margin-right: -166px;
}

.sc-mr--167 {
  margin-right: -167px;
}

.sc-mr--168 {
  margin-right: -168px;
}

.sc-mr--169 {
  margin-right: -169px;
}

.sc-mr--170 {
  margin-right: -170px;
}

.sc-mr--171 {
  margin-right: -171px;
}

.sc-mr--172 {
  margin-right: -172px;
}

.sc-mr--173 {
  margin-right: -173px;
}

.sc-mr--174 {
  margin-right: -174px;
}

.sc-mr--175 {
  margin-right: -175px;
}

.sc-mr--176 {
  margin-right: -176px;
}

.sc-mr--177 {
  margin-right: -177px;
}

.sc-mr--178 {
  margin-right: -178px;
}

.sc-mr--179 {
  margin-right: -179px;
}

.sc-mr--180 {
  margin-right: -180px;
}

.sc-mr--181 {
  margin-right: -181px;
}

.sc-mr--182 {
  margin-right: -182px;
}

.sc-mr--183 {
  margin-right: -183px;
}

.sc-mr--184 {
  margin-right: -184px;
}

.sc-mr--185 {
  margin-right: -185px;
}

.sc-mr--186 {
  margin-right: -186px;
}

.sc-mr--187 {
  margin-right: -187px;
}

.sc-mr--188 {
  margin-right: -188px;
}

.sc-mr--189 {
  margin-right: -189px;
}

.sc-mr--190 {
  margin-right: -190px;
}

.sc-mr--191 {
  margin-right: -191px;
}

.sc-mr--192 {
  margin-right: -192px;
}

.sc-mr--193 {
  margin-right: -193px;
}

.sc-mr--194 {
  margin-right: -194px;
}

.sc-mr--195 {
  margin-right: -195px;
}

.sc-mr--196 {
  margin-right: -196px;
}

.sc-mr--197 {
  margin-right: -197px;
}

.sc-mr--198 {
  margin-right: -198px;
}

.sc-mr--199 {
  margin-right: -199px;
}

.sc-mr--200 {
  margin-right: -200px;
}

@media only screen and (max-width: 1199px) {
  .sc-lg-pl-0 {
    padding-left: 0;
  }

  .sc-lg-pr-0 {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .sc-md-padding-0 {
    padding: 0;
  }

  .sc-md-margin-0 {
    margin: 0;
  }

  .sc-md-pt-0 {
    padding-top: 0;
  }

  .sc-md-pt-1 {
    padding-top: 1px;
  }

  .sc-md-pt-2 {
    padding-top: 2px;
  }

  .sc-md-pt-3 {
    padding-top: 3px;
  }

  .sc-md-pt-4 {
    padding-top: 4px;
  }

  .sc-md-pt-5 {
    padding-top: 5px;
  }

  .sc-md-pt-6 {
    padding-top: 6px;
  }

  .sc-md-pt-7 {
    padding-top: 7px;
  }

  .sc-md-pt-8 {
    padding-top: 8px;
  }

  .sc-md-pt-9 {
    padding-top: 9px;
  }

  .sc-md-pt-10 {
    padding-top: 10px;
  }

  .sc-md-pt-11 {
    padding-top: 11px;
  }

  .sc-md-pt-12 {
    padding-top: 12px;
  }

  .sc-md-pt-13 {
    padding-top: 13px;
  }

  .sc-md-pt-14 {
    padding-top: 14px;
  }

  .sc-md-pt-15 {
    padding-top: 15px;
  }

  .sc-md-pt-16 {
    padding-top: 16px;
  }

  .sc-md-pt-17 {
    padding-top: 17px;
  }

  .sc-md-pt-18 {
    padding-top: 18px;
  }

  .sc-md-pt-19 {
    padding-top: 19px;
  }

  .sc-md-pt-20 {
    padding-top: 20px;
  }

  .sc-md-pt-21 {
    padding-top: 21px;
  }

  .sc-md-pt-22 {
    padding-top: 22px;
  }

  .sc-md-pt-23 {
    padding-top: 23px;
  }

  .sc-md-pt-24 {
    padding-top: 24px;
  }

  .sc-md-pt-25 {
    padding-top: 25px;
  }

  .sc-md-pt-26 {
    padding-top: 26px;
  }

  .sc-md-pt-27 {
    padding-top: 27px;
  }

  .sc-md-pt-28 {
    padding-top: 28px;
  }

  .sc-md-pt-29 {
    padding-top: 29px;
  }

  .sc-md-pt-30 {
    padding-top: 30px;
  }

  .sc-md-pt-31 {
    padding-top: 31px;
  }

  .sc-md-pt-32 {
    padding-top: 32px;
  }

  .sc-md-pt-33 {
    padding-top: 33px;
  }

  .sc-md-pt-34 {
    padding-top: 34px;
  }

  .sc-md-pt-35 {
    padding-top: 35px;
  }

  .sc-md-pt-36 {
    padding-top: 36px;
  }

  .sc-md-pt-37 {
    padding-top: 37px;
  }

  .sc-md-pt-38 {
    padding-top: 38px;
  }

  .sc-md-pt-39 {
    padding-top: 39px;
  }

  .sc-md-pt-40 {
    padding-top: 40px;
  }

  .sc-md-pt-41 {
    padding-top: 41px;
  }

  .sc-md-pt-42 {
    padding-top: 42px;
  }

  .sc-md-pt-43 {
    padding-top: 43px;
  }

  .sc-md-pt-44 {
    padding-top: 44px;
  }

  .sc-md-pt-45 {
    padding-top: 45px;
  }

  .sc-md-pt-46 {
    padding-top: 46px;
  }

  .sc-md-pt-47 {
    padding-top: 47px;
  }

  .sc-md-pt-48 {
    padding-top: 48px;
  }

  .sc-md-pt-49 {
    padding-top: 49px;
  }

  .sc-md-pt-50 {
    padding-top: 50px;
  }

  .sc-md-pt-51 {
    padding-top: 51px;
  }

  .sc-md-pt-52 {
    padding-top: 52px;
  }

  .sc-md-pt-53 {
    padding-top: 53px;
  }

  .sc-md-pt-54 {
    padding-top: 54px;
  }

  .sc-md-pt-55 {
    padding-top: 55px;
  }

  .sc-md-pt-56 {
    padding-top: 56px;
  }

  .sc-md-pt-57 {
    padding-top: 57px;
  }

  .sc-md-pt-58 {
    padding-top: 58px;
  }

  .sc-md-pt-59 {
    padding-top: 59px;
  }

  .sc-md-pt-60 {
    padding-top: 60px;
  }

  .sc-md-pt-61 {
    padding-top: 61px;
  }

  .sc-md-pt-62 {
    padding-top: 62px;
  }

  .sc-md-pt-63 {
    padding-top: 63px;
  }

  .sc-md-pt-64 {
    padding-top: 64px;
  }

  .sc-md-pt-65 {
    padding-top: 65px;
  }

  .sc-md-pt-66 {
    padding-top: 66px;
  }

  .sc-md-pt-67 {
    padding-top: 67px;
  }

  .sc-md-pt-68 {
    padding-top: 68px;
  }

  .sc-md-pt-69 {
    padding-top: 69px;
  }

  .sc-md-pt-70 {
    padding-top: 70px;
  }

  .sc-md-pt-71 {
    padding-top: 71px;
  }

  .sc-md-pt-72 {
    padding-top: 72px;
  }

  .sc-md-pt-73 {
    padding-top: 73px;
  }

  .sc-md-pt-74 {
    padding-top: 74px;
  }

  .sc-md-pt-75 {
    padding-top: 75px;
  }

  .sc-md-pt-76 {
    padding-top: 76px;
  }

  .sc-md-pt-77 {
    padding-top: 77px;
  }

  .sc-md-pt-78 {
    padding-top: 78px;
  }

  .sc-md-pt-79 {
    padding-top: 79px;
  }

  .sc-md-pt-80 {
    padding-top: 80px;
  }

  .sc-md-pt-81 {
    padding-top: 81px;
  }

  .sc-md-pt-82 {
    padding-top: 82px;
  }

  .sc-md-pt-83 {
    padding-top: 83px;
  }

  .sc-md-pt-84 {
    padding-top: 84px;
  }

  .sc-md-pt-85 {
    padding-top: 85px;
  }

  .sc-md-pt-86 {
    padding-top: 86px;
  }

  .sc-md-pt-87 {
    padding-top: 87px;
  }

  .sc-md-pt-88 {
    padding-top: 88px;
  }

  .sc-md-pt-89 {
    padding-top: 89px;
  }

  .sc-md-pt-90 {
    padding-top: 90px;
  }

  .sc-md-pt-91 {
    padding-top: 91px;
  }

  .sc-md-pt-92 {
    padding-top: 92px;
  }

  .sc-md-pt-93 {
    padding-top: 93px;
  }

  .sc-md-pt-94 {
    padding-top: 94px;
  }

  .sc-md-pt-95 {
    padding-top: 95px;
  }

  .sc-md-pt-96 {
    padding-top: 96px;
  }

  .sc-md-pt-97 {
    padding-top: 97px;
  }

  .sc-md-pt-98 {
    padding-top: 98px;
  }

  .sc-md-pt-99 {
    padding-top: 99px;
  }

  .sc-md-pt-100 {
    padding-top: 100px;
  }

  .sc-md-pt-101 {
    padding-top: 101px;
  }

  .sc-md-pt-102 {
    padding-top: 102px;
  }

  .sc-md-pt-103 {
    padding-top: 103px;
  }

  .sc-md-pt-104 {
    padding-top: 104px;
  }

  .sc-md-pt-105 {
    padding-top: 105px;
  }

  .sc-md-pt-106 {
    padding-top: 106px;
  }

  .sc-md-pt-107 {
    padding-top: 107px;
  }

  .sc-md-pt-108 {
    padding-top: 108px;
  }

  .sc-md-pt-109 {
    padding-top: 109px;
  }

  .sc-md-pt-110 {
    padding-top: 110px;
  }

  .sc-md-pt-111 {
    padding-top: 111px;
  }

  .sc-md-pt-112 {
    padding-top: 112px;
  }

  .sc-md-pt-113 {
    padding-top: 113px;
  }

  .sc-md-pt-114 {
    padding-top: 114px;
  }

  .sc-md-pt-115 {
    padding-top: 115px;
  }

  .sc-md-pt-116 {
    padding-top: 116px;
  }

  .sc-md-pt-117 {
    padding-top: 117px;
  }

  .sc-md-pt-118 {
    padding-top: 118px;
  }

  .sc-md-pt-119 {
    padding-top: 119px;
  }

  .sc-md-pt-120 {
    padding-top: 120px;
  }

  .sc-md-pt-121 {
    padding-top: 121px;
  }

  .sc-md-pt-122 {
    padding-top: 122px;
  }

  .sc-md-pt-123 {
    padding-top: 123px;
  }

  .sc-md-pt-124 {
    padding-top: 124px;
  }

  .sc-md-pt-125 {
    padding-top: 125px;
  }

  .sc-md-pt-126 {
    padding-top: 126px;
  }

  .sc-md-pt-127 {
    padding-top: 127px;
  }

  .sc-md-pt-128 {
    padding-top: 128px;
  }

  .sc-md-pt-129 {
    padding-top: 129px;
  }

  .sc-md-pt-130 {
    padding-top: 130px;
  }

  .sc-md-pt-131 {
    padding-top: 131px;
  }

  .sc-md-pt-132 {
    padding-top: 132px;
  }

  .sc-md-pt-133 {
    padding-top: 133px;
  }

  .sc-md-pt-134 {
    padding-top: 134px;
  }

  .sc-md-pt-135 {
    padding-top: 135px;
  }

  .sc-md-pt-136 {
    padding-top: 136px;
  }

  .sc-md-pt-137 {
    padding-top: 137px;
  }

  .sc-md-pt-138 {
    padding-top: 138px;
  }

  .sc-md-pt-139 {
    padding-top: 139px;
  }

  .sc-md-pt-140 {
    padding-top: 140px;
  }

  .sc-md-pt-141 {
    padding-top: 141px;
  }

  .sc-md-pt-142 {
    padding-top: 142px;
  }

  .sc-md-pt-143 {
    padding-top: 143px;
  }

  .sc-md-pt-144 {
    padding-top: 144px;
  }

  .sc-md-pt-145 {
    padding-top: 145px;
  }

  .sc-md-pt-146 {
    padding-top: 146px;
  }

  .sc-md-pt-147 {
    padding-top: 147px;
  }

  .sc-md-pt-148 {
    padding-top: 148px;
  }

  .sc-md-pt-149 {
    padding-top: 149px;
  }

  .sc-md-pt-150 {
    padding-top: 150px;
  }

  .sc-md-pt-151 {
    padding-top: 151px;
  }

  .sc-md-pt-152 {
    padding-top: 152px;
  }

  .sc-md-pt-153 {
    padding-top: 153px;
  }

  .sc-md-pt-154 {
    padding-top: 154px;
  }

  .sc-md-pt-155 {
    padding-top: 155px;
  }

  .sc-md-pt-156 {
    padding-top: 156px;
  }

  .sc-md-pt-157 {
    padding-top: 157px;
  }

  .sc-md-pt-158 {
    padding-top: 158px;
  }

  .sc-md-pt-159 {
    padding-top: 159px;
  }

  .sc-md-pt-160 {
    padding-top: 160px;
  }

  .sc-md-pt-161 {
    padding-top: 161px;
  }

  .sc-md-pt-162 {
    padding-top: 162px;
  }

  .sc-md-pt-163 {
    padding-top: 163px;
  }

  .sc-md-pt-164 {
    padding-top: 164px;
  }

  .sc-md-pt-165 {
    padding-top: 165px;
  }

  .sc-md-pt-166 {
    padding-top: 166px;
  }

  .sc-md-pt-167 {
    padding-top: 167px;
  }

  .sc-md-pt-168 {
    padding-top: 168px;
  }

  .sc-md-pt-169 {
    padding-top: 169px;
  }

  .sc-md-pt-170 {
    padding-top: 170px;
  }

  .sc-md-pt-171 {
    padding-top: 171px;
  }

  .sc-md-pt-172 {
    padding-top: 172px;
  }

  .sc-md-pt-173 {
    padding-top: 173px;
  }

  .sc-md-pt-174 {
    padding-top: 174px;
  }

  .sc-md-pt-175 {
    padding-top: 175px;
  }

  .sc-md-pt-176 {
    padding-top: 176px;
  }

  .sc-md-pt-177 {
    padding-top: 177px;
  }

  .sc-md-pt-178 {
    padding-top: 178px;
  }

  .sc-md-pt-179 {
    padding-top: 179px;
  }

  .sc-md-pt-180 {
    padding-top: 180px;
  }

  .sc-md-pt-181 {
    padding-top: 181px;
  }

  .sc-md-pt-182 {
    padding-top: 182px;
  }

  .sc-md-pt-183 {
    padding-top: 183px;
  }

  .sc-md-pt-184 {
    padding-top: 184px;
  }

  .sc-md-pt-185 {
    padding-top: 185px;
  }

  .sc-md-pt-186 {
    padding-top: 186px;
  }

  .sc-md-pt-187 {
    padding-top: 187px;
  }

  .sc-md-pt-188 {
    padding-top: 188px;
  }

  .sc-md-pt-189 {
    padding-top: 189px;
  }

  .sc-md-pt-190 {
    padding-top: 190px;
  }

  .sc-md-pt-191 {
    padding-top: 191px;
  }

  .sc-md-pt-192 {
    padding-top: 192px;
  }

  .sc-md-pt-193 {
    padding-top: 193px;
  }

  .sc-md-pt-194 {
    padding-top: 194px;
  }

  .sc-md-pt-195 {
    padding-top: 195px;
  }

  .sc-md-pt-196 {
    padding-top: 196px;
  }

  .sc-md-pt-197 {
    padding-top: 197px;
  }

  .sc-md-pt-198 {
    padding-top: 198px;
  }

  .sc-md-pt-199 {
    padding-top: 199px;
  }

  .sc-md-pt-200 {
    padding-top: 200px;
  }

  .sc-md-pb-0 {
    padding-bottom: 0;
  }

  .sc-md-pb-1 {
    padding-bottom: 1px;
  }

  .sc-md-pb-2 {
    padding-bottom: 2px;
  }

  .sc-md-pb-3 {
    padding-bottom: 3px;
  }

  .sc-md-pb-4 {
    padding-bottom: 4px;
  }

  .sc-md-pb-5 {
    padding-bottom: 5px;
  }

  .sc-md-pb-6 {
    padding-bottom: 6px;
  }

  .sc-md-pb-7 {
    padding-bottom: 7px;
  }

  .sc-md-pb-8 {
    padding-bottom: 8px;
  }

  .sc-md-pb-9 {
    padding-bottom: 9px;
  }

  .sc-md-pb-10 {
    padding-bottom: 10px;
  }

  .sc-md-pb-11 {
    padding-bottom: 11px;
  }

  .sc-md-pb-12 {
    padding-bottom: 12px;
  }

  .sc-md-pb-13 {
    padding-bottom: 13px;
  }

  .sc-md-pb-14 {
    padding-bottom: 14px;
  }

  .sc-md-pb-15 {
    padding-bottom: 15px;
  }

  .sc-md-pb-16 {
    padding-bottom: 16px;
  }

  .sc-md-pb-17 {
    padding-bottom: 17px;
  }

  .sc-md-pb-18 {
    padding-bottom: 18px;
  }

  .sc-md-pb-19 {
    padding-bottom: 19px;
  }

  .sc-md-pb-20 {
    padding-bottom: 20px;
  }

  .sc-md-pb-21 {
    padding-bottom: 21px;
  }

  .sc-md-pb-22 {
    padding-bottom: 22px;
  }

  .sc-md-pb-23 {
    padding-bottom: 23px;
  }

  .sc-md-pb-24 {
    padding-bottom: 24px;
  }

  .sc-md-pb-25 {
    padding-bottom: 25px;
  }

  .sc-md-pb-26 {
    padding-bottom: 26px;
  }

  .sc-md-pb-27 {
    padding-bottom: 27px;
  }

  .sc-md-pb-28 {
    padding-bottom: 28px;
  }

  .sc-md-pb-29 {
    padding-bottom: 29px;
  }

  .sc-md-pb-30 {
    padding-bottom: 30px;
  }

  .sc-md-pb-31 {
    padding-bottom: 31px;
  }

  .sc-md-pb-32 {
    padding-bottom: 32px;
  }

  .sc-md-pb-33 {
    padding-bottom: 33px;
  }

  .sc-md-pb-34 {
    padding-bottom: 34px;
  }

  .sc-md-pb-35 {
    padding-bottom: 35px;
  }

  .sc-md-pb-36 {
    padding-bottom: 36px;
  }

  .sc-md-pb-37 {
    padding-bottom: 37px;
  }

  .sc-md-pb-38 {
    padding-bottom: 38px;
  }

  .sc-md-pb-39 {
    padding-bottom: 39px;
  }

  .sc-md-pb-40 {
    padding-bottom: 40px;
  }

  .sc-md-pb-41 {
    padding-bottom: 41px;
  }

  .sc-md-pb-42 {
    padding-bottom: 42px;
  }

  .sc-md-pb-43 {
    padding-bottom: 43px;
  }

  .sc-md-pb-44 {
    padding-bottom: 44px;
  }

  .sc-md-pb-45 {
    padding-bottom: 45px;
  }

  .sc-md-pb-46 {
    padding-bottom: 46px;
  }

  .sc-md-pb-47 {
    padding-bottom: 47px;
  }

  .sc-md-pb-48 {
    padding-bottom: 48px;
  }

  .sc-md-pb-49 {
    padding-bottom: 49px;
  }

  .sc-md-pb-50 {
    padding-bottom: 50px;
  }

  .sc-md-pb-51 {
    padding-bottom: 51px;
  }

  .sc-md-pb-52 {
    padding-bottom: 52px;
  }

  .sc-md-pb-53 {
    padding-bottom: 53px;
  }

  .sc-md-pb-54 {
    padding-bottom: 54px;
  }

  .sc-md-pb-55 {
    padding-bottom: 55px;
  }

  .sc-md-pb-56 {
    padding-bottom: 56px;
  }

  .sc-md-pb-57 {
    padding-bottom: 57px;
  }

  .sc-md-pb-58 {
    padding-bottom: 58px;
  }

  .sc-md-pb-59 {
    padding-bottom: 59px;
  }

  .sc-md-pb-60 {
    padding-bottom: 60px;
  }

  .sc-md-pb-61 {
    padding-bottom: 61px;
  }

  .sc-md-pb-62 {
    padding-bottom: 62px;
  }

  .sc-md-pb-63 {
    padding-bottom: 63px;
  }

  .sc-md-pb-64 {
    padding-bottom: 64px;
  }

  .sc-md-pb-65 {
    padding-bottom: 65px;
  }

  .sc-md-pb-66 {
    padding-bottom: 66px;
  }

  .sc-md-pb-67 {
    padding-bottom: 67px;
  }

  .sc-md-pb-68 {
    padding-bottom: 68px;
  }

  .sc-md-pb-69 {
    padding-bottom: 69px;
  }

  .sc-md-pb-70 {
    padding-bottom: 70px;
  }

  .sc-md-pb-71 {
    padding-bottom: 71px;
  }

  .sc-md-pb-72 {
    padding-bottom: 72px;
  }

  .sc-md-pb-73 {
    padding-bottom: 73px;
  }

  .sc-md-pb-74 {
    padding-bottom: 74px;
  }

  .sc-md-pb-75 {
    padding-bottom: 75px;
  }

  .sc-md-pb-76 {
    padding-bottom: 76px;
  }

  .sc-md-pb-77 {
    padding-bottom: 77px;
  }

  .sc-md-pb-78 {
    padding-bottom: 78px;
  }

  .sc-md-pb-79 {
    padding-bottom: 79px;
  }

  .sc-md-pb-80 {
    padding-bottom: 80px;
  }

  .sc-md-pb-81 {
    padding-bottom: 81px;
  }

  .sc-md-pb-82 {
    padding-bottom: 82px;
  }

  .sc-md-pb-83 {
    padding-bottom: 83px;
  }

  .sc-md-pb-84 {
    padding-bottom: 84px;
  }

  .sc-md-pb-85 {
    padding-bottom: 85px;
  }

  .sc-md-pb-86 {
    padding-bottom: 86px;
  }

  .sc-md-pb-87 {
    padding-bottom: 87px;
  }

  .sc-md-pb-88 {
    padding-bottom: 88px;
  }

  .sc-md-pb-89 {
    padding-bottom: 89px;
  }

  .sc-md-pb-90 {
    padding-bottom: 90px;
  }

  .sc-md-pb-91 {
    padding-bottom: 91px;
  }

  .sc-md-pb-92 {
    padding-bottom: 92px;
  }

  .sc-md-pb-93 {
    padding-bottom: 93px;
  }

  .sc-md-pb-94 {
    padding-bottom: 94px;
  }

  .sc-md-pb-95 {
    padding-bottom: 95px;
  }

  .sc-md-pb-96 {
    padding-bottom: 96px;
  }

  .sc-md-pb-97 {
    padding-bottom: 97px;
  }

  .sc-md-pb-98 {
    padding-bottom: 98px;
  }

  .sc-md-pb-99 {
    padding-bottom: 99px;
  }

  .sc-md-pb-100 {
    padding-bottom: 100px;
  }

  .sc-md-pb-101 {
    padding-bottom: 101px;
  }

  .sc-md-pb-102 {
    padding-bottom: 102px;
  }

  .sc-md-pb-104 {
    padding-bottom: 104px;
  }

  .sc-md-pb-105 {
    padding-bottom: 105px;
  }

  .sc-md-pb-106 {
    padding-bottom: 106px;
  }

  .sc-md-pb-107 {
    padding-bottom: 107px;
  }

  .sc-md-pb-108 {
    padding-bottom: 108px;
  }

  .sc-md-pb-109 {
    padding-bottom: 109px;
  }

  .sc-md-pb-110 {
    padding-bottom: 110px;
  }

  .sc-md-pb-111 {
    padding-bottom: 111px;
  }

  .sc-md-pb-112 {
    padding-bottom: 112px;
  }

  .sc-md-pb-113 {
    padding-bottom: 113px;
  }

  .sc-md-pb-114 {
    padding-bottom: 114px;
  }

  .sc-md-pb-115 {
    padding-bottom: 115px;
  }

  .sc-md-pb-116 {
    padding-bottom: 116px;
  }

  .sc-md-pb-117 {
    padding-bottom: 117px;
  }

  .sc-md-pb-118 {
    padding-bottom: 118px;
  }

  .sc-md-pb-119 {
    padding-bottom: 119px;
  }

  .sc-md-pb-120 {
    padding-bottom: 120px;
  }

  .sc-md-pb-121 {
    padding-bottom: 121px;
  }

  .sc-md-pb-122 {
    padding-bottom: 122px;
  }

  .sc-md-pb-123 {
    padding-bottom: 123px;
  }

  .sc-md-pb-124 {
    padding-bottom: 124px;
  }

  .sc-md-pb-125 {
    padding-bottom: 125px;
  }

  .sc-md-pb-126 {
    padding-bottom: 126px;
  }

  .sc-md-pb-127 {
    padding-bottom: 127px;
  }

  .sc-md-pb-128 {
    padding-bottom: 128px;
  }

  .sc-md-pb-129 {
    padding-bottom: 129px;
  }

  .sc-md-pb-130 {
    padding-bottom: 130px;
  }

  .sc-md-pb-131 {
    padding-bottom: 131px;
  }

  .sc-md-pb-132 {
    padding-bottom: 132px;
  }

  .sc-md-pb-133 {
    padding-bottom: 133px;
  }

  .sc-md-pb-134 {
    padding-bottom: 134px;
  }

  .sc-md-pb-135 {
    padding-bottom: 135px;
  }

  .sc-md-pb-136 {
    padding-bottom: 136px;
  }

  .sc-md-pb-137 {
    padding-bottom: 137px;
  }

  .sc-md-pb-138 {
    padding-bottom: 138px;
  }

  .sc-md-pb-139 {
    padding-bottom: 139px;
  }

  .sc-md-pb-140 {
    padding-bottom: 140px;
  }

  .sc-md-pb-141 {
    padding-bottom: 141px;
  }

  .sc-md-pb-142 {
    padding-bottom: 142px;
  }

  .sc-md-pb-143 {
    padding-bottom: 143px;
  }

  .sc-md-pb-144 {
    padding-bottom: 144px;
  }

  .sc-md-pb-145 {
    padding-bottom: 145px;
  }

  .sc-md-pb-146 {
    padding-bottom: 146px;
  }

  .sc-md-pb-147 {
    padding-bottom: 147px;
  }

  .sc-md-pb-148 {
    padding-bottom: 148px;
  }

  .sc-md-pb-149 {
    padding-bottom: 149px;
  }

  .sc-md-pb-150 {
    padding-bottom: 150px;
  }

  .sc-md-pb-151 {
    padding-bottom: 151px;
  }

  .sc-md-pb-152 {
    padding-bottom: 152px;
  }

  .sc-md-pb-153 {
    padding-bottom: 153px;
  }

  .sc-md-pb-154 {
    padding-bottom: 154px;
  }

  .sc-md-pb-155 {
    padding-bottom: 155px;
  }

  .sc-md-pb-156 {
    padding-bottom: 156px;
  }

  .sc-md-pb-157 {
    padding-bottom: 157px;
  }

  .sc-md-pb-158 {
    padding-bottom: 158px;
  }

  .sc-md-pb-159 {
    padding-bottom: 159px;
  }

  .sc-md-pb-160 {
    padding-bottom: 160px;
  }

  .sc-md-pb-161 {
    padding-bottom: 161px;
  }

  .sc-md-pb-162 {
    padding-bottom: 162px;
  }

  .sc-md-pb-163 {
    padding-bottom: 163px;
  }

  .sc-md-pb-164 {
    padding-bottom: 164px;
  }

  .sc-md-pb-165 {
    padding-bottom: 165px;
  }

  .sc-md-pb-166 {
    padding-bottom: 166px;
  }

  .sc-md-pb-167 {
    padding-bottom: 167px;
  }

  .sc-md-pb-168 {
    padding-bottom: 168px;
  }

  .sc-md-pb-169 {
    padding-bottom: 169px;
  }

  .sc-md-pb-170 {
    padding-bottom: 170px;
  }

  .sc-md-pb-171 {
    padding-bottom: 171px;
  }

  .sc-md-pb-172 {
    padding-bottom: 172px;
  }

  .sc-md-pb-173 {
    padding-bottom: 173px;
  }

  .sc-md-pb-174 {
    padding-bottom: 174px;
  }

  .sc-md-pb-175 {
    padding-bottom: 175px;
  }

  .sc-md-pb-176 {
    padding-bottom: 176px;
  }

  .sc-md-pb-177 {
    padding-bottom: 177px;
  }

  .sc-md-pb-178 {
    padding-bottom: 178px;
  }

  .sc-md-pb-179 {
    padding-bottom: 179px;
  }

  .sc-md-pb-180 {
    padding-bottom: 180px;
  }

  .sc-md-pb-181 {
    padding-bottom: 181px;
  }

  .sc-md-pb-182 {
    padding-bottom: 182px;
  }

  .sc-md-pb-183 {
    padding-bottom: 183px;
  }

  .sc-md-pb-184 {
    padding-bottom: 184px;
  }

  .sc-md-pb-185 {
    padding-bottom: 185px;
  }

  .sc-md-pb-186 {
    padding-bottom: 186px;
  }

  .sc-md-pb-187 {
    padding-bottom: 187px;
  }

  .sc-md-pb-188 {
    padding-bottom: 188px;
  }

  .sc-md-pb-189 {
    padding-bottom: 189px;
  }

  .sc-md-pb-190 {
    padding-bottom: 190px;
  }

  .sc-md-pb-191 {
    padding-bottom: 191px;
  }

  .sc-md-pb-192 {
    padding-bottom: 192px;
  }

  .sc-md-pb-193 {
    padding-bottom: 193px;
  }

  .sc-md-pb-194 {
    padding-bottom: 194px;
  }

  .sc-md-pb-195 {
    padding-bottom: 195px;
  }

  .sc-md-pb-196 {
    padding-bottom: 196px;
  }

  .sc-md-pb-197 {
    padding-bottom: 197px;
  }

  .sc-md-pb-198 {
    padding-bottom: 198px;
  }

  .sc-md-pb-199 {
    padding-bottom: 199px;
  }

  .sc-md-pb-200 {
    padding-bottom: 200px;
  }

  .sc-md-pr-0 {
    padding-right: 0;
  }

  .sc-md-pr-1 {
    padding-right: 1px;
  }

  .sc-md-pr-2 {
    padding-right: 2px;
  }

  .sc-md-pr-4 {
    padding-right: 4px;
  }

  .sc-md-pr-5 {
    padding-right: 5px;
  }

  .sc-md-pr-6 {
    padding-right: 6px;
  }

  .sc-md-pr-7 {
    padding-right: 7px;
  }

  .sc-md-pr-8 {
    padding-right: 8px;
  }

  .sc-md-pr-9 {
    padding-right: 9px;
  }

  .sc-md-pr-10 {
    padding-right: 10px;
  }

  .sc-md-pr-11 {
    padding-right: 11px;
  }

  .sc-md-pr-12 {
    padding-right: 12px;
  }

  .sc-md-pr-13 {
    padding-right: 13px;
  }

  .sc-md-pr-14 {
    padding-right: 14px;
  }

  .sc-md-pr-15 {
    padding-right: 15px;
  }

  .sc-md-pr-16 {
    padding-right: 16px;
  }

  .sc-md-pr-17 {
    padding-right: 17px;
  }

  .sc-md-pr-18 {
    padding-right: 18px;
  }

  .sc-md-pr-19 {
    padding-right: 19px;
  }

  .sc-md-pr-20 {
    padding-right: 20px;
  }

  .sc-md-pr-21 {
    padding-right: 21px;
  }

  .sc-md-pr-22 {
    padding-right: 22px;
  }

  .sc-md-pr-23 {
    padding-right: 23px;
  }

  .sc-md-pr-24 {
    padding-right: 24px;
  }

  .sc-md-pr-25 {
    padding-right: 25px;
  }

  .sc-md-pr-26 {
    padding-right: 26px;
  }

  .sc-md-pr-27 {
    padding-right: 27px;
  }

  .sc-md-pr-28 {
    padding-right: 28px;
  }

  .sc-md-pr-29 {
    padding-right: 29px;
  }

  .sc-md-pr-30 {
    padding-right: 30px;
  }

  .sc-md-pr-31 {
    padding-right: 31px;
  }

  .sc-md-pr-32 {
    padding-right: 32px;
  }

  .sc-md-pr-33 {
    padding-right: 33px;
  }

  .sc-md-pr-34 {
    padding-right: 34px;
  }

  .sc-md-pr-35 {
    padding-right: 35px;
  }

  .sc-md-pr-36 {
    padding-right: 36px;
  }

  .sc-md-pr-37 {
    padding-right: 37px;
  }

  .sc-md-pr-38 {
    padding-right: 38px;
  }

  .sc-md-pr-39 {
    padding-right: 39px;
  }

  .sc-md-pr-40 {
    padding-right: 40px;
  }

  .sc-md-pr-41 {
    padding-right: 41px;
  }

  .sc-md-pr-42 {
    padding-right: 42px;
  }

  .sc-md-pr-43 {
    padding-right: 43px;
  }

  .sc-md-pr-44 {
    padding-right: 44px;
  }

  .sc-md-pr-45 {
    padding-right: 45px;
  }

  .sc-md-pr-46 {
    padding-right: 46px;
  }

  .sc-md-pr-47 {
    padding-right: 47px;
  }

  .sc-md-pr-48 {
    padding-right: 48px;
  }

  .sc-md-pr-49 {
    padding-right: 49px;
  }

  .sc-md-pr-50 {
    padding-right: 50px;
  }

  .sc-md-pr-51 {
    padding-right: 51px;
  }

  .sc-md-pr-52 {
    padding-right: 52px;
  }

  .sc-md-pr-53 {
    padding-right: 53px;
  }

  .sc-md-pr-54 {
    padding-right: 54px;
  }

  .sc-md-pr-55 {
    padding-right: 55px;
  }

  .sc-md-pr-56 {
    padding-right: 56px;
  }

  .sc-md-pr-57 {
    padding-right: 57px;
  }

  .sc-md-pr-58 {
    padding-right: 58px;
  }

  .sc-md-pr-59 {
    padding-right: 59px;
  }

  .sc-md-pr-60 {
    padding-right: 60px;
  }

  .sc-md-pr-61 {
    padding-right: 61px;
  }

  .sc-md-pr-62 {
    padding-right: 62px;
  }

  .sc-md-pr-63 {
    padding-right: 63px;
  }

  .sc-md-pr-64 {
    padding-right: 64px;
  }

  .sc-md-pr-65 {
    padding-right: 65px;
  }

  .sc-md-pr-66 {
    padding-right: 66px;
  }

  .sc-md-pr-67 {
    padding-right: 67px;
  }

  .sc-md-pr-68 {
    padding-right: 68px;
  }

  .sc-md-pr-69 {
    padding-right: 69px;
  }

  .sc-md-pr-70 {
    padding-right: 70px;
  }

  .sc-md-pr-71 {
    padding-right: 71px;
  }

  .sc-md-pr-72 {
    padding-right: 72px;
  }

  .sc-md-pr-73 {
    padding-right: 73px;
  }

  .sc-md-pr-74 {
    padding-right: 74px;
  }

  .sc-md-pr-75 {
    padding-right: 75px;
  }

  .sc-md-pr-76 {
    padding-right: 76px;
  }

  .sc-md-pr-77 {
    padding-right: 77px;
  }

  .sc-md-pr-78 {
    padding-right: 78px;
  }

  .sc-md-pr-79 {
    padding-right: 79px;
  }

  .sc-md-pr-80 {
    padding-right: 80px;
  }

  .sc-md-pr-81 {
    padding-right: 81px;
  }

  .sc-md-pr-82 {
    padding-right: 82px;
  }

  .sc-md-pr-83 {
    padding-right: 83px;
  }

  .sc-md-pr-84 {
    padding-right: 84px;
  }

  .sc-md-pr-85 {
    padding-right: 85px;
  }

  .sc-md-pr-86 {
    padding-right: 86px;
  }

  .sc-md-pr-87 {
    padding-right: 87px;
  }

  .sc-md-pr-88 {
    padding-right: 88px;
  }

  .sc-md-pr-89 {
    padding-right: 89px;
  }

  .sc-md-pr-90 {
    padding-right: 90px;
  }

  .sc-md-pr-91 {
    padding-right: 91px;
  }

  .sc-md-pr-92 {
    padding-right: 92px;
  }

  .sc-md-pr-93 {
    padding-right: 93px;
  }

  .sc-md-pr-94 {
    padding-right: 94px;
  }

  .sc-md-pr-95 {
    padding-right: 95px;
  }

  .sc-md-pr-96 {
    padding-right: 96px;
  }

  .sc-md-pr-97 {
    padding-right: 97px;
  }

  .sc-md-pr-98 {
    padding-right: 98px;
  }

  .sc-md-pr-99 {
    padding-right: 99px;
  }

  .sc-md-pr-100 {
    padding-right: 100px;
  }

  .sc-md-pr-101 {
    padding-right: 101px;
  }

  .sc-md-pr-102 {
    padding-right: 102px;
  }

  .sc-md-pr-104 {
    padding-right: 104px;
  }

  .sc-md-pr-105 {
    padding-right: 105px;
  }

  .sc-md-pr-106 {
    padding-right: 106px;
  }

  .sc-md-pr-107 {
    padding-right: 107px;
  }

  .sc-md-pr-108 {
    padding-right: 108px;
  }

  .sc-md-pr-109 {
    padding-right: 109px;
  }

  .sc-md-pr-110 {
    padding-right: 110px;
  }

  .sc-md-pr-111 {
    padding-right: 111px;
  }

  .sc-md-pr-112 {
    padding-right: 112px;
  }

  .sc-md-pr-113 {
    padding-right: 113px;
  }

  .sc-md-pr-114 {
    padding-right: 114px;
  }

  .sc-md-pr-115 {
    padding-right: 115px;
  }

  .sc-md-pr-116 {
    padding-right: 116px;
  }

  .sc-md-pr-117 {
    padding-right: 117px;
  }

  .sc-md-pr-118 {
    padding-right: 118px;
  }

  .sc-md-pr-119 {
    padding-right: 119px;
  }

  .sc-md-pr-120 {
    padding-right: 120px;
  }

  .sc-md-pr-121 {
    padding-right: 121px;
  }

  .sc-md-pr-122 {
    padding-right: 122px;
  }

  .sc-md-pr-123 {
    padding-right: 123px;
  }

  .sc-md-pr-124 {
    padding-right: 124px;
  }

  .sc-md-pr-125 {
    padding-right: 125px;
  }

  .sc-md-pr-126 {
    padding-right: 126px;
  }

  .sc-md-pr-127 {
    padding-right: 127px;
  }

  .sc-md-pr-128 {
    padding-right: 128px;
  }

  .sc-md-pr-129 {
    padding-right: 129px;
  }

  .sc-md-pr-130 {
    padding-right: 130px;
  }

  .sc-md-pr-131 {
    padding-right: 131px;
  }

  .sc-md-pr-132 {
    padding-right: 132px;
  }

  .sc-md-pr-133 {
    padding-right: 133px;
  }

  .sc-md-pr-134 {
    padding-right: 134px;
  }

  .sc-md-pr-135 {
    padding-right: 135px;
  }

  .sc-md-pr-136 {
    padding-right: 136px;
  }

  .sc-md-pr-137 {
    padding-right: 137px;
  }

  .sc-md-pr-138 {
    padding-right: 138px;
  }

  .sc-md-pr-139 {
    padding-right: 139px;
  }

  .sc-md-pr-140 {
    padding-right: 140px;
  }

  .sc-md-pr-141 {
    padding-right: 141px;
  }

  .sc-md-pr-142 {
    padding-right: 142px;
  }

  .sc-md-pr-143 {
    padding-right: 143px;
  }

  .sc-md-pr-144 {
    padding-right: 144px;
  }

  .sc-md-pr-145 {
    padding-right: 145px;
  }

  .sc-md-pr-146 {
    padding-right: 146px;
  }

  .sc-md-pr-147 {
    padding-right: 147px;
  }

  .sc-md-pr-148 {
    padding-right: 148px;
  }

  .sc-md-pr-149 {
    padding-right: 149px;
  }

  .sc-md-pr-150 {
    padding-right: 150px;
  }

  .sc-md-pr-151 {
    padding-right: 151px;
  }

  .sc-md-pr-152 {
    padding-right: 152px;
  }

  .sc-md-pr-153 {
    padding-right: 153px;
  }

  .sc-md-pr-154 {
    padding-right: 154px;
  }

  .sc-md-pr-155 {
    padding-right: 155px;
  }

  .sc-md-pr-156 {
    padding-right: 156px;
  }

  .sc-md-pr-157 {
    padding-right: 157px;
  }

  .sc-md-pr-158 {
    padding-right: 158px;
  }

  .sc-md-pr-159 {
    padding-right: 159px;
  }

  .sc-md-pr-160 {
    padding-right: 160px;
  }

  .sc-md-pr-161 {
    padding-right: 161px;
  }

  .sc-md-pr-162 {
    padding-right: 162px;
  }

  .sc-md-pr-163 {
    padding-right: 163px;
  }

  .sc-md-pr-164 {
    padding-right: 164px;
  }

  .sc-md-pr-165 {
    padding-right: 165px;
  }

  .sc-md-pr-166 {
    padding-right: 166px;
  }

  .sc-md-pr-167 {
    padding-right: 167px;
  }

  .sc-md-pr-168 {
    padding-right: 168px;
  }

  .sc-md-pr-169 {
    padding-right: 169px;
  }

  .sc-md-pr-170 {
    padding-right: 170px;
  }

  .sc-md-pr-171 {
    padding-right: 171px;
  }

  .sc-md-pr-172 {
    padding-right: 172px;
  }

  .sc-md-pr-173 {
    padding-right: 173px;
  }

  .sc-md-pr-174 {
    padding-right: 174px;
  }

  .sc-md-pr-175 {
    padding-right: 175px;
  }

  .sc-md-pr-176 {
    padding-right: 176px;
  }

  .sc-md-pr-177 {
    padding-right: 177px;
  }

  .sc-md-pr-178 {
    padding-right: 178px;
  }

  .sc-md-pr-179 {
    padding-right: 179px;
  }

  .sc-md-pr-180 {
    padding-right: 180px;
  }

  .sc-md-pr-181 {
    padding-right: 181px;
  }

  .sc-md-pr-182 {
    padding-right: 182px;
  }

  .sc-md-pr-183 {
    padding-right: 183px;
  }

  .sc-md-pr-184 {
    padding-right: 184px;
  }

  .sc-md-pr-185 {
    padding-right: 185px;
  }

  .sc-md-pr-186 {
    padding-right: 186px;
  }

  .sc-md-pr-187 {
    padding-right: 187px;
  }

  .sc-md-pr-188 {
    padding-right: 188px;
  }

  .sc-md-pr-189 {
    padding-right: 189px;
  }

  .sc-md-pr-190 {
    padding-right: 190px;
  }

  .sc-md-pr-191 {
    padding-right: 191px;
  }

  .sc-md-pr-192 {
    padding-right: 192px;
  }

  .sc-md-pr-193 {
    padding-right: 193px;
  }

  .sc-md-pr-194 {
    padding-right: 194px;
  }

  .sc-md-pr-195 {
    padding-right: 195px;
  }

  .sc-md-pr-196 {
    padding-right: 196px;
  }

  .sc-md-pr-197 {
    padding-right: 197px;
  }

  .sc-md-pr-198 {
    padding-right: 198px;
  }

  .sc-md-pr-199 {
    padding-right: 199px;
  }

  .sc-md-pr-200 {
    padding-right: 200px;
  }

  .sc-md-pl-0 {
    padding-left: 0;
  }

  .sc-md-pl-1 {
    padding-left: 1px;
  }

  .sc-md-pl-2 {
    padding-left: 2px;
  }

  .sc-pb-3 {
    padding-bottom: 3px;
  }

  .sc-md-pl-4 {
    padding-left: 4px;
  }

  .sc-md-pl-5 {
    padding-left: 5px;
  }

  .sc-md-pl-6 {
    padding-left: 6px;
  }

  .sc-md-pl-7 {
    padding-left: 7px;
  }

  .sc-md-pl-8 {
    padding-left: 8px;
  }

  .sc-md-pl-9 {
    padding-left: 9px;
  }

  .sc-md-pl-10 {
    padding-left: 10px;
  }

  .sc-md-pl-11 {
    padding-left: 11px;
  }

  .sc-md-pl-12 {
    padding-left: 12px;
  }

  .sc-md-pl-13 {
    padding-left: 13px;
  }

  .sc-md-pl-14 {
    padding-left: 14px;
  }

  .sc-md-pl-15 {
    padding-left: 15px;
  }

  .sc-md-pl-16 {
    padding-left: 16px;
  }

  .sc-md-pl-17 {
    padding-left: 17px;
  }

  .sc-md-pl-18 {
    padding-left: 18px;
  }

  .sc-md-pl-19 {
    padding-left: 19px;
  }

  .sc-md-pl-20 {
    padding-left: 20px;
  }

  .sc-md-pl-21 {
    padding-left: 21px;
  }

  .sc-md-pl-22 {
    padding-left: 22px;
  }

  .sc-md-pl-23 {
    padding-left: 23px;
  }

  .sc-md-pl-24 {
    padding-left: 24px;
  }

  .sc-md-pl-25 {
    padding-left: 25px;
  }

  .sc-md-pl-26 {
    padding-left: 26px;
  }

  .sc-md-pl-27 {
    padding-left: 27px;
  }

  .sc-md-pl-28 {
    padding-left: 28px;
  }

  .sc-md-pl-29 {
    padding-left: 29px;
  }

  .sc-md-pl-30 {
    padding-left: 30px;
  }

  .sc-md-pl-31 {
    padding-left: 31px;
  }

  .sc-md-pl-32 {
    padding-left: 32px;
  }

  .sc-md-pl-33 {
    padding-left: 33px;
  }

  .sc-md-pl-34 {
    padding-left: 34px;
  }

  .sc-md-pl-35 {
    padding-left: 35px;
  }

  .sc-md-pl-36 {
    padding-left: 36px;
  }

  .sc-md-pl-37 {
    padding-left: 37px;
  }

  .sc-md-pl-38 {
    padding-left: 38px;
  }

  .sc-md-pl-39 {
    padding-left: 39px;
  }

  .sc-md-pl-40 {
    padding-left: 40px;
  }

  .sc-md-pl-41 {
    padding-left: 41px;
  }

  .sc-md-pl-42 {
    padding-left: 42px;
  }

  .sc-md-pl-43 {
    padding-left: 43px;
  }

  .sc-md-pl-44 {
    padding-left: 44px;
  }

  .sc-md-pl-45 {
    padding-left: 45px;
  }

  .sc-md-pl-46 {
    padding-left: 46px;
  }

  .sc-md-pl-47 {
    padding-left: 47px;
  }

  .sc-md-pl-48 {
    padding-left: 48px;
  }

  .sc-md-pl-49 {
    padding-left: 49px;
  }

  .sc-md-pl-50 {
    padding-left: 50px;
  }

  .sc-md-pl-51 {
    padding-left: 51px;
  }

  .sc-md-pl-52 {
    padding-left: 52px;
  }

  .sc-md-pl-53 {
    padding-left: 53px;
  }

  .sc-md-pl-54 {
    padding-left: 54px;
  }

  .sc-md-pl-55 {
    padding-left: 55px;
  }

  .sc-md-pl-56 {
    padding-left: 56px;
  }

  .sc-md-pl-57 {
    padding-left: 57px;
  }

  .sc-md-pl-58 {
    padding-left: 58px;
  }

  .sc-md-pl-59 {
    padding-left: 59px;
  }

  .sc-md-pl-60 {
    padding-left: 60px;
  }

  .sc-md-pl-61 {
    padding-left: 61px;
  }

  .sc-md-pl-62 {
    padding-left: 62px;
  }

  .sc-md-pl-63 {
    padding-left: 63px;
  }

  .sc-md-pl-64 {
    padding-left: 64px;
  }

  .sc-md-pl-65 {
    padding-left: 65px;
  }

  .sc-md-pl-66 {
    padding-left: 66px;
  }

  .sc-md-pl-67 {
    padding-left: 67px;
  }

  .sc-md-pl-68 {
    padding-left: 68px;
  }

  .sc-md-pl-69 {
    padding-left: 69px;
  }

  .sc-md-pl-70 {
    padding-left: 70px;
  }

  .sc-md-pl-71 {
    padding-left: 71px;
  }

  .sc-md-pl-72 {
    padding-left: 72px;
  }

  .sc-md-pl-73 {
    padding-left: 73px;
  }

  .sc-md-pl-74 {
    padding-left: 74px;
  }

  .sc-md-pl-75 {
    padding-left: 75px;
  }

  .sc-md-pl-76 {
    padding-left: 76px;
  }

  .sc-md-pl-77 {
    padding-left: 77px;
  }

  .sc-md-pl-78 {
    padding-left: 78px;
  }

  .sc-md-pl-79 {
    padding-left: 79px;
  }

  .sc-md-pl-80 {
    padding-left: 80px;
  }

  .sc-md-pl-81 {
    padding-left: 81px;
  }

  .sc-md-pl-82 {
    padding-left: 82px;
  }

  .sc-md-pl-83 {
    padding-left: 83px;
  }

  .sc-md-pl-84 {
    padding-left: 84px;
  }

  .sc-md-pl-85 {
    padding-left: 85px;
  }

  .sc-md-pl-86 {
    padding-left: 86px;
  }

  .sc-md-pl-87 {
    padding-left: 87px;
  }

  .sc-md-pl-88 {
    padding-left: 88px;
  }

  .sc-md-pl-89 {
    padding-left: 89px;
  }

  .sc-md-pl-90 {
    padding-left: 90px;
  }

  .sc-md-pl-91 {
    padding-left: 91px;
  }

  .sc-md-pl-92 {
    padding-left: 92px;
  }

  .sc-md-pl-93 {
    padding-left: 93px;
  }

  .sc-md-pl-94 {
    padding-left: 94px;
  }

  .sc-md-pl-95 {
    padding-left: 95px;
  }

  .sc-md-pl-96 {
    padding-left: 96px;
  }

  .sc-md-pl-97 {
    padding-left: 97px;
  }

  .sc-md-pl-98 {
    padding-left: 98px;
  }

  .sc-md-pl-99 {
    padding-left: 99px;
  }

  .sc-md-pl-100 {
    padding-left: 100px;
  }

  .sc-md-pl-101 {
    padding-left: 101px;
  }

  .sc-md-pl-102 {
    padding-left: 102px;
  }

  .sc-md-pb-103 {
    padding-bottom: 103px;
  }

  .sc-md-pl-104 {
    padding-left: 104px;
  }

  .sc-md-pl-105 {
    padding-left: 105px;
  }

  .sc-md-pl-106 {
    padding-left: 106px;
  }

  .sc-md-pl-107 {
    padding-left: 107px;
  }

  .sc-md-pl-108 {
    padding-left: 108px;
  }

  .sc-md-pl-109 {
    padding-left: 109px;
  }

  .sc-md-pl-110 {
    padding-left: 110px;
  }

  .sc-md-pl-111 {
    padding-left: 111px;
  }

  .sc-md-pl-112 {
    padding-left: 112px;
  }

  .sc-md-pl-113 {
    padding-left: 113px;
  }

  .sc-md-pl-114 {
    padding-left: 114px;
  }

  .sc-md-pl-115 {
    padding-left: 115px;
  }

  .sc-md-pl-116 {
    padding-left: 116px;
  }

  .sc-md-pl-117 {
    padding-left: 117px;
  }

  .sc-md-pl-118 {
    padding-left: 118px;
  }

  .sc-md-pl-119 {
    padding-left: 119px;
  }

  .sc-md-pl-120 {
    padding-left: 120px;
  }

  .sc-md-pl-121 {
    padding-left: 121px;
  }

  .sc-md-pl-122 {
    padding-left: 122px;
  }

  .sc-md-pl-123 {
    padding-left: 123px;
  }

  .sc-md-pl-124 {
    padding-left: 124px;
  }

  .sc-md-pl-125 {
    padding-left: 125px;
  }

  .sc-md-pl-126 {
    padding-left: 126px;
  }

  .sc-md-pl-127 {
    padding-left: 127px;
  }

  .sc-md-pl-128 {
    padding-left: 128px;
  }

  .sc-md-pl-129 {
    padding-left: 129px;
  }

  .sc-md-pl-130 {
    padding-left: 130px;
  }

  .sc-md-pl-131 {
    padding-left: 131px;
  }

  .sc-md-pl-132 {
    padding-left: 132px;
  }

  .sc-md-pl-133 {
    padding-left: 133px;
  }

  .sc-md-pl-134 {
    padding-left: 134px;
  }

  .sc-md-pl-135 {
    padding-left: 135px;
  }

  .sc-md-pl-136 {
    padding-left: 136px;
  }

  .sc-md-pl-137 {
    padding-left: 137px;
  }

  .sc-md-pl-138 {
    padding-left: 138px;
  }

  .sc-md-pl-139 {
    padding-left: 139px;
  }

  .sc-md-pl-140 {
    padding-left: 140px;
  }

  .sc-md-pl-141 {
    padding-left: 141px;
  }

  .sc-md-pl-142 {
    padding-left: 142px;
  }

  .sc-md-pl-143 {
    padding-left: 143px;
  }

  .sc-md-pl-144 {
    padding-left: 144px;
  }

  .sc-md-pl-145 {
    padding-left: 145px;
  }

  .sc-md-pl-146 {
    padding-left: 146px;
  }

  .sc-md-pl-147 {
    padding-left: 147px;
  }

  .sc-md-pl-148 {
    padding-left: 148px;
  }

  .sc-md-pl-149 {
    padding-left: 149px;
  }

  .sc-md-pl-150 {
    padding-left: 150px;
  }

  .sc-md-pl-151 {
    padding-left: 151px;
  }

  .sc-md-pl-152 {
    padding-left: 152px;
  }

  .sc-md-pl-153 {
    padding-left: 153px;
  }

  .sc-md-pl-154 {
    padding-left: 154px;
  }

  .sc-md-pl-155 {
    padding-left: 155px;
  }

  .sc-md-pl-156 {
    padding-left: 156px;
  }

  .sc-md-pl-157 {
    padding-left: 157px;
  }

  .sc-md-pl-158 {
    padding-left: 158px;
  }

  .sc-md-pl-159 {
    padding-left: 159px;
  }

  .sc-md-pl-160 {
    padding-left: 160px;
  }

  .sc-md-pl-161 {
    padding-left: 161px;
  }

  .sc-md-pl-162 {
    padding-left: 162px;
  }

  .sc-md-pl-163 {
    padding-left: 163px;
  }

  .sc-md-pl-164 {
    padding-left: 164px;
  }

  .sc-md-pl-165 {
    padding-left: 165px;
  }

  .sc-md-pl-166 {
    padding-left: 166px;
  }

  .sc-md-pl-167 {
    padding-left: 167px;
  }

  .sc-md-pl-168 {
    padding-left: 168px;
  }

  .sc-md-pl-169 {
    padding-left: 169px;
  }

  .sc-md-pl-170 {
    padding-left: 170px;
  }

  .sc-md-pl-171 {
    padding-left: 171px;
  }

  .sc-md-pl-172 {
    padding-left: 172px;
  }

  .sc-md-pl-173 {
    padding-left: 173px;
  }

  .sc-md-pl-174 {
    padding-left: 174px;
  }

  .sc-md-pl-175 {
    padding-left: 175px;
  }

  .sc-md-pl-176 {
    padding-left: 176px;
  }

  .sc-md-pl-177 {
    padding-left: 177px;
  }

  .sc-md-pl-178 {
    padding-left: 178px;
  }

  .sc-md-pl-179 {
    padding-left: 179px;
  }

  .sc-md-pl-180 {
    padding-left: 180px;
  }

  .sc-md-pl-181 {
    padding-left: 181px;
  }

  .sc-md-pl-182 {
    padding-left: 182px;
  }

  .sc-md-pl-183 {
    padding-left: 183px;
  }

  .sc-md-pl-184 {
    padding-left: 184px;
  }

  .sc-md-pl-185 {
    padding-left: 185px;
  }

  .sc-md-pl-186 {
    padding-left: 186px;
  }

  .sc-md-pl-187 {
    padding-left: 187px;
  }

  .sc-md-pl-188 {
    padding-left: 188px;
  }

  .sc-md-pl-189 {
    padding-left: 189px;
  }

  .sc-md-pl-190 {
    padding-left: 190px;
  }

  .sc-md-pl-191 {
    padding-left: 191px;
  }

  .sc-md-pl-192 {
    padding-left: 192px;
  }

  .sc-md-pl-193 {
    padding-left: 193px;
  }

  .sc-md-pl-194 {
    padding-left: 194px;
  }

  .sc-md-pl-195 {
    padding-left: 195px;
  }

  .sc-md-pl-196 {
    padding-left: 196px;
  }

  .sc-md-pl-197 {
    padding-left: 197px;
  }

  .sc-md-pl-198 {
    padding-left: 198px;
  }

  .sc-md-pl-199 {
    padding-left: 199px;
  }

  .sc-md-pl-200 {
    padding-left: 200px;
  }

  .sc-md-mt-0 {
    margin-top: 0;
  }

  .sc-md-mt-1 {
    margin-top: 1px;
  }

  .sc-md-mt-2 {
    margin-top: 2px;
  }

  .sc-md-mt-3 {
    margin-top: 3px;
  }

  .sc-md-mt-4 {
    margin-top: 4px;
  }

  .sc-md-mt-5 {
    margin-top: 5px;
  }

  .sc-md-mt-6 {
    margin-top: 6px;
  }

  .sc-md-mt-7 {
    margin-top: 7px;
  }

  .sc-md-mt-8 {
    margin-top: 8px;
  }

  .sc-md-mt-9 {
    margin-top: 9px;
  }

  .sc-md-mt-10 {
    margin-top: 10px;
  }

  .sc-md-mt-11 {
    margin-top: 11px;
  }

  .sc-md-mt-12 {
    margin-top: 12px;
  }

  .sc-md-mt-13 {
    margin-top: 13px;
  }

  .sc-md-mt-14 {
    margin-top: 14px;
  }

  .sc-md-mt-15 {
    margin-top: 15px;
  }

  .sc-md-mt-16 {
    margin-top: 16px;
  }

  .sc-md-mt-17 {
    margin-top: 17px;
  }

  .sc-md-mt-18 {
    margin-top: 18px;
  }

  .sc-md-mt-19 {
    margin-top: 19px;
  }

  .sc-md-mt-20 {
    margin-top: 20px;
  }

  .sc-md-mt-21 {
    margin-top: 21px;
  }

  .sc-md-mt-22 {
    margin-top: 22px;
  }

  .sc-md-mt-23 {
    margin-top: 23px;
  }

  .sc-md-mt-24 {
    margin-top: 24px;
  }

  .sc-md-mt-25 {
    margin-top: 25px;
  }

  .sc-md-mt-26 {
    margin-top: 26px;
  }

  .sc-md-mt-27 {
    margin-top: 27px;
  }

  .sc-md-mt-28 {
    margin-top: 28px;
  }

  .sc-md-mt-29 {
    margin-top: 29px;
  }

  .sc-md-mt-30 {
    margin-top: 30px;
  }

  .sc-md-mt-31 {
    margin-top: 31px;
  }

  .sc-md-mt-32 {
    margin-top: 32px;
  }

  .sc-md-mt-33 {
    margin-top: 33px;
  }

  .sc-md-mt-34 {
    margin-top: 34px;
  }

  .sc-md-mt-35 {
    margin-top: 35px;
  }

  .sc-md-mt-36 {
    margin-top: 36px;
  }

  .sc-md-mt-37 {
    margin-top: 37px;
  }

  .sc-md-mt-38 {
    margin-top: 38px;
  }

  .sc-md-mt-39 {
    margin-top: 39px;
  }

  .sc-md-mt-40 {
    margin-top: 40px;
  }

  .sc-md-mt-41 {
    margin-top: 41px;
  }

  .sc-md-mt-42 {
    margin-top: 42px;
  }

  .sc-md-mt-43 {
    margin-top: 43px;
  }

  .sc-md-mt-44 {
    margin-top: 44px;
  }

  .sc-md-mt-45 {
    margin-top: 45px;
  }

  .sc-md-mt-46 {
    margin-top: 46px;
  }

  .sc-md-mt-47 {
    margin-top: 47px;
  }

  .sc-md-mt-48 {
    margin-top: 48px;
  }

  .sc-md-mt-49 {
    margin-top: 49px;
  }

  .sc-md-mt-50 {
    margin-top: 50px;
  }

  .sc-md-mt-51 {
    margin-top: 51px;
  }

  .sc-md-mt-52 {
    margin-top: 52px;
  }

  .sc-md-mt-53 {
    margin-top: 53px;
  }

  .sc-md-mt-54 {
    margin-top: 54px;
  }

  .sc-md-mt-55 {
    margin-top: 55px;
  }

  .sc-md-mt-56 {
    margin-top: 56px;
  }

  .sc-md-mt-57 {
    margin-top: 57px;
  }

  .sc-md-mt-58 {
    margin-top: 58px;
  }

  .sc-md-mt-59 {
    margin-top: 59px;
  }

  .sc-md-mt-60 {
    margin-top: 60px;
  }

  .sc-md-mt-61 {
    margin-top: 61px;
  }

  .sc-md-mt-62 {
    margin-top: 62px;
  }

  .sc-md-mt-63 {
    margin-top: 63px;
  }

  .sc-md-mt-64 {
    margin-top: 64px;
  }

  .sc-md-mt-65 {
    margin-top: 65px;
  }

  .sc-md-mt-66 {
    margin-top: 66px;
  }

  .sc-md-mt-67 {
    margin-top: 67px;
  }

  .sc-md-mt-68 {
    margin-top: 68px;
  }

  .sc-md-mt-69 {
    margin-top: 69px;
  }

  .sc-md-mt-70 {
    margin-top: 70px;
  }

  .sc-md-mt-71 {
    margin-top: 71px;
  }

  .sc-md-mt-72 {
    margin-top: 72px;
  }

  .sc-md-mt-73 {
    margin-top: 73px;
  }

  .sc-md-mt-74 {
    margin-top: 74px;
  }

  .sc-md-mt-75 {
    margin-top: 75px;
  }

  .sc-md-mt-76 {
    margin-top: 76px;
  }

  .sc-md-mt-77 {
    margin-top: 77px;
  }

  .sc-md-mt-78 {
    margin-top: 78px;
  }

  .sc-md-mt-79 {
    margin-top: 79px;
  }

  .sc-md-mt-80 {
    margin-top: 80px;
  }

  .sc-md-mt-81 {
    margin-top: 81px;
  }

  .sc-md-mt-82 {
    margin-top: 82px;
  }

  .sc-md-mt-83 {
    margin-top: 83px;
  }

  .sc-md-mt-84 {
    margin-top: 84px;
  }

  .sc-md-mt-85 {
    margin-top: 85px;
  }

  .sc-md-mt-86 {
    margin-top: 86px;
  }

  .sc-md-mt-87 {
    margin-top: 87px;
  }

  .sc-md-mt-88 {
    margin-top: 88px;
  }

  .sc-md-mt-89 {
    margin-top: 89px;
  }

  .sc-md-mt-90 {
    margin-top: 90px;
  }

  .sc-md-mt-91 {
    margin-top: 91px;
  }

  .sc-md-mt-92 {
    margin-top: 92px;
  }

  .sc-md-mt-93 {
    margin-top: 93px;
  }

  .sc-md-mt-94 {
    margin-top: 94px;
  }

  .sc-md-mt-95 {
    margin-top: 95px;
  }

  .sc-md-mt-96 {
    margin-top: 96px;
  }

  .sc-md-mt-97 {
    margin-top: 97px;
  }

  .sc-md-mt-98 {
    margin-top: 98px;
  }

  .sc-md-mt-99 {
    margin-top: 99px;
  }

  .sc-md-mt-100 {
    margin-top: 100px;
  }

  .sc-md-mt-101 {
    margin-top: 101px;
  }

  .sc-md-mt-102 {
    margin-top: 102px;
  }

  .sc-md-mt-103 {
    margin-top: 103px;
  }

  .sc-md-mt-104 {
    margin-top: 104px;
  }

  .sc-md-mt-105 {
    margin-top: 105px;
  }

  .sc-md-mt-106 {
    margin-top: 106px;
  }

  .sc-md-mt-107 {
    margin-top: 107px;
  }

  .sc-md-mt-108 {
    margin-top: 108px;
  }

  .sc-md-mt-109 {
    margin-top: 109px;
  }

  .sc-md-mt-110 {
    margin-top: 110px;
  }

  .sc-md-mt-111 {
    margin-top: 111px;
  }

  .sc-md-mt-112 {
    margin-top: 112px;
  }

  .sc-md-mt-113 {
    margin-top: 113px;
  }

  .sc-md-mt-114 {
    margin-top: 114px;
  }

  .sc-md-mt-115 {
    margin-top: 115px;
  }

  .sc-md-mt-116 {
    margin-top: 116px;
  }

  .sc-md-mt-117 {
    margin-top: 117px;
  }

  .sc-md-mt-118 {
    margin-top: 118px;
  }

  .sc-md-mt-119 {
    margin-top: 119px;
  }

  .sc-md-mt-120 {
    margin-top: 120px;
  }

  .sc-md-mt-121 {
    margin-top: 121px;
  }

  .sc-md-mt-122 {
    margin-top: 122px;
  }

  .sc-md-mt-123 {
    margin-top: 123px;
  }

  .sc-md-mt-124 {
    margin-top: 124px;
  }

  .sc-md-mt-125 {
    margin-top: 125px;
  }

  .sc-md-mt-126 {
    margin-top: 126px;
  }

  .sc-md-mt-127 {
    margin-top: 127px;
  }

  .sc-md-mt-128 {
    margin-top: 128px;
  }

  .sc-md-mt-129 {
    margin-top: 129px;
  }

  .sc-md-mt-130 {
    margin-top: 130px;
  }

  .sc-md-mt-131 {
    margin-top: 131px;
  }

  .sc-md-mt-132 {
    margin-top: 132px;
  }

  .sc-md-mt-133 {
    margin-top: 133px;
  }

  .sc-md-mt-134 {
    margin-top: 134px;
  }

  .sc-md-mt-135 {
    margin-top: 135px;
  }

  .sc-md-mt-136 {
    margin-top: 136px;
  }

  .sc-md-mt-137 {
    margin-top: 137px;
  }

  .sc-md-mt-138 {
    margin-top: 138px;
  }

  .sc-md-mt-139 {
    margin-top: 139px;
  }

  .sc-md-mt-140 {
    margin-top: 140px;
  }

  .sc-md-mt-141 {
    margin-top: 141px;
  }

  .sc-md-mt-142 {
    margin-top: 142px;
  }

  .sc-md-mt-143 {
    margin-top: 143px;
  }

  .sc-md-mt-144 {
    margin-top: 144px;
  }

  .sc-md-mt-145 {
    margin-top: 145px;
  }

  .sc-md-mt-146 {
    margin-top: 146px;
  }

  .sc-md-mt-147 {
    margin-top: 147px;
  }

  .sc-md-mt-148 {
    margin-top: 148px;
  }

  .sc-md-mt-149 {
    margin-top: 149px;
  }

  .sc-md-mt-150 {
    margin-top: 150px;
  }

  .sc-md-mt-151 {
    margin-top: 151px;
  }

  .sc-md-mt-152 {
    margin-top: 152px;
  }

  .sc-md-mt-153 {
    margin-top: 153px;
  }

  .sc-md-mt-154 {
    margin-top: 154px;
  }

  .sc-md-mt-155 {
    margin-top: 155px;
  }

  .sc-md-mt-156 {
    margin-top: 156px;
  }

  .sc-md-mt-157 {
    margin-top: 157px;
  }

  .sc-md-mt-158 {
    margin-top: 158px;
  }

  .sc-md-mt-159 {
    margin-top: 159px;
  }

  .sc-md-mt-160 {
    margin-top: 160px;
  }

  .sc-md-mt-161 {
    margin-top: 161px;
  }

  .sc-md-mt-162 {
    margin-top: 162px;
  }

  .sc-md-mt-163 {
    margin-top: 163px;
  }

  .sc-md-mt-164 {
    margin-top: 164px;
  }

  .sc-md-mt-165 {
    margin-top: 165px;
  }

  .sc-md-mt-166 {
    margin-top: 166px;
  }

  .sc-md-mt-167 {
    margin-top: 167px;
  }

  .sc-md-mt-168 {
    margin-top: 168px;
  }

  .sc-md-mt-169 {
    margin-top: 169px;
  }

  .sc-md-mt-170 {
    margin-top: 170px;
  }

  .sc-md-mt-171 {
    margin-top: 171px;
  }

  .sc-md-mt-172 {
    margin-top: 172px;
  }

  .sc-md-mt-173 {
    margin-top: 173px;
  }

  .sc-md-mt-174 {
    margin-top: 174px;
  }

  .sc-md-mt-175 {
    margin-top: 175px;
  }

  .sc-md-mt-176 {
    margin-top: 176px;
  }

  .sc-md-mt-177 {
    margin-top: 177px;
  }

  .sc-md-mt-178 {
    margin-top: 178px;
  }

  .sc-md-mt-179 {
    margin-top: 179px;
  }

  .sc-md-mt-180 {
    margin-top: 180px;
  }

  .sc-md-mt-181 {
    margin-top: 181px;
  }

  .sc-md-mt-182 {
    margin-top: 182px;
  }

  .sc-md-mt-183 {
    margin-top: 183px;
  }

  .sc-md-mt-184 {
    margin-top: 184px;
  }

  .sc-md-mt-185 {
    margin-top: 185px;
  }

  .sc-md-mt-186 {
    margin-top: 186px;
  }

  .sc-md-mt-187 {
    margin-top: 187px;
  }

  .sc-md-mt-188 {
    margin-top: 188px;
  }

  .sc-md-mt-189 {
    margin-top: 189px;
  }

  .sc-md-mt-190 {
    margin-top: 190px;
  }

  .sc-md-mt-191 {
    margin-top: 191px;
  }

  .sc-md-mt-192 {
    margin-top: 192px;
  }

  .sc-md-mt-193 {
    margin-top: 193px;
  }

  .sc-md-mt-194 {
    margin-top: 194px;
  }

  .sc-md-mt-195 {
    margin-top: 195px;
  }

  .sc-md-mt-196 {
    margin-top: 196px;
  }

  .sc-md-mt-197 {
    margin-top: 197px;
  }

  .sc-md-mt-198 {
    margin-top: 198px;
  }

  .sc-md-mt-199 {
    margin-top: 199px;
  }

  .sc-md-mt-200 {
    margin-top: 200px;
  }

  .sc-md-mb-0 {
    margin-bottom: 0;
  }

  .sc-md-mb-1 {
    margin-bottom: 1px;
  }

  .sc-md-mb-2 {
    margin-bottom: 2px;
  }

  .sc-md-mb-3 {
    margin-bottom: 3px;
  }

  .sc-md-mb-4 {
    margin-bottom: 4px;
  }

  .sc-md-mb-5 {
    margin-bottom: 5px;
  }

  .sc-md-mb-6 {
    margin-bottom: 6px;
  }

  .sc-md-mb-7 {
    margin-bottom: 7px;
  }

  .sc-md-mb-8 {
    margin-bottom: 8px;
  }

  .sc-md-mb-9 {
    margin-bottom: 9px;
  }

  .sc-md-mb-10 {
    margin-bottom: 10px;
  }

  .sc-md-mb-11 {
    margin-bottom: 11px;
  }

  .sc-md-mb-12 {
    margin-bottom: 12px;
  }

  .sc-md-mb-13 {
    margin-bottom: 13px;
  }

  .sc-md-mb-14 {
    margin-bottom: 14px;
  }

  .sc-md-mb-15 {
    margin-bottom: 15px;
  }

  .sc-md-mb-16 {
    margin-bottom: 16px;
  }

  .sc-md-mb-17 {
    margin-bottom: 17px;
  }

  .sc-md-mb-18 {
    margin-bottom: 18px;
  }

  .sc-md-mb-19 {
    margin-bottom: 19px;
  }

  .sc-md-mb-20 {
    margin-bottom: 20px;
  }

  .sc-md-mb-21 {
    margin-bottom: 21px;
  }

  .sc-md-mb-22 {
    margin-bottom: 22px;
  }

  .sc-md-mb-23 {
    margin-bottom: 23px;
  }

  .sc-md-mb-24 {
    margin-bottom: 24px;
  }

  .sc-md-mb-25 {
    margin-bottom: 25px;
  }

  .sc-md-mb-26 {
    margin-bottom: 26px;
  }

  .sc-md-mb-27 {
    margin-bottom: 27px;
  }

  .sc-md-mb-28 {
    margin-bottom: 28px;
  }

  .sc-md-mb-29 {
    margin-bottom: 29px;
  }

  .sc-md-mb-30 {
    margin-bottom: 30px;
  }

  .sc-md-mb-31 {
    margin-bottom: 31px;
  }

  .sc-md-mb-32 {
    margin-bottom: 32px;
  }

  .sc-md-mb-33 {
    margin-bottom: 33px;
  }

  .sc-md-mb-34 {
    margin-bottom: 34px;
  }

  .sc-md-mb-35 {
    margin-bottom: 35px;
  }

  .sc-md-mb-36 {
    margin-bottom: 36px;
  }

  .sc-md-mb-37 {
    margin-bottom: 37px;
  }

  .sc-md-mb-38 {
    margin-bottom: 38px;
  }

  .sc-md-mb-39 {
    margin-bottom: 39px;
  }

  .sc-md-mb-40 {
    margin-bottom: 40px;
  }

  .sc-md-mb-41 {
    margin-bottom: 41px;
  }

  .sc-md-mb-42 {
    margin-bottom: 42px;
  }

  .sc-md-mb-43 {
    margin-bottom: 43px;
  }

  .sc-md-mb-44 {
    margin-bottom: 44px;
  }

  .sc-md-mb-45 {
    margin-bottom: 45px;
  }

  .sc-md-mb-46 {
    margin-bottom: 46px;
  }

  .sc-md-mb-47 {
    margin-bottom: 47px;
  }

  .sc-md-mb-48 {
    margin-bottom: 48px;
  }

  .sc-md-mb-49 {
    margin-bottom: 49px;
  }

  .sc-md-mb-50 {
    margin-bottom: 50px;
  }

  .sc-md-mb-51 {
    margin-bottom: 51px;
  }

  .sc-md-mb-52 {
    margin-bottom: 52px;
  }

  .sc-md-mb-53 {
    margin-bottom: 53px;
  }

  .sc-md-mb-54 {
    margin-bottom: 54px;
  }

  .sc-md-mb-55 {
    margin-bottom: 55px;
  }

  .sc-md-mb-56 {
    margin-bottom: 56px;
  }

  .sc-md-mb-57 {
    margin-bottom: 57px;
  }

  .sc-md-mb-58 {
    margin-bottom: 58px;
  }

  .sc-md-mb-59 {
    margin-bottom: 59px;
  }

  .sc-md-mb-60 {
    margin-bottom: 60px;
  }

  .sc-md-mb-61 {
    margin-bottom: 61px;
  }

  .sc-md-mb-62 {
    margin-bottom: 62px;
  }

  .sc-md-mb-63 {
    margin-bottom: 63px;
  }

  .sc-md-mb-64 {
    margin-bottom: 64px;
  }

  .sc-md-mb-65 {
    margin-bottom: 65px;
  }

  .sc-md-mb-66 {
    margin-bottom: 66px;
  }

  .sc-md-mb-67 {
    margin-bottom: 67px;
  }

  .sc-md-mb-68 {
    margin-bottom: 68px;
  }

  .sc-md-mb-69 {
    margin-bottom: 69px;
  }

  .sc-md-mb-70 {
    margin-bottom: 70px;
  }

  .sc-md-mb-71 {
    margin-bottom: 71px;
  }

  .sc-md-mb-72 {
    margin-bottom: 72px;
  }

  .sc-md-mb-73 {
    margin-bottom: 73px;
  }

  .sc-md-mb-74 {
    margin-bottom: 74px;
  }

  .sc-md-mb-75 {
    margin-bottom: 75px;
  }

  .sc-md-mb-76 {
    margin-bottom: 76px;
  }

  .sc-md-mb-77 {
    margin-bottom: 77px;
  }

  .sc-md-mb-78 {
    margin-bottom: 78px;
  }

  .sc-md-mb-79 {
    margin-bottom: 79px;
  }

  .sc-md-mb-80 {
    margin-bottom: 80px;
  }

  .sc-md-mb-81 {
    margin-bottom: 81px;
  }

  .sc-md-mb-82 {
    margin-bottom: 82px;
  }

  .sc-md-mb-83 {
    margin-bottom: 83px;
  }

  .sc-md-mb-84 {
    margin-bottom: 84px;
  }

  .sc-md-mb-85 {
    margin-bottom: 85px;
  }

  .sc-md-mb-86 {
    margin-bottom: 86px;
  }

  .sc-md-mb-87 {
    margin-bottom: 87px;
  }

  .sc-md-mb-88 {
    margin-bottom: 88px;
  }

  .sc-md-mb-89 {
    margin-bottom: 89px;
  }

  .sc-md-mb-90 {
    margin-bottom: 90px;
  }

  .sc-md-mb-91 {
    margin-bottom: 91px;
  }

  .sc-md-mb-92 {
    margin-bottom: 92px;
  }

  .sc-md-mb-93 {
    margin-bottom: 93px;
  }

  .sc-md-mb-94 {
    margin-bottom: 94px;
  }

  .sc-md-mb-95 {
    margin-bottom: 95px;
  }

  .sc-md-mb-96 {
    margin-bottom: 96px;
  }

  .sc-md-mb-97 {
    margin-bottom: 97px;
  }

  .sc-md-mb-98 {
    margin-bottom: 98px;
  }

  .sc-md-mb-99 {
    margin-bottom: 99px;
  }

  .sc-md-mb-100 {
    margin-bottom: 100px;
  }

  .sc-md-mb-101 {
    margin-bottom: 101px;
  }

  .sc-md-mb-102 {
    margin-bottom: 102px;
  }

  .sc-md-mb-103 {
    margin-bottom: 103px;
  }

  .sc-md-mb-104 {
    margin-bottom: 104px;
  }

  .sc-md-mb-105 {
    margin-bottom: 105px;
  }

  .sc-md-mb-106 {
    margin-bottom: 106px;
  }

  .sc-md-mb-107 {
    margin-bottom: 107px;
  }

  .sc-md-mb-108 {
    margin-bottom: 108px;
  }

  .sc-md-mb-109 {
    margin-bottom: 109px;
  }

  .sc-md-mb-110 {
    margin-bottom: 110px;
  }

  .sc-md-mb-111 {
    margin-bottom: 111px;
  }

  .sc-md-mb-112 {
    margin-bottom: 112px;
  }

  .sc-md-mb-113 {
    margin-bottom: 113px;
  }

  .sc-md-mb-114 {
    margin-bottom: 114px;
  }

  .sc-md-mb-115 {
    margin-bottom: 115px;
  }

  .sc-md-mb-116 {
    margin-bottom: 116px;
  }

  .sc-md-mb-117 {
    margin-bottom: 117px;
  }

  .sc-md-mb-118 {
    margin-bottom: 118px;
  }

  .sc-md-mb-119 {
    margin-bottom: 119px;
  }

  .sc-md-mb-120 {
    margin-bottom: 120px;
  }

  .sc-md-mb-121 {
    margin-bottom: 121px;
  }

  .sc-md-mb-122 {
    margin-bottom: 122px;
  }

  .sc-md-mb-123 {
    margin-bottom: 123px;
  }

  .sc-md-mb-124 {
    margin-bottom: 124px;
  }

  .sc-md-mb-125 {
    margin-bottom: 125px;
  }

  .sc-md-mb-126 {
    margin-bottom: 126px;
  }

  .sc-md-mb-127 {
    margin-bottom: 127px;
  }

  .sc-md-mb-128 {
    margin-bottom: 128px;
  }

  .sc-md-mb-129 {
    margin-bottom: 129px;
  }

  .sc-md-mb-130 {
    margin-bottom: 130px;
  }

  .sc-md-mb-131 {
    margin-bottom: 131px;
  }

  .sc-md-mb-132 {
    margin-bottom: 132px;
  }

  .sc-md-mb-133 {
    margin-bottom: 133px;
  }

  .sc-md-mb-134 {
    margin-bottom: 134px;
  }

  .sc-md-mb-135 {
    margin-bottom: 135px;
  }

  .sc-md-mb-136 {
    margin-bottom: 136px;
  }

  .sc-md-mb-137 {
    margin-bottom: 137px;
  }

  .sc-md-mb-138 {
    margin-bottom: 138px;
  }

  .sc-md-mb-139 {
    margin-bottom: 139px;
  }

  .sc-md-mb-140 {
    margin-bottom: 140px;
  }

  .sc-md-mb-141 {
    margin-bottom: 141px;
  }

  .sc-md-mb-142 {
    margin-bottom: 142px;
  }

  .sc-md-mb-143 {
    margin-bottom: 143px;
  }

  .sc-md-mb-144 {
    margin-bottom: 144px;
  }

  .sc-md-mb-145 {
    margin-bottom: 145px;
  }

  .sc-md-mb-146 {
    margin-bottom: 146px;
  }

  .sc-md-mb-147 {
    margin-bottom: 147px;
  }

  .sc-md-mb-148 {
    margin-bottom: 148px;
  }

  .sc-md-mb-149 {
    margin-bottom: 149px;
  }

  .sc-md-mb-150 {
    margin-bottom: 150px;
  }

  .sc-md-mb-151 {
    margin-bottom: 151px;
  }

  .sc-md-mb-152 {
    margin-bottom: 152px;
  }

  .sc-md-mb-153 {
    margin-bottom: 153px;
  }

  .sc-md-mb-154 {
    margin-bottom: 154px;
  }

  .sc-md-mb-155 {
    margin-bottom: 155px;
  }

  .sc-md-mb-156 {
    margin-bottom: 156px;
  }

  .sc-md-mb-157 {
    margin-bottom: 157px;
  }

  .sc-md-mb-158 {
    margin-bottom: 158px;
  }

  .sc-md-mb-159 {
    margin-bottom: 159px;
  }

  .sc-md-mb-160 {
    margin-bottom: 160px;
  }

  .sc-md-mb-161 {
    margin-bottom: 161px;
  }

  .sc-md-mb-162 {
    margin-bottom: 162px;
  }

  .sc-md-mb-163 {
    margin-bottom: 163px;
  }

  .sc-md-mb-164 {
    margin-bottom: 164px;
  }

  .sc-md-mb-165 {
    margin-bottom: 165px;
  }

  .sc-md-mb-166 {
    margin-bottom: 166px;
  }

  .sc-md-mb-167 {
    margin-bottom: 167px;
  }

  .sc-md-mb-168 {
    margin-bottom: 168px;
  }

  .sc-md-mb-169 {
    margin-bottom: 169px;
  }

  .sc-md-mb-170 {
    margin-bottom: 170px;
  }

  .sc-md-mb-171 {
    margin-bottom: 171px;
  }

  .sc-md-mb-172 {
    margin-bottom: 172px;
  }

  .sc-md-mb-173 {
    margin-bottom: 173px;
  }

  .sc-md-mb-174 {
    margin-bottom: 174px;
  }

  .sc-md-mb-175 {
    margin-bottom: 175px;
  }

  .sc-md-mb-176 {
    margin-bottom: 176px;
  }

  .sc-md-mb-177 {
    margin-bottom: 177px;
  }

  .sc-md-mb-178 {
    margin-bottom: 178px;
  }

  .sc-md-mb-179 {
    margin-bottom: 179px;
  }

  .sc-md-mb-180 {
    margin-bottom: 180px;
  }

  .sc-md-mb-181 {
    margin-bottom: 181px;
  }

  .sc-md-mb-182 {
    margin-bottom: 182px;
  }

  .sc-md-mb-183 {
    margin-bottom: 183px;
  }

  .sc-md-mb-184 {
    margin-bottom: 184px;
  }

  .sc-md-mb-185 {
    margin-bottom: 185px;
  }

  .sc-md-mb-186 {
    margin-bottom: 186px;
  }

  .sc-md-mb-187 {
    margin-bottom: 187px;
  }

  .sc-md-mb-188 {
    margin-bottom: 188px;
  }

  .sc-md-mb-189 {
    margin-bottom: 189px;
  }

  .sc-md-mb-190 {
    margin-bottom: 190px;
  }

  .sc-md-mb-191 {
    margin-bottom: 191px;
  }

  .sc-md-mb-192 {
    margin-bottom: 192px;
  }

  .sc-md-mb-193 {
    margin-bottom: 193px;
  }

  .sc-md-mb-194 {
    margin-bottom: 194px;
  }

  .sc-md-mb-195 {
    margin-bottom: 195px;
  }

  .sc-md-mb-196 {
    margin-bottom: 196px;
  }

  .sc-md-mb-197 {
    margin-bottom: 197px;
  }

  .sc-md-mb-198 {
    margin-bottom: 198px;
  }

  .sc-md-mb-199 {
    margin-bottom: 199px;
  }

  .sc-md-mb-200 {
    margin-bottom: 200px;
  }

  .sc-md-mr-0 {
    margin-right: 0;
  }

  .sc-md-mr-1 {
    margin-right: 1px;
  }

  .sc-md-mr-2 {
    margin-right: 2px;
  }

  .sc-md-mr-3 {
    margin-right: 3px;
  }

  .sc-md-mr-4 {
    margin-right: 4px;
  }

  .sc-md-mr-5 {
    margin-right: 5px;
  }

  .sc-md-mr-6 {
    margin-right: 6px;
  }

  .sc-md-mr-7 {
    margin-right: 7px;
  }

  .sc-md-mr-8 {
    margin-right: 8px;
  }

  .sc-md-mr-9 {
    margin-right: 9px;
  }

  .sc-md-mr-10 {
    margin-right: 10px;
  }

  .sc-md-mr-11 {
    margin-right: 11px;
  }

  .sc-md-mr-12 {
    margin-right: 12px;
  }

  .sc-md-mr-13 {
    margin-right: 13px;
  }

  .sc-md-mr-14 {
    margin-right: 14px;
  }

  .sc-md-mr-15 {
    margin-right: 15px;
  }

  .sc-md-mr-16 {
    margin-right: 16px;
  }

  .sc-md-mr-17 {
    margin-right: 17px;
  }

  .sc-md-mr-18 {
    margin-right: 18px;
  }

  .sc-md-mr-19 {
    margin-right: 19px;
  }

  .sc-md-mr-20 {
    margin-right: 20px;
  }

  .sc-md-mr-21 {
    margin-right: 21px;
  }

  .sc-md-mr-22 {
    margin-right: 22px;
  }

  .sc-md-mr-23 {
    margin-right: 23px;
  }

  .sc-md-mr-24 {
    margin-right: 24px;
  }

  .sc-md-mr-25 {
    margin-right: 25px;
  }

  .sc-md-mr-26 {
    margin-right: 26px;
  }

  .sc-md-mr-27 {
    margin-right: 27px;
  }

  .sc-md-mr-28 {
    margin-right: 28px;
  }

  .sc-md-mr-29 {
    margin-right: 29px;
  }

  .sc-md-mr-30 {
    margin-right: 30px;
  }

  .sc-md-mr-31 {
    margin-right: 31px;
  }

  .sc-md-mr-32 {
    margin-right: 32px;
  }

  .sc-md-mr-33 {
    margin-right: 33px;
  }

  .sc-md-mr-34 {
    margin-right: 34px;
  }

  .sc-md-mr-35 {
    margin-right: 35px;
  }

  .sc-md-mr-36 {
    margin-right: 36px;
  }

  .sc-md-mr-37 {
    margin-right: 37px;
  }

  .sc-md-mr-38 {
    margin-right: 38px;
  }

  .sc-md-mr-39 {
    margin-right: 39px;
  }

  .sc-md-mr-40 {
    margin-right: 40px;
  }

  .sc-md-mr-41 {
    margin-right: 41px;
  }

  .sc-md-mr-42 {
    margin-right: 42px;
  }

  .sc-md-mr-43 {
    margin-right: 43px;
  }

  .sc-md-mr-44 {
    margin-right: 44px;
  }

  .sc-md-mr-45 {
    margin-right: 45px;
  }

  .sc-md-mr-46 {
    margin-right: 46px;
  }

  .sc-md-mr-47 {
    margin-right: 47px;
  }

  .sc-md-mr-48 {
    margin-right: 48px;
  }

  .sc-md-mr-49 {
    margin-right: 49px;
  }

  .sc-md-mr-50 {
    margin-right: 50px;
  }

  .sc-md-mr-51 {
    margin-right: 51px;
  }

  .sc-md-mr-52 {
    margin-right: 52px;
  }

  .sc-md-mr-53 {
    margin-right: 53px;
  }

  .sc-md-mr-54 {
    margin-right: 54px;
  }

  .sc-md-mr-55 {
    margin-right: 55px;
  }

  .sc-md-mr-56 {
    margin-right: 56px;
  }

  .sc-md-mr-57 {
    margin-right: 57px;
  }

  .sc-md-mr-58 {
    margin-right: 58px;
  }

  .sc-md-mr-59 {
    margin-right: 59px;
  }

  .sc-md-mr-60 {
    margin-right: 60px;
  }

  .sc-md-mr-61 {
    margin-right: 61px;
  }

  .sc-md-mr-62 {
    margin-right: 62px;
  }

  .sc-md-mr-63 {
    margin-right: 63px;
  }

  .sc-md-mr-64 {
    margin-right: 64px;
  }

  .sc-md-mr-65 {
    margin-right: 65px;
  }

  .sc-md-mr-66 {
    margin-right: 66px;
  }

  .sc-md-mr-67 {
    margin-right: 67px;
  }

  .sc-md-mr-68 {
    margin-right: 68px;
  }

  .sc-md-mr-69 {
    margin-right: 69px;
  }

  .sc-md-mr-70 {
    margin-right: 70px;
  }

  .sc-md-mr-71 {
    margin-right: 71px;
  }

  .sc-md-mr-72 {
    margin-right: 72px;
  }

  .sc-md-mr-73 {
    margin-right: 73px;
  }

  .sc-md-mr-74 {
    margin-right: 74px;
  }

  .sc-md-mr-75 {
    margin-right: 75px;
  }

  .sc-md-mr-76 {
    margin-right: 76px;
  }

  .sc-md-mr-77 {
    margin-right: 77px;
  }

  .sc-md-mr-78 {
    margin-right: 78px;
  }

  .sc-md-mr-79 {
    margin-right: 79px;
  }

  .sc-md-mr-80 {
    margin-right: 80px;
  }

  .sc-md-mr-81 {
    margin-right: 81px;
  }

  .sc-md-mr-82 {
    margin-right: 82px;
  }

  .sc-md-mr-83 {
    margin-right: 83px;
  }

  .sc-md-mr-84 {
    margin-right: 84px;
  }

  .sc-md-mr-85 {
    margin-right: 85px;
  }

  .sc-md-mr-86 {
    margin-right: 86px;
  }

  .sc-md-mr-87 {
    margin-right: 87px;
  }

  .sc-md-mr-88 {
    margin-right: 88px;
  }

  .sc-md-mr-89 {
    margin-right: 89px;
  }

  .sc-md-mr-90 {
    margin-right: 90px;
  }

  .sc-md-mr-91 {
    margin-right: 91px;
  }

  .sc-md-mr-92 {
    margin-right: 92px;
  }

  .sc-md-mr-93 {
    margin-right: 93px;
  }

  .sc-md-mr-94 {
    margin-right: 94px;
  }

  .sc-md-mr-95 {
    margin-right: 95px;
  }

  .sc-md-mr-96 {
    margin-right: 96px;
  }

  .sc-md-mr-97 {
    margin-right: 97px;
  }

  .sc-md-mr-98 {
    margin-right: 98px;
  }

  .sc-md-mr-99 {
    margin-right: 99px;
  }

  .sc-md-mr-100 {
    margin-right: 100px;
  }

  .sc-md-ml-0 {
    margin-left: 0;
  }

  .sc-md-ml-1 {
    margin-left: 1px;
  }

  .sc-md-ml-2 {
    margin-left: 2px;
  }

  .sc-md-ml-3 {
    margin-left: 3px;
  }

  .sc-md-ml-4 {
    margin-left: 4px;
  }

  .sc-md-ml-5 {
    margin-left: 5px;
  }

  .sc-md-ml-6 {
    margin-left: 6px;
  }

  .sc-md-ml-7 {
    margin-left: 7px;
  }

  .sc-md-ml-8 {
    margin-left: 8px;
  }

  .sc-md-ml-9 {
    margin-left: 9px;
  }

  .sc-md-ml-10 {
    margin-left: 10px;
  }

  .sc-md-ml-11 {
    margin-left: 11px;
  }

  .sc-md-ml-12 {
    margin-left: 12px;
  }

  .sc-md-ml-13 {
    margin-left: 13px;
  }

  .sc-md-ml-14 {
    margin-left: 14px;
  }

  .sc-md-ml-15 {
    margin-left: 15px;
  }

  .sc-md-ml-16 {
    margin-left: 16px;
  }

  .sc-md-ml-17 {
    margin-left: 17px;
  }

  .sc-md-ml-18 {
    margin-left: 18px;
  }

  .sc-md-ml-19 {
    margin-left: 19px;
  }

  .sc-md-ml-20 {
    margin-left: 20px;
  }

  .sc-md-ml-21 {
    margin-left: 21px;
  }

  .sc-md-ml-22 {
    margin-left: 22px;
  }

  .sc-md-ml-23 {
    margin-left: 23px;
  }

  .sc-md-ml-24 {
    margin-left: 24px;
  }

  .sc-md-ml-25 {
    margin-left: 25px;
  }

  .sc-md-ml-26 {
    margin-left: 26px;
  }

  .sc-md-ml-27 {
    margin-left: 27px;
  }

  .sc-md-ml-28 {
    margin-left: 28px;
  }

  .sc-md-ml-29 {
    margin-left: 29px;
  }

  .sc-md-ml-30 {
    margin-left: 30px;
  }

  .sc-md-ml-31 {
    margin-left: 31px;
  }

  .sc-md-ml-32 {
    margin-left: 32px;
  }

  .sc-md-ml-33 {
    margin-left: 33px;
  }

  .sc-md-ml-34 {
    margin-left: 34px;
  }

  .sc-md-ml-35 {
    margin-left: 35px;
  }

  .sc-md-ml-36 {
    margin-left: 36px;
  }

  .sc-md-ml-37 {
    margin-left: 37px;
  }

  .sc-md-ml-38 {
    margin-left: 38px;
  }

  .sc-md-ml-39 {
    margin-left: 39px;
  }

  .sc-md-ml-40 {
    margin-left: 40px;
  }

  .sc-md-ml-41 {
    margin-left: 41px;
  }

  .sc-md-ml-42 {
    margin-left: 42px;
  }

  .sc-md-ml-43 {
    margin-left: 43px;
  }

  .sc-md-ml-44 {
    margin-left: 44px;
  }

  .sc-md-ml-45 {
    margin-left: 45px;
  }

  .sc-md-ml-46 {
    margin-left: 46px;
  }

  .sc-md-ml-47 {
    margin-left: 47px;
  }

  .sc-md-ml-48 {
    margin-left: 48px;
  }

  .sc-md-ml-49 {
    margin-left: 49px;
  }

  .sc-md-ml-50 {
    margin-left: 50px;
  }

  .sc-md-ml-51 {
    margin-left: 51px;
  }

  .sc-md-ml-52 {
    margin-left: 52px;
  }

  .sc-md-ml-53 {
    margin-left: 53px;
  }

  .sc-md-ml-54 {
    margin-left: 54px;
  }

  .sc-md-ml-55 {
    margin-left: 55px;
  }

  .sc-md-ml-56 {
    margin-left: 56px;
  }

  .sc-md-ml-57 {
    margin-left: 57px;
  }

  .sc-md-ml-58 {
    margin-left: 58px;
  }

  .sc-md-ml-59 {
    margin-left: 59px;
  }

  .sc-md-ml-60 {
    margin-left: 60px;
  }

  .sc-md-ml-61 {
    margin-left: 61px;
  }

  .sc-md-ml-62 {
    margin-left: 62px;
  }

  .sc-md-ml-63 {
    margin-left: 63px;
  }

  .sc-md-ml-64 {
    margin-left: 64px;
  }

  .sc-md-ml-65 {
    margin-left: 65px;
  }

  .sc-md-ml-66 {
    margin-left: 66px;
  }

  .sc-md-ml-67 {
    margin-left: 67px;
  }

  .sc-md-ml-68 {
    margin-left: 68px;
  }

  .sc-md-ml-69 {
    margin-left: 69px;
  }

  .sc-md-ml-70 {
    margin-left: 70px;
  }

  .sc-md-ml-71 {
    margin-left: 71px;
  }

  .sc-md-ml-72 {
    margin-left: 72px;
  }

  .sc-md-ml-73 {
    margin-left: 73px;
  }

  .sc-md-ml-74 {
    margin-left: 74px;
  }

  .sc-md-ml-75 {
    margin-left: 75px;
  }

  .sc-md-ml-76 {
    margin-left: 76px;
  }

  .sc-md-ml-77 {
    margin-left: 77px;
  }

  .sc-md-ml-78 {
    margin-left: 78px;
  }

  .sc-md-ml-79 {
    margin-left: 79px;
  }

  .sc-md-ml-80 {
    margin-left: 80px;
  }

  .sc-md-ml-81 {
    margin-left: 81px;
  }

  .sc-md-ml-82 {
    margin-left: 82px;
  }

  .sc-md-ml-83 {
    margin-left: 83px;
  }

  .sc-md-ml-84 {
    margin-left: 84px;
  }

  .sc-md-ml-85 {
    margin-left: 85px;
  }

  .sc-md-ml-86 {
    margin-left: 86px;
  }

  .sc-md-ml-87 {
    margin-left: 87px;
  }

  .sc-md-ml-88 {
    margin-left: 88px;
  }

  .sc-md-ml-89 {
    margin-left: 89px;
  }

  .sc-md-ml-90 {
    margin-left: 90px;
  }

  .sc-md-ml-91 {
    margin-left: 91px;
  }

  .sc-md-ml-92 {
    margin-left: 92px;
  }

  .sc-md-ml-93 {
    margin-left: 93px;
  }

  .sc-md-ml-94 {
    margin-left: 94px;
  }

  .sc-md-ml-95 {
    margin-left: 95px;
  }

  .sc-md-ml-96 {
    margin-left: 96px;
  }

  .sc-md-ml-97 {
    margin-left: 97px;
  }

  .sc-md-ml-98 {
    margin-left: 98px;
  }

  .sc-md-ml-99 {
    margin-left: 99px;
  }

  .sc-md-ml-100 {
    margin-left: 100px;
  }

  .sc-md-mt--1 {
    margin-top: -1px;
  }

  .sc-md-mt--2 {
    margin-top: -2px;
  }

  .sc-md-mt--3 {
    margin-top: -3px;
  }

  .sc-md-mt--4 {
    margin-top: -4px;
  }

  .sc-md-mt--5 {
    margin-top: -5px;
  }

  .sc-md-mt--6 {
    margin-top: -6px;
  }

  .sc-md-mt--7 {
    margin-top: -7px;
  }

  .sc-md-mt--8 {
    margin-top: -8px;
  }

  .sc-md-mt--9 {
    margin-top: -9px;
  }

  .sc-md-mt--10 {
    margin-top: -10px;
  }

  .sc-md-mt--11 {
    margin-top: -11px;
  }

  .sc-md-mt--12 {
    margin-top: -12px;
  }

  .sc-md-mt--13 {
    margin-top: -13px;
  }

  .sc-md-mt--14 {
    margin-top: -14px;
  }

  .sc-md-mt--15 {
    margin-top: -15px;
  }

  .sc-md-mt--16 {
    margin-top: -16px;
  }

  .sc-md-mt--17 {
    margin-top: -17px;
  }

  .sc-md-mt--18 {
    margin-top: -18px;
  }

  .sc-md-mt--19 {
    margin-top: -19px;
  }

  .sc-md-mt--20 {
    margin-top: -20px;
  }

  .sc-md-mt--21 {
    margin-top: -21px;
  }

  .sc-md-mt--22 {
    margin-top: -22px;
  }

  .sc-md-mt--23 {
    margin-top: -23px;
  }

  .sc-md-mt--24 {
    margin-top: -24px;
  }

  .sc-md-mt--25 {
    margin-top: -25px;
  }

  .sc-md-mt--26 {
    margin-top: -26px;
  }

  .sc-md-mt--27 {
    margin-top: -27px;
  }

  .sc-md-mt--28 {
    margin-top: -28px;
  }

  .sc-md-mt--29 {
    margin-top: -29px;
  }

  .sc-md-mt--30 {
    margin-top: -30px;
  }

  .sc-md-mt--31 {
    margin-top: -31px;
  }

  .sc-md-mt--32 {
    margin-top: -32px;
  }

  .sc-md-mt--33 {
    margin-top: -33px;
  }

  .sc-md-mt--34 {
    margin-top: -34px;
  }

  .sc-md-mt--35 {
    margin-top: -35px;
  }

  .sc-md-mt--36 {
    margin-top: -36px;
  }

  .sc-md-mt--37 {
    margin-top: -37px;
  }

  .sc-md-mt--38 {
    margin-top: -38px;
  }

  .sc-md-mt--39 {
    margin-top: -39px;
  }

  .sc-md-mt--40 {
    margin-top: -40px;
  }

  .sc-md-mt--41 {
    margin-top: -41px;
  }

  .sc-md-mt--42 {
    margin-top: -42px;
  }

  .sc-md-mt--43 {
    margin-top: -43px;
  }

  .sc-md-mt--44 {
    margin-top: -44px;
  }

  .sc-md-mt--45 {
    margin-top: -45px;
  }

  .sc-md-mt--46 {
    margin-top: -46px;
  }

  .sc-md-mt--47 {
    margin-top: -47px;
  }

  .sc-md-mt--48 {
    margin-top: -48px;
  }

  .sc-md-mt--49 {
    margin-top: -49px;
  }

  .sc-md-mt--50 {
    margin-top: -50px;
  }

  .sc-md-mt--51 {
    margin-top: -51px;
  }

  .sc-md-mt--52 {
    margin-top: -52px;
  }

  .sc-md-mt--53 {
    margin-top: -53px;
  }

  .sc-md-mt--54 {
    margin-top: -54px;
  }

  .sc-md-mt--55 {
    margin-top: -55px;
  }

  .sc-md-mt--56 {
    margin-top: -56px;
  }

  .sc-md-mt--57 {
    margin-top: -57px;
  }

  .sc-md-mt--58 {
    margin-top: -58px;
  }

  .sc-md-mt--59 {
    margin-top: -59px;
  }

  .sc-md-mt--60 {
    margin-top: -60px;
  }

  .sc-md-mt--61 {
    margin-top: -61px;
  }

  .sc-md-mt--62 {
    margin-top: -62px;
  }

  .sc-md-mt--63 {
    margin-top: -63px;
  }

  .sc-md-mt--64 {
    margin-top: -64px;
  }

  .sc-md-mt--65 {
    margin-top: -65px;
  }

  .sc-md-mt--66 {
    margin-top: -66px;
  }

  .sc-md-mt--67 {
    margin-top: -67px;
  }

  .sc-md-mt--68 {
    margin-top: -68px;
  }

  .sc-md-mt--69 {
    margin-top: -69px;
  }

  .sc-md-mt--70 {
    margin-top: -70px;
  }

  .sc-md-mt--71 {
    margin-top: -71px;
  }

  .sc-md-mt--72 {
    margin-top: -72px;
  }

  .sc-md-mt--73 {
    margin-top: -73px;
  }

  .sc-md-mt--74 {
    margin-top: -74px;
  }

  .sc-md-mt--75 {
    margin-top: -75px;
  }

  .sc-md-mt--76 {
    margin-top: -76px;
  }

  .sc-md-mt--77 {
    margin-top: -77px;
  }

  .sc-md-mt--78 {
    margin-top: -78px;
  }

  .sc-md-mt--79 {
    margin-top: -79px;
  }

  .sc-md-mt--80 {
    margin-top: -80px;
  }

  .sc-md-mt--81 {
    margin-top: -81px;
  }

  .sc-md-mt--82 {
    margin-top: -82px;
  }

  .sc-md-mt--83 {
    margin-top: -83px;
  }

  .sc-md-mt--84 {
    margin-top: -84px;
  }

  .sc-md-mt--85 {
    margin-top: -85px;
  }

  .sc-md-mt--86 {
    margin-top: -86px;
  }

  .sc-md-mt--87 {
    margin-top: -87px;
  }

  .sc-md-mt--88 {
    margin-top: -88px;
  }

  .sc-md-mt--89 {
    margin-top: -89px;
  }

  .sc-md-mt--90 {
    margin-top: -90px;
  }

  .sc-md-mt--91 {
    margin-top: -91px;
  }

  .sc-md-mt--92 {
    margin-top: -92px;
  }

  .sc-md-mt--93 {
    margin-top: -93px;
  }

  .sc-md-mt--94 {
    margin-top: -94px;
  }

  .sc-md-mt--95 {
    margin-top: -95px;
  }

  .sc-md-mt--96 {
    margin-top: -96px;
  }

  .sc-md-mt--97 {
    margin-top: -97px;
  }

  .sc-md-mt--98 {
    margin-top: -98px;
  }

  .sc-md-mt--99 {
    margin-top: -99px;
  }

  .sc-md-mt--100 {
    margin-top: -100px;
  }

  .sc-md-mt--101 {
    margin-top: -101px;
  }

  .sc-md-mt--102 {
    margin-top: -102px;
  }

  .sc-md-mt--103 {
    margin-top: -103px;
  }

  .sc-md-mt--104 {
    margin-top: -104px;
  }

  .sc-md-mt--105 {
    margin-top: -105px;
  }

  .sc-md-mt--106 {
    margin-top: -106px;
  }

  .sc-md-mt--107 {
    margin-top: -107px;
  }

  .sc-md-mt--108 {
    margin-top: -108px;
  }

  .sc-md-mt--109 {
    margin-top: -109px;
  }

  .sc-md-mt--110 {
    margin-top: -110px;
  }

  .sc-md-mt--111 {
    margin-top: -111px;
  }

  .sc-md-mt--112 {
    margin-top: -112px;
  }

  .sc-md-mt--113 {
    margin-top: -113px;
  }

  .sc-md-mt--114 {
    margin-top: -114px;
  }

  .sc-md-mt--115 {
    margin-top: -115px;
  }

  .sc-md-mt--116 {
    margin-top: -116px;
  }

  .sc-md-mt--117 {
    margin-top: -117px;
  }

  .sc-md-mt--118 {
    margin-top: -118px;
  }

  .sc-md-mt--119 {
    margin-top: -119px;
  }

  .sc-md-mt--120 {
    margin-top: -120px;
  }

  .sc-md-mt--121 {
    margin-top: -121px;
  }

  .sc-md-mt--122 {
    margin-top: -122px;
  }

  .sc-md-mt--123 {
    margin-top: -123px;
  }

  .sc-md-mt--124 {
    margin-top: -124px;
  }

  .sc-md-mt--125 {
    margin-top: -125px;
  }

  .sc-md-mt--126 {
    margin-top: -126px;
  }

  .sc-md-mt--127 {
    margin-top: -127px;
  }

  .sc-md-mt--128 {
    margin-top: -128px;
  }

  .sc-md-mt--129 {
    margin-top: -129px;
  }

  .sc-md-mt--130 {
    margin-top: -130px;
  }

  .sc-md-mt--131 {
    margin-top: -131px;
  }

  .sc-md-mt--132 {
    margin-top: -132px;
  }

  .sc-md-mt--133 {
    margin-top: -133px;
  }

  .sc-md-mt--134 {
    margin-top: -134px;
  }

  .sc-md-mt--135 {
    margin-top: -135px;
  }

  .sc-md-mt--136 {
    margin-top: -136px;
  }

  .sc-md-mt--137 {
    margin-top: -137px;
  }

  .sc-md-mt--138 {
    margin-top: -138px;
  }

  .sc-md-mt--139 {
    margin-top: -139px;
  }

  .sc-md-mt--140 {
    margin-top: -140px;
  }

  .sc-md-mt--141 {
    margin-top: -141px;
  }

  .sc-md-mt--142 {
    margin-top: -142px;
  }

  .sc-md-mt--143 {
    margin-top: -143px;
  }

  .sc-md-mt--144 {
    margin-top: -144px;
  }

  .sc-md-mt--145 {
    margin-top: -145px;
  }

  .sc-md-mt--146 {
    margin-top: -146px;
  }

  .sc-md-mt--147 {
    margin-top: -147px;
  }

  .sc-md-mt--148 {
    margin-top: -148px;
  }

  .sc-md-mt--149 {
    margin-top: -149px;
  }

  .sc-md-mt--150 {
    margin-top: -150px;
  }

  .sc-md-mt--151 {
    margin-top: -151px;
  }

  .sc-md-mt--152 {
    margin-top: -152px;
  }

  .sc-md-mt--153 {
    margin-top: -153px;
  }

  .sc-md-mt--154 {
    margin-top: -154px;
  }

  .sc-md-mt--155 {
    margin-top: -155px;
  }

  .sc-md-mt--156 {
    margin-top: -156px;
  }

  .sc-md-mt--157 {
    margin-top: -157px;
  }

  .sc-md-mt--158 {
    margin-top: -158px;
  }

  .sc-md-mt--159 {
    margin-top: -159px;
  }

  .sc-md-mt--160 {
    margin-top: -160px;
  }

  .sc-md-mt--161 {
    margin-top: -161px;
  }

  .sc-md-mt--162 {
    margin-top: -162px;
  }

  .sc-md-mt--163 {
    margin-top: -163px;
  }

  .sc-md-mt--164 {
    margin-top: -164px;
  }

  .sc-md-mt--165 {
    margin-top: -165px;
  }

  .sc-md-mt--166 {
    margin-top: -166px;
  }

  .sc-md-mt--167 {
    margin-top: -167px;
  }

  .sc-md-mt--168 {
    margin-top: -168px;
  }

  .sc-md-mt--169 {
    margin-top: -169px;
  }

  .sc-md-mt--170 {
    margin-top: -170px;
  }

  .sc-md-mt--171 {
    margin-top: -171px;
  }

  .sc-md-mt--172 {
    margin-top: -172px;
  }

  .sc-md-mt--173 {
    margin-top: -173px;
  }

  .sc-md-mt--174 {
    margin-top: -174px;
  }

  .sc-md-mt--175 {
    margin-top: -175px;
  }

  .sc-md-mt--176 {
    margin-top: -176px;
  }

  .sc-md-mt--177 {
    margin-top: -177px;
  }

  .sc-md-mt--178 {
    margin-top: -178px;
  }

  .sc-md-mt--179 {
    margin-top: -179px;
  }

  .sc-md-mt--180 {
    margin-top: -180px;
  }

  .sc-md-mt--181 {
    margin-top: -181px;
  }

  .sc-md-mt--182 {
    margin-top: -182px;
  }

  .sc-md-mt--183 {
    margin-top: -183px;
  }

  .sc-md-mt--184 {
    margin-top: -184px;
  }

  .sc-md-mt--185 {
    margin-top: -185px;
  }

  .sc-md-mt--186 {
    margin-top: -186px;
  }

  .sc-md-mt--187 {
    margin-top: -187px;
  }

  .sc-md-mt--188 {
    margin-top: -188px;
  }

  .sc-md-mt--189 {
    margin-top: -189px;
  }

  .sc-md-mt--190 {
    margin-top: -190px;
  }

  .sc-md-mt--191 {
    margin-top: -191px;
  }

  .sc-md-mt--192 {
    margin-top: -192px;
  }

  .sc-md-mt--193 {
    margin-top: -193px;
  }

  .sc-md-mt--194 {
    margin-top: -194px;
  }

  .sc-md-mt--195 {
    margin-top: -195px;
  }

  .sc-md-mt--196 {
    margin-top: -196px;
  }

  .sc-md-mt--197 {
    margin-top: -197px;
  }

  .sc-md-mt--198 {
    margin-top: -198px;
  }

  .sc-md-mt--199 {
    margin-top: -199px;
  }

  .sc-md-mt--200 {
    margin-top: -200px;
  }

  .sc-md-mb--1 {
    margin-bottom: -1px;
  }

  .sc-md-mb--2 {
    margin-bottom: -2px;
  }

  .sc-md-mb--3 {
    margin-bottom: -3px;
  }

  .sc-md-mb--4 {
    margin-bottom: -4px;
  }

  .sc-md-mb--5 {
    margin-bottom: -5px;
  }

  .sc-md-mb--6 {
    margin-bottom: -6px;
  }

  .sc-md-mb--7 {
    margin-bottom: -7px;
  }

  .sc-md-mb--8 {
    margin-bottom: -8px;
  }

  .sc-md-mb--9 {
    margin-bottom: -9px;
  }

  .sc-md-mb--10 {
    margin-bottom: -10px;
  }

  .sc-md-mb--11 {
    margin-bottom: -11px;
  }

  .sc-md-mb--12 {
    margin-bottom: -12px;
  }

  .sc-md-mb--13 {
    margin-bottom: -13px;
  }

  .sc-md-mb--14 {
    margin-bottom: -14px;
  }

  .sc-md-mb--15 {
    margin-bottom: -15px;
  }

  .sc-md-mb--16 {
    margin-bottom: -16px;
  }

  .sc-md-mb--17 {
    margin-bottom: -17px;
  }

  .sc-md-mb--18 {
    margin-bottom: -18px;
  }

  .sc-md-mb--19 {
    margin-bottom: -19px;
  }

  .sc-md-mb--20 {
    margin-bottom: -20px;
  }

  .sc-md-mb--21 {
    margin-bottom: -21px;
  }

  .sc-md-mb--22 {
    margin-bottom: -22px;
  }

  .sc-md-mb--23 {
    margin-bottom: -23px;
  }

  .sc-md-mb--24 {
    margin-bottom: -24px;
  }

  .sc-md-mb--25 {
    margin-bottom: -25px;
  }

  .sc-md-mb--26 {
    margin-bottom: -26px;
  }

  .sc-md-mb--27 {
    margin-bottom: -27px;
  }

  .sc-md-mb--28 {
    margin-bottom: -28px;
  }

  .sc-md-mb--29 {
    margin-bottom: -29px;
  }

  .sc-md-mb--30 {
    margin-bottom: -30px;
  }

  .sc-md-mb--31 {
    margin-bottom: -31px;
  }

  .sc-md-mb--32 {
    margin-bottom: -32px;
  }

  .sc-md-mb--33 {
    margin-bottom: -33px;
  }

  .sc-md-mb--34 {
    margin-bottom: -34px;
  }

  .sc-md-mb--35 {
    margin-bottom: -35px;
  }

  .sc-md-mb--36 {
    margin-bottom: -36px;
  }

  .sc-md-mb--37 {
    margin-bottom: -37px;
  }

  .sc-md-mb--38 {
    margin-bottom: -38px;
  }

  .sc-md-mb--39 {
    margin-bottom: -39px;
  }

  .sc-md-mb--40 {
    margin-bottom: -40px;
  }

  .sc-md-mb--41 {
    margin-bottom: -41px;
  }

  .sc-md-mb--42 {
    margin-bottom: -42px;
  }

  .sc-md-mb--43 {
    margin-bottom: -43px;
  }

  .sc-md-mb--44 {
    margin-bottom: -44px;
  }

  .sc-md-mb--45 {
    margin-bottom: -45px;
  }

  .sc-md-mb--46 {
    margin-bottom: -46px;
  }

  .sc-md-mb--47 {
    margin-bottom: -47px;
  }

  .sc-md-mb--48 {
    margin-bottom: -48px;
  }

  .sc-md-mb--49 {
    margin-bottom: -49px;
  }

  .sc-md-mb--50 {
    margin-bottom: -50px;
  }

  .sc-md-mb--51 {
    margin-bottom: -51px;
  }

  .sc-md-mb--52 {
    margin-bottom: -52px;
  }

  .sc-md-mb--53 {
    margin-bottom: -53px;
  }

  .sc-md-mb--54 {
    margin-bottom: -54px;
  }

  .sc-md-mb--55 {
    margin-bottom: -55px;
  }

  .sc-md-mb--56 {
    margin-bottom: -56px;
  }

  .sc-md-mb--57 {
    margin-bottom: -57px;
  }

  .sc-md-mb--58 {
    margin-bottom: -58px;
  }

  .sc-md-mb--59 {
    margin-bottom: -59px;
  }

  .sc-md-mb--60 {
    margin-bottom: -60px;
  }

  .sc-md-mb--61 {
    margin-bottom: -61px;
  }

  .sc-md-mb--62 {
    margin-bottom: -62px;
  }

  .sc-md-mb--63 {
    margin-bottom: -63px;
  }

  .sc-md-mb--64 {
    margin-bottom: -64px;
  }

  .sc-md-mb--65 {
    margin-bottom: -65px;
  }

  .sc-md-mb--66 {
    margin-bottom: -66px;
  }

  .sc-md-mb--67 {
    margin-bottom: -67px;
  }

  .sc-md-mb--68 {
    margin-bottom: -68px;
  }

  .sc-md-mb--69 {
    margin-bottom: -69px;
  }

  .sc-md-mb--70 {
    margin-bottom: -70px;
  }

  .sc-md-mb--71 {
    margin-bottom: -71px;
  }

  .sc-md-mb--72 {
    margin-bottom: -72px;
  }

  .sc-md-mb--73 {
    margin-bottom: -73px;
  }

  .sc-md-mb--74 {
    margin-bottom: -74px;
  }

  .sc-md-mb--75 {
    margin-bottom: -75px;
  }

  .sc-md-mb--76 {
    margin-bottom: -76px;
  }

  .sc-md-mb--77 {
    margin-bottom: -77px;
  }

  .sc-md-mb--78 {
    margin-bottom: -78px;
  }

  .sc-md-mb--79 {
    margin-bottom: -79px;
  }

  .sc-md-mb--80 {
    margin-bottom: -80px;
  }

  .sc-md-mb--81 {
    margin-bottom: -81px;
  }

  .sc-md-mb--82 {
    margin-bottom: -82px;
  }

  .sc-md-mb--83 {
    margin-bottom: -83px;
  }

  .sc-md-mb--84 {
    margin-bottom: -84px;
  }

  .sc-md-mb--85 {
    margin-bottom: -85px;
  }

  .sc-md-mb--86 {
    margin-bottom: -86px;
  }

  .sc-md-mb--87 {
    margin-bottom: -87px;
  }

  .sc-md-mb--88 {
    margin-bottom: -88px;
  }

  .sc-md-mb--89 {
    margin-bottom: -89px;
  }

  .sc-md-mb--90 {
    margin-bottom: -90px;
  }

  .sc-md-mb--91 {
    margin-bottom: -91px;
  }

  .sc-md-mb--92 {
    margin-bottom: -92px;
  }

  .sc-md-mb--93 {
    margin-bottom: -93px;
  }

  .sc-md-mb--94 {
    margin-bottom: -94px;
  }

  .sc-md-mb--95 {
    margin-bottom: -95px;
  }

  .sc-md-mb--96 {
    margin-bottom: -96px;
  }

  .sc-md-mb--97 {
    margin-bottom: -97px;
  }

  .sc-md-mb--98 {
    margin-bottom: -98px;
  }

  .sc-md-mb--99 {
    margin-bottom: -99px;
  }

  .sc-md-mb--100 {
    margin-bottom: -100px;
  }

  .sc-md-mb--101 {
    margin-bottom: -101px;
  }

  .sc-md-mb--102 {
    margin-bottom: -102px;
  }

  .sc-md-mb--103 {
    margin-bottom: -103px;
  }

  .sc-md-mb--104 {
    margin-bottom: -104px;
  }

  .sc-md-mb--105 {
    margin-bottom: -105px;
  }

  .sc-md-mb--106 {
    margin-bottom: -106px;
  }

  .sc-md-mb--107 {
    margin-bottom: -107px;
  }

  .sc-md-mb--108 {
    margin-bottom: -108px;
  }

  .sc-md-mb--109 {
    margin-bottom: -109px;
  }

  .sc-md-mb--110 {
    margin-bottom: -110px;
  }

  .sc-md-mb--111 {
    margin-bottom: -111px;
  }

  .sc-md-mb--112 {
    margin-bottom: -112px;
  }

  .sc-md-mb--113 {
    margin-bottom: -113px;
  }

  .sc-md-mb--114 {
    margin-bottom: -114px;
  }

  .sc-md-mb--115 {
    margin-bottom: -115px;
  }

  .sc-md-mb--116 {
    margin-bottom: -116px;
  }

  .sc-md-mb--117 {
    margin-bottom: -117px;
  }

  .sc-md-mb--118 {
    margin-bottom: -118px;
  }

  .sc-md-mb--119 {
    margin-bottom: -119px;
  }

  .sc-md-mb--120 {
    margin-bottom: -120px;
  }

  .sc-md-mb--121 {
    margin-bottom: -121px;
  }

  .sc-md-mb--122 {
    margin-bottom: -122px;
  }

  .sc-md-mb--123 {
    margin-bottom: -123px;
  }

  .sc-md-mb--124 {
    margin-bottom: -124px;
  }

  .sc-md-mb--125 {
    margin-bottom: -125px;
  }

  .sc-md-mb--126 {
    margin-bottom: -126px;
  }

  .sc-md-mb--127 {
    margin-bottom: -127px;
  }

  .sc-md-mb--128 {
    margin-bottom: -128px;
  }

  .sc-md-mb--129 {
    margin-bottom: -129px;
  }

  .sc-md-mb--130 {
    margin-bottom: -130px;
  }

  .sc-md-mb--131 {
    margin-bottom: -131px;
  }

  .sc-md-mb--132 {
    margin-bottom: -132px;
  }

  .sc-md-mb--133 {
    margin-bottom: -133px;
  }

  .sc-md-mb--134 {
    margin-bottom: -134px;
  }

  .sc-md-mb--135 {
    margin-bottom: -135px;
  }

  .sc-md-mb--136 {
    margin-bottom: -136px;
  }

  .sc-md-mb--137 {
    margin-bottom: -137px;
  }

  .sc-md-mb--138 {
    margin-bottom: -138px;
  }

  .sc-md-mb--139 {
    margin-bottom: -139px;
  }

  .sc-md-mb--140 {
    margin-bottom: -140px;
  }

  .sc-md-mb--141 {
    margin-bottom: -141px;
  }

  .sc-md-mb--142 {
    margin-bottom: -142px;
  }

  .sc-md-mb--143 {
    margin-bottom: -143px;
  }

  .sc-md-mb--144 {
    margin-bottom: -144px;
  }

  .sc-md-mb--145 {
    margin-bottom: -145px;
  }

  .sc-md-mb--146 {
    margin-bottom: -146px;
  }

  .sc-md-mb--147 {
    margin-bottom: -147px;
  }

  .sc-md-mb--148 {
    margin-bottom: -148px;
  }

  .sc-md-mb--149 {
    margin-bottom: -149px;
  }

  .sc-md-mb--150 {
    margin-bottom: -150px;
  }

  .sc-md-mb--151 {
    margin-bottom: -151px;
  }

  .sc-md-mb--152 {
    margin-bottom: -152px;
  }

  .sc-md-mb--153 {
    margin-bottom: -153px;
  }

  .sc-md-mb--154 {
    margin-bottom: -154px;
  }

  .sc-md-mb--155 {
    margin-bottom: -155px;
  }

  .sc-md-mb--156 {
    margin-bottom: -156px;
  }

  .sc-md-mb--157 {
    margin-bottom: -157px;
  }

  .sc-md-mb--158 {
    margin-bottom: -158px;
  }

  .sc-md-mb--159 {
    margin-bottom: -159px;
  }

  .sc-md-mb--160 {
    margin-bottom: -160px;
  }

  .sc-md-mb--161 {
    margin-bottom: -161px;
  }

  .sc-md-mb--162 {
    margin-bottom: -162px;
  }

  .sc-md-mb--163 {
    margin-bottom: -163px;
  }

  .sc-md-mb--164 {
    margin-bottom: -164px;
  }

  .sc-md-mb--165 {
    margin-bottom: -165px;
  }

  .sc-md-mb--166 {
    margin-bottom: -166px;
  }

  .sc-md-mb--167 {
    margin-bottom: -167px;
  }

  .sc-md-mb--168 {
    margin-bottom: -168px;
  }

  .sc-md-mb--169 {
    margin-bottom: -169px;
  }

  .sc-md-mb--170 {
    margin-bottom: -170px;
  }

  .sc-md-mb--171 {
    margin-bottom: -171px;
  }

  .sc-md-mb--172 {
    margin-bottom: -172px;
  }

  .sc-md-mb--173 {
    margin-bottom: -173px;
  }

  .sc-md-mb--174 {
    margin-bottom: -174px;
  }

  .sc-md-mb--175 {
    margin-bottom: -175px;
  }

  .sc-md-mb--176 {
    margin-bottom: -176px;
  }

  .sc-md-mb--177 {
    margin-bottom: -177px;
  }

  .sc-md-mb--178 {
    margin-bottom: -178px;
  }

  .sc-md-mb--179 {
    margin-bottom: -179px;
  }

  .sc-md-mb--180 {
    margin-bottom: -180px;
  }

  .sc-md-mb--181 {
    margin-bottom: -181px;
  }

  .sc-md-mb--182 {
    margin-bottom: -182px;
  }

  .sc-md-mb--183 {
    margin-bottom: -183px;
  }

  .sc-md-mb--184 {
    margin-bottom: -184px;
  }

  .sc-md-mb--185 {
    margin-bottom: -185px;
  }

  .sc-md-mb--186 {
    margin-bottom: -186px;
  }

  .sc-md-mb--187 {
    margin-bottom: -187px;
  }

  .sc-md-mb--188 {
    margin-bottom: -188px;
  }

  .sc-md-mb--189 {
    margin-bottom: -189px;
  }

  .sc-md-mb--190 {
    margin-bottom: -190px;
  }

  .sc-md-mb--191 {
    margin-bottom: -191px;
  }

  .sc-md-mb--192 {
    margin-bottom: -192px;
  }

  .sc-md-mb--193 {
    margin-bottom: -193px;
  }

  .sc-md-mb--194 {
    margin-bottom: -194px;
  }

  .sc-md-mb--195 {
    margin-bottom: -195px;
  }

  .sc-md-mb--196 {
    margin-bottom: -196px;
  }

  .sc-md-mb--197 {
    margin-bottom: -197px;
  }

  .sc-md-mb--198 {
    margin-bottom: -198px;
  }

  .sc-md-mb--199 {
    margin-bottom: -199px;
  }

  .sc-md-mb--200 {
    margin-bottom: -200px;
  }

  .sc-md-ml--1 {
    margin-left: -1px;
  }

  .sc-md-ml--2 {
    margin-left: -2px;
  }

  .sc-md-ml--3 {
    margin-left: -3px;
  }

  .sc-md-ml--4 {
    margin-left: -4px;
  }

  .sc-md-ml--5 {
    margin-left: -5px;
  }

  .sc-md-ml--6 {
    margin-left: -6px;
  }

  .sc-md-ml--7 {
    margin-left: -7px;
  }

  .sc-md-ml--8 {
    margin-left: -8px;
  }

  .sc-md-ml--9 {
    margin-left: -9px;
  }

  .sc-md-ml--10 {
    margin-left: -10px;
  }

  .sc-md-ml--11 {
    margin-left: -11px;
  }

  .sc-md-ml--12 {
    margin-left: -12px;
  }

  .sc-md-ml--13 {
    margin-left: -13px;
  }

  .sc-md-ml--14 {
    margin-left: -14px;
  }

  .sc-md-ml--15 {
    margin-left: -15px;
  }

  .sc-md-ml--16 {
    margin-left: -16px;
  }

  .sc-md-ml--17 {
    margin-left: -17px;
  }

  .sc-md-ml--18 {
    margin-left: -18px;
  }

  .sc-md-ml--19 {
    margin-left: -19px;
  }

  .sc-md-ml--20 {
    margin-left: -20px;
  }

  .sc-md-ml--21 {
    margin-left: -21px;
  }

  .sc-md-ml--22 {
    margin-left: -22px;
  }

  .sc-md-ml--23 {
    margin-left: -23px;
  }

  .sc-md-ml--24 {
    margin-left: -24px;
  }

  .sc-md-ml--25 {
    margin-left: -25px;
  }

  .sc-md-ml--26 {
    margin-left: -26px;
  }

  .sc-md-ml--27 {
    margin-left: -27px;
  }

  .sc-md-ml--28 {
    margin-left: -28px;
  }

  .sc-md-ml--29 {
    margin-left: -29px;
  }

  .sc-md-ml--30 {
    margin-left: -30px;
  }

  .sc-md-ml--31 {
    margin-left: -31px;
  }

  .sc-md-ml--32 {
    margin-left: -32px;
  }

  .sc-md-ml--33 {
    margin-left: -33px;
  }

  .sc-md-ml--34 {
    margin-left: -34px;
  }

  .sc-md-ml--35 {
    margin-left: -35px;
  }

  .sc-md-ml--36 {
    margin-left: -36px;
  }

  .sc-md-ml--37 {
    margin-left: -37px;
  }

  .sc-md-ml--38 {
    margin-left: -38px;
  }

  .sc-md-ml--39 {
    margin-left: -39px;
  }

  .sc-md-ml--40 {
    margin-left: -40px;
  }

  .sc-md-ml--41 {
    margin-left: -41px;
  }

  .sc-md-ml--42 {
    margin-left: -42px;
  }

  .sc-md-ml--43 {
    margin-left: -43px;
  }

  .sc-md-ml--44 {
    margin-left: -44px;
  }

  .sc-md-ml--45 {
    margin-left: -45px;
  }

  .sc-md-ml--46 {
    margin-left: -46px;
  }

  .sc-md-ml--47 {
    margin-left: -47px;
  }

  .sc-md-ml--48 {
    margin-left: -48px;
  }

  .sc-md-ml--49 {
    margin-left: -49px;
  }

  .sc-md-ml--50 {
    margin-left: -50px;
  }

  .sc-md-ml--51 {
    margin-left: -51px;
  }

  .sc-md-ml--52 {
    margin-left: -52px;
  }

  .sc-md-ml--53 {
    margin-left: -53px;
  }

  .sc-md-ml--54 {
    margin-left: -54px;
  }

  .sc-md-ml--55 {
    margin-left: -55px;
  }

  .sc-md-ml--56 {
    margin-left: -56px;
  }

  .sc-md-ml--57 {
    margin-left: -57px;
  }

  .sc-md-ml--58 {
    margin-left: -58px;
  }

  .sc-md-ml--59 {
    margin-left: -59px;
  }

  .sc-md-ml--60 {
    margin-left: -60px;
  }

  .sc-md-ml--61 {
    margin-left: -61px;
  }

  .sc-md-ml--62 {
    margin-left: -62px;
  }

  .sc-md-ml--63 {
    margin-left: -63px;
  }

  .sc-md-ml--64 {
    margin-left: -64px;
  }

  .sc-md-ml--65 {
    margin-left: -65px;
  }

  .sc-md-ml--66 {
    margin-left: -66px;
  }

  .sc-md-ml--67 {
    margin-left: -67px;
  }

  .sc-md-ml--68 {
    margin-left: -68px;
  }

  .sc-md-ml--69 {
    margin-left: -69px;
  }

  .sc-md-ml--70 {
    margin-left: -70px;
  }

  .sc-md-ml--71 {
    margin-left: -71px;
  }

  .sc-md-ml--72 {
    margin-left: -72px;
  }

  .sc-md-ml--73 {
    margin-left: -73px;
  }

  .sc-md-ml--74 {
    margin-left: -74px;
  }

  .sc-md-ml--75 {
    margin-left: -75px;
  }

  .sc-md-ml--76 {
    margin-left: -76px;
  }

  .sc-md-ml--77 {
    margin-left: -77px;
  }

  .sc-md-ml--78 {
    margin-left: -78px;
  }

  .sc-md-ml--79 {
    margin-left: -79px;
  }

  .sc-md-ml--80 {
    margin-left: -80px;
  }

  .sc-md-ml--81 {
    margin-left: -81px;
  }

  .sc-md-ml--82 {
    margin-left: -82px;
  }

  .sc-md-ml--83 {
    margin-left: -83px;
  }

  .sc-md-ml--84 {
    margin-left: -84px;
  }

  .sc-md-ml--85 {
    margin-left: -85px;
  }

  .sc-md-ml--86 {
    margin-left: -86px;
  }

  .sc-md-ml--87 {
    margin-left: -87px;
  }

  .sc-md-ml--88 {
    margin-left: -88px;
  }

  .sc-md-ml--89 {
    margin-left: -89px;
  }

  .sc-md-ml--90 {
    margin-left: -90px;
  }

  .sc-md-ml--91 {
    margin-left: -91px;
  }

  .sc-md-ml--92 {
    margin-left: -92px;
  }

  .sc-md-ml--93 {
    margin-left: -93px;
  }

  .sc-md-ml--94 {
    margin-left: -94px;
  }

  .sc-md-ml--95 {
    margin-left: -95px;
  }

  .sc-md-ml--96 {
    margin-left: -96px;
  }

  .sc-md-ml--97 {
    margin-left: -97px;
  }

  .sc-md-ml--98 {
    margin-left: -98px;
  }

  .sc-md-ml--99 {
    margin-left: -99px;
  }

  .sc-md-ml--100 {
    margin-left: -100px;
  }

  .sc-md-ml--101 {
    margin-left: -101px;
  }

  .sc-md-ml--102 {
    margin-left: -102px;
  }

  .sc-md-ml--103 {
    margin-left: -103px;
  }

  .sc-md-ml--104 {
    margin-left: -104px;
  }

  .sc-md-ml--105 {
    margin-left: -105px;
  }

  .sc-md-ml--106 {
    margin-left: -106px;
  }

  .sc-md-ml--107 {
    margin-left: -107px;
  }

  .sc-md-ml--108 {
    margin-left: -108px;
  }

  .sc-md-ml--109 {
    margin-left: -109px;
  }

  .sc-md-ml--110 {
    margin-left: -110px;
  }

  .sc-md-ml--111 {
    margin-left: -111px;
  }

  .sc-md-ml--112 {
    margin-left: -112px;
  }

  .sc-md-ml--113 {
    margin-left: -113px;
  }

  .sc-md-ml--114 {
    margin-left: -114px;
  }

  .sc-md-ml--115 {
    margin-left: -115px;
  }

  .sc-md-ml--116 {
    margin-left: -116px;
  }

  .sc-md-ml--117 {
    margin-left: -117px;
  }

  .sc-md-ml--118 {
    margin-left: -118px;
  }

  .sc-md-ml--119 {
    margin-left: -119px;
  }

  .sc-md-ml--120 {
    margin-left: -120px;
  }

  .sc-md-ml--121 {
    margin-left: -121px;
  }

  .sc-md-ml--122 {
    margin-left: -122px;
  }

  .sc-md-ml--123 {
    margin-left: -123px;
  }

  .sc-md-ml--124 {
    margin-left: -124px;
  }

  .sc-md-ml--125 {
    margin-left: -125px;
  }

  .sc-md-ml--126 {
    margin-left: -126px;
  }

  .sc-md-ml--127 {
    margin-left: -127px;
  }

  .sc-md-ml--128 {
    margin-left: -128px;
  }

  .sc-md-ml--129 {
    margin-left: -129px;
  }

  .sc-md-ml--130 {
    margin-left: -130px;
  }

  .sc-md-ml--131 {
    margin-left: -131px;
  }

  .sc-md-ml--132 {
    margin-left: -132px;
  }

  .sc-md-ml--133 {
    margin-left: -133px;
  }

  .sc-md-ml--134 {
    margin-left: -134px;
  }

  .sc-md-ml--135 {
    margin-left: -135px;
  }

  .sc-md-ml--136 {
    margin-left: -136px;
  }

  .sc-md-ml--137 {
    margin-left: -137px;
  }

  .sc-md-ml--138 {
    margin-left: -138px;
  }

  .sc-md-ml--139 {
    margin-left: -139px;
  }

  .sc-md-ml--140 {
    margin-left: -140px;
  }

  .sc-md-ml--141 {
    margin-left: -141px;
  }

  .sc-md-ml--142 {
    margin-left: -142px;
  }

  .sc-md-ml--143 {
    margin-left: -143px;
  }

  .sc-md-ml--144 {
    margin-left: -144px;
  }

  .sc-md-ml--145 {
    margin-left: -145px;
  }

  .sc-md-ml--146 {
    margin-left: -146px;
  }

  .sc-md-ml--147 {
    margin-left: -147px;
  }

  .sc-md-ml--148 {
    margin-left: -148px;
  }

  .sc-md-ml--149 {
    margin-left: -149px;
  }

  .sc-md-ml--150 {
    margin-left: -150px;
  }

  .sc-md-ml--151 {
    margin-left: -151px;
  }

  .sc-md-ml--152 {
    margin-left: -152px;
  }

  .sc-md-ml--153 {
    margin-left: -153px;
  }

  .sc-md-ml--154 {
    margin-left: -154px;
  }

  .sc-md-ml--155 {
    margin-left: -155px;
  }

  .sc-md-ml--156 {
    margin-left: -156px;
  }

  .sc-md-ml--157 {
    margin-left: -157px;
  }

  .sc-md-ml--158 {
    margin-left: -158px;
  }

  .sc-md-ml--159 {
    margin-left: -159px;
  }

  .sc-md-ml--160 {
    margin-left: -160px;
  }

  .sc-md-ml--161 {
    margin-left: -161px;
  }

  .sc-md-ml--162 {
    margin-left: -162px;
  }

  .sc-md-ml--163 {
    margin-left: -163px;
  }

  .sc-md-ml--164 {
    margin-left: -164px;
  }

  .sc-md-ml--165 {
    margin-left: -165px;
  }

  .sc-md-ml--166 {
    margin-left: -166px;
  }

  .sc-md-ml--167 {
    margin-left: -167px;
  }

  .sc-md-ml--168 {
    margin-left: -168px;
  }

  .sc-md-ml--169 {
    margin-left: -169px;
  }

  .sc-md-ml--170 {
    margin-left: -170px;
  }

  .sc-md-ml--171 {
    margin-left: -171px;
  }

  .sc-md-ml--172 {
    margin-left: -172px;
  }

  .sc-md-ml--173 {
    margin-left: -173px;
  }

  .sc-md-ml--174 {
    margin-left: -174px;
  }

  .sc-md-ml--175 {
    margin-left: -175px;
  }

  .sc-md-ml--176 {
    margin-left: -176px;
  }

  .sc-md-ml--177 {
    margin-left: -177px;
  }

  .sc-md-ml--178 {
    margin-left: -178px;
  }

  .sc-md-ml--179 {
    margin-left: -179px;
  }

  .sc-md-ml--180 {
    margin-left: -180px;
  }

  .sc-md-ml--181 {
    margin-left: -181px;
  }

  .sc-md-ml--182 {
    margin-left: -182px;
  }

  .sc-md-ml--183 {
    margin-left: -183px;
  }

  .sc-md-ml--184 {
    margin-left: -184px;
  }

  .sc-md-ml--185 {
    margin-left: -185px;
  }

  .sc-md-ml--186 {
    margin-left: -186px;
  }

  .sc-md-ml--187 {
    margin-left: -187px;
  }

  .sc-md-ml--188 {
    margin-left: -188px;
  }

  .sc-md-ml--189 {
    margin-left: -189px;
  }

  .sc-md-ml--190 {
    margin-left: -190px;
  }

  .sc-md-ml--191 {
    margin-left: -191px;
  }

  .sc-md-ml--192 {
    margin-left: -192px;
  }

  .sc-md-ml--193 {
    margin-left: -193px;
  }

  .sc-md-ml--194 {
    margin-left: -194px;
  }

  .sc-md-ml--195 {
    margin-left: -195px;
  }

  .sc-md-ml--196 {
    margin-left: -196px;
  }

  .sc-md-ml--197 {
    margin-left: -197px;
  }

  .sc-md-ml--198 {
    margin-left: -198px;
  }

  .sc-md-ml--199 {
    margin-left: -199px;
  }

  .sc-md-ml--200 {
    margin-left: -200px;
  }

  .sc-md-mr--1 {
    margin-right: -1px;
  }

  .sc-md-mr--2 {
    margin-right: -2px;
  }

  .sc-md-mr--3 {
    margin-right: -3px;
  }

  .sc-md-mr--4 {
    margin-right: -4px;
  }

  .sc-md-mr--5 {
    margin-right: -5px;
  }

  .sc-md-mr--6 {
    margin-right: -6px;
  }

  .sc-md-mr--7 {
    margin-right: -7px;
  }

  .sc-md-mr--8 {
    margin-right: -8px;
  }

  .sc-md-mr--9 {
    margin-right: -9px;
  }

  .sc-md-mr--10 {
    margin-right: -10px;
  }

  .sc-md-mr--11 {
    margin-right: -11px;
  }

  .sc-md-mr--12 {
    margin-right: -12px;
  }

  .sc-md-mr--13 {
    margin-right: -13px;
  }

  .sc-md-mr--14 {
    margin-right: -14px;
  }

  .sc-md-mr--15 {
    margin-right: -15px;
  }

  .sc-md-mr--16 {
    margin-right: -16px;
  }

  .sc-md-mr--17 {
    margin-right: -17px;
  }

  .sc-md-mr--18 {
    margin-right: -18px;
  }

  .sc-md-mr--19 {
    margin-right: -19px;
  }

  .sc-md-mr--20 {
    margin-right: -20px;
  }

  .sc-md-mr--21 {
    margin-right: -21px;
  }

  .sc-md-mr--22 {
    margin-right: -22px;
  }

  .sc-md-mr--23 {
    margin-right: -23px;
  }

  .sc-md-mr--24 {
    margin-right: -24px;
  }

  .sc-md-mr--25 {
    margin-right: -25px;
  }

  .sc-md-mr--26 {
    margin-right: -26px;
  }

  .sc-md-mr--27 {
    margin-right: -27px;
  }

  .sc-md-mr--28 {
    margin-right: -28px;
  }

  .sc-md-mr--29 {
    margin-right: -29px;
  }

  .sc-md-mr--30 {
    margin-right: -30px;
  }

  .sc-md-mr--31 {
    margin-right: -31px;
  }

  .sc-md-mr--32 {
    margin-right: -32px;
  }

  .sc-md-mr--33 {
    margin-right: -33px;
  }

  .sc-md-mr--34 {
    margin-right: -34px;
  }

  .sc-md-mr--35 {
    margin-right: -35px;
  }

  .sc-md-mr--36 {
    margin-right: -36px;
  }

  .sc-md-mr--37 {
    margin-right: -37px;
  }

  .sc-md-mr--38 {
    margin-right: -38px;
  }

  .sc-md-mr--39 {
    margin-right: -39px;
  }

  .sc-md-mr--40 {
    margin-right: -40px;
  }

  .sc-md-mr--41 {
    margin-right: -41px;
  }

  .sc-md-mr--42 {
    margin-right: -42px;
  }

  .sc-md-mr--43 {
    margin-right: -43px;
  }

  .sc-md-mr--44 {
    margin-right: -44px;
  }

  .sc-md-mr--45 {
    margin-right: -45px;
  }

  .sc-md-mr--46 {
    margin-right: -46px;
  }

  .sc-md-mr--47 {
    margin-right: -47px;
  }

  .sc-md-mr--48 {
    margin-right: -48px;
  }

  .sc-md-mr--49 {
    margin-right: -49px;
  }

  .sc-md-mr--50 {
    margin-right: -50px;
  }

  .sc-md-mr--51 {
    margin-right: -51px;
  }

  .sc-md-mr--52 {
    margin-right: -52px;
  }

  .sc-md-mr--53 {
    margin-right: -53px;
  }

  .sc-md-mr--54 {
    margin-right: -54px;
  }

  .sc-md-mr--55 {
    margin-right: -55px;
  }

  .sc-md-mr--56 {
    margin-right: -56px;
  }

  .sc-md-mr--57 {
    margin-right: -57px;
  }

  .sc-md-mr--58 {
    margin-right: -58px;
  }

  .sc-md-mr--59 {
    margin-right: -59px;
  }

  .sc-md-mr--60 {
    margin-right: -60px;
  }

  .sc-md-mr--61 {
    margin-right: -61px;
  }

  .sc-md-mr--62 {
    margin-right: -62px;
  }

  .sc-md-mr--63 {
    margin-right: -63px;
  }

  .sc-md-mr--64 {
    margin-right: -64px;
  }

  .sc-md-mr--65 {
    margin-right: -65px;
  }

  .sc-md-mr--66 {
    margin-right: -66px;
  }

  .sc-md-mr--67 {
    margin-right: -67px;
  }

  .sc-md-mr--68 {
    margin-right: -68px;
  }

  .sc-md-mr--69 {
    margin-right: -69px;
  }

  .sc-md-mr--70 {
    margin-right: -70px;
  }

  .sc-md-mr--71 {
    margin-right: -71px;
  }

  .sc-md-mr--72 {
    margin-right: -72px;
  }

  .sc-md-mr--73 {
    margin-right: -73px;
  }

  .sc-md-mr--74 {
    margin-right: -74px;
  }

  .sc-md-mr--75 {
    margin-right: -75px;
  }

  .sc-md-mr--76 {
    margin-right: -76px;
  }

  .sc-md-mr--77 {
    margin-right: -77px;
  }

  .sc-md-mr--78 {
    margin-right: -78px;
  }

  .sc-md-mr--79 {
    margin-right: -79px;
  }

  .sc-md-mr--80 {
    margin-right: -80px;
  }

  .sc-md-mr--81 {
    margin-right: -81px;
  }

  .sc-md-mr--82 {
    margin-right: -82px;
  }

  .sc-md-mr--83 {
    margin-right: -83px;
  }

  .sc-md-mr--84 {
    margin-right: -84px;
  }

  .sc-md-mr--85 {
    margin-right: -85px;
  }

  .sc-md-mr--86 {
    margin-right: -86px;
  }

  .sc-md-mr--87 {
    margin-right: -87px;
  }

  .sc-md-mr--88 {
    margin-right: -88px;
  }

  .sc-md-mr--89 {
    margin-right: -89px;
  }

  .sc-md-mr--90 {
    margin-right: -90px;
  }

  .sc-md-mr--91 {
    margin-right: -91px;
  }

  .sc-md-mr--92 {
    margin-right: -92px;
  }

  .sc-md-mr--93 {
    margin-right: -93px;
  }

  .sc-md-mr--94 {
    margin-right: -94px;
  }

  .sc-md-mr--95 {
    margin-right: -95px;
  }

  .sc-md-mr--96 {
    margin-right: -96px;
  }

  .sc-md-mr--97 {
    margin-right: -97px;
  }

  .sc-md-mr--98 {
    margin-right: -98px;
  }

  .sc-md-mr--99 {
    margin-right: -99px;
  }

  .sc-md-mr--100 {
    margin-right: -100px;
  }

  .sc-md-mr--101 {
    margin-right: -101px;
  }

  .sc-md-mr--102 {
    margin-right: -102px;
  }

  .sc-md-mr--103 {
    margin-right: -103px;
  }

  .sc-md-mr--104 {
    margin-right: -104px;
  }

  .sc-md-mr--105 {
    margin-right: -105px;
  }

  .sc-md-mr--106 {
    margin-right: -106px;
  }

  .sc-md-mr--107 {
    margin-right: -107px;
  }

  .sc-md-mr--108 {
    margin-right: -108px;
  }

  .sc-md-mr--109 {
    margin-right: -109px;
  }

  .sc-md-mr--110 {
    margin-right: -110px;
  }

  .sc-md-mr--111 {
    margin-right: -111px;
  }

  .sc-md-mr--112 {
    margin-right: -112px;
  }

  .sc-md-mr--113 {
    margin-right: -113px;
  }

  .sc-md-mr--114 {
    margin-right: -114px;
  }

  .sc-md-mr--115 {
    margin-right: -115px;
  }

  .sc-md-mr--116 {
    margin-right: -116px;
  }

  .sc-md-mr--117 {
    margin-right: -117px;
  }

  .sc-md-mr--118 {
    margin-right: -118px;
  }

  .sc-md-mr--119 {
    margin-right: -119px;
  }

  .sc-md-mr--120 {
    margin-right: -120px;
  }

  .sc-md-mr--121 {
    margin-right: -121px;
  }

  .sc-md-mr--122 {
    margin-right: -122px;
  }

  .sc-md-mr--123 {
    margin-right: -123px;
  }

  .sc-md-mr--124 {
    margin-right: -124px;
  }

  .sc-md-mr--125 {
    margin-right: -125px;
  }

  .sc-md-mr--126 {
    margin-right: -126px;
  }

  .sc-md-mr--127 {
    margin-right: -127px;
  }

  .sc-md-mr--128 {
    margin-right: -128px;
  }

  .sc-md-mr--129 {
    margin-right: -129px;
  }

  .sc-md-mr--130 {
    margin-right: -130px;
  }

  .sc-md-mr--131 {
    margin-right: -131px;
  }

  .sc-md-mr--132 {
    margin-right: -132px;
  }

  .sc-md-mr--133 {
    margin-right: -133px;
  }

  .sc-md-mr--134 {
    margin-right: -134px;
  }

  .sc-md-mr--135 {
    margin-right: -135px;
  }

  .sc-md-mr--136 {
    margin-right: -136px;
  }

  .sc-md-mr--137 {
    margin-right: -137px;
  }

  .sc-md-mr--138 {
    margin-right: -138px;
  }

  .sc-md-mr--139 {
    margin-right: -139px;
  }

  .sc-md-mr--140 {
    margin-right: -140px;
  }

  .sc-md-mr--141 {
    margin-right: -141px;
  }

  .sc-md-mr--142 {
    margin-right: -142px;
  }

  .sc-md-mr--143 {
    margin-right: -143px;
  }

  .sc-md-mr--144 {
    margin-right: -144px;
  }

  .sc-md-mr--145 {
    margin-right: -145px;
  }

  .sc-md-mr--146 {
    margin-right: -146px;
  }

  .sc-md-mr--147 {
    margin-right: -147px;
  }

  .sc-md-mr--148 {
    margin-right: -148px;
  }

  .sc-md-mr--149 {
    margin-right: -149px;
  }

  .sc-md-mr--150 {
    margin-right: -150px;
  }

  .sc-md-mr--151 {
    margin-right: -151px;
  }

  .sc-md-mr--152 {
    margin-right: -152px;
  }

  .sc-md-mr--153 {
    margin-right: -153px;
  }

  .sc-md-mr--154 {
    margin-right: -154px;
  }

  .sc-md-mr--155 {
    margin-right: -155px;
  }

  .sc-md-mr--156 {
    margin-right: -156px;
  }

  .sc-md-mr--157 {
    margin-right: -157px;
  }

  .sc-md-mr--158 {
    margin-right: -158px;
  }

  .sc-md-mr--159 {
    margin-right: -159px;
  }

  .sc-md-mr--160 {
    margin-right: -160px;
  }

  .sc-md-mr--161 {
    margin-right: -161px;
  }

  .sc-md-mr--162 {
    margin-right: -162px;
  }

  .sc-md-mr--163 {
    margin-right: -163px;
  }

  .sc-md-mr--164 {
    margin-right: -164px;
  }

  .sc-md-mr--165 {
    margin-right: -165px;
  }

  .sc-md-mr--166 {
    margin-right: -166px;
  }

  .sc-md-mr--167 {
    margin-right: -167px;
  }

  .sc-md-mr--168 {
    margin-right: -168px;
  }

  .sc-md-mr--169 {
    margin-right: -169px;
  }

  .sc-md-mr--170 {
    margin-right: -170px;
  }

  .sc-md-mr--171 {
    margin-right: -171px;
  }

  .sc-md-mr--172 {
    margin-right: -172px;
  }

  .sc-md-mr--173 {
    margin-right: -173px;
  }

  .sc-md-mr--174 {
    margin-right: -174px;
  }

  .sc-md-mr--175 {
    margin-right: -175px;
  }

  .sc-md-mr--176 {
    margin-right: -176px;
  }

  .sc-md-mr--177 {
    margin-right: -177px;
  }

  .sc-md-mr--178 {
    margin-right: -178px;
  }

  .sc-md-mr--179 {
    margin-right: -179px;
  }

  .sc-md-mr--180 {
    margin-right: -180px;
  }

  .sc-md-mr--181 {
    margin-right: -181px;
  }

  .sc-md-mr--182 {
    margin-right: -182px;
  }

  .sc-md-mr--183 {
    margin-right: -183px;
  }

  .sc-md-mr--184 {
    margin-right: -184px;
  }

  .sc-md-mr--185 {
    margin-right: -185px;
  }

  .sc-md-mr--186 {
    margin-right: -186px;
  }

  .sc-md-mr--187 {
    margin-right: -187px;
  }

  .sc-md-mr--188 {
    margin-right: -188px;
  }

  .sc-md-mr--189 {
    margin-right: -189px;
  }

  .sc-md-mr--190 {
    margin-right: -190px;
  }

  .sc-md-mr--191 {
    margin-right: -191px;
  }

  .sc-md-mr--192 {
    margin-right: -192px;
  }

  .sc-md-mr--193 {
    margin-right: -193px;
  }

  .sc-md-mr--194 {
    margin-right: -194px;
  }

  .sc-md-mr--195 {
    margin-right: -195px;
  }

  .sc-md-mr--196 {
    margin-right: -196px;
  }

  .sc-md-mr--197 {
    margin-right: -197px;
  }

  .sc-md-mr--198 {
    margin-right: -198px;
  }

  .sc-md-mr--199 {
    margin-right: -199px;
  }

  .sc-md-mr--200 {
    margin-right: -200px;
  }
}

@media only screen and (max-width: 767px) {
  .sc-sm-padding-0 {
    padding: 0;
  }

  .sc-sm-margin-0 {
    margin: 0;
  }

  .sc-sm-pt-0 {
    padding-top: 0;
  }

  .sc-sm-pt-1 {
    padding-top: 1px;
  }

  .sc-sm-pt-2 {
    padding-top: 2px;
  }

  .sc-sm-pt-3 {
    padding-top: 3px;
  }

  .sc-sm-pt-4 {
    padding-top: 4px;
  }

  .sc-sm-pt-5 {
    padding-top: 5px;
  }

  .sc-sm-pt-6 {
    padding-top: 6px;
  }

  .sc-sm-pt-7 {
    padding-top: 7px;
  }

  .sc-sm-pt-8 {
    padding-top: 8px;
  }

  .sc-sm-pt-9 {
    padding-top: 9px;
  }

  .sc-sm-pt-10 {
    padding-top: 10px;
  }

  .sc-sm-pt-11 {
    padding-top: 11px;
  }

  .sc-sm-pt-12 {
    padding-top: 12px;
  }

  .sc-sm-pt-13 {
    padding-top: 13px;
  }

  .sc-sm-pt-14 {
    padding-top: 14px;
  }

  .sc-sm-pt-15 {
    padding-top: 15px;
  }

  .sc-sm-pt-16 {
    padding-top: 16px;
  }

  .sc-sm-pt-17 {
    padding-top: 17px;
  }

  .sc-sm-pt-18 {
    padding-top: 18px;
  }

  .sc-sm-pt-19 {
    padding-top: 19px;
  }

  .sc-sm-pt-20 {
    padding-top: 20px;
  }

  .sc-sm-pt-21 {
    padding-top: 21px;
  }

  .sc-sm-pt-22 {
    padding-top: 22px;
  }

  .sc-sm-pt-23 {
    padding-top: 23px;
  }

  .sc-sm-pt-24 {
    padding-top: 24px;
  }

  .sc-sm-pt-25 {
    padding-top: 25px;
  }

  .sc-sm-pt-26 {
    padding-top: 26px;
  }

  .sc-sm-pt-27 {
    padding-top: 27px;
  }

  .sc-sm-pt-28 {
    padding-top: 28px;
  }

  .sc-sm-pt-29 {
    padding-top: 29px;
  }

  .sc-sm-pt-30 {
    padding-top: 30px;
  }

  .sc-sm-pt-31 {
    padding-top: 31px;
  }

  .sc-sm-pt-32 {
    padding-top: 32px;
  }

  .sc-sm-pt-33 {
    padding-top: 33px;
  }

  .sc-sm-pt-34 {
    padding-top: 34px;
  }

  .sc-sm-pt-35 {
    padding-top: 35px;
  }

  .sc-sm-pt-36 {
    padding-top: 36px;
  }

  .sc-sm-pt-37 {
    padding-top: 37px;
  }

  .sc-sm-pt-38 {
    padding-top: 38px;
  }

  .sc-sm-pt-39 {
    padding-top: 39px;
  }

  .sc-sm-pt-40 {
    padding-top: 40px;
  }

  .sc-sm-pt-41 {
    padding-top: 41px;
  }

  .sc-sm-pt-42 {
    padding-top: 42px;
  }

  .sc-sm-pt-43 {
    padding-top: 43px;
  }

  .sc-sm-pt-44 {
    padding-top: 44px;
  }

  .sc-sm-pt-45 {
    padding-top: 45px;
  }

  .sc-sm-pt-46 {
    padding-top: 46px;
  }

  .sc-sm-pt-47 {
    padding-top: 47px;
  }

  .sc-sm-pt-48 {
    padding-top: 48px;
  }

  .sc-sm-pt-49 {
    padding-top: 49px;
  }

  .sc-sm-pt-50 {
    padding-top: 50px;
  }

  .sc-sm-pt-51 {
    padding-top: 51px;
  }

  .sc-sm-pt-52 {
    padding-top: 52px;
  }

  .sc-sm-pt-53 {
    padding-top: 53px;
  }

  .sc-sm-pt-54 {
    padding-top: 54px;
  }

  .sc-sm-pt-55 {
    padding-top: 55px;
  }

  .sc-sm-pt-56 {
    padding-top: 56px;
  }

  .sc-sm-pt-57 {
    padding-top: 57px;
  }

  .sc-sm-pt-58 {
    padding-top: 58px;
  }

  .sc-sm-pt-59 {
    padding-top: 59px;
  }

  .sc-sm-pt-60 {
    padding-top: 60px;
  }

  .sc-sm-pt-61 {
    padding-top: 61px;
  }

  .sc-sm-pt-62 {
    padding-top: 62px;
  }

  .sc-sm-pt-63 {
    padding-top: 63px;
  }

  .sc-sm-pt-64 {
    padding-top: 64px;
  }

  .sc-sm-pt-65 {
    padding-top: 65px;
  }

  .sc-sm-pt-66 {
    padding-top: 66px;
  }

  .sc-sm-pt-67 {
    padding-top: 67px;
  }

  .sc-sm-pt-68 {
    padding-top: 68px;
  }

  .sc-sm-pt-69 {
    padding-top: 69px;
  }

  .sc-sm-pt-70 {
    padding-top: 70px;
  }

  .sc-sm-pt-71 {
    padding-top: 71px;
  }

  .sc-sm-pt-72 {
    padding-top: 72px;
  }

  .sc-sm-pt-73 {
    padding-top: 73px;
  }

  .sc-sm-pt-74 {
    padding-top: 74px;
  }

  .sc-sm-pt-75 {
    padding-top: 75px;
  }

  .sc-sm-pt-76 {
    padding-top: 76px;
  }

  .sc-sm-pt-77 {
    padding-top: 77px;
  }

  .sc-sm-pt-78 {
    padding-top: 78px;
  }

  .sc-sm-pt-79 {
    padding-top: 79px;
  }

  .sc-sm-pt-80 {
    padding-top: 80px;
  }

  .sc-sm-pt-81 {
    padding-top: 81px;
  }

  .sc-sm-pt-82 {
    padding-top: 82px;
  }

  .sc-sm-pt-83 {
    padding-top: 83px;
  }

  .sc-sm-pt-84 {
    padding-top: 84px;
  }

  .sc-sm-pt-85 {
    padding-top: 85px;
  }

  .sc-sm-pt-86 {
    padding-top: 86px;
  }

  .sc-sm-pt-87 {
    padding-top: 87px;
  }

  .sc-sm-pt-88 {
    padding-top: 88px;
  }

  .sc-sm-pt-89 {
    padding-top: 89px;
  }

  .sc-sm-pt-90 {
    padding-top: 90px;
  }

  .sc-sm-pt-91 {
    padding-top: 91px;
  }

  .sc-sm-pt-92 {
    padding-top: 92px;
  }

  .sc-sm-pt-93 {
    padding-top: 93px;
  }

  .sc-sm-pt-94 {
    padding-top: 94px;
  }

  .sc-sm-pt-95 {
    padding-top: 95px;
  }

  .sc-sm-pt-96 {
    padding-top: 96px;
  }

  .sc-sm-pt-97 {
    padding-top: 97px;
  }

  .sc-sm-pt-98 {
    padding-top: 98px;
  }

  .sc-sm-pt-99 {
    padding-top: 99px;
  }

  .sc-sm-pt-100 {
    padding-top: 100px;
  }

  .sc-sm-pt-101 {
    padding-top: 101px;
  }

  .sc-sm-pt-102 {
    padding-top: 102px;
  }

  .sc-sm-pt-103 {
    padding-top: 103px;
  }

  .sc-sm-pt-104 {
    padding-top: 104px;
  }

  .sc-sm-pt-105 {
    padding-top: 105px;
  }

  .sc-sm-pt-106 {
    padding-top: 106px;
  }

  .sc-sm-pt-107 {
    padding-top: 107px;
  }

  .sc-sm-pt-108 {
    padding-top: 108px;
  }

  .sc-sm-pt-109 {
    padding-top: 109px;
  }

  .sc-sm-pt-110 {
    padding-top: 110px;
  }

  .sc-sm-pt-111 {
    padding-top: 111px;
  }

  .sc-sm-pt-112 {
    padding-top: 112px;
  }

  .sc-sm-pt-113 {
    padding-top: 113px;
  }

  .sc-sm-pt-114 {
    padding-top: 114px;
  }

  .sc-sm-pt-115 {
    padding-top: 115px;
  }

  .sc-sm-pt-116 {
    padding-top: 116px;
  }

  .sc-sm-pt-117 {
    padding-top: 117px;
  }

  .sc-sm-pt-118 {
    padding-top: 118px;
  }

  .sc-sm-pt-119 {
    padding-top: 119px;
  }

  .sc-sm-pt-120 {
    padding-top: 120px;
  }

  .sc-sm-pt-121 {
    padding-top: 121px;
  }

  .sc-sm-pt-122 {
    padding-top: 122px;
  }

  .sc-sm-pt-123 {
    padding-top: 123px;
  }

  .sc-sm-pt-124 {
    padding-top: 124px;
  }

  .sc-sm-pt-125 {
    padding-top: 125px;
  }

  .sc-sm-pt-126 {
    padding-top: 126px;
  }

  .sc-sm-pt-127 {
    padding-top: 127px;
  }

  .sc-sm-pt-128 {
    padding-top: 128px;
  }

  .sc-sm-pt-129 {
    padding-top: 129px;
  }

  .sc-sm-pt-130 {
    padding-top: 130px;
  }

  .sc-sm-pt-131 {
    padding-top: 131px;
  }

  .sc-sm-pt-132 {
    padding-top: 132px;
  }

  .sc-sm-pt-133 {
    padding-top: 133px;
  }

  .sc-sm-pt-134 {
    padding-top: 134px;
  }

  .sc-sm-pt-135 {
    padding-top: 135px;
  }

  .sc-sm-pt-136 {
    padding-top: 136px;
  }

  .sc-sm-pt-137 {
    padding-top: 137px;
  }

  .sc-sm-pt-138 {
    padding-top: 138px;
  }

  .sc-sm-pt-139 {
    padding-top: 139px;
  }

  .sc-sm-pt-140 {
    padding-top: 140px;
  }

  .sc-sm-pt-141 {
    padding-top: 141px;
  }

  .sc-sm-pt-142 {
    padding-top: 142px;
  }

  .sc-sm-pt-143 {
    padding-top: 143px;
  }

  .sc-sm-pt-144 {
    padding-top: 144px;
  }

  .sc-sm-pt-145 {
    padding-top: 145px;
  }

  .sc-sm-pt-146 {
    padding-top: 146px;
  }

  .sc-sm-pt-147 {
    padding-top: 147px;
  }

  .sc-sm-pt-148 {
    padding-top: 148px;
  }

  .sc-sm-pt-149 {
    padding-top: 149px;
  }

  .sc-sm-pt-150 {
    padding-top: 150px;
  }

  .sc-sm-pt-151 {
    padding-top: 151px;
  }

  .sc-sm-pt-152 {
    padding-top: 152px;
  }

  .sc-sm-pt-153 {
    padding-top: 153px;
  }

  .sc-sm-pt-154 {
    padding-top: 154px;
  }

  .sc-sm-pt-155 {
    padding-top: 155px;
  }

  .sc-sm-pt-156 {
    padding-top: 156px;
  }

  .sc-sm-pt-157 {
    padding-top: 157px;
  }

  .sc-sm-pt-158 {
    padding-top: 158px;
  }

  .sc-sm-pt-159 {
    padding-top: 159px;
  }

  .sc-sm-pt-160 {
    padding-top: 160px;
  }

  .sc-sm-pt-161 {
    padding-top: 161px;
  }

  .sc-sm-pt-162 {
    padding-top: 162px;
  }

  .sc-sm-pt-163 {
    padding-top: 163px;
  }

  .sc-sm-pt-164 {
    padding-top: 164px;
  }

  .sc-sm-pt-165 {
    padding-top: 165px;
  }

  .sc-sm-pt-166 {
    padding-top: 166px;
  }

  .sc-sm-pt-167 {
    padding-top: 167px;
  }

  .sc-sm-pt-168 {
    padding-top: 168px;
  }

  .sc-sm-pt-169 {
    padding-top: 169px;
  }

  .sc-sm-pt-170 {
    padding-top: 170px;
  }

  .sc-sm-pt-171 {
    padding-top: 171px;
  }

  .sc-sm-pt-172 {
    padding-top: 172px;
  }

  .sc-sm-pt-173 {
    padding-top: 173px;
  }

  .sc-sm-pt-174 {
    padding-top: 174px;
  }

  .sc-sm-pt-175 {
    padding-top: 175px;
  }

  .sc-sm-pt-176 {
    padding-top: 176px;
  }

  .sc-sm-pt-177 {
    padding-top: 177px;
  }

  .sc-sm-pt-178 {
    padding-top: 178px;
  }

  .sc-sm-pt-179 {
    padding-top: 179px;
  }

  .sc-sm-pt-180 {
    padding-top: 180px;
  }

  .sc-sm-pt-181 {
    padding-top: 181px;
  }

  .sc-sm-pt-182 {
    padding-top: 182px;
  }

  .sc-sm-pt-183 {
    padding-top: 183px;
  }

  .sc-sm-pt-184 {
    padding-top: 184px;
  }

  .sc-sm-pt-185 {
    padding-top: 185px;
  }

  .sc-sm-pt-186 {
    padding-top: 186px;
  }

  .sc-sm-pt-187 {
    padding-top: 187px;
  }

  .sc-sm-pt-188 {
    padding-top: 188px;
  }

  .sc-sm-pt-189 {
    padding-top: 189px;
  }

  .sc-sm-pt-190 {
    padding-top: 190px;
  }

  .sc-sm-pt-191 {
    padding-top: 191px;
  }

  .sc-sm-pt-192 {
    padding-top: 192px;
  }

  .sc-sm-pt-193 {
    padding-top: 193px;
  }

  .sc-sm-pt-194 {
    padding-top: 194px;
  }

  .sc-sm-pt-195 {
    padding-top: 195px;
  }

  .sc-sm-pt-196 {
    padding-top: 196px;
  }

  .sc-sm-pt-197 {
    padding-top: 197px;
  }

  .sc-sm-pt-198 {
    padding-top: 198px;
  }

  .sc-sm-pt-199 {
    padding-top: 199px;
  }

  .sc-sm-pt-200 {
    padding-top: 200px;
  }

  .sc-sm-pb-0 {
    padding-bottom: 0;
  }

  .sc-sm-pb-1 {
    padding-bottom: 1px;
  }

  .sc-sm-pb-2 {
    padding-bottom: 2px;
  }

  .sc-sm-pb-3 {
    padding-bottom: 3px;
  }

  .sc-sm-pb-4 {
    padding-bottom: 4px;
  }

  .sc-sm-pb-5 {
    padding-bottom: 5px;
  }

  .sc-sm-pb-6 {
    padding-bottom: 6px;
  }

  .sc-sm-pb-7 {
    padding-bottom: 7px;
  }

  .sc-sm-pb-8 {
    padding-bottom: 8px;
  }

  .sc-sm-pb-9 {
    padding-bottom: 9px;
  }

  .sc-sm-pb-10 {
    padding-bottom: 10px;
  }

  .sc-sm-pb-11 {
    padding-bottom: 11px;
  }

  .sc-sm-pb-12 {
    padding-bottom: 12px;
  }

  .sc-sm-pb-13 {
    padding-bottom: 13px;
  }

  .sc-sm-pb-14 {
    padding-bottom: 14px;
  }

  .sc-sm-pb-15 {
    padding-bottom: 15px;
  }

  .sc-sm-pb-16 {
    padding-bottom: 16px;
  }

  .sc-sm-pb-17 {
    padding-bottom: 17px;
  }

  .sc-sm-pb-18 {
    padding-bottom: 18px;
  }

  .sc-sm-pb-19 {
    padding-bottom: 19px;
  }

  .sc-sm-pb-20 {
    padding-bottom: 20px;
  }

  .sc-sm-pb-21 {
    padding-bottom: 21px;
  }

  .sc-sm-pb-22 {
    padding-bottom: 22px;
  }

  .sc-sm-pb-23 {
    padding-bottom: 23px;
  }

  .sc-sm-pb-24 {
    padding-bottom: 24px;
  }

  .sc-sm-pb-25 {
    padding-bottom: 25px;
  }

  .sc-sm-pb-26 {
    padding-bottom: 26px;
  }

  .sc-sm-pb-27 {
    padding-bottom: 27px;
  }

  .sc-sm-pb-28 {
    padding-bottom: 28px;
  }

  .sc-sm-pb-29 {
    padding-bottom: 29px;
  }

  .sc-sm-pb-30 {
    padding-bottom: 30px;
  }

  .sc-sm-pb-31 {
    padding-bottom: 31px;
  }

  .sc-sm-pb-32 {
    padding-bottom: 32px;
  }

  .sc-sm-pb-33 {
    padding-bottom: 33px;
  }

  .sc-sm-pb-34 {
    padding-bottom: 34px;
  }

  .sc-sm-pb-35 {
    padding-bottom: 35px;
  }

  .sc-sm-pb-36 {
    padding-bottom: 36px;
  }

  .sc-sm-pb-37 {
    padding-bottom: 37px;
  }

  .sc-sm-pb-38 {
    padding-bottom: 38px;
  }

  .sc-sm-pb-39 {
    padding-bottom: 39px;
  }

  .sc-sm-pb-40 {
    padding-bottom: 40px;
  }

  .sc-sm-pb-41 {
    padding-bottom: 41px;
  }

  .sc-sm-pb-42 {
    padding-bottom: 42px;
  }

  .sc-sm-pb-43 {
    padding-bottom: 43px;
  }

  .sc-sm-pb-44 {
    padding-bottom: 44px;
  }

  .sc-sm-pb-45 {
    padding-bottom: 45px;
  }

  .sc-sm-pb-46 {
    padding-bottom: 46px;
  }

  .sc-sm-pb-47 {
    padding-bottom: 47px;
  }

  .sc-sm-pb-48 {
    padding-bottom: 48px;
  }

  .sc-sm-pb-49 {
    padding-bottom: 49px;
  }

  .sc-sm-pb-50 {
    padding-bottom: 50px;
  }

  .sc-sm-pb-51 {
    padding-bottom: 51px;
  }

  .sc-sm-pb-52 {
    padding-bottom: 52px;
  }

  .sc-sm-pb-53 {
    padding-bottom: 53px;
  }

  .sc-sm-pb-54 {
    padding-bottom: 54px;
  }

  .sc-sm-pb-55 {
    padding-bottom: 55px;
  }

  .sc-sm-pb-56 {
    padding-bottom: 56px;
  }

  .sc-sm-pb-57 {
    padding-bottom: 57px;
  }

  .sc-sm-pb-58 {
    padding-bottom: 58px;
  }

  .sc-sm-pb-59 {
    padding-bottom: 59px;
  }

  .sc-sm-pb-60 {
    padding-bottom: 60px;
  }

  .sc-sm-pb-61 {
    padding-bottom: 61px;
  }

  .sc-sm-pb-62 {
    padding-bottom: 62px;
  }

  .sc-sm-pb-63 {
    padding-bottom: 63px;
  }

  .sc-sm-pb-64 {
    padding-bottom: 64px;
  }

  .sc-sm-pb-65 {
    padding-bottom: 65px;
  }

  .sc-sm-pb-66 {
    padding-bottom: 66px;
  }

  .sc-sm-pb-67 {
    padding-bottom: 67px;
  }

  .sc-sm-pb-68 {
    padding-bottom: 68px;
  }

  .sc-sm-pb-69 {
    padding-bottom: 69px;
  }

  .sc-sm-pb-70 {
    padding-bottom: 70px;
  }

  .sc-sm-pb-71 {
    padding-bottom: 71px;
  }

  .sc-sm-pb-72 {
    padding-bottom: 72px;
  }

  .sc-sm-pb-73 {
    padding-bottom: 73px;
  }

  .sc-sm-pb-74 {
    padding-bottom: 74px;
  }

  .sc-sm-pb-75 {
    padding-bottom: 75px;
  }

  .sc-sm-pb-76 {
    padding-bottom: 76px;
  }

  .sc-sm-pb-77 {
    padding-bottom: 77px;
  }

  .sc-sm-pb-78 {
    padding-bottom: 78px;
  }

  .sc-sm-pb-79 {
    padding-bottom: 79px;
  }

  .sc-sm-pb-80 {
    padding-bottom: 80px;
  }

  .sc-sm-pb-81 {
    padding-bottom: 81px;
  }

  .sc-sm-pb-82 {
    padding-bottom: 82px;
  }

  .sc-sm-pb-83 {
    padding-bottom: 83px;
  }

  .sc-sm-pb-84 {
    padding-bottom: 84px;
  }

  .sc-sm-pb-85 {
    padding-bottom: 85px;
  }

  .sc-sm-pb-86 {
    padding-bottom: 86px;
  }

  .sc-sm-pb-87 {
    padding-bottom: 87px;
  }

  .sc-sm-pb-88 {
    padding-bottom: 88px;
  }

  .sc-sm-pb-89 {
    padding-bottom: 89px;
  }

  .sc-sm-pb-90 {
    padding-bottom: 90px;
  }

  .sc-sm-pb-91 {
    padding-bottom: 91px;
  }

  .sc-sm-pb-92 {
    padding-bottom: 92px;
  }

  .sc-sm-pb-93 {
    padding-bottom: 93px;
  }

  .sc-sm-pb-94 {
    padding-bottom: 94px;
  }

  .sc-sm-pb-95 {
    padding-bottom: 95px;
  }

  .sc-sm-pb-96 {
    padding-bottom: 96px;
  }

  .sc-sm-pb-97 {
    padding-bottom: 97px;
  }

  .sc-sm-pb-98 {
    padding-bottom: 98px;
  }

  .sc-sm-pb-99 {
    padding-bottom: 99px;
  }

  .sc-sm-pb-100 {
    padding-bottom: 100px;
  }

  .sc-sm-pb-101 {
    padding-bottom: 101px;
  }

  .sc-sm-pb-102 {
    padding-bottom: 102px;
  }

  .sc-sm-pb-104 {
    padding-bottom: 104px;
  }

  .sc-sm-pb-105 {
    padding-bottom: 105px;
  }

  .sc-sm-pb-106 {
    padding-bottom: 106px;
  }

  .sc-sm-pb-107 {
    padding-bottom: 107px;
  }

  .sc-sm-pb-108 {
    padding-bottom: 108px;
  }

  .sc-sm-pb-109 {
    padding-bottom: 109px;
  }

  .sc-sm-pb-110 {
    padding-bottom: 110px;
  }

  .sc-sm-pb-111 {
    padding-bottom: 111px;
  }

  .sc-sm-pb-112 {
    padding-bottom: 112px;
  }

  .sc-sm-pb-113 {
    padding-bottom: 113px;
  }

  .sc-sm-pb-114 {
    padding-bottom: 114px;
  }

  .sc-sm-pb-115 {
    padding-bottom: 115px;
  }

  .sc-sm-pb-116 {
    padding-bottom: 116px;
  }

  .sc-sm-pb-117 {
    padding-bottom: 117px;
  }

  .sc-sm-pb-118 {
    padding-bottom: 118px;
  }

  .sc-sm-pb-119 {
    padding-bottom: 119px;
  }

  .sc-sm-pb-120 {
    padding-bottom: 120px;
  }

  .sc-sm-pb-121 {
    padding-bottom: 121px;
  }

  .sc-sm-pb-122 {
    padding-bottom: 122px;
  }

  .sc-sm-pb-123 {
    padding-bottom: 123px;
  }

  .sc-sm-pb-124 {
    padding-bottom: 124px;
  }

  .sc-sm-pb-125 {
    padding-bottom: 125px;
  }

  .sc-sm-pb-126 {
    padding-bottom: 126px;
  }

  .sc-sm-pb-127 {
    padding-bottom: 127px;
  }

  .sc-sm-pb-128 {
    padding-bottom: 128px;
  }

  .sc-sm-pb-129 {
    padding-bottom: 129px;
  }

  .sc-sm-pb-130 {
    padding-bottom: 130px;
  }

  .sc-sm-pb-131 {
    padding-bottom: 131px;
  }

  .sc-sm-pb-132 {
    padding-bottom: 132px;
  }

  .sc-sm-pb-133 {
    padding-bottom: 133px;
  }

  .sc-sm-pb-134 {
    padding-bottom: 134px;
  }

  .sc-sm-pb-135 {
    padding-bottom: 135px;
  }

  .sc-sm-pb-136 {
    padding-bottom: 136px;
  }

  .sc-sm-pb-137 {
    padding-bottom: 137px;
  }

  .sc-sm-pb-138 {
    padding-bottom: 138px;
  }

  .sc-sm-pb-139 {
    padding-bottom: 139px;
  }

  .sc-sm-pb-140 {
    padding-bottom: 140px;
  }

  .sc-sm-pb-141 {
    padding-bottom: 141px;
  }

  .sc-sm-pb-142 {
    padding-bottom: 142px;
  }

  .sc-sm-pb-143 {
    padding-bottom: 143px;
  }

  .sc-sm-pb-144 {
    padding-bottom: 144px;
  }

  .sc-sm-pb-145 {
    padding-bottom: 145px;
  }

  .sc-sm-pb-146 {
    padding-bottom: 146px;
  }

  .sc-sm-pb-147 {
    padding-bottom: 147px;
  }

  .sc-sm-pb-148 {
    padding-bottom: 148px;
  }

  .sc-sm-pb-149 {
    padding-bottom: 149px;
  }

  .sc-sm-pb-150 {
    padding-bottom: 150px;
  }

  .sc-sm-pb-151 {
    padding-bottom: 151px;
  }

  .sc-sm-pb-152 {
    padding-bottom: 152px;
  }

  .sc-sm-pb-153 {
    padding-bottom: 153px;
  }

  .sc-sm-pb-154 {
    padding-bottom: 154px;
  }

  .sc-sm-pb-155 {
    padding-bottom: 155px;
  }

  .sc-sm-pb-156 {
    padding-bottom: 156px;
  }

  .sc-sm-pb-157 {
    padding-bottom: 157px;
  }

  .sc-sm-pb-158 {
    padding-bottom: 158px;
  }

  .sc-sm-pb-159 {
    padding-bottom: 159px;
  }

  .sc-sm-pb-160 {
    padding-bottom: 160px;
  }

  .sc-sm-pb-161 {
    padding-bottom: 161px;
  }

  .sc-sm-pb-162 {
    padding-bottom: 162px;
  }

  .sc-sm-pb-163 {
    padding-bottom: 163px;
  }

  .sc-sm-pb-164 {
    padding-bottom: 164px;
  }

  .sc-sm-pb-165 {
    padding-bottom: 165px;
  }

  .sc-sm-pb-166 {
    padding-bottom: 166px;
  }

  .sc-sm-pb-167 {
    padding-bottom: 167px;
  }

  .sc-sm-pb-168 {
    padding-bottom: 168px;
  }

  .sc-sm-pb-169 {
    padding-bottom: 169px;
  }

  .sc-sm-pb-170 {
    padding-bottom: 170px;
  }

  .sc-sm-pb-171 {
    padding-bottom: 171px;
  }

  .sc-sm-pb-172 {
    padding-bottom: 172px;
  }

  .sc-sm-pb-173 {
    padding-bottom: 173px;
  }

  .sc-sm-pb-174 {
    padding-bottom: 174px;
  }

  .sc-sm-pb-175 {
    padding-bottom: 175px;
  }

  .sc-sm-pb-176 {
    padding-bottom: 176px;
  }

  .sc-sm-pb-177 {
    padding-bottom: 177px;
  }

  .sc-sm-pb-178 {
    padding-bottom: 178px;
  }

  .sc-sm-pb-179 {
    padding-bottom: 179px;
  }

  .sc-sm-pb-180 {
    padding-bottom: 180px;
  }

  .sc-sm-pb-181 {
    padding-bottom: 181px;
  }

  .sc-sm-pb-182 {
    padding-bottom: 182px;
  }

  .sc-sm-pb-183 {
    padding-bottom: 183px;
  }

  .sc-sm-pb-184 {
    padding-bottom: 184px;
  }

  .sc-sm-pb-185 {
    padding-bottom: 185px;
  }

  .sc-sm-pb-186 {
    padding-bottom: 186px;
  }

  .sc-sm-pb-187 {
    padding-bottom: 187px;
  }

  .sc-sm-pb-188 {
    padding-bottom: 188px;
  }

  .sc-sm-pb-189 {
    padding-bottom: 189px;
  }

  .sc-sm-pb-190 {
    padding-bottom: 190px;
  }

  .sc-sm-pb-191 {
    padding-bottom: 191px;
  }

  .sc-sm-pb-192 {
    padding-bottom: 192px;
  }

  .sc-sm-pb-193 {
    padding-bottom: 193px;
  }

  .sc-sm-pb-194 {
    padding-bottom: 194px;
  }

  .sc-sm-pb-195 {
    padding-bottom: 195px;
  }

  .sc-sm-pb-196 {
    padding-bottom: 196px;
  }

  .sc-sm-pb-197 {
    padding-bottom: 197px;
  }

  .sc-sm-pb-198 {
    padding-bottom: 198px;
  }

  .sc-sm-pb-199 {
    padding-bottom: 199px;
  }

  .sc-sm-pb-200 {
    padding-bottom: 200px;
  }

  .sc-sm-pr-0 {
    padding-right: 0;
  }

  .sc-sm-pr-1 {
    padding-right: 1px;
  }

  .sc-sm-pr-2 {
    padding-right: 2px;
  }

  .sc-sm-pr-4 {
    padding-right: 4px;
  }

  .sc-sm-pr-5 {
    padding-right: 5px;
  }

  .sc-sm-pr-6 {
    padding-right: 6px;
  }

  .sc-sm-pr-7 {
    padding-right: 7px;
  }

  .sc-sm-pr-8 {
    padding-right: 8px;
  }

  .sc-sm-pr-9 {
    padding-right: 9px;
  }

  .sc-sm-pr-10 {
    padding-right: 10px;
  }

  .sc-sm-pr-11 {
    padding-right: 11px;
  }

  .sc-sm-pr-12 {
    padding-right: 12px;
  }

  .sc-sm-pr-13 {
    padding-right: 13px;
  }

  .sc-sm-pr-14 {
    padding-right: 14px;
  }

  .sc-sm-pr-15 {
    padding-right: 15px;
  }

  .sc-sm-pr-16 {
    padding-right: 16px;
  }

  .sc-sm-pr-17 {
    padding-right: 17px;
  }

  .sc-sm-pr-18 {
    padding-right: 18px;
  }

  .sc-sm-pr-19 {
    padding-right: 19px;
  }

  .sc-sm-pr-20 {
    padding-right: 20px;
  }

  .sc-sm-pr-21 {
    padding-right: 21px;
  }

  .sc-sm-pr-22 {
    padding-right: 22px;
  }

  .sc-sm-pr-23 {
    padding-right: 23px;
  }

  .sc-sm-pr-24 {
    padding-right: 24px;
  }

  .sc-sm-pr-25 {
    padding-right: 25px;
  }

  .sc-sm-pr-26 {
    padding-right: 26px;
  }

  .sc-sm-pr-27 {
    padding-right: 27px;
  }

  .sc-sm-pr-28 {
    padding-right: 28px;
  }

  .sc-sm-pr-29 {
    padding-right: 29px;
  }

  .sc-sm-pr-30 {
    padding-right: 30px;
  }

  .sc-sm-pr-31 {
    padding-right: 31px;
  }

  .sc-sm-pr-32 {
    padding-right: 32px;
  }

  .sc-sm-pr-33 {
    padding-right: 33px;
  }

  .sc-sm-pr-34 {
    padding-right: 34px;
  }

  .sc-sm-pr-35 {
    padding-right: 35px;
  }

  .sc-sm-pr-36 {
    padding-right: 36px;
  }

  .sc-sm-pr-37 {
    padding-right: 37px;
  }

  .sc-sm-pr-38 {
    padding-right: 38px;
  }

  .sc-sm-pr-39 {
    padding-right: 39px;
  }

  .sc-sm-pr-40 {
    padding-right: 40px;
  }

  .sc-sm-pr-41 {
    padding-right: 41px;
  }

  .sc-sm-pr-42 {
    padding-right: 42px;
  }

  .sc-sm-pr-43 {
    padding-right: 43px;
  }

  .sc-sm-pr-44 {
    padding-right: 44px;
  }

  .sc-sm-pr-45 {
    padding-right: 45px;
  }

  .sc-sm-pr-46 {
    padding-right: 46px;
  }

  .sc-sm-pr-47 {
    padding-right: 47px;
  }

  .sc-sm-pr-48 {
    padding-right: 48px;
  }

  .sc-sm-pr-49 {
    padding-right: 49px;
  }

  .sc-sm-pr-50 {
    padding-right: 50px;
  }

  .sc-sm-pr-51 {
    padding-right: 51px;
  }

  .sc-sm-pr-52 {
    padding-right: 52px;
  }

  .sc-sm-pr-53 {
    padding-right: 53px;
  }

  .sc-sm-pr-54 {
    padding-right: 54px;
  }

  .sc-sm-pr-55 {
    padding-right: 55px;
  }

  .sc-sm-pr-56 {
    padding-right: 56px;
  }

  .sc-sm-pr-57 {
    padding-right: 57px;
  }

  .sc-sm-pr-58 {
    padding-right: 58px;
  }

  .sc-sm-pr-59 {
    padding-right: 59px;
  }

  .sc-sm-pr-60 {
    padding-right: 60px;
  }

  .sc-sm-pr-61 {
    padding-right: 61px;
  }

  .sc-sm-pr-62 {
    padding-right: 62px;
  }

  .sc-sm-pr-63 {
    padding-right: 63px;
  }

  .sc-sm-pr-64 {
    padding-right: 64px;
  }

  .sc-sm-pr-65 {
    padding-right: 65px;
  }

  .sc-sm-pr-66 {
    padding-right: 66px;
  }

  .sc-sm-pr-67 {
    padding-right: 67px;
  }

  .sc-sm-pr-68 {
    padding-right: 68px;
  }

  .sc-sm-pr-69 {
    padding-right: 69px;
  }

  .sc-sm-pr-70 {
    padding-right: 70px;
  }

  .sc-sm-pr-71 {
    padding-right: 71px;
  }

  .sc-sm-pr-72 {
    padding-right: 72px;
  }

  .sc-sm-pr-73 {
    padding-right: 73px;
  }

  .sc-sm-pr-74 {
    padding-right: 74px;
  }

  .sc-sm-pr-75 {
    padding-right: 75px;
  }

  .sc-sm-pr-76 {
    padding-right: 76px;
  }

  .sc-sm-pr-77 {
    padding-right: 77px;
  }

  .sc-sm-pr-78 {
    padding-right: 78px;
  }

  .sc-sm-pr-79 {
    padding-right: 79px;
  }

  .sc-sm-pr-80 {
    padding-right: 80px;
  }

  .sc-sm-pr-81 {
    padding-right: 81px;
  }

  .sc-sm-pr-82 {
    padding-right: 82px;
  }

  .sc-sm-pr-83 {
    padding-right: 83px;
  }

  .sc-sm-pr-84 {
    padding-right: 84px;
  }

  .sc-sm-pr-85 {
    padding-right: 85px;
  }

  .sc-sm-pr-86 {
    padding-right: 86px;
  }

  .sc-sm-pr-87 {
    padding-right: 87px;
  }

  .sc-sm-pr-88 {
    padding-right: 88px;
  }

  .sc-sm-pr-89 {
    padding-right: 89px;
  }

  .sc-sm-pr-90 {
    padding-right: 90px;
  }

  .sc-sm-pr-91 {
    padding-right: 91px;
  }

  .sc-sm-pr-92 {
    padding-right: 92px;
  }

  .sc-sm-pr-93 {
    padding-right: 93px;
  }

  .sc-sm-pr-94 {
    padding-right: 94px;
  }

  .sc-sm-pr-95 {
    padding-right: 95px;
  }

  .sc-sm-pr-96 {
    padding-right: 96px;
  }

  .sc-sm-pr-97 {
    padding-right: 97px;
  }

  .sc-sm-pr-98 {
    padding-right: 98px;
  }

  .sc-sm-pr-99 {
    padding-right: 99px;
  }

  .sc-sm-pr-100 {
    padding-right: 100px;
  }

  .sc-sm-pr-101 {
    padding-right: 101px;
  }

  .sc-sm-pr-102 {
    padding-right: 102px;
  }

  .sc-sm-pr-104 {
    padding-right: 104px;
  }

  .sc-sm-pr-105 {
    padding-right: 105px;
  }

  .sc-sm-pr-106 {
    padding-right: 106px;
  }

  .sc-sm-pr-107 {
    padding-right: 107px;
  }

  .sc-sm-pr-108 {
    padding-right: 108px;
  }

  .sc-sm-pr-109 {
    padding-right: 109px;
  }

  .sc-sm-pr-110 {
    padding-right: 110px;
  }

  .sc-sm-pr-111 {
    padding-right: 111px;
  }

  .sc-sm-pr-112 {
    padding-right: 112px;
  }

  .sc-sm-pr-113 {
    padding-right: 113px;
  }

  .sc-sm-pr-114 {
    padding-right: 114px;
  }

  .sc-sm-pr-115 {
    padding-right: 115px;
  }

  .sc-sm-pr-116 {
    padding-right: 116px;
  }

  .sc-sm-pr-117 {
    padding-right: 117px;
  }

  .sc-sm-pr-118 {
    padding-right: 118px;
  }

  .sc-sm-pr-119 {
    padding-right: 119px;
  }

  .sc-sm-pr-120 {
    padding-right: 120px;
  }

  .sc-sm-pr-121 {
    padding-right: 121px;
  }

  .sc-sm-pr-122 {
    padding-right: 122px;
  }

  .sc-sm-pr-123 {
    padding-right: 123px;
  }

  .sc-sm-pr-124 {
    padding-right: 124px;
  }

  .sc-sm-pr-125 {
    padding-right: 125px;
  }

  .sc-sm-pr-126 {
    padding-right: 126px;
  }

  .sc-sm-pr-127 {
    padding-right: 127px;
  }

  .sc-sm-pr-128 {
    padding-right: 128px;
  }

  .sc-sm-pr-129 {
    padding-right: 129px;
  }

  .sc-sm-pr-130 {
    padding-right: 130px;
  }

  .sc-sm-pr-131 {
    padding-right: 131px;
  }

  .sc-sm-pr-132 {
    padding-right: 132px;
  }

  .sc-sm-pr-133 {
    padding-right: 133px;
  }

  .sc-sm-pr-134 {
    padding-right: 134px;
  }

  .sc-sm-pr-135 {
    padding-right: 135px;
  }

  .sc-sm-pr-136 {
    padding-right: 136px;
  }

  .sc-sm-pr-137 {
    padding-right: 137px;
  }

  .sc-sm-pr-138 {
    padding-right: 138px;
  }

  .sc-sm-pr-139 {
    padding-right: 139px;
  }

  .sc-sm-pr-140 {
    padding-right: 140px;
  }

  .sc-sm-pr-141 {
    padding-right: 141px;
  }

  .sc-sm-pr-142 {
    padding-right: 142px;
  }

  .sc-sm-pr-143 {
    padding-right: 143px;
  }

  .sc-sm-pr-144 {
    padding-right: 144px;
  }

  .sc-sm-pr-145 {
    padding-right: 145px;
  }

  .sc-sm-pr-146 {
    padding-right: 146px;
  }

  .sc-sm-pr-147 {
    padding-right: 147px;
  }

  .sc-sm-pr-148 {
    padding-right: 148px;
  }

  .sc-sm-pr-149 {
    padding-right: 149px;
  }

  .sc-sm-pr-150 {
    padding-right: 150px;
  }

  .sc-sm-pr-151 {
    padding-right: 151px;
  }

  .sc-sm-pr-152 {
    padding-right: 152px;
  }

  .sc-sm-pr-153 {
    padding-right: 153px;
  }

  .sc-sm-pr-154 {
    padding-right: 154px;
  }

  .sc-sm-pr-155 {
    padding-right: 155px;
  }

  .sc-sm-pr-156 {
    padding-right: 156px;
  }

  .sc-sm-pr-157 {
    padding-right: 157px;
  }

  .sc-sm-pr-158 {
    padding-right: 158px;
  }

  .sc-sm-pr-159 {
    padding-right: 159px;
  }

  .sc-sm-pr-160 {
    padding-right: 160px;
  }

  .sc-sm-pr-161 {
    padding-right: 161px;
  }

  .sc-sm-pr-162 {
    padding-right: 162px;
  }

  .sc-sm-pr-163 {
    padding-right: 163px;
  }

  .sc-sm-pr-164 {
    padding-right: 164px;
  }

  .sc-sm-pr-165 {
    padding-right: 165px;
  }

  .sc-sm-pr-166 {
    padding-right: 166px;
  }

  .sc-sm-pr-167 {
    padding-right: 167px;
  }

  .sc-sm-pr-168 {
    padding-right: 168px;
  }

  .sc-sm-pr-169 {
    padding-right: 169px;
  }

  .sc-sm-pr-170 {
    padding-right: 170px;
  }

  .sc-sm-pr-171 {
    padding-right: 171px;
  }

  .sc-sm-pr-172 {
    padding-right: 172px;
  }

  .sc-sm-pr-173 {
    padding-right: 173px;
  }

  .sc-sm-pr-174 {
    padding-right: 174px;
  }

  .sc-sm-pr-175 {
    padding-right: 175px;
  }

  .sc-sm-pr-176 {
    padding-right: 176px;
  }

  .sc-sm-pr-177 {
    padding-right: 177px;
  }

  .sc-sm-pr-178 {
    padding-right: 178px;
  }

  .sc-sm-pr-179 {
    padding-right: 179px;
  }

  .sc-sm-pr-180 {
    padding-right: 180px;
  }

  .sc-sm-pr-181 {
    padding-right: 181px;
  }

  .sc-sm-pr-182 {
    padding-right: 182px;
  }

  .sc-sm-pr-183 {
    padding-right: 183px;
  }

  .sc-sm-pr-184 {
    padding-right: 184px;
  }

  .sc-sm-pr-185 {
    padding-right: 185px;
  }

  .sc-sm-pr-186 {
    padding-right: 186px;
  }

  .sc-sm-pr-187 {
    padding-right: 187px;
  }

  .sc-sm-pr-188 {
    padding-right: 188px;
  }

  .sc-sm-pr-189 {
    padding-right: 189px;
  }

  .sc-sm-pr-190 {
    padding-right: 190px;
  }

  .sc-sm-pr-191 {
    padding-right: 191px;
  }

  .sc-sm-pr-192 {
    padding-right: 192px;
  }

  .sc-sm-pr-193 {
    padding-right: 193px;
  }

  .sc-sm-pr-194 {
    padding-right: 194px;
  }

  .sc-sm-pr-195 {
    padding-right: 195px;
  }

  .sc-sm-pr-196 {
    padding-right: 196px;
  }

  .sc-sm-pr-197 {
    padding-right: 197px;
  }

  .sc-sm-pr-198 {
    padding-right: 198px;
  }

  .sc-sm-pr-199 {
    padding-right: 199px;
  }

  .sc-sm-pr-200 {
    padding-right: 200px;
  }

  .sc-sm-pl-0 {
    padding-left: 0;
  }

  .sc-sm-pl-1 {
    padding-left: 1px;
  }

  .sc-sm-pl-2 {
    padding-left: 2px;
  }

  .sc-pb-3 {
    padding-bottom: 3px;
  }

  .sc-sm-pl-4 {
    padding-left: 4px;
  }

  .sc-sm-pl-5 {
    padding-left: 5px;
  }

  .sc-sm-pl-6 {
    padding-left: 6px;
  }

  .sc-sm-pl-7 {
    padding-left: 7px;
  }

  .sc-sm-pl-8 {
    padding-left: 8px;
  }

  .sc-sm-pl-9 {
    padding-left: 9px;
  }

  .sc-sm-pl-10 {
    padding-left: 10px;
  }

  .sc-sm-pl-11 {
    padding-left: 11px;
  }

  .sc-sm-pl-12 {
    padding-left: 12px;
  }

  .sc-sm-pl-13 {
    padding-left: 13px;
  }

  .sc-sm-pl-14 {
    padding-left: 14px;
  }

  .sc-sm-pl-15 {
    padding-left: 15px;
  }

  .sc-sm-pl-16 {
    padding-left: 16px;
  }

  .sc-sm-pl-17 {
    padding-left: 17px;
  }

  .sc-sm-pl-18 {
    padding-left: 18px;
  }

  .sc-sm-pl-19 {
    padding-left: 19px;
  }

  .sc-sm-pl-20 {
    padding-left: 20px;
  }

  .sc-sm-pl-21 {
    padding-left: 21px;
  }

  .sc-sm-pl-22 {
    padding-left: 22px;
  }

  .sc-sm-pl-23 {
    padding-left: 23px;
  }

  .sc-sm-pl-24 {
    padding-left: 24px;
  }

  .sc-sm-pl-25 {
    padding-left: 25px;
  }

  .sc-sm-pl-26 {
    padding-left: 26px;
  }

  .sc-sm-pl-27 {
    padding-left: 27px;
  }

  .sc-sm-pl-28 {
    padding-left: 28px;
  }

  .sc-sm-pl-29 {
    padding-left: 29px;
  }

  .sc-sm-pl-30 {
    padding-left: 30px;
  }

  .sc-sm-pl-31 {
    padding-left: 31px;
  }

  .sc-sm-pl-32 {
    padding-left: 32px;
  }

  .sc-sm-pl-33 {
    padding-left: 33px;
  }

  .sc-sm-pl-34 {
    padding-left: 34px;
  }

  .sc-sm-pl-35 {
    padding-left: 35px;
  }

  .sc-sm-pl-36 {
    padding-left: 36px;
  }

  .sc-sm-pl-37 {
    padding-left: 37px;
  }

  .sc-sm-pl-38 {
    padding-left: 38px;
  }

  .sc-sm-pl-39 {
    padding-left: 39px;
  }

  .sc-sm-pl-40 {
    padding-left: 40px;
  }

  .sc-sm-pl-41 {
    padding-left: 41px;
  }

  .sc-sm-pl-42 {
    padding-left: 42px;
  }

  .sc-sm-pl-43 {
    padding-left: 43px;
  }

  .sc-sm-pl-44 {
    padding-left: 44px;
  }

  .sc-sm-pl-45 {
    padding-left: 45px;
  }

  .sc-sm-pl-46 {
    padding-left: 46px;
  }

  .sc-sm-pl-47 {
    padding-left: 47px;
  }

  .sc-sm-pl-48 {
    padding-left: 48px;
  }

  .sc-sm-pl-49 {
    padding-left: 49px;
  }

  .sc-sm-pl-50 {
    padding-left: 50px;
  }

  .sc-sm-pl-51 {
    padding-left: 51px;
  }

  .sc-sm-pl-52 {
    padding-left: 52px;
  }

  .sc-sm-pl-53 {
    padding-left: 53px;
  }

  .sc-sm-pl-54 {
    padding-left: 54px;
  }

  .sc-sm-pl-55 {
    padding-left: 55px;
  }

  .sc-sm-pl-56 {
    padding-left: 56px;
  }

  .sc-sm-pl-57 {
    padding-left: 57px;
  }

  .sc-sm-pl-58 {
    padding-left: 58px;
  }

  .sc-sm-pl-59 {
    padding-left: 59px;
  }

  .sc-sm-pl-60 {
    padding-left: 60px;
  }

  .sc-sm-pl-61 {
    padding-left: 61px;
  }

  .sc-sm-pl-62 {
    padding-left: 62px;
  }

  .sc-sm-pl-63 {
    padding-left: 63px;
  }

  .sc-sm-pl-64 {
    padding-left: 64px;
  }

  .sc-sm-pl-65 {
    padding-left: 65px;
  }

  .sc-sm-pl-66 {
    padding-left: 66px;
  }

  .sc-sm-pl-67 {
    padding-left: 67px;
  }

  .sc-sm-pl-68 {
    padding-left: 68px;
  }

  .sc-sm-pl-69 {
    padding-left: 69px;
  }

  .sc-sm-pl-70 {
    padding-left: 70px;
  }

  .sc-sm-pl-71 {
    padding-left: 71px;
  }

  .sc-sm-pl-72 {
    padding-left: 72px;
  }

  .sc-sm-pl-73 {
    padding-left: 73px;
  }

  .sc-sm-pl-74 {
    padding-left: 74px;
  }

  .sc-sm-pl-75 {
    padding-left: 75px;
  }

  .sc-sm-pl-76 {
    padding-left: 76px;
  }

  .sc-sm-pl-77 {
    padding-left: 77px;
  }

  .sc-sm-pl-78 {
    padding-left: 78px;
  }

  .sc-sm-pl-79 {
    padding-left: 79px;
  }

  .sc-sm-pl-80 {
    padding-left: 80px;
  }

  .sc-sm-pl-81 {
    padding-left: 81px;
  }

  .sc-sm-pl-82 {
    padding-left: 82px;
  }

  .sc-sm-pl-83 {
    padding-left: 83px;
  }

  .sc-sm-pl-84 {
    padding-left: 84px;
  }

  .sc-sm-pl-85 {
    padding-left: 85px;
  }

  .sc-sm-pl-86 {
    padding-left: 86px;
  }

  .sc-sm-pl-87 {
    padding-left: 87px;
  }

  .sc-sm-pl-88 {
    padding-left: 88px;
  }

  .sc-sm-pl-89 {
    padding-left: 89px;
  }

  .sc-sm-pl-90 {
    padding-left: 90px;
  }

  .sc-sm-pl-91 {
    padding-left: 91px;
  }

  .sc-sm-pl-92 {
    padding-left: 92px;
  }

  .sc-sm-pl-93 {
    padding-left: 93px;
  }

  .sc-sm-pl-94 {
    padding-left: 94px;
  }

  .sc-sm-pl-95 {
    padding-left: 95px;
  }

  .sc-sm-pl-96 {
    padding-left: 96px;
  }

  .sc-sm-pl-97 {
    padding-left: 97px;
  }

  .sc-sm-pl-98 {
    padding-left: 98px;
  }

  .sc-sm-pl-99 {
    padding-left: 99px;
  }

  .sc-sm-pl-100 {
    padding-left: 100px;
  }

  .sc-sm-pl-101 {
    padding-left: 101px;
  }

  .sc-sm-pl-102 {
    padding-left: 102px;
  }

  .sc-sm-pb-103 {
    padding-bottom: 103px;
  }

  .sc-sm-pl-104 {
    padding-left: 104px;
  }

  .sc-sm-pl-105 {
    padding-left: 105px;
  }

  .sc-sm-pl-106 {
    padding-left: 106px;
  }

  .sc-sm-pl-107 {
    padding-left: 107px;
  }

  .sc-sm-pl-108 {
    padding-left: 108px;
  }

  .sc-sm-pl-109 {
    padding-left: 109px;
  }

  .sc-sm-pl-110 {
    padding-left: 110px;
  }

  .sc-sm-pl-111 {
    padding-left: 111px;
  }

  .sc-sm-pl-112 {
    padding-left: 112px;
  }

  .sc-sm-pl-113 {
    padding-left: 113px;
  }

  .sc-sm-pl-114 {
    padding-left: 114px;
  }

  .sc-sm-pl-115 {
    padding-left: 115px;
  }

  .sc-sm-pl-116 {
    padding-left: 116px;
  }

  .sc-sm-pl-117 {
    padding-left: 117px;
  }

  .sc-sm-pl-118 {
    padding-left: 118px;
  }

  .sc-sm-pl-119 {
    padding-left: 119px;
  }

  .sc-sm-pl-120 {
    padding-left: 120px;
  }

  .sc-sm-pl-121 {
    padding-left: 121px;
  }

  .sc-sm-pl-122 {
    padding-left: 122px;
  }

  .sc-sm-pl-123 {
    padding-left: 123px;
  }

  .sc-sm-pl-124 {
    padding-left: 124px;
  }

  .sc-sm-pl-125 {
    padding-left: 125px;
  }

  .sc-sm-pl-126 {
    padding-left: 126px;
  }

  .sc-sm-pl-127 {
    padding-left: 127px;
  }

  .sc-sm-pl-128 {
    padding-left: 128px;
  }

  .sc-sm-pl-129 {
    padding-left: 129px;
  }

  .sc-sm-pl-130 {
    padding-left: 130px;
  }

  .sc-sm-pl-131 {
    padding-left: 131px;
  }

  .sc-sm-pl-132 {
    padding-left: 132px;
  }

  .sc-sm-pl-133 {
    padding-left: 133px;
  }

  .sc-sm-pl-134 {
    padding-left: 134px;
  }

  .sc-sm-pl-135 {
    padding-left: 135px;
  }

  .sc-sm-pl-136 {
    padding-left: 136px;
  }

  .sc-sm-pl-137 {
    padding-left: 137px;
  }

  .sc-sm-pl-138 {
    padding-left: 138px;
  }

  .sc-sm-pl-139 {
    padding-left: 139px;
  }

  .sc-sm-pl-140 {
    padding-left: 140px;
  }

  .sc-sm-pl-141 {
    padding-left: 141px;
  }

  .sc-sm-pl-142 {
    padding-left: 142px;
  }

  .sc-sm-pl-143 {
    padding-left: 143px;
  }

  .sc-sm-pl-144 {
    padding-left: 144px;
  }

  .sc-sm-pl-145 {
    padding-left: 145px;
  }

  .sc-sm-pl-146 {
    padding-left: 146px;
  }

  .sc-sm-pl-147 {
    padding-left: 147px;
  }

  .sc-sm-pl-148 {
    padding-left: 148px;
  }

  .sc-sm-pl-149 {
    padding-left: 149px;
  }

  .sc-sm-pl-150 {
    padding-left: 150px;
  }

  .sc-sm-pl-151 {
    padding-left: 151px;
  }

  .sc-sm-pl-152 {
    padding-left: 152px;
  }

  .sc-sm-pl-153 {
    padding-left: 153px;
  }

  .sc-sm-pl-154 {
    padding-left: 154px;
  }

  .sc-sm-pl-155 {
    padding-left: 155px;
  }

  .sc-sm-pl-156 {
    padding-left: 156px;
  }

  .sc-sm-pl-157 {
    padding-left: 157px;
  }

  .sc-sm-pl-158 {
    padding-left: 158px;
  }

  .sc-sm-pl-159 {
    padding-left: 159px;
  }

  .sc-sm-pl-160 {
    padding-left: 160px;
  }

  .sc-sm-pl-161 {
    padding-left: 161px;
  }

  .sc-sm-pl-162 {
    padding-left: 162px;
  }

  .sc-sm-pl-163 {
    padding-left: 163px;
  }

  .sc-sm-pl-164 {
    padding-left: 164px;
  }

  .sc-sm-pl-165 {
    padding-left: 165px;
  }

  .sc-sm-pl-166 {
    padding-left: 166px;
  }

  .sc-sm-pl-167 {
    padding-left: 167px;
  }

  .sc-sm-pl-168 {
    padding-left: 168px;
  }

  .sc-sm-pl-169 {
    padding-left: 169px;
  }

  .sc-sm-pl-170 {
    padding-left: 170px;
  }

  .sc-sm-pl-171 {
    padding-left: 171px;
  }

  .sc-sm-pl-172 {
    padding-left: 172px;
  }

  .sc-sm-pl-173 {
    padding-left: 173px;
  }

  .sc-sm-pl-174 {
    padding-left: 174px;
  }

  .sc-sm-pl-175 {
    padding-left: 175px;
  }

  .sc-sm-pl-176 {
    padding-left: 176px;
  }

  .sc-sm-pl-177 {
    padding-left: 177px;
  }

  .sc-sm-pl-178 {
    padding-left: 178px;
  }

  .sc-sm-pl-179 {
    padding-left: 179px;
  }

  .sc-sm-pl-180 {
    padding-left: 180px;
  }

  .sc-sm-pl-181 {
    padding-left: 181px;
  }

  .sc-sm-pl-182 {
    padding-left: 182px;
  }

  .sc-sm-pl-183 {
    padding-left: 183px;
  }

  .sc-sm-pl-184 {
    padding-left: 184px;
  }

  .sc-sm-pl-185 {
    padding-left: 185px;
  }

  .sc-sm-pl-186 {
    padding-left: 186px;
  }

  .sc-sm-pl-187 {
    padding-left: 187px;
  }

  .sc-sm-pl-188 {
    padding-left: 188px;
  }

  .sc-sm-pl-189 {
    padding-left: 189px;
  }

  .sc-sm-pl-190 {
    padding-left: 190px;
  }

  .sc-sm-pl-191 {
    padding-left: 191px;
  }

  .sc-sm-pl-192 {
    padding-left: 192px;
  }

  .sc-sm-pl-193 {
    padding-left: 193px;
  }

  .sc-sm-pl-194 {
    padding-left: 194px;
  }

  .sc-sm-pl-195 {
    padding-left: 195px;
  }

  .sc-sm-pl-196 {
    padding-left: 196px;
  }

  .sc-sm-pl-197 {
    padding-left: 197px;
  }

  .sc-sm-pl-198 {
    padding-left: 198px;
  }

  .sc-sm-pl-199 {
    padding-left: 199px;
  }

  .sc-sm-pl-200 {
    padding-left: 200px;
  }

  .sc-sm-mt-0 {
    margin-top: 0;
  }

  .sc-sm-mt-1 {
    margin-top: 1px;
  }

  .sc-sm-mt-2 {
    margin-top: 2px;
  }

  .sc-sm-mt-3 {
    margin-top: 3px;
  }

  .sc-sm-mt-4 {
    margin-top: 4px;
  }

  .sc-sm-mt-5 {
    margin-top: 5px;
  }

  .sc-sm-mt-6 {
    margin-top: 6px;
  }

  .sc-sm-mt-7 {
    margin-top: 7px;
  }

  .sc-sm-mt-8 {
    margin-top: 8px;
  }

  .sc-sm-mt-9 {
    margin-top: 9px;
  }

  .sc-sm-mt-10 {
    margin-top: 10px;
  }

  .sc-sm-mt-11 {
    margin-top: 11px;
  }

  .sc-sm-mt-12 {
    margin-top: 12px;
  }

  .sc-sm-mt-13 {
    margin-top: 13px;
  }

  .sc-sm-mt-14 {
    margin-top: 14px;
  }

  .sc-sm-mt-15 {
    margin-top: 15px;
  }

  .sc-sm-mt-16 {
    margin-top: 16px;
  }

  .sc-sm-mt-17 {
    margin-top: 17px;
  }

  .sc-sm-mt-18 {
    margin-top: 18px;
  }

  .sc-sm-mt-19 {
    margin-top: 19px;
  }

  .sc-sm-mt-20 {
    margin-top: 20px;
  }

  .sc-sm-mt-21 {
    margin-top: 21px;
  }

  .sc-sm-mt-22 {
    margin-top: 22px;
  }

  .sc-sm-mt-23 {
    margin-top: 23px;
  }

  .sc-sm-mt-24 {
    margin-top: 24px;
  }

  .sc-sm-mt-25 {
    margin-top: 25px;
  }

  .sc-sm-mt-26 {
    margin-top: 26px;
  }

  .sc-sm-mt-27 {
    margin-top: 27px;
  }

  .sc-sm-mt-28 {
    margin-top: 28px;
  }

  .sc-sm-mt-29 {
    margin-top: 29px;
  }

  .sc-sm-mt-30 {
    margin-top: 30px;
  }

  .sc-sm-mt-31 {
    margin-top: 31px;
  }

  .sc-sm-mt-32 {
    margin-top: 32px;
  }

  .sc-sm-mt-33 {
    margin-top: 33px;
  }

  .sc-sm-mt-34 {
    margin-top: 34px;
  }

  .sc-sm-mt-35 {
    margin-top: 35px;
  }

  .sc-sm-mt-36 {
    margin-top: 36px;
  }

  .sc-sm-mt-37 {
    margin-top: 37px;
  }

  .sc-sm-mt-38 {
    margin-top: 38px;
  }

  .sc-sm-mt-39 {
    margin-top: 39px;
  }

  .sc-sm-mt-40 {
    margin-top: 40px;
  }

  .sc-sm-mt-41 {
    margin-top: 41px;
  }

  .sc-sm-mt-42 {
    margin-top: 42px;
  }

  .sc-sm-mt-43 {
    margin-top: 43px;
  }

  .sc-sm-mt-44 {
    margin-top: 44px;
  }

  .sc-sm-mt-45 {
    margin-top: 45px;
  }

  .sc-sm-mt-46 {
    margin-top: 46px;
  }

  .sc-sm-mt-47 {
    margin-top: 47px;
  }

  .sc-sm-mt-48 {
    margin-top: 48px;
  }

  .sc-sm-mt-49 {
    margin-top: 49px;
  }

  .sc-sm-mt-50 {
    margin-top: 50px;
  }

  .sc-sm-mt-51 {
    margin-top: 51px;
  }

  .sc-sm-mt-52 {
    margin-top: 52px;
  }

  .sc-sm-mt-53 {
    margin-top: 53px;
  }

  .sc-sm-mt-54 {
    margin-top: 54px;
  }

  .sc-sm-mt-55 {
    margin-top: 55px;
  }

  .sc-sm-mt-56 {
    margin-top: 56px;
  }

  .sc-sm-mt-57 {
    margin-top: 57px;
  }

  .sc-sm-mt-58 {
    margin-top: 58px;
  }

  .sc-sm-mt-59 {
    margin-top: 59px;
  }

  .sc-sm-mt-60 {
    margin-top: 60px;
  }

  .sc-sm-mt-61 {
    margin-top: 61px;
  }

  .sc-sm-mt-62 {
    margin-top: 62px;
  }

  .sc-sm-mt-63 {
    margin-top: 63px;
  }

  .sc-sm-mt-64 {
    margin-top: 64px;
  }

  .sc-sm-mt-65 {
    margin-top: 65px;
  }

  .sc-sm-mt-66 {
    margin-top: 66px;
  }

  .sc-sm-mt-67 {
    margin-top: 67px;
  }

  .sc-sm-mt-68 {
    margin-top: 68px;
  }

  .sc-sm-mt-69 {
    margin-top: 69px;
  }

  .sc-sm-mt-70 {
    margin-top: 70px;
  }

  .sc-sm-mt-71 {
    margin-top: 71px;
  }

  .sc-sm-mt-72 {
    margin-top: 72px;
  }

  .sc-sm-mt-73 {
    margin-top: 73px;
  }

  .sc-sm-mt-74 {
    margin-top: 74px;
  }

  .sc-sm-mt-75 {
    margin-top: 75px;
  }

  .sc-sm-mt-76 {
    margin-top: 76px;
  }

  .sc-sm-mt-77 {
    margin-top: 77px;
  }

  .sc-sm-mt-78 {
    margin-top: 78px;
  }

  .sc-sm-mt-79 {
    margin-top: 79px;
  }

  .sc-sm-mt-80 {
    margin-top: 80px;
  }

  .sc-sm-mt-81 {
    margin-top: 81px;
  }

  .sc-sm-mt-82 {
    margin-top: 82px;
  }

  .sc-sm-mt-83 {
    margin-top: 83px;
  }

  .sc-sm-mt-84 {
    margin-top: 84px;
  }

  .sc-sm-mt-85 {
    margin-top: 85px;
  }

  .sc-sm-mt-86 {
    margin-top: 86px;
  }

  .sc-sm-mt-87 {
    margin-top: 87px;
  }

  .sc-sm-mt-88 {
    margin-top: 88px;
  }

  .sc-sm-mt-89 {
    margin-top: 89px;
  }

  .sc-sm-mt-90 {
    margin-top: 90px;
  }

  .sc-sm-mt-91 {
    margin-top: 91px;
  }

  .sc-sm-mt-92 {
    margin-top: 92px;
  }

  .sc-sm-mt-93 {
    margin-top: 93px;
  }

  .sc-sm-mt-94 {
    margin-top: 94px;
  }

  .sc-sm-mt-95 {
    margin-top: 95px;
  }

  .sc-sm-mt-96 {
    margin-top: 96px;
  }

  .sc-sm-mt-97 {
    margin-top: 97px;
  }

  .sc-sm-mt-98 {
    margin-top: 98px;
  }

  .sc-sm-mt-99 {
    margin-top: 99px;
  }

  .sc-sm-mt-100 {
    margin-top: 100px;
  }

  .sc-sm-mt-101 {
    margin-top: 101px;
  }

  .sc-sm-mt-102 {
    margin-top: 102px;
  }

  .sc-sm-mt-103 {
    margin-top: 103px;
  }

  .sc-sm-mt-104 {
    margin-top: 104px;
  }

  .sc-sm-mt-105 {
    margin-top: 105px;
  }

  .sc-sm-mt-106 {
    margin-top: 106px;
  }

  .sc-sm-mt-107 {
    margin-top: 107px;
  }

  .sc-sm-mt-108 {
    margin-top: 108px;
  }

  .sc-sm-mt-109 {
    margin-top: 109px;
  }

  .sc-sm-mt-110 {
    margin-top: 110px;
  }

  .sc-sm-mt-111 {
    margin-top: 111px;
  }

  .sc-sm-mt-112 {
    margin-top: 112px;
  }

  .sc-sm-mt-113 {
    margin-top: 113px;
  }

  .sc-sm-mt-114 {
    margin-top: 114px;
  }

  .sc-sm-mt-115 {
    margin-top: 115px;
  }

  .sc-sm-mt-116 {
    margin-top: 116px;
  }

  .sc-sm-mt-117 {
    margin-top: 117px;
  }

  .sc-sm-mt-118 {
    margin-top: 118px;
  }

  .sc-sm-mt-119 {
    margin-top: 119px;
  }

  .sc-sm-mt-120 {
    margin-top: 120px;
  }

  .sc-sm-mt-121 {
    margin-top: 121px;
  }

  .sc-sm-mt-122 {
    margin-top: 122px;
  }

  .sc-sm-mt-123 {
    margin-top: 123px;
  }

  .sc-sm-mt-124 {
    margin-top: 124px;
  }

  .sc-sm-mt-125 {
    margin-top: 125px;
  }

  .sc-sm-mt-126 {
    margin-top: 126px;
  }

  .sc-sm-mt-127 {
    margin-top: 127px;
  }

  .sc-sm-mt-128 {
    margin-top: 128px;
  }

  .sc-sm-mt-129 {
    margin-top: 129px;
  }

  .sc-sm-mt-130 {
    margin-top: 130px;
  }

  .sc-sm-mt-131 {
    margin-top: 131px;
  }

  .sc-sm-mt-132 {
    margin-top: 132px;
  }

  .sc-sm-mt-133 {
    margin-top: 133px;
  }

  .sc-sm-mt-134 {
    margin-top: 134px;
  }

  .sc-sm-mt-135 {
    margin-top: 135px;
  }

  .sc-sm-mt-136 {
    margin-top: 136px;
  }

  .sc-sm-mt-137 {
    margin-top: 137px;
  }

  .sc-sm-mt-138 {
    margin-top: 138px;
  }

  .sc-sm-mt-139 {
    margin-top: 139px;
  }

  .sc-sm-mt-140 {
    margin-top: 140px;
  }

  .sc-sm-mt-141 {
    margin-top: 141px;
  }

  .sc-sm-mt-142 {
    margin-top: 142px;
  }

  .sc-sm-mt-143 {
    margin-top: 143px;
  }

  .sc-sm-mt-144 {
    margin-top: 144px;
  }

  .sc-sm-mt-145 {
    margin-top: 145px;
  }

  .sc-sm-mt-146 {
    margin-top: 146px;
  }

  .sc-sm-mt-147 {
    margin-top: 147px;
  }

  .sc-sm-mt-148 {
    margin-top: 148px;
  }

  .sc-sm-mt-149 {
    margin-top: 149px;
  }

  .sc-sm-mt-150 {
    margin-top: 150px;
  }

  .sc-sm-mt-151 {
    margin-top: 151px;
  }

  .sc-sm-mt-152 {
    margin-top: 152px;
  }

  .sc-sm-mt-153 {
    margin-top: 153px;
  }

  .sc-sm-mt-154 {
    margin-top: 154px;
  }

  .sc-sm-mt-155 {
    margin-top: 155px;
  }

  .sc-sm-mt-156 {
    margin-top: 156px;
  }

  .sc-sm-mt-157 {
    margin-top: 157px;
  }

  .sc-sm-mt-158 {
    margin-top: 158px;
  }

  .sc-sm-mt-159 {
    margin-top: 159px;
  }

  .sc-sm-mt-160 {
    margin-top: 160px;
  }

  .sc-sm-mt-161 {
    margin-top: 161px;
  }

  .sc-sm-mt-162 {
    margin-top: 162px;
  }

  .sc-sm-mt-163 {
    margin-top: 163px;
  }

  .sc-sm-mt-164 {
    margin-top: 164px;
  }

  .sc-sm-mt-165 {
    margin-top: 165px;
  }

  .sc-sm-mt-166 {
    margin-top: 166px;
  }

  .sc-sm-mt-167 {
    margin-top: 167px;
  }

  .sc-sm-mt-168 {
    margin-top: 168px;
  }

  .sc-sm-mt-169 {
    margin-top: 169px;
  }

  .sc-sm-mt-170 {
    margin-top: 170px;
  }

  .sc-sm-mt-171 {
    margin-top: 171px;
  }

  .sc-sm-mt-172 {
    margin-top: 172px;
  }

  .sc-sm-mt-173 {
    margin-top: 173px;
  }

  .sc-sm-mt-174 {
    margin-top: 174px;
  }

  .sc-sm-mt-175 {
    margin-top: 175px;
  }

  .sc-sm-mt-176 {
    margin-top: 176px;
  }

  .sc-sm-mt-177 {
    margin-top: 177px;
  }

  .sc-sm-mt-178 {
    margin-top: 178px;
  }

  .sc-sm-mt-179 {
    margin-top: 179px;
  }

  .sc-sm-mt-180 {
    margin-top: 180px;
  }

  .sc-sm-mt-181 {
    margin-top: 181px;
  }

  .sc-sm-mt-182 {
    margin-top: 182px;
  }

  .sc-sm-mt-183 {
    margin-top: 183px;
  }

  .sc-sm-mt-184 {
    margin-top: 184px;
  }

  .sc-sm-mt-185 {
    margin-top: 185px;
  }

  .sc-sm-mt-186 {
    margin-top: 186px;
  }

  .sc-sm-mt-187 {
    margin-top: 187px;
  }

  .sc-sm-mt-188 {
    margin-top: 188px;
  }

  .sc-sm-mt-189 {
    margin-top: 189px;
  }

  .sc-sm-mt-190 {
    margin-top: 190px;
  }

  .sc-sm-mt-191 {
    margin-top: 191px;
  }

  .sc-sm-mt-192 {
    margin-top: 192px;
  }

  .sc-sm-mt-193 {
    margin-top: 193px;
  }

  .sc-sm-mt-194 {
    margin-top: 194px;
  }

  .sc-sm-mt-195 {
    margin-top: 195px;
  }

  .sc-sm-mt-196 {
    margin-top: 196px;
  }

  .sc-sm-mt-197 {
    margin-top: 197px;
  }

  .sc-sm-mt-198 {
    margin-top: 198px;
  }

  .sc-sm-mt-199 {
    margin-top: 199px;
  }

  .sc-sm-mt-200 {
    margin-top: 200px;
  }

  .sc-sm-mb-0 {
    margin-bottom: 0;
  }

  .sc-sm-mb-1 {
    margin-bottom: 1px;
  }

  .sc-sm-mb-2 {
    margin-bottom: 2px;
  }

  .sc-sm-mb-3 {
    margin-bottom: 3px;
  }

  .sc-sm-mb-4 {
    margin-bottom: 4px;
  }

  .sc-sm-mb-5 {
    margin-bottom: 5px;
  }

  .sc-sm-mb-6 {
    margin-bottom: 6px;
  }

  .sc-sm-mb-7 {
    margin-bottom: 7px;
  }

  .sc-sm-mb-8 {
    margin-bottom: 8px;
  }

  .sc-sm-mb-9 {
    margin-bottom: 9px;
  }

  .sc-sm-mb-10 {
    margin-bottom: 10px;
  }

  .sc-sm-mb-11 {
    margin-bottom: 11px;
  }

  .sc-sm-mb-12 {
    margin-bottom: 12px;
  }

  .sc-sm-mb-13 {
    margin-bottom: 13px;
  }

  .sc-sm-mb-14 {
    margin-bottom: 14px;
  }

  .sc-sm-mb-15 {
    margin-bottom: 15px;
  }

  .sc-sm-mb-16 {
    margin-bottom: 16px;
  }

  .sc-sm-mb-17 {
    margin-bottom: 17px;
  }

  .sc-sm-mb-18 {
    margin-bottom: 18px;
  }

  .sc-sm-mb-19 {
    margin-bottom: 19px;
  }

  .sc-sm-mb-20 {
    margin-bottom: 20px;
  }

  .sc-sm-mb-21 {
    margin-bottom: 21px;
  }

  .sc-sm-mb-22 {
    margin-bottom: 22px;
  }

  .sc-sm-mb-23 {
    margin-bottom: 23px;
  }

  .sc-sm-mb-24 {
    margin-bottom: 24px;
  }

  .sc-sm-mb-25 {
    margin-bottom: 25px;
  }

  .sc-sm-mb-26 {
    margin-bottom: 26px;
  }

  .sc-sm-mb-27 {
    margin-bottom: 27px;
  }

  .sc-sm-mb-28 {
    margin-bottom: 28px;
  }

  .sc-sm-mb-29 {
    margin-bottom: 29px;
  }

  .sc-sm-mb-30 {
    margin-bottom: 30px;
  }

  .sc-sm-mb-31 {
    margin-bottom: 31px;
  }

  .sc-sm-mb-32 {
    margin-bottom: 32px;
  }

  .sc-sm-mb-33 {
    margin-bottom: 33px;
  }

  .sc-sm-mb-34 {
    margin-bottom: 34px;
  }

  .sc-sm-mb-35 {
    margin-bottom: 35px;
  }

  .sc-sm-mb-36 {
    margin-bottom: 36px;
  }

  .sc-sm-mb-37 {
    margin-bottom: 37px;
  }

  .sc-sm-mb-38 {
    margin-bottom: 38px;
  }

  .sc-sm-mb-39 {
    margin-bottom: 39px;
  }

  .sc-sm-mb-40 {
    margin-bottom: 40px;
  }

  .sc-sm-mb-41 {
    margin-bottom: 41px;
  }

  .sc-sm-mb-42 {
    margin-bottom: 42px;
  }

  .sc-sm-mb-43 {
    margin-bottom: 43px;
  }

  .sc-sm-mb-44 {
    margin-bottom: 44px;
  }

  .sc-sm-mb-45 {
    margin-bottom: 45px;
  }

  .sc-sm-mb-46 {
    margin-bottom: 46px;
  }

  .sc-sm-mb-47 {
    margin-bottom: 47px;
  }

  .sc-sm-mb-48 {
    margin-bottom: 48px;
  }

  .sc-sm-mb-49 {
    margin-bottom: 49px;
  }

  .sc-sm-mb-50 {
    margin-bottom: 50px;
  }

  .sc-sm-mb-51 {
    margin-bottom: 51px;
  }

  .sc-sm-mb-52 {
    margin-bottom: 52px;
  }

  .sc-sm-mb-53 {
    margin-bottom: 53px;
  }

  .sc-sm-mb-54 {
    margin-bottom: 54px;
  }

  .sc-sm-mb-55 {
    margin-bottom: 55px;
  }

  .sc-sm-mb-56 {
    margin-bottom: 56px;
  }

  .sc-sm-mb-57 {
    margin-bottom: 57px;
  }

  .sc-sm-mb-58 {
    margin-bottom: 58px;
  }

  .sc-sm-mb-59 {
    margin-bottom: 59px;
  }

  .sc-sm-mb-60 {
    margin-bottom: 60px;
  }

  .sc-sm-mb-61 {
    margin-bottom: 61px;
  }

  .sc-sm-mb-62 {
    margin-bottom: 62px;
  }

  .sc-sm-mb-63 {
    margin-bottom: 63px;
  }

  .sc-sm-mb-64 {
    margin-bottom: 64px;
  }

  .sc-sm-mb-65 {
    margin-bottom: 65px;
  }

  .sc-sm-mb-66 {
    margin-bottom: 66px;
  }

  .sc-sm-mb-67 {
    margin-bottom: 67px;
  }

  .sc-sm-mb-68 {
    margin-bottom: 68px;
  }

  .sc-sm-mb-69 {
    margin-bottom: 69px;
  }

  .sc-sm-mb-70 {
    margin-bottom: 70px;
  }

  .sc-sm-mb-71 {
    margin-bottom: 71px;
  }

  .sc-sm-mb-72 {
    margin-bottom: 72px;
  }

  .sc-sm-mb-73 {
    margin-bottom: 73px;
  }

  .sc-sm-mb-74 {
    margin-bottom: 74px;
  }

  .sc-sm-mb-75 {
    margin-bottom: 75px;
  }

  .sc-sm-mb-76 {
    margin-bottom: 76px;
  }

  .sc-sm-mb-77 {
    margin-bottom: 77px;
  }

  .sc-sm-mb-78 {
    margin-bottom: 78px;
  }

  .sc-sm-mb-79 {
    margin-bottom: 79px;
  }

  .sc-sm-mb-80 {
    margin-bottom: 80px;
  }

  .sc-sm-mb-81 {
    margin-bottom: 81px;
  }

  .sc-sm-mb-82 {
    margin-bottom: 82px;
  }

  .sc-sm-mb-83 {
    margin-bottom: 83px;
  }

  .sc-sm-mb-84 {
    margin-bottom: 84px;
  }

  .sc-sm-mb-85 {
    margin-bottom: 85px;
  }

  .sc-sm-mb-86 {
    margin-bottom: 86px;
  }

  .sc-sm-mb-87 {
    margin-bottom: 87px;
  }

  .sc-sm-mb-88 {
    margin-bottom: 88px;
  }

  .sc-sm-mb-89 {
    margin-bottom: 89px;
  }

  .sc-sm-mb-90 {
    margin-bottom: 90px;
  }

  .sc-sm-mb-91 {
    margin-bottom: 91px;
  }

  .sc-sm-mb-92 {
    margin-bottom: 92px;
  }

  .sc-sm-mb-93 {
    margin-bottom: 93px;
  }

  .sc-sm-mb-94 {
    margin-bottom: 94px;
  }

  .sc-sm-mb-95 {
    margin-bottom: 95px;
  }

  .sc-sm-mb-96 {
    margin-bottom: 96px;
  }

  .sc-sm-mb-97 {
    margin-bottom: 97px;
  }

  .sc-sm-mb-98 {
    margin-bottom: 98px;
  }

  .sc-sm-mb-99 {
    margin-bottom: 99px;
  }

  .sc-sm-mb-100 {
    margin-bottom: 100px;
  }

  .sc-sm-mb-101 {
    margin-bottom: 101px;
  }

  .sc-sm-mb-102 {
    margin-bottom: 102px;
  }

  .sc-sm-mb-103 {
    margin-bottom: 103px;
  }

  .sc-sm-mb-104 {
    margin-bottom: 104px;
  }

  .sc-sm-mb-105 {
    margin-bottom: 105px;
  }

  .sc-sm-mb-106 {
    margin-bottom: 106px;
  }

  .sc-sm-mb-107 {
    margin-bottom: 107px;
  }

  .sc-sm-mb-108 {
    margin-bottom: 108px;
  }

  .sc-sm-mb-109 {
    margin-bottom: 109px;
  }

  .sc-sm-mb-110 {
    margin-bottom: 110px;
  }

  .sc-sm-mb-111 {
    margin-bottom: 111px;
  }

  .sc-sm-mb-112 {
    margin-bottom: 112px;
  }

  .sc-sm-mb-113 {
    margin-bottom: 113px;
  }

  .sc-sm-mb-114 {
    margin-bottom: 114px;
  }

  .sc-sm-mb-115 {
    margin-bottom: 115px;
  }

  .sc-sm-mb-116 {
    margin-bottom: 116px;
  }

  .sc-sm-mb-117 {
    margin-bottom: 117px;
  }

  .sc-sm-mb-118 {
    margin-bottom: 118px;
  }

  .sc-sm-mb-119 {
    margin-bottom: 119px;
  }

  .sc-sm-mb-120 {
    margin-bottom: 120px;
  }

  .sc-sm-mb-121 {
    margin-bottom: 121px;
  }

  .sc-sm-mb-122 {
    margin-bottom: 122px;
  }

  .sc-sm-mb-123 {
    margin-bottom: 123px;
  }

  .sc-sm-mb-124 {
    margin-bottom: 124px;
  }

  .sc-sm-mb-125 {
    margin-bottom: 125px;
  }

  .sc-sm-mb-126 {
    margin-bottom: 126px;
  }

  .sc-sm-mb-127 {
    margin-bottom: 127px;
  }

  .sc-sm-mb-128 {
    margin-bottom: 128px;
  }

  .sc-sm-mb-129 {
    margin-bottom: 129px;
  }

  .sc-sm-mb-130 {
    margin-bottom: 130px;
  }

  .sc-sm-mb-131 {
    margin-bottom: 131px;
  }

  .sc-sm-mb-132 {
    margin-bottom: 132px;
  }

  .sc-sm-mb-133 {
    margin-bottom: 133px;
  }

  .sc-sm-mb-134 {
    margin-bottom: 134px;
  }

  .sc-sm-mb-135 {
    margin-bottom: 135px;
  }

  .sc-sm-mb-136 {
    margin-bottom: 136px;
  }

  .sc-sm-mb-137 {
    margin-bottom: 137px;
  }

  .sc-sm-mb-138 {
    margin-bottom: 138px;
  }

  .sc-sm-mb-139 {
    margin-bottom: 139px;
  }

  .sc-sm-mb-140 {
    margin-bottom: 140px;
  }

  .sc-sm-mb-141 {
    margin-bottom: 141px;
  }

  .sc-sm-mb-142 {
    margin-bottom: 142px;
  }

  .sc-sm-mb-143 {
    margin-bottom: 143px;
  }

  .sc-sm-mb-144 {
    margin-bottom: 144px;
  }

  .sc-sm-mb-145 {
    margin-bottom: 145px;
  }

  .sc-sm-mb-146 {
    margin-bottom: 146px;
  }

  .sc-sm-mb-147 {
    margin-bottom: 147px;
  }

  .sc-sm-mb-148 {
    margin-bottom: 148px;
  }

  .sc-sm-mb-149 {
    margin-bottom: 149px;
  }

  .sc-sm-mb-150 {
    margin-bottom: 150px;
  }

  .sc-sm-mb-151 {
    margin-bottom: 151px;
  }

  .sc-sm-mb-152 {
    margin-bottom: 152px;
  }

  .sc-sm-mb-153 {
    margin-bottom: 153px;
  }

  .sc-sm-mb-154 {
    margin-bottom: 154px;
  }

  .sc-sm-mb-155 {
    margin-bottom: 155px;
  }

  .sc-sm-mb-156 {
    margin-bottom: 156px;
  }

  .sc-sm-mb-157 {
    margin-bottom: 157px;
  }

  .sc-sm-mb-158 {
    margin-bottom: 158px;
  }

  .sc-sm-mb-159 {
    margin-bottom: 159px;
  }

  .sc-sm-mb-160 {
    margin-bottom: 160px;
  }

  .sc-sm-mb-161 {
    margin-bottom: 161px;
  }

  .sc-sm-mb-162 {
    margin-bottom: 162px;
  }

  .sc-sm-mb-163 {
    margin-bottom: 163px;
  }

  .sc-sm-mb-164 {
    margin-bottom: 164px;
  }

  .sc-sm-mb-165 {
    margin-bottom: 165px;
  }

  .sc-sm-mb-166 {
    margin-bottom: 166px;
  }

  .sc-sm-mb-167 {
    margin-bottom: 167px;
  }

  .sc-sm-mb-168 {
    margin-bottom: 168px;
  }

  .sc-sm-mb-169 {
    margin-bottom: 169px;
  }

  .sc-sm-mb-170 {
    margin-bottom: 170px;
  }

  .sc-sm-mb-171 {
    margin-bottom: 171px;
  }

  .sc-sm-mb-172 {
    margin-bottom: 172px;
  }

  .sc-sm-mb-173 {
    margin-bottom: 173px;
  }

  .sc-sm-mb-174 {
    margin-bottom: 174px;
  }

  .sc-sm-mb-175 {
    margin-bottom: 175px;
  }

  .sc-sm-mb-176 {
    margin-bottom: 176px;
  }

  .sc-sm-mb-177 {
    margin-bottom: 177px;
  }

  .sc-sm-mb-178 {
    margin-bottom: 178px;
  }

  .sc-sm-mb-179 {
    margin-bottom: 179px;
  }

  .sc-sm-mb-180 {
    margin-bottom: 180px;
  }

  .sc-sm-mb-181 {
    margin-bottom: 181px;
  }

  .sc-sm-mb-182 {
    margin-bottom: 182px;
  }

  .sc-sm-mb-183 {
    margin-bottom: 183px;
  }

  .sc-sm-mb-184 {
    margin-bottom: 184px;
  }

  .sc-sm-mb-185 {
    margin-bottom: 185px;
  }

  .sc-sm-mb-186 {
    margin-bottom: 186px;
  }

  .sc-sm-mb-187 {
    margin-bottom: 187px;
  }

  .sc-sm-mb-188 {
    margin-bottom: 188px;
  }

  .sc-sm-mb-189 {
    margin-bottom: 189px;
  }

  .sc-sm-mb-190 {
    margin-bottom: 190px;
  }

  .sc-sm-mb-191 {
    margin-bottom: 191px;
  }

  .sc-sm-mb-192 {
    margin-bottom: 192px;
  }

  .sc-sm-mb-193 {
    margin-bottom: 193px;
  }

  .sc-sm-mb-194 {
    margin-bottom: 194px;
  }

  .sc-sm-mb-195 {
    margin-bottom: 195px;
  }

  .sc-sm-mb-196 {
    margin-bottom: 196px;
  }

  .sc-sm-mb-197 {
    margin-bottom: 197px;
  }

  .sc-sm-mb-198 {
    margin-bottom: 198px;
  }

  .sc-sm-mb-199 {
    margin-bottom: 199px;
  }

  .sc-sm-mb-200 {
    margin-bottom: 200px;
  }

  .sc-sm-mr-0 {
    margin-right: 0;
  }

  .sc-sm-mr-1 {
    margin-right: 1px;
  }

  .sc-sm-mr-2 {
    margin-right: 2px;
  }

  .sc-sm-mr-3 {
    margin-right: 3px;
  }

  .sc-sm-mr-4 {
    margin-right: 4px;
  }

  .sc-sm-mr-5 {
    margin-right: 5px;
  }

  .sc-sm-mr-6 {
    margin-right: 6px;
  }

  .sc-sm-mr-7 {
    margin-right: 7px;
  }

  .sc-sm-mr-8 {
    margin-right: 8px;
  }

  .sc-sm-mr-9 {
    margin-right: 9px;
  }

  .sc-sm-mr-10 {
    margin-right: 10px;
  }

  .sc-sm-mr-11 {
    margin-right: 11px;
  }

  .sc-sm-mr-12 {
    margin-right: 12px;
  }

  .sc-sm-mr-13 {
    margin-right: 13px;
  }

  .sc-sm-mr-14 {
    margin-right: 14px;
  }

  .sc-sm-mr-15 {
    margin-right: 15px;
  }

  .sc-sm-mr-16 {
    margin-right: 16px;
  }

  .sc-sm-mr-17 {
    margin-right: 17px;
  }

  .sc-sm-mr-18 {
    margin-right: 18px;
  }

  .sc-sm-mr-19 {
    margin-right: 19px;
  }

  .sc-sm-mr-20 {
    margin-right: 20px;
  }

  .sc-sm-mr-21 {
    margin-right: 21px;
  }

  .sc-sm-mr-22 {
    margin-right: 22px;
  }

  .sc-sm-mr-23 {
    margin-right: 23px;
  }

  .sc-sm-mr-24 {
    margin-right: 24px;
  }

  .sc-sm-mr-25 {
    margin-right: 25px;
  }

  .sc-sm-mr-26 {
    margin-right: 26px;
  }

  .sc-sm-mr-27 {
    margin-right: 27px;
  }

  .sc-sm-mr-28 {
    margin-right: 28px;
  }

  .sc-sm-mr-29 {
    margin-right: 29px;
  }

  .sc-sm-mr-30 {
    margin-right: 30px;
  }

  .sc-sm-mr-31 {
    margin-right: 31px;
  }

  .sc-sm-mr-32 {
    margin-right: 32px;
  }

  .sc-sm-mr-33 {
    margin-right: 33px;
  }

  .sc-sm-mr-34 {
    margin-right: 34px;
  }

  .sc-sm-mr-35 {
    margin-right: 35px;
  }

  .sc-sm-mr-36 {
    margin-right: 36px;
  }

  .sc-sm-mr-37 {
    margin-right: 37px;
  }

  .sc-sm-mr-38 {
    margin-right: 38px;
  }

  .sc-sm-mr-39 {
    margin-right: 39px;
  }

  .sc-sm-mr-40 {
    margin-right: 40px;
  }

  .sc-sm-mr-41 {
    margin-right: 41px;
  }

  .sc-sm-mr-42 {
    margin-right: 42px;
  }

  .sc-sm-mr-43 {
    margin-right: 43px;
  }

  .sc-sm-mr-44 {
    margin-right: 44px;
  }

  .sc-sm-mr-45 {
    margin-right: 45px;
  }

  .sc-sm-mr-46 {
    margin-right: 46px;
  }

  .sc-sm-mr-47 {
    margin-right: 47px;
  }

  .sc-sm-mr-48 {
    margin-right: 48px;
  }

  .sc-sm-mr-49 {
    margin-right: 49px;
  }

  .sc-sm-mr-50 {
    margin-right: 50px;
  }

  .sc-sm-mr-51 {
    margin-right: 51px;
  }

  .sc-sm-mr-52 {
    margin-right: 52px;
  }

  .sc-sm-mr-53 {
    margin-right: 53px;
  }

  .sc-sm-mr-54 {
    margin-right: 54px;
  }

  .sc-sm-mr-55 {
    margin-right: 55px;
  }

  .sc-sm-mr-56 {
    margin-right: 56px;
  }

  .sc-sm-mr-57 {
    margin-right: 57px;
  }

  .sc-sm-mr-58 {
    margin-right: 58px;
  }

  .sc-sm-mr-59 {
    margin-right: 59px;
  }

  .sc-sm-mr-60 {
    margin-right: 60px;
  }

  .sc-sm-mr-61 {
    margin-right: 61px;
  }

  .sc-sm-mr-62 {
    margin-right: 62px;
  }

  .sc-sm-mr-63 {
    margin-right: 63px;
  }

  .sc-sm-mr-64 {
    margin-right: 64px;
  }

  .sc-sm-mr-65 {
    margin-right: 65px;
  }

  .sc-sm-mr-66 {
    margin-right: 66px;
  }

  .sc-sm-mr-67 {
    margin-right: 67px;
  }

  .sc-sm-mr-68 {
    margin-right: 68px;
  }

  .sc-sm-mr-69 {
    margin-right: 69px;
  }

  .sc-sm-mr-70 {
    margin-right: 70px;
  }

  .sc-sm-mr-71 {
    margin-right: 71px;
  }

  .sc-sm-mr-72 {
    margin-right: 72px;
  }

  .sc-sm-mr-73 {
    margin-right: 73px;
  }

  .sc-sm-mr-74 {
    margin-right: 74px;
  }

  .sc-sm-mr-75 {
    margin-right: 75px;
  }

  .sc-sm-mr-76 {
    margin-right: 76px;
  }

  .sc-sm-mr-77 {
    margin-right: 77px;
  }

  .sc-sm-mr-78 {
    margin-right: 78px;
  }

  .sc-sm-mr-79 {
    margin-right: 79px;
  }

  .sc-sm-mr-80 {
    margin-right: 80px;
  }

  .sc-sm-mr-81 {
    margin-right: 81px;
  }

  .sc-sm-mr-82 {
    margin-right: 82px;
  }

  .sc-sm-mr-83 {
    margin-right: 83px;
  }

  .sc-sm-mr-84 {
    margin-right: 84px;
  }

  .sc-sm-mr-85 {
    margin-right: 85px;
  }

  .sc-sm-mr-86 {
    margin-right: 86px;
  }

  .sc-sm-mr-87 {
    margin-right: 87px;
  }

  .sc-sm-mr-88 {
    margin-right: 88px;
  }

  .sc-sm-mr-89 {
    margin-right: 89px;
  }

  .sc-sm-mr-90 {
    margin-right: 90px;
  }

  .sc-sm-mr-91 {
    margin-right: 91px;
  }

  .sc-sm-mr-92 {
    margin-right: 92px;
  }

  .sc-sm-mr-93 {
    margin-right: 93px;
  }

  .sc-sm-mr-94 {
    margin-right: 94px;
  }

  .sc-sm-mr-95 {
    margin-right: 95px;
  }

  .sc-sm-mr-96 {
    margin-right: 96px;
  }

  .sc-sm-mr-97 {
    margin-right: 97px;
  }

  .sc-sm-mr-98 {
    margin-right: 98px;
  }

  .sc-sm-mr-99 {
    margin-right: 99px;
  }

  .sc-sm-mr-100 {
    margin-right: 100px;
  }

  .sc-sm-ml-0 {
    margin-left: 0;
  }

  .sc-sm-ml-1 {
    margin-left: 1px;
  }

  .sc-sm-ml-2 {
    margin-left: 2px;
  }

  .sc-sm-ml-3 {
    margin-left: 3px;
  }

  .sc-sm-ml-4 {
    margin-left: 4px;
  }

  .sc-sm-ml-5 {
    margin-left: 5px;
  }

  .sc-sm-ml-6 {
    margin-left: 6px;
  }

  .sc-sm-ml-7 {
    margin-left: 7px;
  }

  .sc-sm-ml-8 {
    margin-left: 8px;
  }

  .sc-sm-ml-9 {
    margin-left: 9px;
  }

  .sc-sm-ml-10 {
    margin-left: 10px;
  }

  .sc-sm-ml-11 {
    margin-left: 11px;
  }

  .sc-sm-ml-12 {
    margin-left: 12px;
  }

  .sc-sm-ml-13 {
    margin-left: 13px;
  }

  .sc-sm-ml-14 {
    margin-left: 14px;
  }

  .sc-sm-ml-15 {
    margin-left: 15px;
  }

  .sc-sm-ml-16 {
    margin-left: 16px;
  }

  .sc-sm-ml-17 {
    margin-left: 17px;
  }

  .sc-sm-ml-18 {
    margin-left: 18px;
  }

  .sc-sm-ml-19 {
    margin-left: 19px;
  }

  .sc-sm-ml-20 {
    margin-left: 20px;
  }

  .sc-sm-ml-21 {
    margin-left: 21px;
  }

  .sc-sm-ml-22 {
    margin-left: 22px;
  }

  .sc-sm-ml-23 {
    margin-left: 23px;
  }

  .sc-sm-ml-24 {
    margin-left: 24px;
  }

  .sc-sm-ml-25 {
    margin-left: 25px;
  }

  .sc-sm-ml-26 {
    margin-left: 26px;
  }

  .sc-sm-ml-27 {
    margin-left: 27px;
  }

  .sc-sm-ml-28 {
    margin-left: 28px;
  }

  .sc-sm-ml-29 {
    margin-left: 29px;
  }

  .sc-sm-ml-30 {
    margin-left: 30px;
  }

  .sc-sm-ml-31 {
    margin-left: 31px;
  }

  .sc-sm-ml-32 {
    margin-left: 32px;
  }

  .sc-sm-ml-33 {
    margin-left: 33px;
  }

  .sc-sm-ml-34 {
    margin-left: 34px;
  }

  .sc-sm-ml-35 {
    margin-left: 35px;
  }

  .sc-sm-ml-36 {
    margin-left: 36px;
  }

  .sc-sm-ml-37 {
    margin-left: 37px;
  }

  .sc-sm-ml-38 {
    margin-left: 38px;
  }

  .sc-sm-ml-39 {
    margin-left: 39px;
  }

  .sc-sm-ml-40 {
    margin-left: 40px;
  }

  .sc-sm-ml-41 {
    margin-left: 41px;
  }

  .sc-sm-ml-42 {
    margin-left: 42px;
  }

  .sc-sm-ml-43 {
    margin-left: 43px;
  }

  .sc-sm-ml-44 {
    margin-left: 44px;
  }

  .sc-sm-ml-45 {
    margin-left: 45px;
  }

  .sc-sm-ml-46 {
    margin-left: 46px;
  }

  .sc-sm-ml-47 {
    margin-left: 47px;
  }

  .sc-sm-ml-48 {
    margin-left: 48px;
  }

  .sc-sm-ml-49 {
    margin-left: 49px;
  }

  .sc-sm-ml-50 {
    margin-left: 50px;
  }

  .sc-sm-ml-51 {
    margin-left: 51px;
  }

  .sc-sm-ml-52 {
    margin-left: 52px;
  }

  .sc-sm-ml-53 {
    margin-left: 53px;
  }

  .sc-sm-ml-54 {
    margin-left: 54px;
  }

  .sc-sm-ml-55 {
    margin-left: 55px;
  }

  .sc-sm-ml-56 {
    margin-left: 56px;
  }

  .sc-sm-ml-57 {
    margin-left: 57px;
  }

  .sc-sm-ml-58 {
    margin-left: 58px;
  }

  .sc-sm-ml-59 {
    margin-left: 59px;
  }

  .sc-sm-ml-60 {
    margin-left: 60px;
  }

  .sc-sm-ml-61 {
    margin-left: 61px;
  }

  .sc-sm-ml-62 {
    margin-left: 62px;
  }

  .sc-sm-ml-63 {
    margin-left: 63px;
  }

  .sc-sm-ml-64 {
    margin-left: 64px;
  }

  .sc-sm-ml-65 {
    margin-left: 65px;
  }

  .sc-sm-ml-66 {
    margin-left: 66px;
  }

  .sc-sm-ml-67 {
    margin-left: 67px;
  }

  .sc-sm-ml-68 {
    margin-left: 68px;
  }

  .sc-sm-ml-69 {
    margin-left: 69px;
  }

  .sc-sm-ml-70 {
    margin-left: 70px;
  }

  .sc-sm-ml-71 {
    margin-left: 71px;
  }

  .sc-sm-ml-72 {
    margin-left: 72px;
  }

  .sc-sm-ml-73 {
    margin-left: 73px;
  }

  .sc-sm-ml-74 {
    margin-left: 74px;
  }

  .sc-sm-ml-75 {
    margin-left: 75px;
  }

  .sc-sm-ml-76 {
    margin-left: 76px;
  }

  .sc-sm-ml-77 {
    margin-left: 77px;
  }

  .sc-sm-ml-78 {
    margin-left: 78px;
  }

  .sc-sm-ml-79 {
    margin-left: 79px;
  }

  .sc-sm-ml-80 {
    margin-left: 80px;
  }

  .sc-sm-ml-81 {
    margin-left: 81px;
  }

  .sc-sm-ml-82 {
    margin-left: 82px;
  }

  .sc-sm-ml-83 {
    margin-left: 83px;
  }

  .sc-sm-ml-84 {
    margin-left: 84px;
  }

  .sc-sm-ml-85 {
    margin-left: 85px;
  }

  .sc-sm-ml-86 {
    margin-left: 86px;
  }

  .sc-sm-ml-87 {
    margin-left: 87px;
  }

  .sc-sm-ml-88 {
    margin-left: 88px;
  }

  .sc-sm-ml-89 {
    margin-left: 89px;
  }

  .sc-sm-ml-90 {
    margin-left: 90px;
  }

  .sc-sm-ml-91 {
    margin-left: 91px;
  }

  .sc-sm-ml-92 {
    margin-left: 92px;
  }

  .sc-sm-ml-93 {
    margin-left: 93px;
  }

  .sc-sm-ml-94 {
    margin-left: 94px;
  }

  .sc-sm-ml-95 {
    margin-left: 95px;
  }

  .sc-sm-ml-96 {
    margin-left: 96px;
  }

  .sc-sm-ml-97 {
    margin-left: 97px;
  }

  .sc-sm-ml-98 {
    margin-left: 98px;
  }

  .sc-sm-ml-99 {
    margin-left: 99px;
  }

  .sc-sm-ml-100 {
    margin-left: 100px;
  }

  .sc-sm-mt--1 {
    margin-top: -1px;
  }

  .sc-sm-mt--2 {
    margin-top: -2px;
  }

  .sc-sm-mt--3 {
    margin-top: -3px;
  }

  .sc-sm-mt--4 {
    margin-top: -4px;
  }

  .sc-sm-mt--5 {
    margin-top: -5px;
  }

  .sc-sm-mt--6 {
    margin-top: -6px;
  }

  .sc-sm-mt--7 {
    margin-top: -7px;
  }

  .sc-sm-mt--8 {
    margin-top: -8px;
  }

  .sc-sm-mt--9 {
    margin-top: -9px;
  }

  .sc-sm-mt--10 {
    margin-top: -10px;
  }

  .sc-sm-mt--11 {
    margin-top: -11px;
  }

  .sc-sm-mt--12 {
    margin-top: -12px;
  }

  .sc-sm-mt--13 {
    margin-top: -13px;
  }

  .sc-sm-mt--14 {
    margin-top: -14px;
  }

  .sc-sm-mt--15 {
    margin-top: -15px;
  }

  .sc-sm-mt--16 {
    margin-top: -16px;
  }

  .sc-sm-mt--17 {
    margin-top: -17px;
  }

  .sc-sm-mt--18 {
    margin-top: -18px;
  }

  .sc-sm-mt--19 {
    margin-top: -19px;
  }

  .sc-sm-mt--20 {
    margin-top: -20px;
  }

  .sc-sm-mt--21 {
    margin-top: -21px;
  }

  .sc-sm-mt--22 {
    margin-top: -22px;
  }

  .sc-sm-mt--23 {
    margin-top: -23px;
  }

  .sc-sm-mt--24 {
    margin-top: -24px;
  }

  .sc-sm-mt--25 {
    margin-top: -25px;
  }

  .sc-sm-mt--26 {
    margin-top: -26px;
  }

  .sc-sm-mt--27 {
    margin-top: -27px;
  }

  .sc-sm-mt--28 {
    margin-top: -28px;
  }

  .sc-sm-mt--29 {
    margin-top: -29px;
  }

  .sc-sm-mt--30 {
    margin-top: -30px;
  }

  .sc-sm-mt--31 {
    margin-top: -31px;
  }

  .sc-sm-mt--32 {
    margin-top: -32px;
  }

  .sc-sm-mt--33 {
    margin-top: -33px;
  }

  .sc-sm-mt--34 {
    margin-top: -34px;
  }

  .sc-sm-mt--35 {
    margin-top: -35px;
  }

  .sc-sm-mt--36 {
    margin-top: -36px;
  }

  .sc-sm-mt--37 {
    margin-top: -37px;
  }

  .sc-sm-mt--38 {
    margin-top: -38px;
  }

  .sc-sm-mt--39 {
    margin-top: -39px;
  }

  .sc-sm-mt--40 {
    margin-top: -40px;
  }

  .sc-sm-mt--41 {
    margin-top: -41px;
  }

  .sc-sm-mt--42 {
    margin-top: -42px;
  }

  .sc-sm-mt--43 {
    margin-top: -43px;
  }

  .sc-sm-mt--44 {
    margin-top: -44px;
  }

  .sc-sm-mt--45 {
    margin-top: -45px;
  }

  .sc-sm-mt--46 {
    margin-top: -46px;
  }

  .sc-sm-mt--47 {
    margin-top: -47px;
  }

  .sc-sm-mt--48 {
    margin-top: -48px;
  }

  .sc-sm-mt--49 {
    margin-top: -49px;
  }

  .sc-sm-mt--50 {
    margin-top: -50px;
  }

  .sc-sm-mt--51 {
    margin-top: -51px;
  }

  .sc-sm-mt--52 {
    margin-top: -52px;
  }

  .sc-sm-mt--53 {
    margin-top: -53px;
  }

  .sc-sm-mt--54 {
    margin-top: -54px;
  }

  .sc-sm-mt--55 {
    margin-top: -55px;
  }

  .sc-sm-mt--56 {
    margin-top: -56px;
  }

  .sc-sm-mt--57 {
    margin-top: -57px;
  }

  .sc-sm-mt--58 {
    margin-top: -58px;
  }

  .sc-sm-mt--59 {
    margin-top: -59px;
  }

  .sc-sm-mt--60 {
    margin-top: -60px;
  }

  .sc-sm-mt--61 {
    margin-top: -61px;
  }

  .sc-sm-mt--62 {
    margin-top: -62px;
  }

  .sc-sm-mt--63 {
    margin-top: -63px;
  }

  .sc-sm-mt--64 {
    margin-top: -64px;
  }

  .sc-sm-mt--65 {
    margin-top: -65px;
  }

  .sc-sm-mt--66 {
    margin-top: -66px;
  }

  .sc-sm-mt--67 {
    margin-top: -67px;
  }

  .sc-sm-mt--68 {
    margin-top: -68px;
  }

  .sc-sm-mt--69 {
    margin-top: -69px;
  }

  .sc-sm-mt--70 {
    margin-top: -70px;
  }

  .sc-sm-mt--71 {
    margin-top: -71px;
  }

  .sc-sm-mt--72 {
    margin-top: -72px;
  }

  .sc-sm-mt--73 {
    margin-top: -73px;
  }

  .sc-sm-mt--74 {
    margin-top: -74px;
  }

  .sc-sm-mt--75 {
    margin-top: -75px;
  }

  .sc-sm-mt--76 {
    margin-top: -76px;
  }

  .sc-sm-mt--77 {
    margin-top: -77px;
  }

  .sc-sm-mt--78 {
    margin-top: -78px;
  }

  .sc-sm-mt--79 {
    margin-top: -79px;
  }

  .sc-sm-mt--80 {
    margin-top: -80px;
  }

  .sc-sm-mt--81 {
    margin-top: -81px;
  }

  .sc-sm-mt--82 {
    margin-top: -82px;
  }

  .sc-sm-mt--83 {
    margin-top: -83px;
  }

  .sc-sm-mt--84 {
    margin-top: -84px;
  }

  .sc-sm-mt--85 {
    margin-top: -85px;
  }

  .sc-sm-mt--86 {
    margin-top: -86px;
  }

  .sc-sm-mt--87 {
    margin-top: -87px;
  }

  .sc-sm-mt--88 {
    margin-top: -88px;
  }

  .sc-sm-mt--89 {
    margin-top: -89px;
  }

  .sc-sm-mt--90 {
    margin-top: -90px;
  }

  .sc-sm-mt--91 {
    margin-top: -91px;
  }

  .sc-sm-mt--92 {
    margin-top: -92px;
  }

  .sc-sm-mt--93 {
    margin-top: -93px;
  }

  .sc-sm-mt--94 {
    margin-top: -94px;
  }

  .sc-sm-mt--95 {
    margin-top: -95px;
  }

  .sc-sm-mt--96 {
    margin-top: -96px;
  }

  .sc-sm-mt--97 {
    margin-top: -97px;
  }

  .sc-sm-mt--98 {
    margin-top: -98px;
  }

  .sc-sm-mt--99 {
    margin-top: -99px;
  }

  .sc-sm-mt--100 {
    margin-top: -100px;
  }

  .sc-sm-mt--101 {
    margin-top: -101px;
  }

  .sc-sm-mt--102 {
    margin-top: -102px;
  }

  .sc-sm-mt--103 {
    margin-top: -103px;
  }

  .sc-sm-mt--104 {
    margin-top: -104px;
  }

  .sc-sm-mt--105 {
    margin-top: -105px;
  }

  .sc-sm-mt--106 {
    margin-top: -106px;
  }

  .sc-sm-mt--107 {
    margin-top: -107px;
  }

  .sc-sm-mt--108 {
    margin-top: -108px;
  }

  .sc-sm-mt--109 {
    margin-top: -109px;
  }

  .sc-sm-mt--110 {
    margin-top: -110px;
  }

  .sc-sm-mt--111 {
    margin-top: -111px;
  }

  .sc-sm-mt--112 {
    margin-top: -112px;
  }

  .sc-sm-mt--113 {
    margin-top: -113px;
  }

  .sc-sm-mt--114 {
    margin-top: -114px;
  }

  .sc-sm-mt--115 {
    margin-top: -115px;
  }

  .sc-sm-mt--116 {
    margin-top: -116px;
  }

  .sc-sm-mt--117 {
    margin-top: -117px;
  }

  .sc-sm-mt--118 {
    margin-top: -118px;
  }

  .sc-sm-mt--119 {
    margin-top: -119px;
  }

  .sc-sm-mt--120 {
    margin-top: -120px;
  }

  .sc-sm-mt--121 {
    margin-top: -121px;
  }

  .sc-sm-mt--122 {
    margin-top: -122px;
  }

  .sc-sm-mt--123 {
    margin-top: -123px;
  }

  .sc-sm-mt--124 {
    margin-top: -124px;
  }

  .sc-sm-mt--125 {
    margin-top: -125px;
  }

  .sc-sm-mt--126 {
    margin-top: -126px;
  }

  .sc-sm-mt--127 {
    margin-top: -127px;
  }

  .sc-sm-mt--128 {
    margin-top: -128px;
  }

  .sc-sm-mt--129 {
    margin-top: -129px;
  }

  .sc-sm-mt--130 {
    margin-top: -130px;
  }

  .sc-sm-mt--131 {
    margin-top: -131px;
  }

  .sc-sm-mt--132 {
    margin-top: -132px;
  }

  .sc-sm-mt--133 {
    margin-top: -133px;
  }

  .sc-sm-mt--134 {
    margin-top: -134px;
  }

  .sc-sm-mt--135 {
    margin-top: -135px;
  }

  .sc-sm-mt--136 {
    margin-top: -136px;
  }

  .sc-sm-mt--137 {
    margin-top: -137px;
  }

  .sc-sm-mt--138 {
    margin-top: -138px;
  }

  .sc-sm-mt--139 {
    margin-top: -139px;
  }

  .sc-sm-mt--140 {
    margin-top: -140px;
  }

  .sc-sm-mt--141 {
    margin-top: -141px;
  }

  .sc-sm-mt--142 {
    margin-top: -142px;
  }

  .sc-sm-mt--143 {
    margin-top: -143px;
  }

  .sc-sm-mt--144 {
    margin-top: -144px;
  }

  .sc-sm-mt--145 {
    margin-top: -145px;
  }

  .sc-sm-mt--146 {
    margin-top: -146px;
  }

  .sc-sm-mt--147 {
    margin-top: -147px;
  }

  .sc-sm-mt--148 {
    margin-top: -148px;
  }

  .sc-sm-mt--149 {
    margin-top: -149px;
  }

  .sc-sm-mt--150 {
    margin-top: -150px;
  }

  .sc-sm-mt--151 {
    margin-top: -151px;
  }

  .sc-sm-mt--152 {
    margin-top: -152px;
  }

  .sc-sm-mt--153 {
    margin-top: -153px;
  }

  .sc-sm-mt--154 {
    margin-top: -154px;
  }

  .sc-sm-mt--155 {
    margin-top: -155px;
  }

  .sc-sm-mt--156 {
    margin-top: -156px;
  }

  .sc-sm-mt--157 {
    margin-top: -157px;
  }

  .sc-sm-mt--158 {
    margin-top: -158px;
  }

  .sc-sm-mt--159 {
    margin-top: -159px;
  }

  .sc-sm-mt--160 {
    margin-top: -160px;
  }

  .sc-sm-mt--161 {
    margin-top: -161px;
  }

  .sc-sm-mt--162 {
    margin-top: -162px;
  }

  .sc-sm-mt--163 {
    margin-top: -163px;
  }

  .sc-sm-mt--164 {
    margin-top: -164px;
  }

  .sc-sm-mt--165 {
    margin-top: -165px;
  }

  .sc-sm-mt--166 {
    margin-top: -166px;
  }

  .sc-sm-mt--167 {
    margin-top: -167px;
  }

  .sc-sm-mt--168 {
    margin-top: -168px;
  }

  .sc-sm-mt--169 {
    margin-top: -169px;
  }

  .sc-sm-mt--170 {
    margin-top: -170px;
  }

  .sc-sm-mt--171 {
    margin-top: -171px;
  }

  .sc-sm-mt--172 {
    margin-top: -172px;
  }

  .sc-sm-mt--173 {
    margin-top: -173px;
  }

  .sc-sm-mt--174 {
    margin-top: -174px;
  }

  .sc-sm-mt--175 {
    margin-top: -175px;
  }

  .sc-sm-mt--176 {
    margin-top: -176px;
  }

  .sc-sm-mt--177 {
    margin-top: -177px;
  }

  .sc-sm-mt--178 {
    margin-top: -178px;
  }

  .sc-sm-mt--179 {
    margin-top: -179px;
  }

  .sc-sm-mt--180 {
    margin-top: -180px;
  }

  .sc-sm-mt--181 {
    margin-top: -181px;
  }

  .sc-sm-mt--182 {
    margin-top: -182px;
  }

  .sc-sm-mt--183 {
    margin-top: -183px;
  }

  .sc-sm-mt--184 {
    margin-top: -184px;
  }

  .sc-sm-mt--185 {
    margin-top: -185px;
  }

  .sc-sm-mt--186 {
    margin-top: -186px;
  }

  .sc-sm-mt--187 {
    margin-top: -187px;
  }

  .sc-sm-mt--188 {
    margin-top: -188px;
  }

  .sc-sm-mt--189 {
    margin-top: -189px;
  }

  .sc-sm-mt--190 {
    margin-top: -190px;
  }

  .sc-sm-mt--191 {
    margin-top: -191px;
  }

  .sc-sm-mt--192 {
    margin-top: -192px;
  }

  .sc-sm-mt--193 {
    margin-top: -193px;
  }

  .sc-sm-mt--194 {
    margin-top: -194px;
  }

  .sc-sm-mt--195 {
    margin-top: -195px;
  }

  .sc-sm-mt--196 {
    margin-top: -196px;
  }

  .sc-sm-mt--197 {
    margin-top: -197px;
  }

  .sc-sm-mt--198 {
    margin-top: -198px;
  }

  .sc-sm-mt--199 {
    margin-top: -199px;
  }

  .sc-sm-mt--200 {
    margin-top: -200px;
  }

  .sc-sm-mb--1 {
    margin-bottom: -1px;
  }

  .sc-sm-mb--2 {
    margin-bottom: -2px;
  }

  .sc-sm-mb--3 {
    margin-bottom: -3px;
  }

  .sc-sm-mb--4 {
    margin-bottom: -4px;
  }

  .sc-sm-mb--5 {
    margin-bottom: -5px;
  }

  .sc-sm-mb--6 {
    margin-bottom: -6px;
  }

  .sc-sm-mb--7 {
    margin-bottom: -7px;
  }

  .sc-sm-mb--8 {
    margin-bottom: -8px;
  }

  .sc-sm-mb--9 {
    margin-bottom: -9px;
  }

  .sc-sm-mb--10 {
    margin-bottom: -10px;
  }

  .sc-sm-mb--11 {
    margin-bottom: -11px;
  }

  .sc-sm-mb--12 {
    margin-bottom: -12px;
  }

  .sc-sm-mb--13 {
    margin-bottom: -13px;
  }

  .sc-sm-mb--14 {
    margin-bottom: -14px;
  }

  .sc-sm-mb--15 {
    margin-bottom: -15px;
  }

  .sc-sm-mb--16 {
    margin-bottom: -16px;
  }

  .sc-sm-mb--17 {
    margin-bottom: -17px;
  }

  .sc-sm-mb--18 {
    margin-bottom: -18px;
  }

  .sc-sm-mb--19 {
    margin-bottom: -19px;
  }

  .sc-sm-mb--20 {
    margin-bottom: -20px;
  }

  .sc-sm-mb--21 {
    margin-bottom: -21px;
  }

  .sc-sm-mb--22 {
    margin-bottom: -22px;
  }

  .sc-sm-mb--23 {
    margin-bottom: -23px;
  }

  .sc-sm-mb--24 {
    margin-bottom: -24px;
  }

  .sc-sm-mb--25 {
    margin-bottom: -25px;
  }

  .sc-sm-mb--26 {
    margin-bottom: -26px;
  }

  .sc-sm-mb--27 {
    margin-bottom: -27px;
  }

  .sc-sm-mb--28 {
    margin-bottom: -28px;
  }

  .sc-sm-mb--29 {
    margin-bottom: -29px;
  }

  .sc-sm-mb--30 {
    margin-bottom: -30px;
  }

  .sc-sm-mb--31 {
    margin-bottom: -31px;
  }

  .sc-sm-mb--32 {
    margin-bottom: -32px;
  }

  .sc-sm-mb--33 {
    margin-bottom: -33px;
  }

  .sc-sm-mb--34 {
    margin-bottom: -34px;
  }

  .sc-sm-mb--35 {
    margin-bottom: -35px;
  }

  .sc-sm-mb--36 {
    margin-bottom: -36px;
  }

  .sc-sm-mb--37 {
    margin-bottom: -37px;
  }

  .sc-sm-mb--38 {
    margin-bottom: -38px;
  }

  .sc-sm-mb--39 {
    margin-bottom: -39px;
  }

  .sc-sm-mb--40 {
    margin-bottom: -40px;
  }

  .sc-sm-mb--41 {
    margin-bottom: -41px;
  }

  .sc-sm-mb--42 {
    margin-bottom: -42px;
  }

  .sc-sm-mb--43 {
    margin-bottom: -43px;
  }

  .sc-sm-mb--44 {
    margin-bottom: -44px;
  }

  .sc-sm-mb--45 {
    margin-bottom: -45px;
  }

  .sc-sm-mb--46 {
    margin-bottom: -46px;
  }

  .sc-sm-mb--47 {
    margin-bottom: -47px;
  }

  .sc-sm-mb--48 {
    margin-bottom: -48px;
  }

  .sc-sm-mb--49 {
    margin-bottom: -49px;
  }

  .sc-sm-mb--50 {
    margin-bottom: -50px;
  }

  .sc-sm-mb--51 {
    margin-bottom: -51px;
  }

  .sc-sm-mb--52 {
    margin-bottom: -52px;
  }

  .sc-sm-mb--53 {
    margin-bottom: -53px;
  }

  .sc-sm-mb--54 {
    margin-bottom: -54px;
  }

  .sc-sm-mb--55 {
    margin-bottom: -55px;
  }

  .sc-sm-mb--56 {
    margin-bottom: -56px;
  }

  .sc-sm-mb--57 {
    margin-bottom: -57px;
  }

  .sc-sm-mb--58 {
    margin-bottom: -58px;
  }

  .sc-sm-mb--59 {
    margin-bottom: -59px;
  }

  .sc-sm-mb--60 {
    margin-bottom: -60px;
  }

  .sc-sm-mb--61 {
    margin-bottom: -61px;
  }

  .sc-sm-mb--62 {
    margin-bottom: -62px;
  }

  .sc-sm-mb--63 {
    margin-bottom: -63px;
  }

  .sc-sm-mb--64 {
    margin-bottom: -64px;
  }

  .sc-sm-mb--65 {
    margin-bottom: -65px;
  }

  .sc-sm-mb--66 {
    margin-bottom: -66px;
  }

  .sc-sm-mb--67 {
    margin-bottom: -67px;
  }

  .sc-sm-mb--68 {
    margin-bottom: -68px;
  }

  .sc-sm-mb--69 {
    margin-bottom: -69px;
  }

  .sc-sm-mb--70 {
    margin-bottom: -70px;
  }

  .sc-sm-mb--71 {
    margin-bottom: -71px;
  }

  .sc-sm-mb--72 {
    margin-bottom: -72px;
  }

  .sc-sm-mb--73 {
    margin-bottom: -73px;
  }

  .sc-sm-mb--74 {
    margin-bottom: -74px;
  }

  .sc-sm-mb--75 {
    margin-bottom: -75px;
  }

  .sc-sm-mb--76 {
    margin-bottom: -76px;
  }

  .sc-sm-mb--77 {
    margin-bottom: -77px;
  }

  .sc-sm-mb--78 {
    margin-bottom: -78px;
  }

  .sc-sm-mb--79 {
    margin-bottom: -79px;
  }

  .sc-sm-mb--80 {
    margin-bottom: -80px;
  }

  .sc-sm-mb--81 {
    margin-bottom: -81px;
  }

  .sc-sm-mb--82 {
    margin-bottom: -82px;
  }

  .sc-sm-mb--83 {
    margin-bottom: -83px;
  }

  .sc-sm-mb--84 {
    margin-bottom: -84px;
  }

  .sc-sm-mb--85 {
    margin-bottom: -85px;
  }

  .sc-sm-mb--86 {
    margin-bottom: -86px;
  }

  .sc-sm-mb--87 {
    margin-bottom: -87px;
  }

  .sc-sm-mb--88 {
    margin-bottom: -88px;
  }

  .sc-sm-mb--89 {
    margin-bottom: -89px;
  }

  .sc-sm-mb--90 {
    margin-bottom: -90px;
  }

  .sc-sm-mb--91 {
    margin-bottom: -91px;
  }

  .sc-sm-mb--92 {
    margin-bottom: -92px;
  }

  .sc-sm-mb--93 {
    margin-bottom: -93px;
  }

  .sc-sm-mb--94 {
    margin-bottom: -94px;
  }

  .sc-sm-mb--95 {
    margin-bottom: -95px;
  }

  .sc-sm-mb--96 {
    margin-bottom: -96px;
  }

  .sc-sm-mb--97 {
    margin-bottom: -97px;
  }

  .sc-sm-mb--98 {
    margin-bottom: -98px;
  }

  .sc-sm-mb--99 {
    margin-bottom: -99px;
  }

  .sc-sm-mb--100 {
    margin-bottom: -100px;
  }

  .sc-sm-mb--101 {
    margin-bottom: -101px;
  }

  .sc-sm-mb--102 {
    margin-bottom: -102px;
  }

  .sc-sm-mb--103 {
    margin-bottom: -103px;
  }

  .sc-sm-mb--104 {
    margin-bottom: -104px;
  }

  .sc-sm-mb--105 {
    margin-bottom: -105px;
  }

  .sc-sm-mb--106 {
    margin-bottom: -106px;
  }

  .sc-sm-mb--107 {
    margin-bottom: -107px;
  }

  .sc-sm-mb--108 {
    margin-bottom: -108px;
  }

  .sc-sm-mb--109 {
    margin-bottom: -109px;
  }

  .sc-sm-mb--110 {
    margin-bottom: -110px;
  }

  .sc-sm-mb--111 {
    margin-bottom: -111px;
  }

  .sc-sm-mb--112 {
    margin-bottom: -112px;
  }

  .sc-sm-mb--113 {
    margin-bottom: -113px;
  }

  .sc-sm-mb--114 {
    margin-bottom: -114px;
  }

  .sc-sm-mb--115 {
    margin-bottom: -115px;
  }

  .sc-sm-mb--116 {
    margin-bottom: -116px;
  }

  .sc-sm-mb--117 {
    margin-bottom: -117px;
  }

  .sc-sm-mb--118 {
    margin-bottom: -118px;
  }

  .sc-sm-mb--119 {
    margin-bottom: -119px;
  }

  .sc-sm-mb--120 {
    margin-bottom: -120px;
  }

  .sc-sm-mb--121 {
    margin-bottom: -121px;
  }

  .sc-sm-mb--122 {
    margin-bottom: -122px;
  }

  .sc-sm-mb--123 {
    margin-bottom: -123px;
  }

  .sc-sm-mb--124 {
    margin-bottom: -124px;
  }

  .sc-sm-mb--125 {
    margin-bottom: -125px;
  }

  .sc-sm-mb--126 {
    margin-bottom: -126px;
  }

  .sc-sm-mb--127 {
    margin-bottom: -127px;
  }

  .sc-sm-mb--128 {
    margin-bottom: -128px;
  }

  .sc-sm-mb--129 {
    margin-bottom: -129px;
  }

  .sc-sm-mb--130 {
    margin-bottom: -130px;
  }

  .sc-sm-mb--131 {
    margin-bottom: -131px;
  }

  .sc-sm-mb--132 {
    margin-bottom: -132px;
  }

  .sc-sm-mb--133 {
    margin-bottom: -133px;
  }

  .sc-sm-mb--134 {
    margin-bottom: -134px;
  }

  .sc-sm-mb--135 {
    margin-bottom: -135px;
  }

  .sc-sm-mb--136 {
    margin-bottom: -136px;
  }

  .sc-sm-mb--137 {
    margin-bottom: -137px;
  }

  .sc-sm-mb--138 {
    margin-bottom: -138px;
  }

  .sc-sm-mb--139 {
    margin-bottom: -139px;
  }

  .sc-sm-mb--140 {
    margin-bottom: -140px;
  }

  .sc-sm-mb--141 {
    margin-bottom: -141px;
  }

  .sc-sm-mb--142 {
    margin-bottom: -142px;
  }

  .sc-sm-mb--143 {
    margin-bottom: -143px;
  }

  .sc-sm-mb--144 {
    margin-bottom: -144px;
  }

  .sc-sm-mb--145 {
    margin-bottom: -145px;
  }

  .sc-sm-mb--146 {
    margin-bottom: -146px;
  }

  .sc-sm-mb--147 {
    margin-bottom: -147px;
  }

  .sc-sm-mb--148 {
    margin-bottom: -148px;
  }

  .sc-sm-mb--149 {
    margin-bottom: -149px;
  }

  .sc-sm-mb--150 {
    margin-bottom: -150px;
  }

  .sc-sm-mb--151 {
    margin-bottom: -151px;
  }

  .sc-sm-mb--152 {
    margin-bottom: -152px;
  }

  .sc-sm-mb--153 {
    margin-bottom: -153px;
  }

  .sc-sm-mb--154 {
    margin-bottom: -154px;
  }

  .sc-sm-mb--155 {
    margin-bottom: -155px;
  }

  .sc-sm-mb--156 {
    margin-bottom: -156px;
  }

  .sc-sm-mb--157 {
    margin-bottom: -157px;
  }

  .sc-sm-mb--158 {
    margin-bottom: -158px;
  }

  .sc-sm-mb--159 {
    margin-bottom: -159px;
  }

  .sc-sm-mb--160 {
    margin-bottom: -160px;
  }

  .sc-sm-mb--161 {
    margin-bottom: -161px;
  }

  .sc-sm-mb--162 {
    margin-bottom: -162px;
  }

  .sc-sm-mb--163 {
    margin-bottom: -163px;
  }

  .sc-sm-mb--164 {
    margin-bottom: -164px;
  }

  .sc-sm-mb--165 {
    margin-bottom: -165px;
  }

  .sc-sm-mb--166 {
    margin-bottom: -166px;
  }

  .sc-sm-mb--167 {
    margin-bottom: -167px;
  }

  .sc-sm-mb--168 {
    margin-bottom: -168px;
  }

  .sc-sm-mb--169 {
    margin-bottom: -169px;
  }

  .sc-sm-mb--170 {
    margin-bottom: -170px;
  }

  .sc-sm-mb--171 {
    margin-bottom: -171px;
  }

  .sc-sm-mb--172 {
    margin-bottom: -172px;
  }

  .sc-sm-mb--173 {
    margin-bottom: -173px;
  }

  .sc-sm-mb--174 {
    margin-bottom: -174px;
  }

  .sc-sm-mb--175 {
    margin-bottom: -175px;
  }

  .sc-sm-mb--176 {
    margin-bottom: -176px;
  }

  .sc-sm-mb--177 {
    margin-bottom: -177px;
  }

  .sc-sm-mb--178 {
    margin-bottom: -178px;
  }

  .sc-sm-mb--179 {
    margin-bottom: -179px;
  }

  .sc-sm-mb--180 {
    margin-bottom: -180px;
  }

  .sc-sm-mb--181 {
    margin-bottom: -181px;
  }

  .sc-sm-mb--182 {
    margin-bottom: -182px;
  }

  .sc-sm-mb--183 {
    margin-bottom: -183px;
  }

  .sc-sm-mb--184 {
    margin-bottom: -184px;
  }

  .sc-sm-mb--185 {
    margin-bottom: -185px;
  }

  .sc-sm-mb--186 {
    margin-bottom: -186px;
  }

  .sc-sm-mb--187 {
    margin-bottom: -187px;
  }

  .sc-sm-mb--188 {
    margin-bottom: -188px;
  }

  .sc-sm-mb--189 {
    margin-bottom: -189px;
  }

  .sc-sm-mb--190 {
    margin-bottom: -190px;
  }

  .sc-sm-mb--191 {
    margin-bottom: -191px;
  }

  .sc-sm-mb--192 {
    margin-bottom: -192px;
  }

  .sc-sm-mb--193 {
    margin-bottom: -193px;
  }

  .sc-sm-mb--194 {
    margin-bottom: -194px;
  }

  .sc-sm-mb--195 {
    margin-bottom: -195px;
  }

  .sc-sm-mb--196 {
    margin-bottom: -196px;
  }

  .sc-sm-mb--197 {
    margin-bottom: -197px;
  }

  .sc-sm-mb--198 {
    margin-bottom: -198px;
  }

  .sc-sm-mb--199 {
    margin-bottom: -199px;
  }

  .sc-sm-mb--200 {
    margin-bottom: -200px;
  }

  .sc-sm-ml--1 {
    margin-left: -1px;
  }

  .sc-sm-ml--2 {
    margin-left: -2px;
  }

  .sc-sm-ml--3 {
    margin-left: -3px;
  }

  .sc-sm-ml--4 {
    margin-left: -4px;
  }

  .sc-sm-ml--5 {
    margin-left: -5px;
  }

  .sc-sm-ml--6 {
    margin-left: -6px;
  }

  .sc-sm-ml--7 {
    margin-left: -7px;
  }

  .sc-sm-ml--8 {
    margin-left: -8px;
  }

  .sc-sm-ml--9 {
    margin-left: -9px;
  }

  .sc-sm-ml--10 {
    margin-left: -10px;
  }

  .sc-sm-ml--11 {
    margin-left: -11px;
  }

  .sc-sm-ml--12 {
    margin-left: -12px;
  }

  .sc-sm-ml--13 {
    margin-left: -13px;
  }

  .sc-sm-ml--14 {
    margin-left: -14px;
  }

  .sc-sm-ml--15 {
    margin-left: -15px;
  }

  .sc-sm-ml--16 {
    margin-left: -16px;
  }

  .sc-sm-ml--17 {
    margin-left: -17px;
  }

  .sc-sm-ml--18 {
    margin-left: -18px;
  }

  .sc-sm-ml--19 {
    margin-left: -19px;
  }

  .sc-sm-ml--20 {
    margin-left: -20px;
  }

  .sc-sm-ml--21 {
    margin-left: -21px;
  }

  .sc-sm-ml--22 {
    margin-left: -22px;
  }

  .sc-sm-ml--23 {
    margin-left: -23px;
  }

  .sc-sm-ml--24 {
    margin-left: -24px;
  }

  .sc-sm-ml--25 {
    margin-left: -25px;
  }

  .sc-sm-ml--26 {
    margin-left: -26px;
  }

  .sc-sm-ml--27 {
    margin-left: -27px;
  }

  .sc-sm-ml--28 {
    margin-left: -28px;
  }

  .sc-sm-ml--29 {
    margin-left: -29px;
  }

  .sc-sm-ml--30 {
    margin-left: -30px;
  }

  .sc-sm-ml--31 {
    margin-left: -31px;
  }

  .sc-sm-ml--32 {
    margin-left: -32px;
  }

  .sc-sm-ml--33 {
    margin-left: -33px;
  }

  .sc-sm-ml--34 {
    margin-left: -34px;
  }

  .sc-sm-ml--35 {
    margin-left: -35px;
  }

  .sc-sm-ml--36 {
    margin-left: -36px;
  }

  .sc-sm-ml--37 {
    margin-left: -37px;
  }

  .sc-sm-ml--38 {
    margin-left: -38px;
  }

  .sc-sm-ml--39 {
    margin-left: -39px;
  }

  .sc-sm-ml--40 {
    margin-left: -40px;
  }

  .sc-sm-ml--41 {
    margin-left: -41px;
  }

  .sc-sm-ml--42 {
    margin-left: -42px;
  }

  .sc-sm-ml--43 {
    margin-left: -43px;
  }

  .sc-sm-ml--44 {
    margin-left: -44px;
  }

  .sc-sm-ml--45 {
    margin-left: -45px;
  }

  .sc-sm-ml--46 {
    margin-left: -46px;
  }

  .sc-sm-ml--47 {
    margin-left: -47px;
  }

  .sc-sm-ml--48 {
    margin-left: -48px;
  }

  .sc-sm-ml--49 {
    margin-left: -49px;
  }

  .sc-sm-ml--50 {
    margin-left: -50px;
  }

  .sc-sm-ml--51 {
    margin-left: -51px;
  }

  .sc-sm-ml--52 {
    margin-left: -52px;
  }

  .sc-sm-ml--53 {
    margin-left: -53px;
  }

  .sc-sm-ml--54 {
    margin-left: -54px;
  }

  .sc-sm-ml--55 {
    margin-left: -55px;
  }

  .sc-sm-ml--56 {
    margin-left: -56px;
  }

  .sc-sm-ml--57 {
    margin-left: -57px;
  }

  .sc-sm-ml--58 {
    margin-left: -58px;
  }

  .sc-sm-ml--59 {
    margin-left: -59px;
  }

  .sc-sm-ml--60 {
    margin-left: -60px;
  }

  .sc-sm-ml--61 {
    margin-left: -61px;
  }

  .sc-sm-ml--62 {
    margin-left: -62px;
  }

  .sc-sm-ml--63 {
    margin-left: -63px;
  }

  .sc-sm-ml--64 {
    margin-left: -64px;
  }

  .sc-sm-ml--65 {
    margin-left: -65px;
  }

  .sc-sm-ml--66 {
    margin-left: -66px;
  }

  .sc-sm-ml--67 {
    margin-left: -67px;
  }

  .sc-sm-ml--68 {
    margin-left: -68px;
  }

  .sc-sm-ml--69 {
    margin-left: -69px;
  }

  .sc-sm-ml--70 {
    margin-left: -70px;
  }

  .sc-sm-ml--71 {
    margin-left: -71px;
  }

  .sc-sm-ml--72 {
    margin-left: -72px;
  }

  .sc-sm-ml--73 {
    margin-left: -73px;
  }

  .sc-sm-ml--74 {
    margin-left: -74px;
  }

  .sc-sm-ml--75 {
    margin-left: -75px;
  }

  .sc-sm-ml--76 {
    margin-left: -76px;
  }

  .sc-sm-ml--77 {
    margin-left: -77px;
  }

  .sc-sm-ml--78 {
    margin-left: -78px;
  }

  .sc-sm-ml--79 {
    margin-left: -79px;
  }

  .sc-sm-ml--80 {
    margin-left: -80px;
  }

  .sc-sm-ml--81 {
    margin-left: -81px;
  }

  .sc-sm-ml--82 {
    margin-left: -82px;
  }

  .sc-sm-ml--83 {
    margin-left: -83px;
  }

  .sc-sm-ml--84 {
    margin-left: -84px;
  }

  .sc-sm-ml--85 {
    margin-left: -85px;
  }

  .sc-sm-ml--86 {
    margin-left: -86px;
  }

  .sc-sm-ml--87 {
    margin-left: -87px;
  }

  .sc-sm-ml--88 {
    margin-left: -88px;
  }

  .sc-sm-ml--89 {
    margin-left: -89px;
  }

  .sc-sm-ml--90 {
    margin-left: -90px;
  }

  .sc-sm-ml--91 {
    margin-left: -91px;
  }

  .sc-sm-ml--92 {
    margin-left: -92px;
  }

  .sc-sm-ml--93 {
    margin-left: -93px;
  }

  .sc-sm-ml--94 {
    margin-left: -94px;
  }

  .sc-sm-ml--95 {
    margin-left: -95px;
  }

  .sc-sm-ml--96 {
    margin-left: -96px;
  }

  .sc-sm-ml--97 {
    margin-left: -97px;
  }

  .sc-sm-ml--98 {
    margin-left: -98px;
  }

  .sc-sm-ml--99 {
    margin-left: -99px;
  }

  .sc-sm-ml--100 {
    margin-left: -100px;
  }

  .sc-sm-ml--101 {
    margin-left: -101px;
  }

  .sc-sm-ml--102 {
    margin-left: -102px;
  }

  .sc-sm-ml--103 {
    margin-left: -103px;
  }

  .sc-sm-ml--104 {
    margin-left: -104px;
  }

  .sc-sm-ml--105 {
    margin-left: -105px;
  }

  .sc-sm-ml--106 {
    margin-left: -106px;
  }

  .sc-sm-ml--107 {
    margin-left: -107px;
  }

  .sc-sm-ml--108 {
    margin-left: -108px;
  }

  .sc-sm-ml--109 {
    margin-left: -109px;
  }

  .sc-sm-ml--110 {
    margin-left: -110px;
  }

  .sc-sm-ml--111 {
    margin-left: -111px;
  }

  .sc-sm-ml--112 {
    margin-left: -112px;
  }

  .sc-sm-ml--113 {
    margin-left: -113px;
  }

  .sc-sm-ml--114 {
    margin-left: -114px;
  }

  .sc-sm-ml--115 {
    margin-left: -115px;
  }

  .sc-sm-ml--116 {
    margin-left: -116px;
  }

  .sc-sm-ml--117 {
    margin-left: -117px;
  }

  .sc-sm-ml--118 {
    margin-left: -118px;
  }

  .sc-sm-ml--119 {
    margin-left: -119px;
  }

  .sc-sm-ml--120 {
    margin-left: -120px;
  }

  .sc-sm-ml--121 {
    margin-left: -121px;
  }

  .sc-sm-ml--122 {
    margin-left: -122px;
  }

  .sc-sm-ml--123 {
    margin-left: -123px;
  }

  .sc-sm-ml--124 {
    margin-left: -124px;
  }

  .sc-sm-ml--125 {
    margin-left: -125px;
  }

  .sc-sm-ml--126 {
    margin-left: -126px;
  }

  .sc-sm-ml--127 {
    margin-left: -127px;
  }

  .sc-sm-ml--128 {
    margin-left: -128px;
  }

  .sc-sm-ml--129 {
    margin-left: -129px;
  }

  .sc-sm-ml--130 {
    margin-left: -130px;
  }

  .sc-sm-ml--131 {
    margin-left: -131px;
  }

  .sc-sm-ml--132 {
    margin-left: -132px;
  }

  .sc-sm-ml--133 {
    margin-left: -133px;
  }

  .sc-sm-ml--134 {
    margin-left: -134px;
  }

  .sc-sm-ml--135 {
    margin-left: -135px;
  }

  .sc-sm-ml--136 {
    margin-left: -136px;
  }

  .sc-sm-ml--137 {
    margin-left: -137px;
  }

  .sc-sm-ml--138 {
    margin-left: -138px;
  }

  .sc-sm-ml--139 {
    margin-left: -139px;
  }

  .sc-sm-ml--140 {
    margin-left: -140px;
  }

  .sc-sm-ml--141 {
    margin-left: -141px;
  }

  .sc-sm-ml--142 {
    margin-left: -142px;
  }

  .sc-sm-ml--143 {
    margin-left: -143px;
  }

  .sc-sm-ml--144 {
    margin-left: -144px;
  }

  .sc-sm-ml--145 {
    margin-left: -145px;
  }

  .sc-sm-ml--146 {
    margin-left: -146px;
  }

  .sc-sm-ml--147 {
    margin-left: -147px;
  }

  .sc-sm-ml--148 {
    margin-left: -148px;
  }

  .sc-sm-ml--149 {
    margin-left: -149px;
  }

  .sc-sm-ml--150 {
    margin-left: -150px;
  }

  .sc-sm-ml--151 {
    margin-left: -151px;
  }

  .sc-sm-ml--152 {
    margin-left: -152px;
  }

  .sc-sm-ml--153 {
    margin-left: -153px;
  }

  .sc-sm-ml--154 {
    margin-left: -154px;
  }

  .sc-sm-ml--155 {
    margin-left: -155px;
  }

  .sc-sm-ml--156 {
    margin-left: -156px;
  }

  .sc-sm-ml--157 {
    margin-left: -157px;
  }

  .sc-sm-ml--158 {
    margin-left: -158px;
  }

  .sc-sm-ml--159 {
    margin-left: -159px;
  }

  .sc-sm-ml--160 {
    margin-left: -160px;
  }

  .sc-sm-ml--161 {
    margin-left: -161px;
  }

  .sc-sm-ml--162 {
    margin-left: -162px;
  }

  .sc-sm-ml--163 {
    margin-left: -163px;
  }

  .sc-sm-ml--164 {
    margin-left: -164px;
  }

  .sc-sm-ml--165 {
    margin-left: -165px;
  }

  .sc-sm-ml--166 {
    margin-left: -166px;
  }

  .sc-sm-ml--167 {
    margin-left: -167px;
  }

  .sc-sm-ml--168 {
    margin-left: -168px;
  }

  .sc-sm-ml--169 {
    margin-left: -169px;
  }

  .sc-sm-ml--170 {
    margin-left: -170px;
  }

  .sc-sm-ml--171 {
    margin-left: -171px;
  }

  .sc-sm-ml--172 {
    margin-left: -172px;
  }

  .sc-sm-ml--173 {
    margin-left: -173px;
  }

  .sc-sm-ml--174 {
    margin-left: -174px;
  }

  .sc-sm-ml--175 {
    margin-left: -175px;
  }

  .sc-sm-ml--176 {
    margin-left: -176px;
  }

  .sc-sm-ml--177 {
    margin-left: -177px;
  }

  .sc-sm-ml--178 {
    margin-left: -178px;
  }

  .sc-sm-ml--179 {
    margin-left: -179px;
  }

  .sc-sm-ml--180 {
    margin-left: -180px;
  }

  .sc-sm-ml--181 {
    margin-left: -181px;
  }

  .sc-sm-ml--182 {
    margin-left: -182px;
  }

  .sc-sm-ml--183 {
    margin-left: -183px;
  }

  .sc-sm-ml--184 {
    margin-left: -184px;
  }

  .sc-sm-ml--185 {
    margin-left: -185px;
  }

  .sc-sm-ml--186 {
    margin-left: -186px;
  }

  .sc-sm-ml--187 {
    margin-left: -187px;
  }

  .sc-sm-ml--188 {
    margin-left: -188px;
  }

  .sc-sm-ml--189 {
    margin-left: -189px;
  }

  .sc-sm-ml--190 {
    margin-left: -190px;
  }

  .sc-sm-ml--191 {
    margin-left: -191px;
  }

  .sc-sm-ml--192 {
    margin-left: -192px;
  }

  .sc-sm-ml--193 {
    margin-left: -193px;
  }

  .sc-sm-ml--194 {
    margin-left: -194px;
  }

  .sc-sm-ml--195 {
    margin-left: -195px;
  }

  .sc-sm-ml--196 {
    margin-left: -196px;
  }

  .sc-sm-ml--197 {
    margin-left: -197px;
  }

  .sc-sm-ml--198 {
    margin-left: -198px;
  }

  .sc-sm-ml--199 {
    margin-left: -199px;
  }

  .sc-sm-ml--200 {
    margin-left: -200px;
  }

  .sc-sm-mr--1 {
    margin-right: -1px;
  }

  .sc-sm-mr--2 {
    margin-right: -2px;
  }

  .sc-sm-mr--3 {
    margin-right: -3px;
  }

  .sc-sm-mr--4 {
    margin-right: -4px;
  }

  .sc-sm-mr--5 {
    margin-right: -5px;
  }

  .sc-sm-mr--6 {
    margin-right: -6px;
  }

  .sc-sm-mr--7 {
    margin-right: -7px;
  }

  .sc-sm-mr--8 {
    margin-right: -8px;
  }

  .sc-sm-mr--9 {
    margin-right: -9px;
  }

  .sc-sm-mr--10 {
    margin-right: -10px;
  }

  .sc-sm-mr--11 {
    margin-right: -11px;
  }

  .sc-sm-mr--12 {
    margin-right: -12px;
  }

  .sc-sm-mr--13 {
    margin-right: -13px;
  }

  .sc-sm-mr--14 {
    margin-right: -14px;
  }

  .sc-sm-mr--15 {
    margin-right: -15px;
  }

  .sc-sm-mr--16 {
    margin-right: -16px;
  }

  .sc-sm-mr--17 {
    margin-right: -17px;
  }

  .sc-sm-mr--18 {
    margin-right: -18px;
  }

  .sc-sm-mr--19 {
    margin-right: -19px;
  }

  .sc-sm-mr--20 {
    margin-right: -20px;
  }

  .sc-sm-mr--21 {
    margin-right: -21px;
  }

  .sc-sm-mr--22 {
    margin-right: -22px;
  }

  .sc-sm-mr--23 {
    margin-right: -23px;
  }

  .sc-sm-mr--24 {
    margin-right: -24px;
  }

  .sc-sm-mr--25 {
    margin-right: -25px;
  }

  .sc-sm-mr--26 {
    margin-right: -26px;
  }

  .sc-sm-mr--27 {
    margin-right: -27px;
  }

  .sc-sm-mr--28 {
    margin-right: -28px;
  }

  .sc-sm-mr--29 {
    margin-right: -29px;
  }

  .sc-sm-mr--30 {
    margin-right: -30px;
  }

  .sc-sm-mr--31 {
    margin-right: -31px;
  }

  .sc-sm-mr--32 {
    margin-right: -32px;
  }

  .sc-sm-mr--33 {
    margin-right: -33px;
  }

  .sc-sm-mr--34 {
    margin-right: -34px;
  }

  .sc-sm-mr--35 {
    margin-right: -35px;
  }

  .sc-sm-mr--36 {
    margin-right: -36px;
  }

  .sc-sm-mr--37 {
    margin-right: -37px;
  }

  .sc-sm-mr--38 {
    margin-right: -38px;
  }

  .sc-sm-mr--39 {
    margin-right: -39px;
  }

  .sc-sm-mr--40 {
    margin-right: -40px;
  }

  .sc-sm-mr--41 {
    margin-right: -41px;
  }

  .sc-sm-mr--42 {
    margin-right: -42px;
  }

  .sc-sm-mr--43 {
    margin-right: -43px;
  }

  .sc-sm-mr--44 {
    margin-right: -44px;
  }

  .sc-sm-mr--45 {
    margin-right: -45px;
  }

  .sc-sm-mr--46 {
    margin-right: -46px;
  }

  .sc-sm-mr--47 {
    margin-right: -47px;
  }

  .sc-sm-mr--48 {
    margin-right: -48px;
  }

  .sc-sm-mr--49 {
    margin-right: -49px;
  }

  .sc-sm-mr--50 {
    margin-right: -50px;
  }

  .sc-sm-mr--51 {
    margin-right: -51px;
  }

  .sc-sm-mr--52 {
    margin-right: -52px;
  }

  .sc-sm-mr--53 {
    margin-right: -53px;
  }

  .sc-sm-mr--54 {
    margin-right: -54px;
  }

  .sc-sm-mr--55 {
    margin-right: -55px;
  }

  .sc-sm-mr--56 {
    margin-right: -56px;
  }

  .sc-sm-mr--57 {
    margin-right: -57px;
  }

  .sc-sm-mr--58 {
    margin-right: -58px;
  }

  .sc-sm-mr--59 {
    margin-right: -59px;
  }

  .sc-sm-mr--60 {
    margin-right: -60px;
  }

  .sc-sm-mr--61 {
    margin-right: -61px;
  }

  .sc-sm-mr--62 {
    margin-right: -62px;
  }

  .sc-sm-mr--63 {
    margin-right: -63px;
  }

  .sc-sm-mr--64 {
    margin-right: -64px;
  }

  .sc-sm-mr--65 {
    margin-right: -65px;
  }

  .sc-sm-mr--66 {
    margin-right: -66px;
  }

  .sc-sm-mr--67 {
    margin-right: -67px;
  }

  .sc-sm-mr--68 {
    margin-right: -68px;
  }

  .sc-sm-mr--69 {
    margin-right: -69px;
  }

  .sc-sm-mr--70 {
    margin-right: -70px;
  }

  .sc-sm-mr--71 {
    margin-right: -71px;
  }

  .sc-sm-mr--72 {
    margin-right: -72px;
  }

  .sc-sm-mr--73 {
    margin-right: -73px;
  }

  .sc-sm-mr--74 {
    margin-right: -74px;
  }

  .sc-sm-mr--75 {
    margin-right: -75px;
  }

  .sc-sm-mr--76 {
    margin-right: -76px;
  }

  .sc-sm-mr--77 {
    margin-right: -77px;
  }

  .sc-sm-mr--78 {
    margin-right: -78px;
  }

  .sc-sm-mr--79 {
    margin-right: -79px;
  }

  .sc-sm-mr--80 {
    margin-right: -80px;
  }

  .sc-sm-mr--81 {
    margin-right: -81px;
  }

  .sc-sm-mr--82 {
    margin-right: -82px;
  }

  .sc-sm-mr--83 {
    margin-right: -83px;
  }

  .sc-sm-mr--84 {
    margin-right: -84px;
  }

  .sc-sm-mr--85 {
    margin-right: -85px;
  }

  .sc-sm-mr--86 {
    margin-right: -86px;
  }

  .sc-sm-mr--87 {
    margin-right: -87px;
  }

  .sc-sm-mr--88 {
    margin-right: -88px;
  }

  .sc-sm-mr--89 {
    margin-right: -89px;
  }

  .sc-sm-mr--90 {
    margin-right: -90px;
  }

  .sc-sm-mr--91 {
    margin-right: -91px;
  }

  .sc-sm-mr--92 {
    margin-right: -92px;
  }

  .sc-sm-mr--93 {
    margin-right: -93px;
  }

  .sc-sm-mr--94 {
    margin-right: -94px;
  }

  .sc-sm-mr--95 {
    margin-right: -95px;
  }

  .sc-sm-mr--96 {
    margin-right: -96px;
  }

  .sc-sm-mr--97 {
    margin-right: -97px;
  }

  .sc-sm-mr--98 {
    margin-right: -98px;
  }

  .sc-sm-mr--99 {
    margin-right: -99px;
  }

  .sc-sm-mr--100 {
    margin-right: -100px;
  }

  .sc-sm-mr--101 {
    margin-right: -101px;
  }

  .sc-sm-mr--102 {
    margin-right: -102px;
  }

  .sc-sm-mr--103 {
    margin-right: -103px;
  }

  .sc-sm-mr--104 {
    margin-right: -104px;
  }

  .sc-sm-mr--105 {
    margin-right: -105px;
  }

  .sc-sm-mr--106 {
    margin-right: -106px;
  }

  .sc-sm-mr--107 {
    margin-right: -107px;
  }

  .sc-sm-mr--108 {
    margin-right: -108px;
  }

  .sc-sm-mr--109 {
    margin-right: -109px;
  }

  .sc-sm-mr--110 {
    margin-right: -110px;
  }

  .sc-sm-mr--111 {
    margin-right: -111px;
  }

  .sc-sm-mr--112 {
    margin-right: -112px;
  }

  .sc-sm-mr--113 {
    margin-right: -113px;
  }

  .sc-sm-mr--114 {
    margin-right: -114px;
  }

  .sc-sm-mr--115 {
    margin-right: -115px;
  }

  .sc-sm-mr--116 {
    margin-right: -116px;
  }

  .sc-sm-mr--117 {
    margin-right: -117px;
  }

  .sc-sm-mr--118 {
    margin-right: -118px;
  }

  .sc-sm-mr--119 {
    margin-right: -119px;
  }

  .sc-sm-mr--120 {
    margin-right: -120px;
  }

  .sc-sm-mr--121 {
    margin-right: -121px;
  }

  .sc-sm-mr--122 {
    margin-right: -122px;
  }

  .sc-sm-mr--123 {
    margin-right: -123px;
  }

  .sc-sm-mr--124 {
    margin-right: -124px;
  }

  .sc-sm-mr--125 {
    margin-right: -125px;
  }

  .sc-sm-mr--126 {
    margin-right: -126px;
  }

  .sc-sm-mr--127 {
    margin-right: -127px;
  }

  .sc-sm-mr--128 {
    margin-right: -128px;
  }

  .sc-sm-mr--129 {
    margin-right: -129px;
  }

  .sc-sm-mr--130 {
    margin-right: -130px;
  }

  .sc-sm-mr--131 {
    margin-right: -131px;
  }

  .sc-sm-mr--132 {
    margin-right: -132px;
  }

  .sc-sm-mr--133 {
    margin-right: -133px;
  }

  .sc-sm-mr--134 {
    margin-right: -134px;
  }

  .sc-sm-mr--135 {
    margin-right: -135px;
  }

  .sc-sm-mr--136 {
    margin-right: -136px;
  }

  .sc-sm-mr--137 {
    margin-right: -137px;
  }

  .sc-sm-mr--138 {
    margin-right: -138px;
  }

  .sc-sm-mr--139 {
    margin-right: -139px;
  }

  .sc-sm-mr--140 {
    margin-right: -140px;
  }

  .sc-sm-mr--141 {
    margin-right: -141px;
  }

  .sc-sm-mr--142 {
    margin-right: -142px;
  }

  .sc-sm-mr--143 {
    margin-right: -143px;
  }

  .sc-sm-mr--144 {
    margin-right: -144px;
  }

  .sc-sm-mr--145 {
    margin-right: -145px;
  }

  .sc-sm-mr--146 {
    margin-right: -146px;
  }

  .sc-sm-mr--147 {
    margin-right: -147px;
  }

  .sc-sm-mr--148 {
    margin-right: -148px;
  }

  .sc-sm-mr--149 {
    margin-right: -149px;
  }

  .sc-sm-mr--150 {
    margin-right: -150px;
  }

  .sc-sm-mr--151 {
    margin-right: -151px;
  }

  .sc-sm-mr--152 {
    margin-right: -152px;
  }

  .sc-sm-mr--153 {
    margin-right: -153px;
  }

  .sc-sm-mr--154 {
    margin-right: -154px;
  }

  .sc-sm-mr--155 {
    margin-right: -155px;
  }

  .sc-sm-mr--156 {
    margin-right: -156px;
  }

  .sc-sm-mr--157 {
    margin-right: -157px;
  }

  .sc-sm-mr--158 {
    margin-right: -158px;
  }

  .sc-sm-mr--159 {
    margin-right: -159px;
  }

  .sc-sm-mr--160 {
    margin-right: -160px;
  }

  .sc-sm-mr--161 {
    margin-right: -161px;
  }

  .sc-sm-mr--162 {
    margin-right: -162px;
  }

  .sc-sm-mr--163 {
    margin-right: -163px;
  }

  .sc-sm-mr--164 {
    margin-right: -164px;
  }

  .sc-sm-mr--165 {
    margin-right: -165px;
  }

  .sc-sm-mr--166 {
    margin-right: -166px;
  }

  .sc-sm-mr--167 {
    margin-right: -167px;
  }

  .sc-sm-mr--168 {
    margin-right: -168px;
  }

  .sc-sm-mr--169 {
    margin-right: -169px;
  }

  .sc-sm-mr--170 {
    margin-right: -170px;
  }

  .sc-sm-mr--171 {
    margin-right: -171px;
  }

  .sc-sm-mr--172 {
    margin-right: -172px;
  }

  .sc-sm-mr--173 {
    margin-right: -173px;
  }

  .sc-sm-mr--174 {
    margin-right: -174px;
  }

  .sc-sm-mr--175 {
    margin-right: -175px;
  }

  .sc-sm-mr--176 {
    margin-right: -176px;
  }

  .sc-sm-mr--177 {
    margin-right: -177px;
  }

  .sc-sm-mr--178 {
    margin-right: -178px;
  }

  .sc-sm-mr--179 {
    margin-right: -179px;
  }

  .sc-sm-mr--180 {
    margin-right: -180px;
  }

  .sc-sm-mr--181 {
    margin-right: -181px;
  }

  .sc-sm-mr--182 {
    margin-right: -182px;
  }

  .sc-sm-mr--183 {
    margin-right: -183px;
  }

  .sc-sm-mr--184 {
    margin-right: -184px;
  }

  .sc-sm-mr--185 {
    margin-right: -185px;
  }

  .sc-sm-mr--186 {
    margin-right: -186px;
  }

  .sc-sm-mr--187 {
    margin-right: -187px;
  }

  .sc-sm-mr--188 {
    margin-right: -188px;
  }

  .sc-sm-mr--189 {
    margin-right: -189px;
  }

  .sc-sm-mr--190 {
    margin-right: -190px;
  }

  .sc-sm-mr--191 {
    margin-right: -191px;
  }

  .sc-sm-mr--192 {
    margin-right: -192px;
  }

  .sc-sm-mr--193 {
    margin-right: -193px;
  }

  .sc-sm-mr--194 {
    margin-right: -194px;
  }

  .sc-sm-mr--195 {
    margin-right: -195px;
  }

  .sc-sm-mr--196 {
    margin-right: -196px;
  }

  .sc-sm-mr--197 {
    margin-right: -197px;
  }

  .sc-sm-mr--198 {
    margin-right: -198px;
  }

  .sc-sm-mr--199 {
    margin-right: -199px;
  }

  .sc-sm-mr--200 {
    margin-right: -200px;
  }
}

@media only screen and (max-width: 575px) {
  .sc-xs-padding-0 {
    padding: 0;
  }

  .sc-xs-margin-0 {
    margin: 0;
  }

  .sc-xs-pt-0 {
    padding-top: 0;
  }

  .sc-xs-pt-1 {
    padding-top: 1px;
  }

  .sc-xs-pt-2 {
    padding-top: 2px;
  }

  .sc-xs-pt-3 {
    padding-top: 3px;
  }

  .sc-xs-pt-4 {
    padding-top: 4px;
  }

  .sc-xs-pt-5 {
    padding-top: 5px;
  }

  .sc-xs-pt-6 {
    padding-top: 6px;
  }

  .sc-xs-pt-7 {
    padding-top: 7px;
  }

  .sc-xs-pt-8 {
    padding-top: 8px;
  }

  .sc-xs-pt-9 {
    padding-top: 9px;
  }

  .sc-xs-pt-10 {
    padding-top: 10px;
  }

  .sc-xs-pt-11 {
    padding-top: 11px;
  }

  .sc-xs-pt-12 {
    padding-top: 12px;
  }

  .sc-xs-pt-13 {
    padding-top: 13px;
  }

  .sc-xs-pt-14 {
    padding-top: 14px;
  }

  .sc-xs-pt-15 {
    padding-top: 15px;
  }

  .sc-xs-pt-16 {
    padding-top: 16px;
  }

  .sc-xs-pt-17 {
    padding-top: 17px;
  }

  .sc-xs-pt-18 {
    padding-top: 18px;
  }

  .sc-xs-pt-19 {
    padding-top: 19px;
  }

  .sc-xs-pt-20 {
    padding-top: 20px;
  }

  .sc-xs-pt-21 {
    padding-top: 21px;
  }

  .sc-xs-pt-22 {
    padding-top: 22px;
  }

  .sc-xs-pt-23 {
    padding-top: 23px;
  }

  .sc-xs-pt-24 {
    padding-top: 24px;
  }

  .sc-xs-pt-25 {
    padding-top: 25px;
  }

  .sc-xs-pt-26 {
    padding-top: 26px;
  }

  .sc-xs-pt-27 {
    padding-top: 27px;
  }

  .sc-xs-pt-28 {
    padding-top: 28px;
  }

  .sc-xs-pt-29 {
    padding-top: 29px;
  }

  .sc-xs-pt-30 {
    padding-top: 30px;
  }

  .sc-xs-pt-31 {
    padding-top: 31px;
  }

  .sc-xs-pt-32 {
    padding-top: 32px;
  }

  .sc-xs-pt-33 {
    padding-top: 33px;
  }

  .sc-xs-pt-34 {
    padding-top: 34px;
  }

  .sc-xs-pt-35 {
    padding-top: 35px;
  }

  .sc-xs-pt-36 {
    padding-top: 36px;
  }

  .sc-xs-pt-37 {
    padding-top: 37px;
  }

  .sc-xs-pt-38 {
    padding-top: 38px;
  }

  .sc-xs-pt-39 {
    padding-top: 39px;
  }

  .sc-xs-pt-40 {
    padding-top: 40px;
  }

  .sc-xs-pt-41 {
    padding-top: 41px;
  }

  .sc-xs-pt-42 {
    padding-top: 42px;
  }

  .sc-xs-pt-43 {
    padding-top: 43px;
  }

  .sc-xs-pt-44 {
    padding-top: 44px;
  }

  .sc-xs-pt-45 {
    padding-top: 45px;
  }

  .sc-xs-pt-46 {
    padding-top: 46px;
  }

  .sc-xs-pt-47 {
    padding-top: 47px;
  }

  .sc-xs-pt-48 {
    padding-top: 48px;
  }

  .sc-xs-pt-49 {
    padding-top: 49px;
  }

  .sc-xs-pt-50 {
    padding-top: 50px;
  }

  .sc-xs-pt-51 {
    padding-top: 51px;
  }

  .sc-xs-pt-52 {
    padding-top: 52px;
  }

  .sc-xs-pt-53 {
    padding-top: 53px;
  }

  .sc-xs-pt-54 {
    padding-top: 54px;
  }

  .sc-xs-pt-55 {
    padding-top: 55px;
  }

  .sc-xs-pt-56 {
    padding-top: 56px;
  }

  .sc-xs-pt-57 {
    padding-top: 57px;
  }

  .sc-xs-pt-58 {
    padding-top: 58px;
  }

  .sc-xs-pt-59 {
    padding-top: 59px;
  }

  .sc-xs-pt-60 {
    padding-top: 60px;
  }

  .sc-xs-pt-61 {
    padding-top: 61px;
  }

  .sc-xs-pt-62 {
    padding-top: 62px;
  }

  .sc-xs-pt-63 {
    padding-top: 63px;
  }

  .sc-xs-pt-64 {
    padding-top: 64px;
  }

  .sc-xs-pt-65 {
    padding-top: 65px;
  }

  .sc-xs-pt-66 {
    padding-top: 66px;
  }

  .sc-xs-pt-67 {
    padding-top: 67px;
  }

  .sc-xs-pt-68 {
    padding-top: 68px;
  }

  .sc-xs-pt-69 {
    padding-top: 69px;
  }

  .sc-xs-pt-70 {
    padding-top: 70px;
  }

  .sc-xs-pt-71 {
    padding-top: 71px;
  }

  .sc-xs-pt-72 {
    padding-top: 72px;
  }

  .sc-xs-pt-73 {
    padding-top: 73px;
  }

  .sc-xs-pt-74 {
    padding-top: 74px;
  }

  .sc-xs-pt-75 {
    padding-top: 75px;
  }

  .sc-xs-pt-76 {
    padding-top: 76px;
  }

  .sc-xs-pt-77 {
    padding-top: 77px;
  }

  .sc-xs-pt-78 {
    padding-top: 78px;
  }

  .sc-xs-pt-79 {
    padding-top: 79px;
  }

  .sc-xs-pt-80 {
    padding-top: 80px;
  }

  .sc-xs-pt-81 {
    padding-top: 81px;
  }

  .sc-xs-pt-82 {
    padding-top: 82px;
  }

  .sc-xs-pt-83 {
    padding-top: 83px;
  }

  .sc-xs-pt-84 {
    padding-top: 84px;
  }

  .sc-xs-pt-85 {
    padding-top: 85px;
  }

  .sc-xs-pt-86 {
    padding-top: 86px;
  }

  .sc-xs-pt-87 {
    padding-top: 87px;
  }

  .sc-xs-pt-88 {
    padding-top: 88px;
  }

  .sc-xs-pt-89 {
    padding-top: 89px;
  }

  .sc-xs-pt-90 {
    padding-top: 90px;
  }

  .sc-xs-pt-91 {
    padding-top: 91px;
  }

  .sc-xs-pt-92 {
    padding-top: 92px;
  }

  .sc-xs-pt-93 {
    padding-top: 93px;
  }

  .sc-xs-pt-94 {
    padding-top: 94px;
  }

  .sc-xs-pt-95 {
    padding-top: 95px;
  }

  .sc-xs-pt-96 {
    padding-top: 96px;
  }

  .sc-xs-pt-97 {
    padding-top: 97px;
  }

  .sc-xs-pt-98 {
    padding-top: 98px;
  }

  .sc-xs-pt-99 {
    padding-top: 99px;
  }

  .sc-xs-pt-100 {
    padding-top: 100px;
  }

  .sc-xs-pt-101 {
    padding-top: 101px;
  }

  .sc-xs-pt-102 {
    padding-top: 102px;
  }

  .sc-xs-pt-103 {
    padding-top: 103px;
  }

  .sc-xs-pt-104 {
    padding-top: 104px;
  }

  .sc-xs-pt-105 {
    padding-top: 105px;
  }

  .sc-xs-pt-106 {
    padding-top: 106px;
  }

  .sc-xs-pt-107 {
    padding-top: 107px;
  }

  .sc-xs-pt-108 {
    padding-top: 108px;
  }

  .sc-xs-pt-109 {
    padding-top: 109px;
  }

  .sc-xs-pt-110 {
    padding-top: 110px;
  }

  .sc-xs-pt-111 {
    padding-top: 111px;
  }

  .sc-xs-pt-112 {
    padding-top: 112px;
  }

  .sc-xs-pt-113 {
    padding-top: 113px;
  }

  .sc-xs-pt-114 {
    padding-top: 114px;
  }

  .sc-xs-pt-115 {
    padding-top: 115px;
  }

  .sc-xs-pt-116 {
    padding-top: 116px;
  }

  .sc-xs-pt-117 {
    padding-top: 117px;
  }

  .sc-xs-pt-118 {
    padding-top: 118px;
  }

  .sc-xs-pt-119 {
    padding-top: 119px;
  }

  .sc-xs-pt-120 {
    padding-top: 120px;
  }

  .sc-xs-pt-121 {
    padding-top: 121px;
  }

  .sc-xs-pt-122 {
    padding-top: 122px;
  }

  .sc-xs-pt-123 {
    padding-top: 123px;
  }

  .sc-xs-pt-124 {
    padding-top: 124px;
  }

  .sc-xs-pt-125 {
    padding-top: 125px;
  }

  .sc-xs-pt-126 {
    padding-top: 126px;
  }

  .sc-xs-pt-127 {
    padding-top: 127px;
  }

  .sc-xs-pt-128 {
    padding-top: 128px;
  }

  .sc-xs-pt-129 {
    padding-top: 129px;
  }

  .sc-xs-pt-130 {
    padding-top: 130px;
  }

  .sc-xs-pt-131 {
    padding-top: 131px;
  }

  .sc-xs-pt-132 {
    padding-top: 132px;
  }

  .sc-xs-pt-133 {
    padding-top: 133px;
  }

  .sc-xs-pt-134 {
    padding-top: 134px;
  }

  .sc-xs-pt-135 {
    padding-top: 135px;
  }

  .sc-xs-pt-136 {
    padding-top: 136px;
  }

  .sc-xs-pt-137 {
    padding-top: 137px;
  }

  .sc-xs-pt-138 {
    padding-top: 138px;
  }

  .sc-xs-pt-139 {
    padding-top: 139px;
  }

  .sc-xs-pt-140 {
    padding-top: 140px;
  }

  .sc-xs-pt-141 {
    padding-top: 141px;
  }

  .sc-xs-pt-142 {
    padding-top: 142px;
  }

  .sc-xs-pt-143 {
    padding-top: 143px;
  }

  .sc-xs-pt-144 {
    padding-top: 144px;
  }

  .sc-xs-pt-145 {
    padding-top: 145px;
  }

  .sc-xs-pt-146 {
    padding-top: 146px;
  }

  .sc-xs-pt-147 {
    padding-top: 147px;
  }

  .sc-xs-pt-148 {
    padding-top: 148px;
  }

  .sc-xs-pt-149 {
    padding-top: 149px;
  }

  .sc-xs-pt-150 {
    padding-top: 150px;
  }

  .sc-xs-pt-151 {
    padding-top: 151px;
  }

  .sc-xs-pt-152 {
    padding-top: 152px;
  }

  .sc-xs-pt-153 {
    padding-top: 153px;
  }

  .sc-xs-pt-154 {
    padding-top: 154px;
  }

  .sc-xs-pt-155 {
    padding-top: 155px;
  }

  .sc-xs-pt-156 {
    padding-top: 156px;
  }

  .sc-xs-pt-157 {
    padding-top: 157px;
  }

  .sc-xs-pt-158 {
    padding-top: 158px;
  }

  .sc-xs-pt-159 {
    padding-top: 159px;
  }

  .sc-xs-pt-160 {
    padding-top: 160px;
  }

  .sc-xs-pt-161 {
    padding-top: 161px;
  }

  .sc-xs-pt-162 {
    padding-top: 162px;
  }

  .sc-xs-pt-163 {
    padding-top: 163px;
  }

  .sc-xs-pt-164 {
    padding-top: 164px;
  }

  .sc-xs-pt-165 {
    padding-top: 165px;
  }

  .sc-xs-pt-166 {
    padding-top: 166px;
  }

  .sc-xs-pt-167 {
    padding-top: 167px;
  }

  .sc-xs-pt-168 {
    padding-top: 168px;
  }

  .sc-xs-pt-169 {
    padding-top: 169px;
  }

  .sc-xs-pt-170 {
    padding-top: 170px;
  }

  .sc-xs-pt-171 {
    padding-top: 171px;
  }

  .sc-xs-pt-172 {
    padding-top: 172px;
  }

  .sc-xs-pt-173 {
    padding-top: 173px;
  }

  .sc-xs-pt-174 {
    padding-top: 174px;
  }

  .sc-xs-pt-175 {
    padding-top: 175px;
  }

  .sc-xs-pt-176 {
    padding-top: 176px;
  }

  .sc-xs-pt-177 {
    padding-top: 177px;
  }

  .sc-xs-pt-178 {
    padding-top: 178px;
  }

  .sc-xs-pt-179 {
    padding-top: 179px;
  }

  .sc-xs-pt-180 {
    padding-top: 180px;
  }

  .sc-xs-pt-181 {
    padding-top: 181px;
  }

  .sc-xs-pt-182 {
    padding-top: 182px;
  }

  .sc-xs-pt-183 {
    padding-top: 183px;
  }

  .sc-xs-pt-184 {
    padding-top: 184px;
  }

  .sc-xs-pt-185 {
    padding-top: 185px;
  }

  .sc-xs-pt-186 {
    padding-top: 186px;
  }

  .sc-xs-pt-187 {
    padding-top: 187px;
  }

  .sc-xs-pt-188 {
    padding-top: 188px;
  }

  .sc-xs-pt-189 {
    padding-top: 189px;
  }

  .sc-xs-pt-190 {
    padding-top: 190px;
  }

  .sc-xs-pt-191 {
    padding-top: 191px;
  }

  .sc-xs-pt-192 {
    padding-top: 192px;
  }

  .sc-xs-pt-193 {
    padding-top: 193px;
  }

  .sc-xs-pt-194 {
    padding-top: 194px;
  }

  .sc-xs-pt-195 {
    padding-top: 195px;
  }

  .sc-xs-pt-196 {
    padding-top: 196px;
  }

  .sc-xs-pt-197 {
    padding-top: 197px;
  }

  .sc-xs-pt-198 {
    padding-top: 198px;
  }

  .sc-xs-pt-199 {
    padding-top: 199px;
  }

  .sc-xs-pt-200 {
    padding-top: 200px;
  }

  .sc-xs-pb-0 {
    padding-bottom: 0;
  }

  .sc-xs-pb-1 {
    padding-bottom: 1px;
  }

  .sc-xs-pb-2 {
    padding-bottom: 2px;
  }

  .sc-xs-pb-3 {
    padding-bottom: 3px;
  }

  .sc-xs-pb-4 {
    padding-bottom: 4px;
  }

  .sc-xs-pb-5 {
    padding-bottom: 5px;
  }

  .sc-xs-pb-6 {
    padding-bottom: 6px;
  }

  .sc-xs-pb-7 {
    padding-bottom: 7px;
  }

  .sc-xs-pb-8 {
    padding-bottom: 8px;
  }

  .sc-xs-pb-9 {
    padding-bottom: 9px;
  }

  .sc-xs-pb-10 {
    padding-bottom: 10px;
  }

  .sc-xs-pb-11 {
    padding-bottom: 11px;
  }

  .sc-xs-pb-12 {
    padding-bottom: 12px;
  }

  .sc-xs-pb-13 {
    padding-bottom: 13px;
  }

  .sc-xs-pb-14 {
    padding-bottom: 14px;
  }

  .sc-xs-pb-15 {
    padding-bottom: 15px;
  }

  .sc-xs-pb-16 {
    padding-bottom: 16px;
  }

  .sc-xs-pb-17 {
    padding-bottom: 17px;
  }

  .sc-xs-pb-18 {
    padding-bottom: 18px;
  }

  .sc-xs-pb-19 {
    padding-bottom: 19px;
  }

  .sc-xs-pb-20 {
    padding-bottom: 20px;
  }

  .sc-xs-pb-21 {
    padding-bottom: 21px;
  }

  .sc-xs-pb-22 {
    padding-bottom: 22px;
  }

  .sc-xs-pb-23 {
    padding-bottom: 23px;
  }

  .sc-xs-pb-24 {
    padding-bottom: 24px;
  }

  .sc-xs-pb-25 {
    padding-bottom: 25px;
  }

  .sc-xs-pb-26 {
    padding-bottom: 26px;
  }

  .sc-xs-pb-27 {
    padding-bottom: 27px;
  }

  .sc-xs-pb-28 {
    padding-bottom: 28px;
  }

  .sc-xs-pb-29 {
    padding-bottom: 29px;
  }

  .sc-xs-pb-30 {
    padding-bottom: 30px;
  }

  .sc-xs-pb-31 {
    padding-bottom: 31px;
  }

  .sc-xs-pb-32 {
    padding-bottom: 32px;
  }

  .sc-xs-pb-33 {
    padding-bottom: 33px;
  }

  .sc-xs-pb-34 {
    padding-bottom: 34px;
  }

  .sc-xs-pb-35 {
    padding-bottom: 35px;
  }

  .sc-xs-pb-36 {
    padding-bottom: 36px;
  }

  .sc-xs-pb-37 {
    padding-bottom: 37px;
  }

  .sc-xs-pb-38 {
    padding-bottom: 38px;
  }

  .sc-xs-pb-39 {
    padding-bottom: 39px;
  }

  .sc-xs-pb-40 {
    padding-bottom: 40px;
  }

  .sc-xs-pb-41 {
    padding-bottom: 41px;
  }

  .sc-xs-pb-42 {
    padding-bottom: 42px;
  }

  .sc-xs-pb-43 {
    padding-bottom: 43px;
  }

  .sc-xs-pb-44 {
    padding-bottom: 44px;
  }

  .sc-xs-pb-45 {
    padding-bottom: 45px;
  }

  .sc-xs-pb-46 {
    padding-bottom: 46px;
  }

  .sc-xs-pb-47 {
    padding-bottom: 47px;
  }

  .sc-xs-pb-48 {
    padding-bottom: 48px;
  }

  .sc-xs-pb-49 {
    padding-bottom: 49px;
  }

  .sc-xs-pb-50 {
    padding-bottom: 50px;
  }

  .sc-xs-pb-51 {
    padding-bottom: 51px;
  }

  .sc-xs-pb-52 {
    padding-bottom: 52px;
  }

  .sc-xs-pb-53 {
    padding-bottom: 53px;
  }

  .sc-xs-pb-54 {
    padding-bottom: 54px;
  }

  .sc-xs-pb-55 {
    padding-bottom: 55px;
  }

  .sc-xs-pb-56 {
    padding-bottom: 56px;
  }

  .sc-xs-pb-57 {
    padding-bottom: 57px;
  }

  .sc-xs-pb-58 {
    padding-bottom: 58px;
  }

  .sc-xs-pb-59 {
    padding-bottom: 59px;
  }

  .sc-xs-pb-60 {
    padding-bottom: 60px;
  }

  .sc-xs-pb-61 {
    padding-bottom: 61px;
  }

  .sc-xs-pb-62 {
    padding-bottom: 62px;
  }

  .sc-xs-pb-63 {
    padding-bottom: 63px;
  }

  .sc-xs-pb-64 {
    padding-bottom: 64px;
  }

  .sc-xs-pb-65 {
    padding-bottom: 65px;
  }

  .sc-xs-pb-66 {
    padding-bottom: 66px;
  }

  .sc-xs-pb-67 {
    padding-bottom: 67px;
  }

  .sc-xs-pb-68 {
    padding-bottom: 68px;
  }

  .sc-xs-pb-69 {
    padding-bottom: 69px;
  }

  .sc-xs-pb-70 {
    padding-bottom: 70px;
  }

  .sc-xs-pb-71 {
    padding-bottom: 71px;
  }

  .sc-xs-pb-72 {
    padding-bottom: 72px;
  }

  .sc-xs-pb-73 {
    padding-bottom: 73px;
  }

  .sc-xs-pb-74 {
    padding-bottom: 74px;
  }

  .sc-xs-pb-75 {
    padding-bottom: 75px;
  }

  .sc-xs-pb-76 {
    padding-bottom: 76px;
  }

  .sc-xs-pb-77 {
    padding-bottom: 77px;
  }

  .sc-xs-pb-78 {
    padding-bottom: 78px;
  }

  .sc-xs-pb-79 {
    padding-bottom: 79px;
  }

  .sc-xs-pb-80 {
    padding-bottom: 80px;
  }

  .sc-xs-pb-81 {
    padding-bottom: 81px;
  }

  .sc-xs-pb-82 {
    padding-bottom: 82px;
  }

  .sc-xs-pb-83 {
    padding-bottom: 83px;
  }

  .sc-xs-pb-84 {
    padding-bottom: 84px;
  }

  .sc-xs-pb-85 {
    padding-bottom: 85px;
  }

  .sc-xs-pb-86 {
    padding-bottom: 86px;
  }

  .sc-xs-pb-87 {
    padding-bottom: 87px;
  }

  .sc-xs-pb-88 {
    padding-bottom: 88px;
  }

  .sc-xs-pb-89 {
    padding-bottom: 89px;
  }

  .sc-xs-pb-90 {
    padding-bottom: 90px;
  }

  .sc-xs-pb-91 {
    padding-bottom: 91px;
  }

  .sc-xs-pb-92 {
    padding-bottom: 92px;
  }

  .sc-xs-pb-93 {
    padding-bottom: 93px;
  }

  .sc-xs-pb-94 {
    padding-bottom: 94px;
  }

  .sc-xs-pb-95 {
    padding-bottom: 95px;
  }

  .sc-xs-pb-96 {
    padding-bottom: 96px;
  }

  .sc-xs-pb-97 {
    padding-bottom: 97px;
  }

  .sc-xs-pb-98 {
    padding-bottom: 98px;
  }

  .sc-xs-pb-99 {
    padding-bottom: 99px;
  }

  .sc-xs-pb-100 {
    padding-bottom: 100px;
  }

  .sc-xs-pb-101 {
    padding-bottom: 101px;
  }

  .sc-xs-pb-102 {
    padding-bottom: 102px;
  }

  .sc-xs-pb-104 {
    padding-bottom: 104px;
  }

  .sc-xs-pb-105 {
    padding-bottom: 105px;
  }

  .sc-xs-pb-106 {
    padding-bottom: 106px;
  }

  .sc-xs-pb-107 {
    padding-bottom: 107px;
  }

  .sc-xs-pb-108 {
    padding-bottom: 108px;
  }

  .sc-xs-pb-109 {
    padding-bottom: 109px;
  }

  .sc-xs-pb-110 {
    padding-bottom: 110px;
  }

  .sc-xs-pb-111 {
    padding-bottom: 111px;
  }

  .sc-xs-pb-112 {
    padding-bottom: 112px;
  }

  .sc-xs-pb-113 {
    padding-bottom: 113px;
  }

  .sc-xs-pb-114 {
    padding-bottom: 114px;
  }

  .sc-xs-pb-115 {
    padding-bottom: 115px;
  }

  .sc-xs-pb-116 {
    padding-bottom: 116px;
  }

  .sc-xs-pb-117 {
    padding-bottom: 117px;
  }

  .sc-xs-pb-118 {
    padding-bottom: 118px;
  }

  .sc-xs-pb-119 {
    padding-bottom: 119px;
  }

  .sc-xs-pb-120 {
    padding-bottom: 120px;
  }

  .sc-xs-pb-121 {
    padding-bottom: 121px;
  }

  .sc-xs-pb-122 {
    padding-bottom: 122px;
  }

  .sc-xs-pb-123 {
    padding-bottom: 123px;
  }

  .sc-xs-pb-124 {
    padding-bottom: 124px;
  }

  .sc-xs-pb-125 {
    padding-bottom: 125px;
  }

  .sc-xs-pb-126 {
    padding-bottom: 126px;
  }

  .sc-xs-pb-127 {
    padding-bottom: 127px;
  }

  .sc-xs-pb-128 {
    padding-bottom: 128px;
  }

  .sc-xs-pb-129 {
    padding-bottom: 129px;
  }

  .sc-xs-pb-130 {
    padding-bottom: 130px;
  }

  .sc-xs-pb-131 {
    padding-bottom: 131px;
  }

  .sc-xs-pb-132 {
    padding-bottom: 132px;
  }

  .sc-xs-pb-133 {
    padding-bottom: 133px;
  }

  .sc-xs-pb-134 {
    padding-bottom: 134px;
  }

  .sc-xs-pb-135 {
    padding-bottom: 135px;
  }

  .sc-xs-pb-136 {
    padding-bottom: 136px;
  }

  .sc-xs-pb-137 {
    padding-bottom: 137px;
  }

  .sc-xs-pb-138 {
    padding-bottom: 138px;
  }

  .sc-xs-pb-139 {
    padding-bottom: 139px;
  }

  .sc-xs-pb-140 {
    padding-bottom: 140px;
  }

  .sc-xs-pb-141 {
    padding-bottom: 141px;
  }

  .sc-xs-pb-142 {
    padding-bottom: 142px;
  }

  .sc-xs-pb-143 {
    padding-bottom: 143px;
  }

  .sc-xs-pb-144 {
    padding-bottom: 144px;
  }

  .sc-xs-pb-145 {
    padding-bottom: 145px;
  }

  .sc-xs-pb-146 {
    padding-bottom: 146px;
  }

  .sc-xs-pb-147 {
    padding-bottom: 147px;
  }

  .sc-xs-pb-148 {
    padding-bottom: 148px;
  }

  .sc-xs-pb-149 {
    padding-bottom: 149px;
  }

  .sc-xs-pb-150 {
    padding-bottom: 150px;
  }

  .sc-xs-pb-151 {
    padding-bottom: 151px;
  }

  .sc-xs-pb-152 {
    padding-bottom: 152px;
  }

  .sc-xs-pb-153 {
    padding-bottom: 153px;
  }

  .sc-xs-pb-154 {
    padding-bottom: 154px;
  }

  .sc-xs-pb-155 {
    padding-bottom: 155px;
  }

  .sc-xs-pb-156 {
    padding-bottom: 156px;
  }

  .sc-xs-pb-157 {
    padding-bottom: 157px;
  }

  .sc-xs-pb-158 {
    padding-bottom: 158px;
  }

  .sc-xs-pb-159 {
    padding-bottom: 159px;
  }

  .sc-xs-pb-160 {
    padding-bottom: 160px;
  }

  .sc-xs-pb-161 {
    padding-bottom: 161px;
  }

  .sc-xs-pb-162 {
    padding-bottom: 162px;
  }

  .sc-xs-pb-163 {
    padding-bottom: 163px;
  }

  .sc-xs-pb-164 {
    padding-bottom: 164px;
  }

  .sc-xs-pb-165 {
    padding-bottom: 165px;
  }

  .sc-xs-pb-166 {
    padding-bottom: 166px;
  }

  .sc-xs-pb-167 {
    padding-bottom: 167px;
  }

  .sc-xs-pb-168 {
    padding-bottom: 168px;
  }

  .sc-xs-pb-169 {
    padding-bottom: 169px;
  }

  .sc-xs-pb-170 {
    padding-bottom: 170px;
  }

  .sc-xs-pb-171 {
    padding-bottom: 171px;
  }

  .sc-xs-pb-172 {
    padding-bottom: 172px;
  }

  .sc-xs-pb-173 {
    padding-bottom: 173px;
  }

  .sc-xs-pb-174 {
    padding-bottom: 174px;
  }

  .sc-xs-pb-175 {
    padding-bottom: 175px;
  }

  .sc-xs-pb-176 {
    padding-bottom: 176px;
  }

  .sc-xs-pb-177 {
    padding-bottom: 177px;
  }

  .sc-xs-pb-178 {
    padding-bottom: 178px;
  }

  .sc-xs-pb-179 {
    padding-bottom: 179px;
  }

  .sc-xs-pb-180 {
    padding-bottom: 180px;
  }

  .sc-xs-pb-181 {
    padding-bottom: 181px;
  }

  .sc-xs-pb-182 {
    padding-bottom: 182px;
  }

  .sc-xs-pb-183 {
    padding-bottom: 183px;
  }

  .sc-xs-pb-184 {
    padding-bottom: 184px;
  }

  .sc-xs-pb-185 {
    padding-bottom: 185px;
  }

  .sc-xs-pb-186 {
    padding-bottom: 186px;
  }

  .sc-xs-pb-187 {
    padding-bottom: 187px;
  }

  .sc-xs-pb-188 {
    padding-bottom: 188px;
  }

  .sc-xs-pb-189 {
    padding-bottom: 189px;
  }

  .sc-xs-pb-190 {
    padding-bottom: 190px;
  }

  .sc-xs-pb-191 {
    padding-bottom: 191px;
  }

  .sc-xs-pb-192 {
    padding-bottom: 192px;
  }

  .sc-xs-pb-193 {
    padding-bottom: 193px;
  }

  .sc-xs-pb-194 {
    padding-bottom: 194px;
  }

  .sc-xs-pb-195 {
    padding-bottom: 195px;
  }

  .sc-xs-pb-196 {
    padding-bottom: 196px;
  }

  .sc-xs-pb-197 {
    padding-bottom: 197px;
  }

  .sc-xs-pb-198 {
    padding-bottom: 198px;
  }

  .sc-xs-pb-199 {
    padding-bottom: 199px;
  }

  .sc-xs-pb-200 {
    padding-bottom: 200px;
  }

  .sc-xs-pr-0 {
    padding-right: 0;
  }

  .sc-xs-pr-1 {
    padding-right: 1px;
  }

  .sc-xs-pr-2 {
    padding-right: 2px;
  }

  .sc-xs-pr-4 {
    padding-right: 4px;
  }

  .sc-xs-pr-5 {
    padding-right: 5px;
  }

  .sc-xs-pr-6 {
    padding-right: 6px;
  }

  .sc-xs-pr-7 {
    padding-right: 7px;
  }

  .sc-xs-pr-8 {
    padding-right: 8px;
  }

  .sc-xs-pr-9 {
    padding-right: 9px;
  }

  .sc-xs-pr-10 {
    padding-right: 10px;
  }

  .sc-xs-pr-11 {
    padding-right: 11px;
  }

  .sc-xs-pr-12 {
    padding-right: 12px;
  }

  .sc-xs-pr-13 {
    padding-right: 13px;
  }

  .sc-xs-pr-14 {
    padding-right: 14px;
  }

  .sc-xs-pr-15 {
    padding-right: 15px;
  }

  .sc-xs-pr-16 {
    padding-right: 16px;
  }

  .sc-xs-pr-17 {
    padding-right: 17px;
  }

  .sc-xs-pr-18 {
    padding-right: 18px;
  }

  .sc-xs-pr-19 {
    padding-right: 19px;
  }

  .sc-xs-pr-20 {
    padding-right: 20px;
  }

  .sc-xs-pr-21 {
    padding-right: 21px;
  }

  .sc-xs-pr-22 {
    padding-right: 22px;
  }

  .sc-xs-pr-23 {
    padding-right: 23px;
  }

  .sc-xs-pr-24 {
    padding-right: 24px;
  }

  .sc-xs-pr-25 {
    padding-right: 25px;
  }

  .sc-xs-pr-26 {
    padding-right: 26px;
  }

  .sc-xs-pr-27 {
    padding-right: 27px;
  }

  .sc-xs-pr-28 {
    padding-right: 28px;
  }

  .sc-xs-pr-29 {
    padding-right: 29px;
  }

  .sc-xs-pr-30 {
    padding-right: 30px;
  }

  .sc-xs-pr-31 {
    padding-right: 31px;
  }

  .sc-xs-pr-32 {
    padding-right: 32px;
  }

  .sc-xs-pr-33 {
    padding-right: 33px;
  }

  .sc-xs-pr-34 {
    padding-right: 34px;
  }

  .sc-xs-pr-35 {
    padding-right: 35px;
  }

  .sc-xs-pr-36 {
    padding-right: 36px;
  }

  .sc-xs-pr-37 {
    padding-right: 37px;
  }

  .sc-xs-pr-38 {
    padding-right: 38px;
  }

  .sc-xs-pr-39 {
    padding-right: 39px;
  }

  .sc-xs-pr-40 {
    padding-right: 40px;
  }

  .sc-xs-pr-41 {
    padding-right: 41px;
  }

  .sc-xs-pr-42 {
    padding-right: 42px;
  }

  .sc-xs-pr-43 {
    padding-right: 43px;
  }

  .sc-xs-pr-44 {
    padding-right: 44px;
  }

  .sc-xs-pr-45 {
    padding-right: 45px;
  }

  .sc-xs-pr-46 {
    padding-right: 46px;
  }

  .sc-xs-pr-47 {
    padding-right: 47px;
  }

  .sc-xs-pr-48 {
    padding-right: 48px;
  }

  .sc-xs-pr-49 {
    padding-right: 49px;
  }

  .sc-xs-pr-50 {
    padding-right: 50px;
  }

  .sc-xs-pr-51 {
    padding-right: 51px;
  }

  .sc-xs-pr-52 {
    padding-right: 52px;
  }

  .sc-xs-pr-53 {
    padding-right: 53px;
  }

  .sc-xs-pr-54 {
    padding-right: 54px;
  }

  .sc-xs-pr-55 {
    padding-right: 55px;
  }

  .sc-xs-pr-56 {
    padding-right: 56px;
  }

  .sc-xs-pr-57 {
    padding-right: 57px;
  }

  .sc-xs-pr-58 {
    padding-right: 58px;
  }

  .sc-xs-pr-59 {
    padding-right: 59px;
  }

  .sc-xs-pr-60 {
    padding-right: 60px;
  }

  .sc-xs-pr-61 {
    padding-right: 61px;
  }

  .sc-xs-pr-62 {
    padding-right: 62px;
  }

  .sc-xs-pr-63 {
    padding-right: 63px;
  }

  .sc-xs-pr-64 {
    padding-right: 64px;
  }

  .sc-xs-pr-65 {
    padding-right: 65px;
  }

  .sc-xs-pr-66 {
    padding-right: 66px;
  }

  .sc-xs-pr-67 {
    padding-right: 67px;
  }

  .sc-xs-pr-68 {
    padding-right: 68px;
  }

  .sc-xs-pr-69 {
    padding-right: 69px;
  }

  .sc-xs-pr-70 {
    padding-right: 70px;
  }

  .sc-xs-pr-71 {
    padding-right: 71px;
  }

  .sc-xs-pr-72 {
    padding-right: 72px;
  }

  .sc-xs-pr-73 {
    padding-right: 73px;
  }

  .sc-xs-pr-74 {
    padding-right: 74px;
  }

  .sc-xs-pr-75 {
    padding-right: 75px;
  }

  .sc-xs-pr-76 {
    padding-right: 76px;
  }

  .sc-xs-pr-77 {
    padding-right: 77px;
  }

  .sc-xs-pr-78 {
    padding-right: 78px;
  }

  .sc-xs-pr-79 {
    padding-right: 79px;
  }

  .sc-xs-pr-80 {
    padding-right: 80px;
  }

  .sc-xs-pr-81 {
    padding-right: 81px;
  }

  .sc-xs-pr-82 {
    padding-right: 82px;
  }

  .sc-xs-pr-83 {
    padding-right: 83px;
  }

  .sc-xs-pr-84 {
    padding-right: 84px;
  }

  .sc-xs-pr-85 {
    padding-right: 85px;
  }

  .sc-xs-pr-86 {
    padding-right: 86px;
  }

  .sc-xs-pr-87 {
    padding-right: 87px;
  }

  .sc-xs-pr-88 {
    padding-right: 88px;
  }

  .sc-xs-pr-89 {
    padding-right: 89px;
  }

  .sc-xs-pr-90 {
    padding-right: 90px;
  }

  .sc-xs-pr-91 {
    padding-right: 91px;
  }

  .sc-xs-pr-92 {
    padding-right: 92px;
  }

  .sc-xs-pr-93 {
    padding-right: 93px;
  }

  .sc-xs-pr-94 {
    padding-right: 94px;
  }

  .sc-xs-pr-95 {
    padding-right: 95px;
  }

  .sc-xs-pr-96 {
    padding-right: 96px;
  }

  .sc-xs-pr-97 {
    padding-right: 97px;
  }

  .sc-xs-pr-98 {
    padding-right: 98px;
  }

  .sc-xs-pr-99 {
    padding-right: 99px;
  }

  .sc-xs-pr-100 {
    padding-right: 100px;
  }

  .sc-xs-pr-101 {
    padding-right: 101px;
  }

  .sc-xs-pr-102 {
    padding-right: 102px;
  }

  .sc-xs-pr-104 {
    padding-right: 104px;
  }

  .sc-xs-pr-105 {
    padding-right: 105px;
  }

  .sc-xs-pr-106 {
    padding-right: 106px;
  }

  .sc-xs-pr-107 {
    padding-right: 107px;
  }

  .sc-xs-pr-108 {
    padding-right: 108px;
  }

  .sc-xs-pr-109 {
    padding-right: 109px;
  }

  .sc-xs-pr-110 {
    padding-right: 110px;
  }

  .sc-xs-pr-111 {
    padding-right: 111px;
  }

  .sc-xs-pr-112 {
    padding-right: 112px;
  }

  .sc-xs-pr-113 {
    padding-right: 113px;
  }

  .sc-xs-pr-114 {
    padding-right: 114px;
  }

  .sc-xs-pr-115 {
    padding-right: 115px;
  }

  .sc-xs-pr-116 {
    padding-right: 116px;
  }

  .sc-xs-pr-117 {
    padding-right: 117px;
  }

  .sc-xs-pr-118 {
    padding-right: 118px;
  }

  .sc-xs-pr-119 {
    padding-right: 119px;
  }

  .sc-xs-pr-120 {
    padding-right: 120px;
  }

  .sc-xs-pr-121 {
    padding-right: 121px;
  }

  .sc-xs-pr-122 {
    padding-right: 122px;
  }

  .sc-xs-pr-123 {
    padding-right: 123px;
  }

  .sc-xs-pr-124 {
    padding-right: 124px;
  }

  .sc-xs-pr-125 {
    padding-right: 125px;
  }

  .sc-xs-pr-126 {
    padding-right: 126px;
  }

  .sc-xs-pr-127 {
    padding-right: 127px;
  }

  .sc-xs-pr-128 {
    padding-right: 128px;
  }

  .sc-xs-pr-129 {
    padding-right: 129px;
  }

  .sc-xs-pr-130 {
    padding-right: 130px;
  }

  .sc-xs-pr-131 {
    padding-right: 131px;
  }

  .sc-xs-pr-132 {
    padding-right: 132px;
  }

  .sc-xs-pr-133 {
    padding-right: 133px;
  }

  .sc-xs-pr-134 {
    padding-right: 134px;
  }

  .sc-xs-pr-135 {
    padding-right: 135px;
  }

  .sc-xs-pr-136 {
    padding-right: 136px;
  }

  .sc-xs-pr-137 {
    padding-right: 137px;
  }

  .sc-xs-pr-138 {
    padding-right: 138px;
  }

  .sc-xs-pr-139 {
    padding-right: 139px;
  }

  .sc-xs-pr-140 {
    padding-right: 140px;
  }

  .sc-xs-pr-141 {
    padding-right: 141px;
  }

  .sc-xs-pr-142 {
    padding-right: 142px;
  }

  .sc-xs-pr-143 {
    padding-right: 143px;
  }

  .sc-xs-pr-144 {
    padding-right: 144px;
  }

  .sc-xs-pr-145 {
    padding-right: 145px;
  }

  .sc-xs-pr-146 {
    padding-right: 146px;
  }

  .sc-xs-pr-147 {
    padding-right: 147px;
  }

  .sc-xs-pr-148 {
    padding-right: 148px;
  }

  .sc-xs-pr-149 {
    padding-right: 149px;
  }

  .sc-xs-pr-150 {
    padding-right: 150px;
  }

  .sc-xs-pr-151 {
    padding-right: 151px;
  }

  .sc-xs-pr-152 {
    padding-right: 152px;
  }

  .sc-xs-pr-153 {
    padding-right: 153px;
  }

  .sc-xs-pr-154 {
    padding-right: 154px;
  }

  .sc-xs-pr-155 {
    padding-right: 155px;
  }

  .sc-xs-pr-156 {
    padding-right: 156px;
  }

  .sc-xs-pr-157 {
    padding-right: 157px;
  }

  .sc-xs-pr-158 {
    padding-right: 158px;
  }

  .sc-xs-pr-159 {
    padding-right: 159px;
  }

  .sc-xs-pr-160 {
    padding-right: 160px;
  }

  .sc-xs-pr-161 {
    padding-right: 161px;
  }

  .sc-xs-pr-162 {
    padding-right: 162px;
  }

  .sc-xs-pr-163 {
    padding-right: 163px;
  }

  .sc-xs-pr-164 {
    padding-right: 164px;
  }

  .sc-xs-pr-165 {
    padding-right: 165px;
  }

  .sc-xs-pr-166 {
    padding-right: 166px;
  }

  .sc-xs-pr-167 {
    padding-right: 167px;
  }

  .sc-xs-pr-168 {
    padding-right: 168px;
  }

  .sc-xs-pr-169 {
    padding-right: 169px;
  }

  .sc-xs-pr-170 {
    padding-right: 170px;
  }

  .sc-xs-pr-171 {
    padding-right: 171px;
  }

  .sc-xs-pr-172 {
    padding-right: 172px;
  }

  .sc-xs-pr-173 {
    padding-right: 173px;
  }

  .sc-xs-pr-174 {
    padding-right: 174px;
  }

  .sc-xs-pr-175 {
    padding-right: 175px;
  }

  .sc-xs-pr-176 {
    padding-right: 176px;
  }

  .sc-xs-pr-177 {
    padding-right: 177px;
  }

  .sc-xs-pr-178 {
    padding-right: 178px;
  }

  .sc-xs-pr-179 {
    padding-right: 179px;
  }

  .sc-xs-pr-180 {
    padding-right: 180px;
  }

  .sc-xs-pr-181 {
    padding-right: 181px;
  }

  .sc-xs-pr-182 {
    padding-right: 182px;
  }

  .sc-xs-pr-183 {
    padding-right: 183px;
  }

  .sc-xs-pr-184 {
    padding-right: 184px;
  }

  .sc-xs-pr-185 {
    padding-right: 185px;
  }

  .sc-xs-pr-186 {
    padding-right: 186px;
  }

  .sc-xs-pr-187 {
    padding-right: 187px;
  }

  .sc-xs-pr-188 {
    padding-right: 188px;
  }

  .sc-xs-pr-189 {
    padding-right: 189px;
  }

  .sc-xs-pr-190 {
    padding-right: 190px;
  }

  .sc-xs-pr-191 {
    padding-right: 191px;
  }

  .sc-xs-pr-192 {
    padding-right: 192px;
  }

  .sc-xs-pr-193 {
    padding-right: 193px;
  }

  .sc-xs-pr-194 {
    padding-right: 194px;
  }

  .sc-xs-pr-195 {
    padding-right: 195px;
  }

  .sc-xs-pr-196 {
    padding-right: 196px;
  }

  .sc-xs-pr-197 {
    padding-right: 197px;
  }

  .sc-xs-pr-198 {
    padding-right: 198px;
  }

  .sc-xs-pr-199 {
    padding-right: 199px;
  }

  .sc-xs-pr-200 {
    padding-right: 200px;
  }

  .sc-xs-pl-0 {
    padding-left: 0;
  }

  .sc-xs-pl-1 {
    padding-left: 1px;
  }

  .sc-xs-pl-2 {
    padding-left: 2px;
  }

  .sc-pb-3 {
    padding-bottom: 3px;
  }

  .sc-xs-pl-4 {
    padding-left: 4px;
  }

  .sc-xs-pl-5 {
    padding-left: 5px;
  }

  .sc-xs-pl-6 {
    padding-left: 6px;
  }

  .sc-xs-pl-7 {
    padding-left: 7px;
  }

  .sc-xs-pl-8 {
    padding-left: 8px;
  }

  .sc-xs-pl-9 {
    padding-left: 9px;
  }

  .sc-xs-pl-10 {
    padding-left: 10px;
  }

  .sc-xs-pl-11 {
    padding-left: 11px;
  }

  .sc-xs-pl-12 {
    padding-left: 12px;
  }

  .sc-xs-pl-13 {
    padding-left: 13px;
  }

  .sc-xs-pl-14 {
    padding-left: 14px;
  }

  .sc-xs-pl-15 {
    padding-left: 15px;
  }

  .sc-xs-pl-16 {
    padding-left: 16px;
  }

  .sc-xs-pl-17 {
    padding-left: 17px;
  }

  .sc-xs-pl-18 {
    padding-left: 18px;
  }

  .sc-xs-pl-19 {
    padding-left: 19px;
  }

  .sc-xs-pl-20 {
    padding-left: 20px;
  }

  .sc-xs-pl-21 {
    padding-left: 21px;
  }

  .sc-xs-pl-22 {
    padding-left: 22px;
  }

  .sc-xs-pl-23 {
    padding-left: 23px;
  }

  .sc-xs-pl-24 {
    padding-left: 24px;
  }

  .sc-xs-pl-25 {
    padding-left: 25px;
  }

  .sc-xs-pl-26 {
    padding-left: 26px;
  }

  .sc-xs-pl-27 {
    padding-left: 27px;
  }

  .sc-xs-pl-28 {
    padding-left: 28px;
  }

  .sc-xs-pl-29 {
    padding-left: 29px;
  }

  .sc-xs-pl-30 {
    padding-left: 30px;
  }

  .sc-xs-pl-31 {
    padding-left: 31px;
  }

  .sc-xs-pl-32 {
    padding-left: 32px;
  }

  .sc-xs-pl-33 {
    padding-left: 33px;
  }

  .sc-xs-pl-34 {
    padding-left: 34px;
  }

  .sc-xs-pl-35 {
    padding-left: 35px;
  }

  .sc-xs-pl-36 {
    padding-left: 36px;
  }

  .sc-xs-pl-37 {
    padding-left: 37px;
  }

  .sc-xs-pl-38 {
    padding-left: 38px;
  }

  .sc-xs-pl-39 {
    padding-left: 39px;
  }

  .sc-xs-pl-40 {
    padding-left: 40px;
  }

  .sc-xs-pl-41 {
    padding-left: 41px;
  }

  .sc-xs-pl-42 {
    padding-left: 42px;
  }

  .sc-xs-pl-43 {
    padding-left: 43px;
  }

  .sc-xs-pl-44 {
    padding-left: 44px;
  }

  .sc-xs-pl-45 {
    padding-left: 45px;
  }

  .sc-xs-pl-46 {
    padding-left: 46px;
  }

  .sc-xs-pl-47 {
    padding-left: 47px;
  }

  .sc-xs-pl-48 {
    padding-left: 48px;
  }

  .sc-xs-pl-49 {
    padding-left: 49px;
  }

  .sc-xs-pl-50 {
    padding-left: 50px;
  }

  .sc-xs-pl-51 {
    padding-left: 51px;
  }

  .sc-xs-pl-52 {
    padding-left: 52px;
  }

  .sc-xs-pl-53 {
    padding-left: 53px;
  }

  .sc-xs-pl-54 {
    padding-left: 54px;
  }

  .sc-xs-pl-55 {
    padding-left: 55px;
  }

  .sc-xs-pl-56 {
    padding-left: 56px;
  }

  .sc-xs-pl-57 {
    padding-left: 57px;
  }

  .sc-xs-pl-58 {
    padding-left: 58px;
  }

  .sc-xs-pl-59 {
    padding-left: 59px;
  }

  .sc-xs-pl-60 {
    padding-left: 60px;
  }

  .sc-xs-pl-61 {
    padding-left: 61px;
  }

  .sc-xs-pl-62 {
    padding-left: 62px;
  }

  .sc-xs-pl-63 {
    padding-left: 63px;
  }

  .sc-xs-pl-64 {
    padding-left: 64px;
  }

  .sc-xs-pl-65 {
    padding-left: 65px;
  }

  .sc-xs-pl-66 {
    padding-left: 66px;
  }

  .sc-xs-pl-67 {
    padding-left: 67px;
  }

  .sc-xs-pl-68 {
    padding-left: 68px;
  }

  .sc-xs-pl-69 {
    padding-left: 69px;
  }

  .sc-xs-pl-70 {
    padding-left: 70px;
  }

  .sc-xs-pl-71 {
    padding-left: 71px;
  }

  .sc-xs-pl-72 {
    padding-left: 72px;
  }

  .sc-xs-pl-73 {
    padding-left: 73px;
  }

  .sc-xs-pl-74 {
    padding-left: 74px;
  }

  .sc-xs-pl-75 {
    padding-left: 75px;
  }

  .sc-xs-pl-76 {
    padding-left: 76px;
  }

  .sc-xs-pl-77 {
    padding-left: 77px;
  }

  .sc-xs-pl-78 {
    padding-left: 78px;
  }

  .sc-xs-pl-79 {
    padding-left: 79px;
  }

  .sc-xs-pl-80 {
    padding-left: 80px;
  }

  .sc-xs-pl-81 {
    padding-left: 81px;
  }

  .sc-xs-pl-82 {
    padding-left: 82px;
  }

  .sc-xs-pl-83 {
    padding-left: 83px;
  }

  .sc-xs-pl-84 {
    padding-left: 84px;
  }

  .sc-xs-pl-85 {
    padding-left: 85px;
  }

  .sc-xs-pl-86 {
    padding-left: 86px;
  }

  .sc-xs-pl-87 {
    padding-left: 87px;
  }

  .sc-xs-pl-88 {
    padding-left: 88px;
  }

  .sc-xs-pl-89 {
    padding-left: 89px;
  }

  .sc-xs-pl-90 {
    padding-left: 90px;
  }

  .sc-xs-pl-91 {
    padding-left: 91px;
  }

  .sc-xs-pl-92 {
    padding-left: 92px;
  }

  .sc-xs-pl-93 {
    padding-left: 93px;
  }

  .sc-xs-pl-94 {
    padding-left: 94px;
  }

  .sc-xs-pl-95 {
    padding-left: 95px;
  }

  .sc-xs-pl-96 {
    padding-left: 96px;
  }

  .sc-xs-pl-97 {
    padding-left: 97px;
  }

  .sc-xs-pl-98 {
    padding-left: 98px;
  }

  .sc-xs-pl-99 {
    padding-left: 99px;
  }

  .sc-xs-pl-100 {
    padding-left: 100px;
  }

  .sc-xs-pl-101 {
    padding-left: 101px;
  }

  .sc-xs-pl-102 {
    padding-left: 102px;
  }

  .sc-xs-pb-103 {
    padding-bottom: 103px;
  }

  .sc-xs-pl-104 {
    padding-left: 104px;
  }

  .sc-xs-pl-105 {
    padding-left: 105px;
  }

  .sc-xs-pl-106 {
    padding-left: 106px;
  }

  .sc-xs-pl-107 {
    padding-left: 107px;
  }

  .sc-xs-pl-108 {
    padding-left: 108px;
  }

  .sc-xs-pl-109 {
    padding-left: 109px;
  }

  .sc-xs-pl-110 {
    padding-left: 110px;
  }

  .sc-xs-pl-111 {
    padding-left: 111px;
  }

  .sc-xs-pl-112 {
    padding-left: 112px;
  }

  .sc-xs-pl-113 {
    padding-left: 113px;
  }

  .sc-xs-pl-114 {
    padding-left: 114px;
  }

  .sc-xs-pl-115 {
    padding-left: 115px;
  }

  .sc-xs-pl-116 {
    padding-left: 116px;
  }

  .sc-xs-pl-117 {
    padding-left: 117px;
  }

  .sc-xs-pl-118 {
    padding-left: 118px;
  }

  .sc-xs-pl-119 {
    padding-left: 119px;
  }

  .sc-xs-pl-120 {
    padding-left: 120px;
  }

  .sc-xs-pl-121 {
    padding-left: 121px;
  }

  .sc-xs-pl-122 {
    padding-left: 122px;
  }

  .sc-xs-pl-123 {
    padding-left: 123px;
  }

  .sc-xs-pl-124 {
    padding-left: 124px;
  }

  .sc-xs-pl-125 {
    padding-left: 125px;
  }

  .sc-xs-pl-126 {
    padding-left: 126px;
  }

  .sc-xs-pl-127 {
    padding-left: 127px;
  }

  .sc-xs-pl-128 {
    padding-left: 128px;
  }

  .sc-xs-pl-129 {
    padding-left: 129px;
  }

  .sc-xs-pl-130 {
    padding-left: 130px;
  }

  .sc-xs-pl-131 {
    padding-left: 131px;
  }

  .sc-xs-pl-132 {
    padding-left: 132px;
  }

  .sc-xs-pl-133 {
    padding-left: 133px;
  }

  .sc-xs-pl-134 {
    padding-left: 134px;
  }

  .sc-xs-pl-135 {
    padding-left: 135px;
  }

  .sc-xs-pl-136 {
    padding-left: 136px;
  }

  .sc-xs-pl-137 {
    padding-left: 137px;
  }

  .sc-xs-pl-138 {
    padding-left: 138px;
  }

  .sc-xs-pl-139 {
    padding-left: 139px;
  }

  .sc-xs-pl-140 {
    padding-left: 140px;
  }

  .sc-xs-pl-141 {
    padding-left: 141px;
  }

  .sc-xs-pl-142 {
    padding-left: 142px;
  }

  .sc-xs-pl-143 {
    padding-left: 143px;
  }

  .sc-xs-pl-144 {
    padding-left: 144px;
  }

  .sc-xs-pl-145 {
    padding-left: 145px;
  }

  .sc-xs-pl-146 {
    padding-left: 146px;
  }

  .sc-xs-pl-147 {
    padding-left: 147px;
  }

  .sc-xs-pl-148 {
    padding-left: 148px;
  }

  .sc-xs-pl-149 {
    padding-left: 149px;
  }

  .sc-xs-pl-150 {
    padding-left: 150px;
  }

  .sc-xs-pl-151 {
    padding-left: 151px;
  }

  .sc-xs-pl-152 {
    padding-left: 152px;
  }

  .sc-xs-pl-153 {
    padding-left: 153px;
  }

  .sc-xs-pl-154 {
    padding-left: 154px;
  }

  .sc-xs-pl-155 {
    padding-left: 155px;
  }

  .sc-xs-pl-156 {
    padding-left: 156px;
  }

  .sc-xs-pl-157 {
    padding-left: 157px;
  }

  .sc-xs-pl-158 {
    padding-left: 158px;
  }

  .sc-xs-pl-159 {
    padding-left: 159px;
  }

  .sc-xs-pl-160 {
    padding-left: 160px;
  }

  .sc-xs-pl-161 {
    padding-left: 161px;
  }

  .sc-xs-pl-162 {
    padding-left: 162px;
  }

  .sc-xs-pl-163 {
    padding-left: 163px;
  }

  .sc-xs-pl-164 {
    padding-left: 164px;
  }

  .sc-xs-pl-165 {
    padding-left: 165px;
  }

  .sc-xs-pl-166 {
    padding-left: 166px;
  }

  .sc-xs-pl-167 {
    padding-left: 167px;
  }

  .sc-xs-pl-168 {
    padding-left: 168px;
  }

  .sc-xs-pl-169 {
    padding-left: 169px;
  }

  .sc-xs-pl-170 {
    padding-left: 170px;
  }

  .sc-xs-pl-171 {
    padding-left: 171px;
  }

  .sc-xs-pl-172 {
    padding-left: 172px;
  }

  .sc-xs-pl-173 {
    padding-left: 173px;
  }

  .sc-xs-pl-174 {
    padding-left: 174px;
  }

  .sc-xs-pl-175 {
    padding-left: 175px;
  }

  .sc-xs-pl-176 {
    padding-left: 176px;
  }

  .sc-xs-pl-177 {
    padding-left: 177px;
  }

  .sc-xs-pl-178 {
    padding-left: 178px;
  }

  .sc-xs-pl-179 {
    padding-left: 179px;
  }

  .sc-xs-pl-180 {
    padding-left: 180px;
  }

  .sc-xs-pl-181 {
    padding-left: 181px;
  }

  .sc-xs-pl-182 {
    padding-left: 182px;
  }

  .sc-xs-pl-183 {
    padding-left: 183px;
  }

  .sc-xs-pl-184 {
    padding-left: 184px;
  }

  .sc-xs-pl-185 {
    padding-left: 185px;
  }

  .sc-xs-pl-186 {
    padding-left: 186px;
  }

  .sc-xs-pl-187 {
    padding-left: 187px;
  }

  .sc-xs-pl-188 {
    padding-left: 188px;
  }

  .sc-xs-pl-189 {
    padding-left: 189px;
  }

  .sc-xs-pl-190 {
    padding-left: 190px;
  }

  .sc-xs-pl-191 {
    padding-left: 191px;
  }

  .sc-xs-pl-192 {
    padding-left: 192px;
  }

  .sc-xs-pl-193 {
    padding-left: 193px;
  }

  .sc-xs-pl-194 {
    padding-left: 194px;
  }

  .sc-xs-pl-195 {
    padding-left: 195px;
  }

  .sc-xs-pl-196 {
    padding-left: 196px;
  }

  .sc-xs-pl-197 {
    padding-left: 197px;
  }

  .sc-xs-pl-198 {
    padding-left: 198px;
  }

  .sc-xs-pl-199 {
    padding-left: 199px;
  }

  .sc-xs-pl-200 {
    padding-left: 200px;
  }

  .sc-xs-mt-0 {
    margin-top: 0;
  }

  .sc-xs-mt-1 {
    margin-top: 1px;
  }

  .sc-xs-mt-2 {
    margin-top: 2px;
  }

  .sc-xs-mt-3 {
    margin-top: 3px;
  }

  .sc-xs-mt-4 {
    margin-top: 4px;
  }

  .sc-xs-mt-5 {
    margin-top: 5px;
  }

  .sc-xs-mt-6 {
    margin-top: 6px;
  }

  .sc-xs-mt-7 {
    margin-top: 7px;
  }

  .sc-xs-mt-8 {
    margin-top: 8px;
  }

  .sc-xs-mt-9 {
    margin-top: 9px;
  }

  .sc-xs-mt-10 {
    margin-top: 10px;
  }

  .sc-xs-mt-11 {
    margin-top: 11px;
  }

  .sc-xs-mt-12 {
    margin-top: 12px;
  }

  .sc-xs-mt-13 {
    margin-top: 13px;
  }

  .sc-xs-mt-14 {
    margin-top: 14px;
  }

  .sc-xs-mt-15 {
    margin-top: 15px;
  }

  .sc-xs-mt-16 {
    margin-top: 16px;
  }

  .sc-xs-mt-17 {
    margin-top: 17px;
  }

  .sc-xs-mt-18 {
    margin-top: 18px;
  }

  .sc-xs-mt-19 {
    margin-top: 19px;
  }

  .sc-xs-mt-20 {
    margin-top: 20px;
  }

  .sc-xs-mt-21 {
    margin-top: 21px;
  }

  .sc-xs-mt-22 {
    margin-top: 22px;
  }

  .sc-xs-mt-23 {
    margin-top: 23px;
  }

  .sc-xs-mt-24 {
    margin-top: 24px;
  }

  .sc-xs-mt-25 {
    margin-top: 25px;
  }

  .sc-xs-mt-26 {
    margin-top: 26px;
  }

  .sc-xs-mt-27 {
    margin-top: 27px;
  }

  .sc-xs-mt-28 {
    margin-top: 28px;
  }

  .sc-xs-mt-29 {
    margin-top: 29px;
  }

  .sc-xs-mt-30 {
    margin-top: 30px;
  }

  .sc-xs-mt-31 {
    margin-top: 31px;
  }

  .sc-xs-mt-32 {
    margin-top: 32px;
  }

  .sc-xs-mt-33 {
    margin-top: 33px;
  }

  .sc-xs-mt-34 {
    margin-top: 34px;
  }

  .sc-xs-mt-35 {
    margin-top: 35px;
  }

  .sc-xs-mt-36 {
    margin-top: 36px;
  }

  .sc-xs-mt-37 {
    margin-top: 37px;
  }

  .sc-xs-mt-38 {
    margin-top: 38px;
  }

  .sc-xs-mt-39 {
    margin-top: 39px;
  }

  .sc-xs-mt-40 {
    margin-top: 40px;
  }

  .sc-xs-mt-41 {
    margin-top: 41px;
  }

  .sc-xs-mt-42 {
    margin-top: 42px;
  }

  .sc-xs-mt-43 {
    margin-top: 43px;
  }

  .sc-xs-mt-44 {
    margin-top: 44px;
  }

  .sc-xs-mt-45 {
    margin-top: 45px;
  }

  .sc-xs-mt-46 {
    margin-top: 46px;
  }

  .sc-xs-mt-47 {
    margin-top: 47px;
  }

  .sc-xs-mt-48 {
    margin-top: 48px;
  }

  .sc-xs-mt-49 {
    margin-top: 49px;
  }

  .sc-xs-mt-50 {
    margin-top: 50px;
  }

  .sc-xs-mt-51 {
    margin-top: 51px;
  }

  .sc-xs-mt-52 {
    margin-top: 52px;
  }

  .sc-xs-mt-53 {
    margin-top: 53px;
  }

  .sc-xs-mt-54 {
    margin-top: 54px;
  }

  .sc-xs-mt-55 {
    margin-top: 55px;
  }

  .sc-xs-mt-56 {
    margin-top: 56px;
  }

  .sc-xs-mt-57 {
    margin-top: 57px;
  }

  .sc-xs-mt-58 {
    margin-top: 58px;
  }

  .sc-xs-mt-59 {
    margin-top: 59px;
  }

  .sc-xs-mt-60 {
    margin-top: 60px;
  }

  .sc-xs-mt-61 {
    margin-top: 61px;
  }

  .sc-xs-mt-62 {
    margin-top: 62px;
  }

  .sc-xs-mt-63 {
    margin-top: 63px;
  }

  .sc-xs-mt-64 {
    margin-top: 64px;
  }

  .sc-xs-mt-65 {
    margin-top: 65px;
  }

  .sc-xs-mt-66 {
    margin-top: 66px;
  }

  .sc-xs-mt-67 {
    margin-top: 67px;
  }

  .sc-xs-mt-68 {
    margin-top: 68px;
  }

  .sc-xs-mt-69 {
    margin-top: 69px;
  }

  .sc-xs-mt-70 {
    margin-top: 70px;
  }

  .sc-xs-mt-71 {
    margin-top: 71px;
  }

  .sc-xs-mt-72 {
    margin-top: 72px;
  }

  .sc-xs-mt-73 {
    margin-top: 73px;
  }

  .sc-xs-mt-74 {
    margin-top: 74px;
  }

  .sc-xs-mt-75 {
    margin-top: 75px;
  }

  .sc-xs-mt-76 {
    margin-top: 76px;
  }

  .sc-xs-mt-77 {
    margin-top: 77px;
  }

  .sc-xs-mt-78 {
    margin-top: 78px;
  }

  .sc-xs-mt-79 {
    margin-top: 79px;
  }

  .sc-xs-mt-80 {
    margin-top: 80px;
  }

  .sc-xs-mt-81 {
    margin-top: 81px;
  }

  .sc-xs-mt-82 {
    margin-top: 82px;
  }

  .sc-xs-mt-83 {
    margin-top: 83px;
  }

  .sc-xs-mt-84 {
    margin-top: 84px;
  }

  .sc-xs-mt-85 {
    margin-top: 85px;
  }

  .sc-xs-mt-86 {
    margin-top: 86px;
  }

  .sc-xs-mt-87 {
    margin-top: 87px;
  }

  .sc-xs-mt-88 {
    margin-top: 88px;
  }

  .sc-xs-mt-89 {
    margin-top: 89px;
  }

  .sc-xs-mt-90 {
    margin-top: 90px;
  }

  .sc-xs-mt-91 {
    margin-top: 91px;
  }

  .sc-xs-mt-92 {
    margin-top: 92px;
  }

  .sc-xs-mt-93 {
    margin-top: 93px;
  }

  .sc-xs-mt-94 {
    margin-top: 94px;
  }

  .sc-xs-mt-95 {
    margin-top: 95px;
  }

  .sc-xs-mt-96 {
    margin-top: 96px;
  }

  .sc-xs-mt-97 {
    margin-top: 97px;
  }

  .sc-xs-mt-98 {
    margin-top: 98px;
  }

  .sc-xs-mt-99 {
    margin-top: 99px;
  }

  .sc-xs-mt-100 {
    margin-top: 100px;
  }

  .sc-xs-mt-101 {
    margin-top: 101px;
  }

  .sc-xs-mt-102 {
    margin-top: 102px;
  }

  .sc-xs-mt-103 {
    margin-top: 103px;
  }

  .sc-xs-mt-104 {
    margin-top: 104px;
  }

  .sc-xs-mt-105 {
    margin-top: 105px;
  }

  .sc-xs-mt-106 {
    margin-top: 106px;
  }

  .sc-xs-mt-107 {
    margin-top: 107px;
  }

  .sc-xs-mt-108 {
    margin-top: 108px;
  }

  .sc-xs-mt-109 {
    margin-top: 109px;
  }

  .sc-xs-mt-110 {
    margin-top: 110px;
  }

  .sc-xs-mt-111 {
    margin-top: 111px;
  }

  .sc-xs-mt-112 {
    margin-top: 112px;
  }

  .sc-xs-mt-113 {
    margin-top: 113px;
  }

  .sc-xs-mt-114 {
    margin-top: 114px;
  }

  .sc-xs-mt-115 {
    margin-top: 115px;
  }

  .sc-xs-mt-116 {
    margin-top: 116px;
  }

  .sc-xs-mt-117 {
    margin-top: 117px;
  }

  .sc-xs-mt-118 {
    margin-top: 118px;
  }

  .sc-xs-mt-119 {
    margin-top: 119px;
  }

  .sc-xs-mt-120 {
    margin-top: 120px;
  }

  .sc-xs-mt-121 {
    margin-top: 121px;
  }

  .sc-xs-mt-122 {
    margin-top: 122px;
  }

  .sc-xs-mt-123 {
    margin-top: 123px;
  }

  .sc-xs-mt-124 {
    margin-top: 124px;
  }

  .sc-xs-mt-125 {
    margin-top: 125px;
  }

  .sc-xs-mt-126 {
    margin-top: 126px;
  }

  .sc-xs-mt-127 {
    margin-top: 127px;
  }

  .sc-xs-mt-128 {
    margin-top: 128px;
  }

  .sc-xs-mt-129 {
    margin-top: 129px;
  }

  .sc-xs-mt-130 {
    margin-top: 130px;
  }

  .sc-xs-mt-131 {
    margin-top: 131px;
  }

  .sc-xs-mt-132 {
    margin-top: 132px;
  }

  .sc-xs-mt-133 {
    margin-top: 133px;
  }

  .sc-xs-mt-134 {
    margin-top: 134px;
  }

  .sc-xs-mt-135 {
    margin-top: 135px;
  }

  .sc-xs-mt-136 {
    margin-top: 136px;
  }

  .sc-xs-mt-137 {
    margin-top: 137px;
  }

  .sc-xs-mt-138 {
    margin-top: 138px;
  }

  .sc-xs-mt-139 {
    margin-top: 139px;
  }

  .sc-xs-mt-140 {
    margin-top: 140px;
  }

  .sc-xs-mt-141 {
    margin-top: 141px;
  }

  .sc-xs-mt-142 {
    margin-top: 142px;
  }

  .sc-xs-mt-143 {
    margin-top: 143px;
  }

  .sc-xs-mt-144 {
    margin-top: 144px;
  }

  .sc-xs-mt-145 {
    margin-top: 145px;
  }

  .sc-xs-mt-146 {
    margin-top: 146px;
  }

  .sc-xs-mt-147 {
    margin-top: 147px;
  }

  .sc-xs-mt-148 {
    margin-top: 148px;
  }

  .sc-xs-mt-149 {
    margin-top: 149px;
  }

  .sc-xs-mt-150 {
    margin-top: 150px;
  }

  .sc-xs-mt-151 {
    margin-top: 151px;
  }

  .sc-xs-mt-152 {
    margin-top: 152px;
  }

  .sc-xs-mt-153 {
    margin-top: 153px;
  }

  .sc-xs-mt-154 {
    margin-top: 154px;
  }

  .sc-xs-mt-155 {
    margin-top: 155px;
  }

  .sc-xs-mt-156 {
    margin-top: 156px;
  }

  .sc-xs-mt-157 {
    margin-top: 157px;
  }

  .sc-xs-mt-158 {
    margin-top: 158px;
  }

  .sc-xs-mt-159 {
    margin-top: 159px;
  }

  .sc-xs-mt-160 {
    margin-top: 160px;
  }

  .sc-xs-mt-161 {
    margin-top: 161px;
  }

  .sc-xs-mt-162 {
    margin-top: 162px;
  }

  .sc-xs-mt-163 {
    margin-top: 163px;
  }

  .sc-xs-mt-164 {
    margin-top: 164px;
  }

  .sc-xs-mt-165 {
    margin-top: 165px;
  }

  .sc-xs-mt-166 {
    margin-top: 166px;
  }

  .sc-xs-mt-167 {
    margin-top: 167px;
  }

  .sc-xs-mt-168 {
    margin-top: 168px;
  }

  .sc-xs-mt-169 {
    margin-top: 169px;
  }

  .sc-xs-mt-170 {
    margin-top: 170px;
  }

  .sc-xs-mt-171 {
    margin-top: 171px;
  }

  .sc-xs-mt-172 {
    margin-top: 172px;
  }

  .sc-xs-mt-173 {
    margin-top: 173px;
  }

  .sc-xs-mt-174 {
    margin-top: 174px;
  }

  .sc-xs-mt-175 {
    margin-top: 175px;
  }

  .sc-xs-mt-176 {
    margin-top: 176px;
  }

  .sc-xs-mt-177 {
    margin-top: 177px;
  }

  .sc-xs-mt-178 {
    margin-top: 178px;
  }

  .sc-xs-mt-179 {
    margin-top: 179px;
  }

  .sc-xs-mt-180 {
    margin-top: 180px;
  }

  .sc-xs-mt-181 {
    margin-top: 181px;
  }

  .sc-xs-mt-182 {
    margin-top: 182px;
  }

  .sc-xs-mt-183 {
    margin-top: 183px;
  }

  .sc-xs-mt-184 {
    margin-top: 184px;
  }

  .sc-xs-mt-185 {
    margin-top: 185px;
  }

  .sc-xs-mt-186 {
    margin-top: 186px;
  }

  .sc-xs-mt-187 {
    margin-top: 187px;
  }

  .sc-xs-mt-188 {
    margin-top: 188px;
  }

  .sc-xs-mt-189 {
    margin-top: 189px;
  }

  .sc-xs-mt-190 {
    margin-top: 190px;
  }

  .sc-xs-mt-191 {
    margin-top: 191px;
  }

  .sc-xs-mt-192 {
    margin-top: 192px;
  }

  .sc-xs-mt-193 {
    margin-top: 193px;
  }

  .sc-xs-mt-194 {
    margin-top: 194px;
  }

  .sc-xs-mt-195 {
    margin-top: 195px;
  }

  .sc-xs-mt-196 {
    margin-top: 196px;
  }

  .sc-xs-mt-197 {
    margin-top: 197px;
  }

  .sc-xs-mt-198 {
    margin-top: 198px;
  }

  .sc-xs-mt-199 {
    margin-top: 199px;
  }

  .sc-xs-mt-200 {
    margin-top: 200px;
  }

  .sc-xs-mb-0 {
    margin-bottom: 0;
  }

  .sc-xs-mb-1 {
    margin-bottom: 1px;
  }

  .sc-xs-mb-2 {
    margin-bottom: 2px;
  }

  .sc-xs-mb-3 {
    margin-bottom: 3px;
  }

  .sc-xs-mb-4 {
    margin-bottom: 4px;
  }

  .sc-xs-mb-5 {
    margin-bottom: 5px;
  }

  .sc-xs-mb-6 {
    margin-bottom: 6px;
  }

  .sc-xs-mb-7 {
    margin-bottom: 7px;
  }

  .sc-xs-mb-8 {
    margin-bottom: 8px;
  }

  .sc-xs-mb-9 {
    margin-bottom: 9px;
  }

  .sc-xs-mb-10 {
    margin-bottom: 10px;
  }

  .sc-xs-mb-11 {
    margin-bottom: 11px;
  }

  .sc-xs-mb-12 {
    margin-bottom: 12px;
  }

  .sc-xs-mb-13 {
    margin-bottom: 13px;
  }

  .sc-xs-mb-14 {
    margin-bottom: 14px;
  }

  .sc-xs-mb-15 {
    margin-bottom: 15px;
  }

  .sc-xs-mb-16 {
    margin-bottom: 16px;
  }

  .sc-xs-mb-17 {
    margin-bottom: 17px;
  }

  .sc-xs-mb-18 {
    margin-bottom: 18px;
  }

  .sc-xs-mb-19 {
    margin-bottom: 19px;
  }

  .sc-xs-mb-20 {
    margin-bottom: 20px;
  }

  .sc-xs-mb-21 {
    margin-bottom: 21px;
  }

  .sc-xs-mb-22 {
    margin-bottom: 22px;
  }

  .sc-xs-mb-23 {
    margin-bottom: 23px;
  }

  .sc-xs-mb-24 {
    margin-bottom: 24px;
  }

  .sc-xs-mb-25 {
    margin-bottom: 25px;
  }

  .sc-xs-mb-26 {
    margin-bottom: 26px;
  }

  .sc-xs-mb-27 {
    margin-bottom: 27px;
  }

  .sc-xs-mb-28 {
    margin-bottom: 28px;
  }

  .sc-xs-mb-29 {
    margin-bottom: 29px;
  }

  .sc-xs-mb-30 {
    margin-bottom: 30px;
  }

  .sc-xs-mb-31 {
    margin-bottom: 31px;
  }

  .sc-xs-mb-32 {
    margin-bottom: 32px;
  }

  .sc-xs-mb-33 {
    margin-bottom: 33px;
  }

  .sc-xs-mb-34 {
    margin-bottom: 34px;
  }

  .sc-xs-mb-35 {
    margin-bottom: 35px;
  }

  .sc-xs-mb-36 {
    margin-bottom: 36px;
  }

  .sc-xs-mb-37 {
    margin-bottom: 37px;
  }

  .sc-xs-mb-38 {
    margin-bottom: 38px;
  }

  .sc-xs-mb-39 {
    margin-bottom: 39px;
  }

  .sc-xs-mb-40 {
    margin-bottom: 40px;
  }

  .sc-xs-mb-41 {
    margin-bottom: 41px;
  }

  .sc-xs-mb-42 {
    margin-bottom: 42px;
  }

  .sc-xs-mb-43 {
    margin-bottom: 43px;
  }

  .sc-xs-mb-44 {
    margin-bottom: 44px;
  }

  .sc-xs-mb-45 {
    margin-bottom: 45px;
  }

  .sc-xs-mb-46 {
    margin-bottom: 46px;
  }

  .sc-xs-mb-47 {
    margin-bottom: 47px;
  }

  .sc-xs-mb-48 {
    margin-bottom: 48px;
  }

  .sc-xs-mb-49 {
    margin-bottom: 49px;
  }

  .sc-xs-mb-50 {
    margin-bottom: 50px;
  }

  .sc-xs-mb-51 {
    margin-bottom: 51px;
  }

  .sc-xs-mb-52 {
    margin-bottom: 52px;
  }

  .sc-xs-mb-53 {
    margin-bottom: 53px;
  }

  .sc-xs-mb-54 {
    margin-bottom: 54px;
  }

  .sc-xs-mb-55 {
    margin-bottom: 55px;
  }

  .sc-xs-mb-56 {
    margin-bottom: 56px;
  }

  .sc-xs-mb-57 {
    margin-bottom: 57px;
  }

  .sc-xs-mb-58 {
    margin-bottom: 58px;
  }

  .sc-xs-mb-59 {
    margin-bottom: 59px;
  }

  .sc-xs-mb-60 {
    margin-bottom: 60px;
  }

  .sc-xs-mb-61 {
    margin-bottom: 61px;
  }

  .sc-xs-mb-62 {
    margin-bottom: 62px;
  }

  .sc-xs-mb-63 {
    margin-bottom: 63px;
  }

  .sc-xs-mb-64 {
    margin-bottom: 64px;
  }

  .sc-xs-mb-65 {
    margin-bottom: 65px;
  }

  .sc-xs-mb-66 {
    margin-bottom: 66px;
  }

  .sc-xs-mb-67 {
    margin-bottom: 67px;
  }

  .sc-xs-mb-68 {
    margin-bottom: 68px;
  }

  .sc-xs-mb-69 {
    margin-bottom: 69px;
  }

  .sc-xs-mb-70 {
    margin-bottom: 70px;
  }

  .sc-xs-mb-71 {
    margin-bottom: 71px;
  }

  .sc-xs-mb-72 {
    margin-bottom: 72px;
  }

  .sc-xs-mb-73 {
    margin-bottom: 73px;
  }

  .sc-xs-mb-74 {
    margin-bottom: 74px;
  }

  .sc-xs-mb-75 {
    margin-bottom: 75px;
  }

  .sc-xs-mb-76 {
    margin-bottom: 76px;
  }

  .sc-xs-mb-77 {
    margin-bottom: 77px;
  }

  .sc-xs-mb-78 {
    margin-bottom: 78px;
  }

  .sc-xs-mb-79 {
    margin-bottom: 79px;
  }

  .sc-xs-mb-80 {
    margin-bottom: 80px;
  }

  .sc-xs-mb-81 {
    margin-bottom: 81px;
  }

  .sc-xs-mb-82 {
    margin-bottom: 82px;
  }

  .sc-xs-mb-83 {
    margin-bottom: 83px;
  }

  .sc-xs-mb-84 {
    margin-bottom: 84px;
  }

  .sc-xs-mb-85 {
    margin-bottom: 85px;
  }

  .sc-xs-mb-86 {
    margin-bottom: 86px;
  }

  .sc-xs-mb-87 {
    margin-bottom: 87px;
  }

  .sc-xs-mb-88 {
    margin-bottom: 88px;
  }

  .sc-xs-mb-89 {
    margin-bottom: 89px;
  }

  .sc-xs-mb-90 {
    margin-bottom: 90px;
  }

  .sc-xs-mb-91 {
    margin-bottom: 91px;
  }

  .sc-xs-mb-92 {
    margin-bottom: 92px;
  }

  .sc-xs-mb-93 {
    margin-bottom: 93px;
  }

  .sc-xs-mb-94 {
    margin-bottom: 94px;
  }

  .sc-xs-mb-95 {
    margin-bottom: 95px;
  }

  .sc-xs-mb-96 {
    margin-bottom: 96px;
  }

  .sc-xs-mb-97 {
    margin-bottom: 97px;
  }

  .sc-xs-mb-98 {
    margin-bottom: 98px;
  }

  .sc-xs-mb-99 {
    margin-bottom: 99px;
  }

  .sc-xs-mb-100 {
    margin-bottom: 100px;
  }

  .sc-xs-mb-101 {
    margin-bottom: 101px;
  }

  .sc-xs-mb-102 {
    margin-bottom: 102px;
  }

  .sc-xs-mb-103 {
    margin-bottom: 103px;
  }

  .sc-xs-mb-104 {
    margin-bottom: 104px;
  }

  .sc-xs-mb-105 {
    margin-bottom: 105px;
  }

  .sc-xs-mb-106 {
    margin-bottom: 106px;
  }

  .sc-xs-mb-107 {
    margin-bottom: 107px;
  }

  .sc-xs-mb-108 {
    margin-bottom: 108px;
  }

  .sc-xs-mb-109 {
    margin-bottom: 109px;
  }

  .sc-xs-mb-110 {
    margin-bottom: 110px;
  }

  .sc-xs-mb-111 {
    margin-bottom: 111px;
  }

  .sc-xs-mb-112 {
    margin-bottom: 112px;
  }

  .sc-xs-mb-113 {
    margin-bottom: 113px;
  }

  .sc-xs-mb-114 {
    margin-bottom: 114px;
  }

  .sc-xs-mb-115 {
    margin-bottom: 115px;
  }

  .sc-xs-mb-116 {
    margin-bottom: 116px;
  }

  .sc-xs-mb-117 {
    margin-bottom: 117px;
  }

  .sc-xs-mb-118 {
    margin-bottom: 118px;
  }

  .sc-xs-mb-119 {
    margin-bottom: 119px;
  }

  .sc-xs-mb-120 {
    margin-bottom: 120px;
  }

  .sc-xs-mb-121 {
    margin-bottom: 121px;
  }

  .sc-xs-mb-122 {
    margin-bottom: 122px;
  }

  .sc-xs-mb-123 {
    margin-bottom: 123px;
  }

  .sc-xs-mb-124 {
    margin-bottom: 124px;
  }

  .sc-xs-mb-125 {
    margin-bottom: 125px;
  }

  .sc-xs-mb-126 {
    margin-bottom: 126px;
  }

  .sc-xs-mb-127 {
    margin-bottom: 127px;
  }

  .sc-xs-mb-128 {
    margin-bottom: 128px;
  }

  .sc-xs-mb-129 {
    margin-bottom: 129px;
  }

  .sc-xs-mb-130 {
    margin-bottom: 130px;
  }

  .sc-xs-mb-131 {
    margin-bottom: 131px;
  }

  .sc-xs-mb-132 {
    margin-bottom: 132px;
  }

  .sc-xs-mb-133 {
    margin-bottom: 133px;
  }

  .sc-xs-mb-134 {
    margin-bottom: 134px;
  }

  .sc-xs-mb-135 {
    margin-bottom: 135px;
  }

  .sc-xs-mb-136 {
    margin-bottom: 136px;
  }

  .sc-xs-mb-137 {
    margin-bottom: 137px;
  }

  .sc-xs-mb-138 {
    margin-bottom: 138px;
  }

  .sc-xs-mb-139 {
    margin-bottom: 139px;
  }

  .sc-xs-mb-140 {
    margin-bottom: 140px;
  }

  .sc-xs-mb-141 {
    margin-bottom: 141px;
  }

  .sc-xs-mb-142 {
    margin-bottom: 142px;
  }

  .sc-xs-mb-143 {
    margin-bottom: 143px;
  }

  .sc-xs-mb-144 {
    margin-bottom: 144px;
  }

  .sc-xs-mb-145 {
    margin-bottom: 145px;
  }

  .sc-xs-mb-146 {
    margin-bottom: 146px;
  }

  .sc-xs-mb-147 {
    margin-bottom: 147px;
  }

  .sc-xs-mb-148 {
    margin-bottom: 148px;
  }

  .sc-xs-mb-149 {
    margin-bottom: 149px;
  }

  .sc-xs-mb-150 {
    margin-bottom: 150px;
  }

  .sc-xs-mb-151 {
    margin-bottom: 151px;
  }

  .sc-xs-mb-152 {
    margin-bottom: 152px;
  }

  .sc-xs-mb-153 {
    margin-bottom: 153px;
  }

  .sc-xs-mb-154 {
    margin-bottom: 154px;
  }

  .sc-xs-mb-155 {
    margin-bottom: 155px;
  }

  .sc-xs-mb-156 {
    margin-bottom: 156px;
  }

  .sc-xs-mb-157 {
    margin-bottom: 157px;
  }

  .sc-xs-mb-158 {
    margin-bottom: 158px;
  }

  .sc-xs-mb-159 {
    margin-bottom: 159px;
  }

  .sc-xs-mb-160 {
    margin-bottom: 160px;
  }

  .sc-xs-mb-161 {
    margin-bottom: 161px;
  }

  .sc-xs-mb-162 {
    margin-bottom: 162px;
  }

  .sc-xs-mb-163 {
    margin-bottom: 163px;
  }

  .sc-xs-mb-164 {
    margin-bottom: 164px;
  }

  .sc-xs-mb-165 {
    margin-bottom: 165px;
  }

  .sc-xs-mb-166 {
    margin-bottom: 166px;
  }

  .sc-xs-mb-167 {
    margin-bottom: 167px;
  }

  .sc-xs-mb-168 {
    margin-bottom: 168px;
  }

  .sc-xs-mb-169 {
    margin-bottom: 169px;
  }

  .sc-xs-mb-170 {
    margin-bottom: 170px;
  }

  .sc-xs-mb-171 {
    margin-bottom: 171px;
  }

  .sc-xs-mb-172 {
    margin-bottom: 172px;
  }

  .sc-xs-mb-173 {
    margin-bottom: 173px;
  }

  .sc-xs-mb-174 {
    margin-bottom: 174px;
  }

  .sc-xs-mb-175 {
    margin-bottom: 175px;
  }

  .sc-xs-mb-176 {
    margin-bottom: 176px;
  }

  .sc-xs-mb-177 {
    margin-bottom: 177px;
  }

  .sc-xs-mb-178 {
    margin-bottom: 178px;
  }

  .sc-xs-mb-179 {
    margin-bottom: 179px;
  }

  .sc-xs-mb-180 {
    margin-bottom: 180px;
  }

  .sc-xs-mb-181 {
    margin-bottom: 181px;
  }

  .sc-xs-mb-182 {
    margin-bottom: 182px;
  }

  .sc-xs-mb-183 {
    margin-bottom: 183px;
  }

  .sc-xs-mb-184 {
    margin-bottom: 184px;
  }

  .sc-xs-mb-185 {
    margin-bottom: 185px;
  }

  .sc-xs-mb-186 {
    margin-bottom: 186px;
  }

  .sc-xs-mb-187 {
    margin-bottom: 187px;
  }

  .sc-xs-mb-188 {
    margin-bottom: 188px;
  }

  .sc-xs-mb-189 {
    margin-bottom: 189px;
  }

  .sc-xs-mb-190 {
    margin-bottom: 190px;
  }

  .sc-xs-mb-191 {
    margin-bottom: 191px;
  }

  .sc-xs-mb-192 {
    margin-bottom: 192px;
  }

  .sc-xs-mb-193 {
    margin-bottom: 193px;
  }

  .sc-xs-mb-194 {
    margin-bottom: 194px;
  }

  .sc-xs-mb-195 {
    margin-bottom: 195px;
  }

  .sc-xs-mb-196 {
    margin-bottom: 196px;
  }

  .sc-xs-mb-197 {
    margin-bottom: 197px;
  }

  .sc-xs-mb-198 {
    margin-bottom: 198px;
  }

  .sc-xs-mb-199 {
    margin-bottom: 199px;
  }

  .sc-xs-mb-200 {
    margin-bottom: 200px;
  }

  .sc-xs-mr-0 {
    margin-right: 0;
  }

  .sc-xs-mr-1 {
    margin-right: 1px;
  }

  .sc-xs-mr-2 {
    margin-right: 2px;
  }

  .sc-xs-mr-3 {
    margin-right: 3px;
  }

  .sc-xs-mr-4 {
    margin-right: 4px;
  }

  .sc-xs-mr-5 {
    margin-right: 5px;
  }

  .sc-xs-mr-6 {
    margin-right: 6px;
  }

  .sc-xs-mr-7 {
    margin-right: 7px;
  }

  .sc-xs-mr-8 {
    margin-right: 8px;
  }

  .sc-xs-mr-9 {
    margin-right: 9px;
  }

  .sc-xs-mr-10 {
    margin-right: 10px;
  }

  .sc-xs-mr-11 {
    margin-right: 11px;
  }

  .sc-xs-mr-12 {
    margin-right: 12px;
  }

  .sc-xs-mr-13 {
    margin-right: 13px;
  }

  .sc-xs-mr-14 {
    margin-right: 14px;
  }

  .sc-xs-mr-15 {
    margin-right: 15px;
  }

  .sc-xs-mr-16 {
    margin-right: 16px;
  }

  .sc-xs-mr-17 {
    margin-right: 17px;
  }

  .sc-xs-mr-18 {
    margin-right: 18px;
  }

  .sc-xs-mr-19 {
    margin-right: 19px;
  }

  .sc-xs-mr-20 {
    margin-right: 20px;
  }

  .sc-xs-mr-21 {
    margin-right: 21px;
  }

  .sc-xs-mr-22 {
    margin-right: 22px;
  }

  .sc-xs-mr-23 {
    margin-right: 23px;
  }

  .sc-xs-mr-24 {
    margin-right: 24px;
  }

  .sc-xs-mr-25 {
    margin-right: 25px;
  }

  .sc-xs-mr-26 {
    margin-right: 26px;
  }

  .sc-xs-mr-27 {
    margin-right: 27px;
  }

  .sc-xs-mr-28 {
    margin-right: 28px;
  }

  .sc-xs-mr-29 {
    margin-right: 29px;
  }

  .sc-xs-mr-30 {
    margin-right: 30px;
  }

  .sc-xs-mr-31 {
    margin-right: 31px;
  }

  .sc-xs-mr-32 {
    margin-right: 32px;
  }

  .sc-xs-mr-33 {
    margin-right: 33px;
  }

  .sc-xs-mr-34 {
    margin-right: 34px;
  }

  .sc-xs-mr-35 {
    margin-right: 35px;
  }

  .sc-xs-mr-36 {
    margin-right: 36px;
  }

  .sc-xs-mr-37 {
    margin-right: 37px;
  }

  .sc-xs-mr-38 {
    margin-right: 38px;
  }

  .sc-xs-mr-39 {
    margin-right: 39px;
  }

  .sc-xs-mr-40 {
    margin-right: 40px;
  }

  .sc-xs-mr-41 {
    margin-right: 41px;
  }

  .sc-xs-mr-42 {
    margin-right: 42px;
  }

  .sc-xs-mr-43 {
    margin-right: 43px;
  }

  .sc-xs-mr-44 {
    margin-right: 44px;
  }

  .sc-xs-mr-45 {
    margin-right: 45px;
  }

  .sc-xs-mr-46 {
    margin-right: 46px;
  }

  .sc-xs-mr-47 {
    margin-right: 47px;
  }

  .sc-xs-mr-48 {
    margin-right: 48px;
  }

  .sc-xs-mr-49 {
    margin-right: 49px;
  }

  .sc-xs-mr-50 {
    margin-right: 50px;
  }

  .sc-xs-mr-51 {
    margin-right: 51px;
  }

  .sc-xs-mr-52 {
    margin-right: 52px;
  }

  .sc-xs-mr-53 {
    margin-right: 53px;
  }

  .sc-xs-mr-54 {
    margin-right: 54px;
  }

  .sc-xs-mr-55 {
    margin-right: 55px;
  }

  .sc-xs-mr-56 {
    margin-right: 56px;
  }

  .sc-xs-mr-57 {
    margin-right: 57px;
  }

  .sc-xs-mr-58 {
    margin-right: 58px;
  }

  .sc-xs-mr-59 {
    margin-right: 59px;
  }

  .sc-xs-mr-60 {
    margin-right: 60px;
  }

  .sc-xs-mr-61 {
    margin-right: 61px;
  }

  .sc-xs-mr-62 {
    margin-right: 62px;
  }

  .sc-xs-mr-63 {
    margin-right: 63px;
  }

  .sc-xs-mr-64 {
    margin-right: 64px;
  }

  .sc-xs-mr-65 {
    margin-right: 65px;
  }

  .sc-xs-mr-66 {
    margin-right: 66px;
  }

  .sc-xs-mr-67 {
    margin-right: 67px;
  }

  .sc-xs-mr-68 {
    margin-right: 68px;
  }

  .sc-xs-mr-69 {
    margin-right: 69px;
  }

  .sc-xs-mr-70 {
    margin-right: 70px;
  }

  .sc-xs-mr-71 {
    margin-right: 71px;
  }

  .sc-xs-mr-72 {
    margin-right: 72px;
  }

  .sc-xs-mr-73 {
    margin-right: 73px;
  }

  .sc-xs-mr-74 {
    margin-right: 74px;
  }

  .sc-xs-mr-75 {
    margin-right: 75px;
  }

  .sc-xs-mr-76 {
    margin-right: 76px;
  }

  .sc-xs-mr-77 {
    margin-right: 77px;
  }

  .sc-xs-mr-78 {
    margin-right: 78px;
  }

  .sc-xs-mr-79 {
    margin-right: 79px;
  }

  .sc-xs-mr-80 {
    margin-right: 80px;
  }

  .sc-xs-mr-81 {
    margin-right: 81px;
  }

  .sc-xs-mr-82 {
    margin-right: 82px;
  }

  .sc-xs-mr-83 {
    margin-right: 83px;
  }

  .sc-xs-mr-84 {
    margin-right: 84px;
  }

  .sc-xs-mr-85 {
    margin-right: 85px;
  }

  .sc-xs-mr-86 {
    margin-right: 86px;
  }

  .sc-xs-mr-87 {
    margin-right: 87px;
  }

  .sc-xs-mr-88 {
    margin-right: 88px;
  }

  .sc-xs-mr-89 {
    margin-right: 89px;
  }

  .sc-xs-mr-90 {
    margin-right: 90px;
  }

  .sc-xs-mr-91 {
    margin-right: 91px;
  }

  .sc-xs-mr-92 {
    margin-right: 92px;
  }

  .sc-xs-mr-93 {
    margin-right: 93px;
  }

  .sc-xs-mr-94 {
    margin-right: 94px;
  }

  .sc-xs-mr-95 {
    margin-right: 95px;
  }

  .sc-xs-mr-96 {
    margin-right: 96px;
  }

  .sc-xs-mr-97 {
    margin-right: 97px;
  }

  .sc-xs-mr-98 {
    margin-right: 98px;
  }

  .sc-xs-mr-99 {
    margin-right: 99px;
  }

  .sc-xs-mr-100 {
    margin-right: 100px;
  }

  .sc-xs-ml-0 {
    margin-left: 0;
  }

  .sc-xs-ml-1 {
    margin-left: 1px;
  }

  .sc-xs-ml-2 {
    margin-left: 2px;
  }

  .sc-xs-ml-3 {
    margin-left: 3px;
  }

  .sc-xs-ml-4 {
    margin-left: 4px;
  }

  .sc-xs-ml-5 {
    margin-left: 5px;
  }

  .sc-xs-ml-6 {
    margin-left: 6px;
  }

  .sc-xs-ml-7 {
    margin-left: 7px;
  }

  .sc-xs-ml-8 {
    margin-left: 8px;
  }

  .sc-xs-ml-9 {
    margin-left: 9px;
  }

  .sc-xs-ml-10 {
    margin-left: 10px;
  }

  .sc-xs-ml-11 {
    margin-left: 11px;
  }

  .sc-xs-ml-12 {
    margin-left: 12px;
  }

  .sc-xs-ml-13 {
    margin-left: 13px;
  }

  .sc-xs-ml-14 {
    margin-left: 14px;
  }

  .sc-xs-ml-15 {
    margin-left: 15px;
  }

  .sc-xs-ml-16 {
    margin-left: 16px;
  }

  .sc-xs-ml-17 {
    margin-left: 17px;
  }

  .sc-xs-ml-18 {
    margin-left: 18px;
  }

  .sc-xs-ml-19 {
    margin-left: 19px;
  }

  .sc-xs-ml-20 {
    margin-left: 20px;
  }

  .sc-xs-ml-21 {
    margin-left: 21px;
  }

  .sc-xs-ml-22 {
    margin-left: 22px;
  }

  .sc-xs-ml-23 {
    margin-left: 23px;
  }

  .sc-xs-ml-24 {
    margin-left: 24px;
  }

  .sc-xs-ml-25 {
    margin-left: 25px;
  }

  .sc-xs-ml-26 {
    margin-left: 26px;
  }

  .sc-xs-ml-27 {
    margin-left: 27px;
  }

  .sc-xs-ml-28 {
    margin-left: 28px;
  }

  .sc-xs-ml-29 {
    margin-left: 29px;
  }

  .sc-xs-ml-30 {
    margin-left: 30px;
  }

  .sc-xs-ml-31 {
    margin-left: 31px;
  }

  .sc-xs-ml-32 {
    margin-left: 32px;
  }

  .sc-xs-ml-33 {
    margin-left: 33px;
  }

  .sc-xs-ml-34 {
    margin-left: 34px;
  }

  .sc-xs-ml-35 {
    margin-left: 35px;
  }

  .sc-xs-ml-36 {
    margin-left: 36px;
  }

  .sc-xs-ml-37 {
    margin-left: 37px;
  }

  .sc-xs-ml-38 {
    margin-left: 38px;
  }

  .sc-xs-ml-39 {
    margin-left: 39px;
  }

  .sc-xs-ml-40 {
    margin-left: 40px;
  }

  .sc-xs-ml-41 {
    margin-left: 41px;
  }

  .sc-xs-ml-42 {
    margin-left: 42px;
  }

  .sc-xs-ml-43 {
    margin-left: 43px;
  }

  .sc-xs-ml-44 {
    margin-left: 44px;
  }

  .sc-xs-ml-45 {
    margin-left: 45px;
  }

  .sc-xs-ml-46 {
    margin-left: 46px;
  }

  .sc-xs-ml-47 {
    margin-left: 47px;
  }

  .sc-xs-ml-48 {
    margin-left: 48px;
  }

  .sc-xs-ml-49 {
    margin-left: 49px;
  }

  .sc-xs-ml-50 {
    margin-left: 50px;
  }

  .sc-xs-ml-51 {
    margin-left: 51px;
  }

  .sc-xs-ml-52 {
    margin-left: 52px;
  }

  .sc-xs-ml-53 {
    margin-left: 53px;
  }

  .sc-xs-ml-54 {
    margin-left: 54px;
  }

  .sc-xs-ml-55 {
    margin-left: 55px;
  }

  .sc-xs-ml-56 {
    margin-left: 56px;
  }

  .sc-xs-ml-57 {
    margin-left: 57px;
  }

  .sc-xs-ml-58 {
    margin-left: 58px;
  }

  .sc-xs-ml-59 {
    margin-left: 59px;
  }

  .sc-xs-ml-60 {
    margin-left: 60px;
  }

  .sc-xs-ml-61 {
    margin-left: 61px;
  }

  .sc-xs-ml-62 {
    margin-left: 62px;
  }

  .sc-xs-ml-63 {
    margin-left: 63px;
  }

  .sc-xs-ml-64 {
    margin-left: 64px;
  }

  .sc-xs-ml-65 {
    margin-left: 65px;
  }

  .sc-xs-ml-66 {
    margin-left: 66px;
  }

  .sc-xs-ml-67 {
    margin-left: 67px;
  }

  .sc-xs-ml-68 {
    margin-left: 68px;
  }

  .sc-xs-ml-69 {
    margin-left: 69px;
  }

  .sc-xs-ml-70 {
    margin-left: 70px;
  }

  .sc-xs-ml-71 {
    margin-left: 71px;
  }

  .sc-xs-ml-72 {
    margin-left: 72px;
  }

  .sc-xs-ml-73 {
    margin-left: 73px;
  }

  .sc-xs-ml-74 {
    margin-left: 74px;
  }

  .sc-xs-ml-75 {
    margin-left: 75px;
  }

  .sc-xs-ml-76 {
    margin-left: 76px;
  }

  .sc-xs-ml-77 {
    margin-left: 77px;
  }

  .sc-xs-ml-78 {
    margin-left: 78px;
  }

  .sc-xs-ml-79 {
    margin-left: 79px;
  }

  .sc-xs-ml-80 {
    margin-left: 80px;
  }

  .sc-xs-ml-81 {
    margin-left: 81px;
  }

  .sc-xs-ml-82 {
    margin-left: 82px;
  }

  .sc-xs-ml-83 {
    margin-left: 83px;
  }

  .sc-xs-ml-84 {
    margin-left: 84px;
  }

  .sc-xs-ml-85 {
    margin-left: 85px;
  }

  .sc-xs-ml-86 {
    margin-left: 86px;
  }

  .sc-xs-ml-87 {
    margin-left: 87px;
  }

  .sc-xs-ml-88 {
    margin-left: 88px;
  }

  .sc-xs-ml-89 {
    margin-left: 89px;
  }

  .sc-xs-ml-90 {
    margin-left: 90px;
  }

  .sc-xs-ml-91 {
    margin-left: 91px;
  }

  .sc-xs-ml-92 {
    margin-left: 92px;
  }

  .sc-xs-ml-93 {
    margin-left: 93px;
  }

  .sc-xs-ml-94 {
    margin-left: 94px;
  }

  .sc-xs-ml-95 {
    margin-left: 95px;
  }

  .sc-xs-ml-96 {
    margin-left: 96px;
  }

  .sc-xs-ml-97 {
    margin-left: 97px;
  }

  .sc-xs-ml-98 {
    margin-left: 98px;
  }

  .sc-xs-ml-99 {
    margin-left: 99px;
  }

  .sc-xs-ml-100 {
    margin-left: 100px;
  }

  .sc-xs-mt--1 {
    margin-top: -1px;
  }

  .sc-xs-mt--2 {
    margin-top: -2px;
  }

  .sc-xs-mt--3 {
    margin-top: -3px;
  }

  .sc-xs-mt--4 {
    margin-top: -4px;
  }

  .sc-xs-mt--5 {
    margin-top: -5px;
  }

  .sc-xs-mt--6 {
    margin-top: -6px;
  }

  .sc-xs-mt--7 {
    margin-top: -7px;
  }

  .sc-xs-mt--8 {
    margin-top: -8px;
  }

  .sc-xs-mt--9 {
    margin-top: -9px;
  }

  .sc-xs-mt--10 {
    margin-top: -10px;
  }

  .sc-xs-mt--11 {
    margin-top: -11px;
  }

  .sc-xs-mt--12 {
    margin-top: -12px;
  }

  .sc-xs-mt--13 {
    margin-top: -13px;
  }

  .sc-xs-mt--14 {
    margin-top: -14px;
  }

  .sc-xs-mt--15 {
    margin-top: -15px;
  }

  .sc-xs-mt--16 {
    margin-top: -16px;
  }

  .sc-xs-mt--17 {
    margin-top: -17px;
  }

  .sc-xs-mt--18 {
    margin-top: -18px;
  }

  .sc-xs-mt--19 {
    margin-top: -19px;
  }

  .sc-xs-mt--20 {
    margin-top: -20px;
  }

  .sc-xs-mt--21 {
    margin-top: -21px;
  }

  .sc-xs-mt--22 {
    margin-top: -22px;
  }

  .sc-xs-mt--23 {
    margin-top: -23px;
  }

  .sc-xs-mt--24 {
    margin-top: -24px;
  }

  .sc-xs-mt--25 {
    margin-top: -25px;
  }

  .sc-xs-mt--26 {
    margin-top: -26px;
  }

  .sc-xs-mt--27 {
    margin-top: -27px;
  }

  .sc-xs-mt--28 {
    margin-top: -28px;
  }

  .sc-xs-mt--29 {
    margin-top: -29px;
  }

  .sc-xs-mt--30 {
    margin-top: -30px;
  }

  .sc-xs-mt--31 {
    margin-top: -31px;
  }

  .sc-xs-mt--32 {
    margin-top: -32px;
  }

  .sc-xs-mt--33 {
    margin-top: -33px;
  }

  .sc-xs-mt--34 {
    margin-top: -34px;
  }

  .sc-xs-mt--35 {
    margin-top: -35px;
  }

  .sc-xs-mt--36 {
    margin-top: -36px;
  }

  .sc-xs-mt--37 {
    margin-top: -37px;
  }

  .sc-xs-mt--38 {
    margin-top: -38px;
  }

  .sc-xs-mt--39 {
    margin-top: -39px;
  }

  .sc-xs-mt--40 {
    margin-top: -40px;
  }

  .sc-xs-mt--41 {
    margin-top: -41px;
  }

  .sc-xs-mt--42 {
    margin-top: -42px;
  }

  .sc-xs-mt--43 {
    margin-top: -43px;
  }

  .sc-xs-mt--44 {
    margin-top: -44px;
  }

  .sc-xs-mt--45 {
    margin-top: -45px;
  }

  .sc-xs-mt--46 {
    margin-top: -46px;
  }

  .sc-xs-mt--47 {
    margin-top: -47px;
  }

  .sc-xs-mt--48 {
    margin-top: -48px;
  }

  .sc-xs-mt--49 {
    margin-top: -49px;
  }

  .sc-xs-mt--50 {
    margin-top: -50px;
  }

  .sc-xs-mt--51 {
    margin-top: -51px;
  }

  .sc-xs-mt--52 {
    margin-top: -52px;
  }

  .sc-xs-mt--53 {
    margin-top: -53px;
  }

  .sc-xs-mt--54 {
    margin-top: -54px;
  }

  .sc-xs-mt--55 {
    margin-top: -55px;
  }

  .sc-xs-mt--56 {
    margin-top: -56px;
  }

  .sc-xs-mt--57 {
    margin-top: -57px;
  }

  .sc-xs-mt--58 {
    margin-top: -58px;
  }

  .sc-xs-mt--59 {
    margin-top: -59px;
  }

  .sc-xs-mt--60 {
    margin-top: -60px;
  }

  .sc-xs-mt--61 {
    margin-top: -61px;
  }

  .sc-xs-mt--62 {
    margin-top: -62px;
  }

  .sc-xs-mt--63 {
    margin-top: -63px;
  }

  .sc-xs-mt--64 {
    margin-top: -64px;
  }

  .sc-xs-mt--65 {
    margin-top: -65px;
  }

  .sc-xs-mt--66 {
    margin-top: -66px;
  }

  .sc-xs-mt--67 {
    margin-top: -67px;
  }

  .sc-xs-mt--68 {
    margin-top: -68px;
  }

  .sc-xs-mt--69 {
    margin-top: -69px;
  }

  .sc-xs-mt--70 {
    margin-top: -70px;
  }

  .sc-xs-mt--71 {
    margin-top: -71px;
  }

  .sc-xs-mt--72 {
    margin-top: -72px;
  }

  .sc-xs-mt--73 {
    margin-top: -73px;
  }

  .sc-xs-mt--74 {
    margin-top: -74px;
  }

  .sc-xs-mt--75 {
    margin-top: -75px;
  }

  .sc-xs-mt--76 {
    margin-top: -76px;
  }

  .sc-xs-mt--77 {
    margin-top: -77px;
  }

  .sc-xs-mt--78 {
    margin-top: -78px;
  }

  .sc-xs-mt--79 {
    margin-top: -79px;
  }

  .sc-xs-mt--80 {
    margin-top: -80px;
  }

  .sc-xs-mt--81 {
    margin-top: -81px;
  }

  .sc-xs-mt--82 {
    margin-top: -82px;
  }

  .sc-xs-mt--83 {
    margin-top: -83px;
  }

  .sc-xs-mt--84 {
    margin-top: -84px;
  }

  .sc-xs-mt--85 {
    margin-top: -85px;
  }

  .sc-xs-mt--86 {
    margin-top: -86px;
  }

  .sc-xs-mt--87 {
    margin-top: -87px;
  }

  .sc-xs-mt--88 {
    margin-top: -88px;
  }

  .sc-xs-mt--89 {
    margin-top: -89px;
  }

  .sc-xs-mt--90 {
    margin-top: -90px;
  }

  .sc-xs-mt--91 {
    margin-top: -91px;
  }

  .sc-xs-mt--92 {
    margin-top: -92px;
  }

  .sc-xs-mt--93 {
    margin-top: -93px;
  }

  .sc-xs-mt--94 {
    margin-top: -94px;
  }

  .sc-xs-mt--95 {
    margin-top: -95px;
  }

  .sc-xs-mt--96 {
    margin-top: -96px;
  }

  .sc-xs-mt--97 {
    margin-top: -97px;
  }

  .sc-xs-mt--98 {
    margin-top: -98px;
  }

  .sc-xs-mt--99 {
    margin-top: -99px;
  }

  .sc-xs-mt--100 {
    margin-top: -100px;
  }

  .sc-xs-mt--101 {
    margin-top: -101px;
  }

  .sc-xs-mt--102 {
    margin-top: -102px;
  }

  .sc-xs-mt--103 {
    margin-top: -103px;
  }

  .sc-xs-mt--104 {
    margin-top: -104px;
  }

  .sc-xs-mt--105 {
    margin-top: -105px;
  }

  .sc-xs-mt--106 {
    margin-top: -106px;
  }

  .sc-xs-mt--107 {
    margin-top: -107px;
  }

  .sc-xs-mt--108 {
    margin-top: -108px;
  }

  .sc-xs-mt--109 {
    margin-top: -109px;
  }

  .sc-xs-mt--110 {
    margin-top: -110px;
  }

  .sc-xs-mt--111 {
    margin-top: -111px;
  }

  .sc-xs-mt--112 {
    margin-top: -112px;
  }

  .sc-xs-mt--113 {
    margin-top: -113px;
  }

  .sc-xs-mt--114 {
    margin-top: -114px;
  }

  .sc-xs-mt--115 {
    margin-top: -115px;
  }

  .sc-xs-mt--116 {
    margin-top: -116px;
  }

  .sc-xs-mt--117 {
    margin-top: -117px;
  }

  .sc-xs-mt--118 {
    margin-top: -118px;
  }

  .sc-xs-mt--119 {
    margin-top: -119px;
  }

  .sc-xs-mt--120 {
    margin-top: -120px;
  }

  .sc-xs-mt--121 {
    margin-top: -121px;
  }

  .sc-xs-mt--122 {
    margin-top: -122px;
  }

  .sc-xs-mt--123 {
    margin-top: -123px;
  }

  .sc-xs-mt--124 {
    margin-top: -124px;
  }

  .sc-xs-mt--125 {
    margin-top: -125px;
  }

  .sc-xs-mt--126 {
    margin-top: -126px;
  }

  .sc-xs-mt--127 {
    margin-top: -127px;
  }

  .sc-xs-mt--128 {
    margin-top: -128px;
  }

  .sc-xs-mt--129 {
    margin-top: -129px;
  }

  .sc-xs-mt--130 {
    margin-top: -130px;
  }

  .sc-xs-mt--131 {
    margin-top: -131px;
  }

  .sc-xs-mt--132 {
    margin-top: -132px;
  }

  .sc-xs-mt--133 {
    margin-top: -133px;
  }

  .sc-xs-mt--134 {
    margin-top: -134px;
  }

  .sc-xs-mt--135 {
    margin-top: -135px;
  }

  .sc-xs-mt--136 {
    margin-top: -136px;
  }

  .sc-xs-mt--137 {
    margin-top: -137px;
  }

  .sc-xs-mt--138 {
    margin-top: -138px;
  }

  .sc-xs-mt--139 {
    margin-top: -139px;
  }

  .sc-xs-mt--140 {
    margin-top: -140px;
  }

  .sc-xs-mt--141 {
    margin-top: -141px;
  }

  .sc-xs-mt--142 {
    margin-top: -142px;
  }

  .sc-xs-mt--143 {
    margin-top: -143px;
  }

  .sc-xs-mt--144 {
    margin-top: -144px;
  }

  .sc-xs-mt--145 {
    margin-top: -145px;
  }

  .sc-xs-mt--146 {
    margin-top: -146px;
  }

  .sc-xs-mt--147 {
    margin-top: -147px;
  }

  .sc-xs-mt--148 {
    margin-top: -148px;
  }

  .sc-xs-mt--149 {
    margin-top: -149px;
  }

  .sc-xs-mt--150 {
    margin-top: -150px;
  }

  .sc-xs-mt--151 {
    margin-top: -151px;
  }

  .sc-xs-mt--152 {
    margin-top: -152px;
  }

  .sc-xs-mt--153 {
    margin-top: -153px;
  }

  .sc-xs-mt--154 {
    margin-top: -154px;
  }

  .sc-xs-mt--155 {
    margin-top: -155px;
  }

  .sc-xs-mt--156 {
    margin-top: -156px;
  }

  .sc-xs-mt--157 {
    margin-top: -157px;
  }

  .sc-xs-mt--158 {
    margin-top: -158px;
  }

  .sc-xs-mt--159 {
    margin-top: -159px;
  }

  .sc-xs-mt--160 {
    margin-top: -160px;
  }

  .sc-xs-mt--161 {
    margin-top: -161px;
  }

  .sc-xs-mt--162 {
    margin-top: -162px;
  }

  .sc-xs-mt--163 {
    margin-top: -163px;
  }

  .sc-xs-mt--164 {
    margin-top: -164px;
  }

  .sc-xs-mt--165 {
    margin-top: -165px;
  }

  .sc-xs-mt--166 {
    margin-top: -166px;
  }

  .sc-xs-mt--167 {
    margin-top: -167px;
  }

  .sc-xs-mt--168 {
    margin-top: -168px;
  }

  .sc-xs-mt--169 {
    margin-top: -169px;
  }

  .sc-xs-mt--170 {
    margin-top: -170px;
  }

  .sc-xs-mt--171 {
    margin-top: -171px;
  }

  .sc-xs-mt--172 {
    margin-top: -172px;
  }

  .sc-xs-mt--173 {
    margin-top: -173px;
  }

  .sc-xs-mt--174 {
    margin-top: -174px;
  }

  .sc-xs-mt--175 {
    margin-top: -175px;
  }

  .sc-xs-mt--176 {
    margin-top: -176px;
  }

  .sc-xs-mt--177 {
    margin-top: -177px;
  }

  .sc-xs-mt--178 {
    margin-top: -178px;
  }

  .sc-xs-mt--179 {
    margin-top: -179px;
  }

  .sc-xs-mt--180 {
    margin-top: -180px;
  }

  .sc-xs-mt--181 {
    margin-top: -181px;
  }

  .sc-xs-mt--182 {
    margin-top: -182px;
  }

  .sc-xs-mt--183 {
    margin-top: -183px;
  }

  .sc-xs-mt--184 {
    margin-top: -184px;
  }

  .sc-xs-mt--185 {
    margin-top: -185px;
  }

  .sc-xs-mt--186 {
    margin-top: -186px;
  }

  .sc-xs-mt--187 {
    margin-top: -187px;
  }

  .sc-xs-mt--188 {
    margin-top: -188px;
  }

  .sc-xs-mt--189 {
    margin-top: -189px;
  }

  .sc-xs-mt--190 {
    margin-top: -190px;
  }

  .sc-xs-mt--191 {
    margin-top: -191px;
  }

  .sc-xs-mt--192 {
    margin-top: -192px;
  }

  .sc-xs-mt--193 {
    margin-top: -193px;
  }

  .sc-xs-mt--194 {
    margin-top: -194px;
  }

  .sc-xs-mt--195 {
    margin-top: -195px;
  }

  .sc-xs-mt--196 {
    margin-top: -196px;
  }

  .sc-xs-mt--197 {
    margin-top: -197px;
  }

  .sc-xs-mt--198 {
    margin-top: -198px;
  }

  .sc-xs-mt--199 {
    margin-top: -199px;
  }

  .sc-xs-mt--200 {
    margin-top: -200px;
  }

  .sc-xs-mb--1 {
    margin-bottom: -1px;
  }

  .sc-xs-mb--2 {
    margin-bottom: -2px;
  }

  .sc-xs-mb--3 {
    margin-bottom: -3px;
  }

  .sc-xs-mb--4 {
    margin-bottom: -4px;
  }

  .sc-xs-mb--5 {
    margin-bottom: -5px;
  }

  .sc-xs-mb--6 {
    margin-bottom: -6px;
  }

  .sc-xs-mb--7 {
    margin-bottom: -7px;
  }

  .sc-xs-mb--8 {
    margin-bottom: -8px;
  }

  .sc-xs-mb--9 {
    margin-bottom: -9px;
  }

  .sc-xs-mb--10 {
    margin-bottom: -10px;
  }

  .sc-xs-mb--11 {
    margin-bottom: -11px;
  }

  .sc-xs-mb--12 {
    margin-bottom: -12px;
  }

  .sc-xs-mb--13 {
    margin-bottom: -13px;
  }

  .sc-xs-mb--14 {
    margin-bottom: -14px;
  }

  .sc-xs-mb--15 {
    margin-bottom: -15px;
  }

  .sc-xs-mb--16 {
    margin-bottom: -16px;
  }

  .sc-xs-mb--17 {
    margin-bottom: -17px;
  }

  .sc-xs-mb--18 {
    margin-bottom: -18px;
  }

  .sc-xs-mb--19 {
    margin-bottom: -19px;
  }

  .sc-xs-mb--20 {
    margin-bottom: -20px;
  }

  .sc-xs-mb--21 {
    margin-bottom: -21px;
  }

  .sc-xs-mb--22 {
    margin-bottom: -22px;
  }

  .sc-xs-mb--23 {
    margin-bottom: -23px;
  }

  .sc-xs-mb--24 {
    margin-bottom: -24px;
  }

  .sc-xs-mb--25 {
    margin-bottom: -25px;
  }

  .sc-xs-mb--26 {
    margin-bottom: -26px;
  }

  .sc-xs-mb--27 {
    margin-bottom: -27px;
  }

  .sc-xs-mb--28 {
    margin-bottom: -28px;
  }

  .sc-xs-mb--29 {
    margin-bottom: -29px;
  }

  .sc-xs-mb--30 {
    margin-bottom: -30px;
  }

  .sc-xs-mb--31 {
    margin-bottom: -31px;
  }

  .sc-xs-mb--32 {
    margin-bottom: -32px;
  }

  .sc-xs-mb--33 {
    margin-bottom: -33px;
  }

  .sc-xs-mb--34 {
    margin-bottom: -34px;
  }

  .sc-xs-mb--35 {
    margin-bottom: -35px;
  }

  .sc-xs-mb--36 {
    margin-bottom: -36px;
  }

  .sc-xs-mb--37 {
    margin-bottom: -37px;
  }

  .sc-xs-mb--38 {
    margin-bottom: -38px;
  }

  .sc-xs-mb--39 {
    margin-bottom: -39px;
  }

  .sc-xs-mb--40 {
    margin-bottom: -40px;
  }

  .sc-xs-mb--41 {
    margin-bottom: -41px;
  }

  .sc-xs-mb--42 {
    margin-bottom: -42px;
  }

  .sc-xs-mb--43 {
    margin-bottom: -43px;
  }

  .sc-xs-mb--44 {
    margin-bottom: -44px;
  }

  .sc-xs-mb--45 {
    margin-bottom: -45px;
  }

  .sc-xs-mb--46 {
    margin-bottom: -46px;
  }

  .sc-xs-mb--47 {
    margin-bottom: -47px;
  }

  .sc-xs-mb--48 {
    margin-bottom: -48px;
  }

  .sc-xs-mb--49 {
    margin-bottom: -49px;
  }

  .sc-xs-mb--50 {
    margin-bottom: -50px;
  }

  .sc-xs-mb--51 {
    margin-bottom: -51px;
  }

  .sc-xs-mb--52 {
    margin-bottom: -52px;
  }

  .sc-xs-mb--53 {
    margin-bottom: -53px;
  }

  .sc-xs-mb--54 {
    margin-bottom: -54px;
  }

  .sc-xs-mb--55 {
    margin-bottom: -55px;
  }

  .sc-xs-mb--56 {
    margin-bottom: -56px;
  }

  .sc-xs-mb--57 {
    margin-bottom: -57px;
  }

  .sc-xs-mb--58 {
    margin-bottom: -58px;
  }

  .sc-xs-mb--59 {
    margin-bottom: -59px;
  }

  .sc-xs-mb--60 {
    margin-bottom: -60px;
  }

  .sc-xs-mb--61 {
    margin-bottom: -61px;
  }

  .sc-xs-mb--62 {
    margin-bottom: -62px;
  }

  .sc-xs-mb--63 {
    margin-bottom: -63px;
  }

  .sc-xs-mb--64 {
    margin-bottom: -64px;
  }

  .sc-xs-mb--65 {
    margin-bottom: -65px;
  }

  .sc-xs-mb--66 {
    margin-bottom: -66px;
  }

  .sc-xs-mb--67 {
    margin-bottom: -67px;
  }

  .sc-xs-mb--68 {
    margin-bottom: -68px;
  }

  .sc-xs-mb--69 {
    margin-bottom: -69px;
  }

  .sc-xs-mb--70 {
    margin-bottom: -70px;
  }

  .sc-xs-mb--71 {
    margin-bottom: -71px;
  }

  .sc-xs-mb--72 {
    margin-bottom: -72px;
  }

  .sc-xs-mb--73 {
    margin-bottom: -73px;
  }

  .sc-xs-mb--74 {
    margin-bottom: -74px;
  }

  .sc-xs-mb--75 {
    margin-bottom: -75px;
  }

  .sc-xs-mb--76 {
    margin-bottom: -76px;
  }

  .sc-xs-mb--77 {
    margin-bottom: -77px;
  }

  .sc-xs-mb--78 {
    margin-bottom: -78px;
  }

  .sc-xs-mb--79 {
    margin-bottom: -79px;
  }

  .sc-xs-mb--80 {
    margin-bottom: -80px;
  }

  .sc-xs-mb--81 {
    margin-bottom: -81px;
  }

  .sc-xs-mb--82 {
    margin-bottom: -82px;
  }

  .sc-xs-mb--83 {
    margin-bottom: -83px;
  }

  .sc-xs-mb--84 {
    margin-bottom: -84px;
  }

  .sc-xs-mb--85 {
    margin-bottom: -85px;
  }

  .sc-xs-mb--86 {
    margin-bottom: -86px;
  }

  .sc-xs-mb--87 {
    margin-bottom: -87px;
  }

  .sc-xs-mb--88 {
    margin-bottom: -88px;
  }

  .sc-xs-mb--89 {
    margin-bottom: -89px;
  }

  .sc-xs-mb--90 {
    margin-bottom: -90px;
  }

  .sc-xs-mb--91 {
    margin-bottom: -91px;
  }

  .sc-xs-mb--92 {
    margin-bottom: -92px;
  }

  .sc-xs-mb--93 {
    margin-bottom: -93px;
  }

  .sc-xs-mb--94 {
    margin-bottom: -94px;
  }

  .sc-xs-mb--95 {
    margin-bottom: -95px;
  }

  .sc-xs-mb--96 {
    margin-bottom: -96px;
  }

  .sc-xs-mb--97 {
    margin-bottom: -97px;
  }

  .sc-xs-mb--98 {
    margin-bottom: -98px;
  }

  .sc-xs-mb--99 {
    margin-bottom: -99px;
  }

  .sc-xs-mb--100 {
    margin-bottom: -100px;
  }

  .sc-xs-mb--101 {
    margin-bottom: -101px;
  }

  .sc-xs-mb--102 {
    margin-bottom: -102px;
  }

  .sc-xs-mb--103 {
    margin-bottom: -103px;
  }

  .sc-xs-mb--104 {
    margin-bottom: -104px;
  }

  .sc-xs-mb--105 {
    margin-bottom: -105px;
  }

  .sc-xs-mb--106 {
    margin-bottom: -106px;
  }

  .sc-xs-mb--107 {
    margin-bottom: -107px;
  }

  .sc-xs-mb--108 {
    margin-bottom: -108px;
  }

  .sc-xs-mb--109 {
    margin-bottom: -109px;
  }

  .sc-xs-mb--110 {
    margin-bottom: -110px;
  }

  .sc-xs-mb--111 {
    margin-bottom: -111px;
  }

  .sc-xs-mb--112 {
    margin-bottom: -112px;
  }

  .sc-xs-mb--113 {
    margin-bottom: -113px;
  }

  .sc-xs-mb--114 {
    margin-bottom: -114px;
  }

  .sc-xs-mb--115 {
    margin-bottom: -115px;
  }

  .sc-xs-mb--116 {
    margin-bottom: -116px;
  }

  .sc-xs-mb--117 {
    margin-bottom: -117px;
  }

  .sc-xs-mb--118 {
    margin-bottom: -118px;
  }

  .sc-xs-mb--119 {
    margin-bottom: -119px;
  }

  .sc-xs-mb--120 {
    margin-bottom: -120px;
  }

  .sc-xs-mb--121 {
    margin-bottom: -121px;
  }

  .sc-xs-mb--122 {
    margin-bottom: -122px;
  }

  .sc-xs-mb--123 {
    margin-bottom: -123px;
  }

  .sc-xs-mb--124 {
    margin-bottom: -124px;
  }

  .sc-xs-mb--125 {
    margin-bottom: -125px;
  }

  .sc-xs-mb--126 {
    margin-bottom: -126px;
  }

  .sc-xs-mb--127 {
    margin-bottom: -127px;
  }

  .sc-xs-mb--128 {
    margin-bottom: -128px;
  }

  .sc-xs-mb--129 {
    margin-bottom: -129px;
  }

  .sc-xs-mb--130 {
    margin-bottom: -130px;
  }

  .sc-xs-mb--131 {
    margin-bottom: -131px;
  }

  .sc-xs-mb--132 {
    margin-bottom: -132px;
  }

  .sc-xs-mb--133 {
    margin-bottom: -133px;
  }

  .sc-xs-mb--134 {
    margin-bottom: -134px;
  }

  .sc-xs-mb--135 {
    margin-bottom: -135px;
  }

  .sc-xs-mb--136 {
    margin-bottom: -136px;
  }

  .sc-xs-mb--137 {
    margin-bottom: -137px;
  }

  .sc-xs-mb--138 {
    margin-bottom: -138px;
  }

  .sc-xs-mb--139 {
    margin-bottom: -139px;
  }

  .sc-xs-mb--140 {
    margin-bottom: -140px;
  }

  .sc-xs-mb--141 {
    margin-bottom: -141px;
  }

  .sc-xs-mb--142 {
    margin-bottom: -142px;
  }

  .sc-xs-mb--143 {
    margin-bottom: -143px;
  }

  .sc-xs-mb--144 {
    margin-bottom: -144px;
  }

  .sc-xs-mb--145 {
    margin-bottom: -145px;
  }

  .sc-xs-mb--146 {
    margin-bottom: -146px;
  }

  .sc-xs-mb--147 {
    margin-bottom: -147px;
  }

  .sc-xs-mb--148 {
    margin-bottom: -148px;
  }

  .sc-xs-mb--149 {
    margin-bottom: -149px;
  }

  .sc-xs-mb--150 {
    margin-bottom: -150px;
  }

  .sc-xs-mb--151 {
    margin-bottom: -151px;
  }

  .sc-xs-mb--152 {
    margin-bottom: -152px;
  }

  .sc-xs-mb--153 {
    margin-bottom: -153px;
  }

  .sc-xs-mb--154 {
    margin-bottom: -154px;
  }

  .sc-xs-mb--155 {
    margin-bottom: -155px;
  }

  .sc-xs-mb--156 {
    margin-bottom: -156px;
  }

  .sc-xs-mb--157 {
    margin-bottom: -157px;
  }

  .sc-xs-mb--158 {
    margin-bottom: -158px;
  }

  .sc-xs-mb--159 {
    margin-bottom: -159px;
  }

  .sc-xs-mb--160 {
    margin-bottom: -160px;
  }

  .sc-xs-mb--161 {
    margin-bottom: -161px;
  }

  .sc-xs-mb--162 {
    margin-bottom: -162px;
  }

  .sc-xs-mb--163 {
    margin-bottom: -163px;
  }

  .sc-xs-mb--164 {
    margin-bottom: -164px;
  }

  .sc-xs-mb--165 {
    margin-bottom: -165px;
  }

  .sc-xs-mb--166 {
    margin-bottom: -166px;
  }

  .sc-xs-mb--167 {
    margin-bottom: -167px;
  }

  .sc-xs-mb--168 {
    margin-bottom: -168px;
  }

  .sc-xs-mb--169 {
    margin-bottom: -169px;
  }

  .sc-xs-mb--170 {
    margin-bottom: -170px;
  }

  .sc-xs-mb--171 {
    margin-bottom: -171px;
  }

  .sc-xs-mb--172 {
    margin-bottom: -172px;
  }

  .sc-xs-mb--173 {
    margin-bottom: -173px;
  }

  .sc-xs-mb--174 {
    margin-bottom: -174px;
  }

  .sc-xs-mb--175 {
    margin-bottom: -175px;
  }

  .sc-xs-mb--176 {
    margin-bottom: -176px;
  }

  .sc-xs-mb--177 {
    margin-bottom: -177px;
  }

  .sc-xs-mb--178 {
    margin-bottom: -178px;
  }

  .sc-xs-mb--179 {
    margin-bottom: -179px;
  }

  .sc-xs-mb--180 {
    margin-bottom: -180px;
  }

  .sc-xs-mb--181 {
    margin-bottom: -181px;
  }

  .sc-xs-mb--182 {
    margin-bottom: -182px;
  }

  .sc-xs-mb--183 {
    margin-bottom: -183px;
  }

  .sc-xs-mb--184 {
    margin-bottom: -184px;
  }

  .sc-xs-mb--185 {
    margin-bottom: -185px;
  }

  .sc-xs-mb--186 {
    margin-bottom: -186px;
  }

  .sc-xs-mb--187 {
    margin-bottom: -187px;
  }

  .sc-xs-mb--188 {
    margin-bottom: -188px;
  }

  .sc-xs-mb--189 {
    margin-bottom: -189px;
  }

  .sc-xs-mb--190 {
    margin-bottom: -190px;
  }

  .sc-xs-mb--191 {
    margin-bottom: -191px;
  }

  .sc-xs-mb--192 {
    margin-bottom: -192px;
  }

  .sc-xs-mb--193 {
    margin-bottom: -193px;
  }

  .sc-xs-mb--194 {
    margin-bottom: -194px;
  }

  .sc-xs-mb--195 {
    margin-bottom: -195px;
  }

  .sc-xs-mb--196 {
    margin-bottom: -196px;
  }

  .sc-xs-mb--197 {
    margin-bottom: -197px;
  }

  .sc-xs-mb--198 {
    margin-bottom: -198px;
  }

  .sc-xs-mb--199 {
    margin-bottom: -199px;
  }

  .sc-xs-mb--200 {
    margin-bottom: -200px;
  }

  .sc-xs-ml--1 {
    margin-left: -1px;
  }

  .sc-xs-ml--2 {
    margin-left: -2px;
  }

  .sc-xs-ml--3 {
    margin-left: -3px;
  }

  .sc-xs-ml--4 {
    margin-left: -4px;
  }

  .sc-xs-ml--5 {
    margin-left: -5px;
  }

  .sc-xs-ml--6 {
    margin-left: -6px;
  }

  .sc-xs-ml--7 {
    margin-left: -7px;
  }

  .sc-xs-ml--8 {
    margin-left: -8px;
  }

  .sc-xs-ml--9 {
    margin-left: -9px;
  }

  .sc-xs-ml--10 {
    margin-left: -10px;
  }

  .sc-xs-ml--11 {
    margin-left: -11px;
  }

  .sc-xs-ml--12 {
    margin-left: -12px;
  }

  .sc-xs-ml--13 {
    margin-left: -13px;
  }

  .sc-xs-ml--14 {
    margin-left: -14px;
  }

  .sc-xs-ml--15 {
    margin-left: -15px;
  }

  .sc-xs-ml--16 {
    margin-left: -16px;
  }

  .sc-xs-ml--17 {
    margin-left: -17px;
  }

  .sc-xs-ml--18 {
    margin-left: -18px;
  }

  .sc-xs-ml--19 {
    margin-left: -19px;
  }

  .sc-xs-ml--20 {
    margin-left: -20px;
  }

  .sc-xs-ml--21 {
    margin-left: -21px;
  }

  .sc-xs-ml--22 {
    margin-left: -22px;
  }

  .sc-xs-ml--23 {
    margin-left: -23px;
  }

  .sc-xs-ml--24 {
    margin-left: -24px;
  }

  .sc-xs-ml--25 {
    margin-left: -25px;
  }

  .sc-xs-ml--26 {
    margin-left: -26px;
  }

  .sc-xs-ml--27 {
    margin-left: -27px;
  }

  .sc-xs-ml--28 {
    margin-left: -28px;
  }

  .sc-xs-ml--29 {
    margin-left: -29px;
  }

  .sc-xs-ml--30 {
    margin-left: -30px;
  }

  .sc-xs-ml--31 {
    margin-left: -31px;
  }

  .sc-xs-ml--32 {
    margin-left: -32px;
  }

  .sc-xs-ml--33 {
    margin-left: -33px;
  }

  .sc-xs-ml--34 {
    margin-left: -34px;
  }

  .sc-xs-ml--35 {
    margin-left: -35px;
  }

  .sc-xs-ml--36 {
    margin-left: -36px;
  }

  .sc-xs-ml--37 {
    margin-left: -37px;
  }

  .sc-xs-ml--38 {
    margin-left: -38px;
  }

  .sc-xs-ml--39 {
    margin-left: -39px;
  }

  .sc-xs-ml--40 {
    margin-left: -40px;
  }

  .sc-xs-ml--41 {
    margin-left: -41px;
  }

  .sc-xs-ml--42 {
    margin-left: -42px;
  }

  .sc-xs-ml--43 {
    margin-left: -43px;
  }

  .sc-xs-ml--44 {
    margin-left: -44px;
  }

  .sc-xs-ml--45 {
    margin-left: -45px;
  }

  .sc-xs-ml--46 {
    margin-left: -46px;
  }

  .sc-xs-ml--47 {
    margin-left: -47px;
  }

  .sc-xs-ml--48 {
    margin-left: -48px;
  }

  .sc-xs-ml--49 {
    margin-left: -49px;
  }

  .sc-xs-ml--50 {
    margin-left: -50px;
  }

  .sc-xs-ml--51 {
    margin-left: -51px;
  }

  .sc-xs-ml--52 {
    margin-left: -52px;
  }

  .sc-xs-ml--53 {
    margin-left: -53px;
  }

  .sc-xs-ml--54 {
    margin-left: -54px;
  }

  .sc-xs-ml--55 {
    margin-left: -55px;
  }

  .sc-xs-ml--56 {
    margin-left: -56px;
  }

  .sc-xs-ml--57 {
    margin-left: -57px;
  }

  .sc-xs-ml--58 {
    margin-left: -58px;
  }

  .sc-xs-ml--59 {
    margin-left: -59px;
  }

  .sc-xs-ml--60 {
    margin-left: -60px;
  }

  .sc-xs-ml--61 {
    margin-left: -61px;
  }

  .sc-xs-ml--62 {
    margin-left: -62px;
  }

  .sc-xs-ml--63 {
    margin-left: -63px;
  }

  .sc-xs-ml--64 {
    margin-left: -64px;
  }

  .sc-xs-ml--65 {
    margin-left: -65px;
  }

  .sc-xs-ml--66 {
    margin-left: -66px;
  }

  .sc-xs-ml--67 {
    margin-left: -67px;
  }

  .sc-xs-ml--68 {
    margin-left: -68px;
  }

  .sc-xs-ml--69 {
    margin-left: -69px;
  }

  .sc-xs-ml--70 {
    margin-left: -70px;
  }

  .sc-xs-ml--71 {
    margin-left: -71px;
  }

  .sc-xs-ml--72 {
    margin-left: -72px;
  }

  .sc-xs-ml--73 {
    margin-left: -73px;
  }

  .sc-xs-ml--74 {
    margin-left: -74px;
  }

  .sc-xs-ml--75 {
    margin-left: -75px;
  }

  .sc-xs-ml--76 {
    margin-left: -76px;
  }

  .sc-xs-ml--77 {
    margin-left: -77px;
  }

  .sc-xs-ml--78 {
    margin-left: -78px;
  }

  .sc-xs-ml--79 {
    margin-left: -79px;
  }

  .sc-xs-ml--80 {
    margin-left: -80px;
  }

  .sc-xs-ml--81 {
    margin-left: -81px;
  }

  .sc-xs-ml--82 {
    margin-left: -82px;
  }

  .sc-xs-ml--83 {
    margin-left: -83px;
  }

  .sc-xs-ml--84 {
    margin-left: -84px;
  }

  .sc-xs-ml--85 {
    margin-left: -85px;
  }

  .sc-xs-ml--86 {
    margin-left: -86px;
  }

  .sc-xs-ml--87 {
    margin-left: -87px;
  }

  .sc-xs-ml--88 {
    margin-left: -88px;
  }

  .sc-xs-ml--89 {
    margin-left: -89px;
  }

  .sc-xs-ml--90 {
    margin-left: -90px;
  }

  .sc-xs-ml--91 {
    margin-left: -91px;
  }

  .sc-xs-ml--92 {
    margin-left: -92px;
  }

  .sc-xs-ml--93 {
    margin-left: -93px;
  }

  .sc-xs-ml--94 {
    margin-left: -94px;
  }

  .sc-xs-ml--95 {
    margin-left: -95px;
  }

  .sc-xs-ml--96 {
    margin-left: -96px;
  }

  .sc-xs-ml--97 {
    margin-left: -97px;
  }

  .sc-xs-ml--98 {
    margin-left: -98px;
  }

  .sc-xs-ml--99 {
    margin-left: -99px;
  }

  .sc-xs-ml--100 {
    margin-left: -100px;
  }

  .sc-xs-ml--101 {
    margin-left: -101px;
  }

  .sc-xs-ml--102 {
    margin-left: -102px;
  }

  .sc-xs-ml--103 {
    margin-left: -103px;
  }

  .sc-xs-ml--104 {
    margin-left: -104px;
  }

  .sc-xs-ml--105 {
    margin-left: -105px;
  }

  .sc-xs-ml--106 {
    margin-left: -106px;
  }

  .sc-xs-ml--107 {
    margin-left: -107px;
  }

  .sc-xs-ml--108 {
    margin-left: -108px;
  }

  .sc-xs-ml--109 {
    margin-left: -109px;
  }

  .sc-xs-ml--110 {
    margin-left: -110px;
  }

  .sc-xs-ml--111 {
    margin-left: -111px;
  }

  .sc-xs-ml--112 {
    margin-left: -112px;
  }

  .sc-xs-ml--113 {
    margin-left: -113px;
  }

  .sc-xs-ml--114 {
    margin-left: -114px;
  }

  .sc-xs-ml--115 {
    margin-left: -115px;
  }

  .sc-xs-ml--116 {
    margin-left: -116px;
  }

  .sc-xs-ml--117 {
    margin-left: -117px;
  }

  .sc-xs-ml--118 {
    margin-left: -118px;
  }

  .sc-xs-ml--119 {
    margin-left: -119px;
  }

  .sc-xs-ml--120 {
    margin-left: -120px;
  }

  .sc-xs-ml--121 {
    margin-left: -121px;
  }

  .sc-xs-ml--122 {
    margin-left: -122px;
  }

  .sc-xs-ml--123 {
    margin-left: -123px;
  }

  .sc-xs-ml--124 {
    margin-left: -124px;
  }

  .sc-xs-ml--125 {
    margin-left: -125px;
  }

  .sc-xs-ml--126 {
    margin-left: -126px;
  }

  .sc-xs-ml--127 {
    margin-left: -127px;
  }

  .sc-xs-ml--128 {
    margin-left: -128px;
  }

  .sc-xs-ml--129 {
    margin-left: -129px;
  }

  .sc-xs-ml--130 {
    margin-left: -130px;
  }

  .sc-xs-ml--131 {
    margin-left: -131px;
  }

  .sc-xs-ml--132 {
    margin-left: -132px;
  }

  .sc-xs-ml--133 {
    margin-left: -133px;
  }

  .sc-xs-ml--134 {
    margin-left: -134px;
  }

  .sc-xs-ml--135 {
    margin-left: -135px;
  }

  .sc-xs-ml--136 {
    margin-left: -136px;
  }

  .sc-xs-ml--137 {
    margin-left: -137px;
  }

  .sc-xs-ml--138 {
    margin-left: -138px;
  }

  .sc-xs-ml--139 {
    margin-left: -139px;
  }

  .sc-xs-ml--140 {
    margin-left: -140px;
  }

  .sc-xs-ml--141 {
    margin-left: -141px;
  }

  .sc-xs-ml--142 {
    margin-left: -142px;
  }

  .sc-xs-ml--143 {
    margin-left: -143px;
  }

  .sc-xs-ml--144 {
    margin-left: -144px;
  }

  .sc-xs-ml--145 {
    margin-left: -145px;
  }

  .sc-xs-ml--146 {
    margin-left: -146px;
  }

  .sc-xs-ml--147 {
    margin-left: -147px;
  }

  .sc-xs-ml--148 {
    margin-left: -148px;
  }

  .sc-xs-ml--149 {
    margin-left: -149px;
  }

  .sc-xs-ml--150 {
    margin-left: -150px;
  }

  .sc-xs-ml--151 {
    margin-left: -151px;
  }

  .sc-xs-ml--152 {
    margin-left: -152px;
  }

  .sc-xs-ml--153 {
    margin-left: -153px;
  }

  .sc-xs-ml--154 {
    margin-left: -154px;
  }

  .sc-xs-ml--155 {
    margin-left: -155px;
  }

  .sc-xs-ml--156 {
    margin-left: -156px;
  }

  .sc-xs-ml--157 {
    margin-left: -157px;
  }

  .sc-xs-ml--158 {
    margin-left: -158px;
  }

  .sc-xs-ml--159 {
    margin-left: -159px;
  }

  .sc-xs-ml--160 {
    margin-left: -160px;
  }

  .sc-xs-ml--161 {
    margin-left: -161px;
  }

  .sc-xs-ml--162 {
    margin-left: -162px;
  }

  .sc-xs-ml--163 {
    margin-left: -163px;
  }

  .sc-xs-ml--164 {
    margin-left: -164px;
  }

  .sc-xs-ml--165 {
    margin-left: -165px;
  }

  .sc-xs-ml--166 {
    margin-left: -166px;
  }

  .sc-xs-ml--167 {
    margin-left: -167px;
  }

  .sc-xs-ml--168 {
    margin-left: -168px;
  }

  .sc-xs-ml--169 {
    margin-left: -169px;
  }

  .sc-xs-ml--170 {
    margin-left: -170px;
  }

  .sc-xs-ml--171 {
    margin-left: -171px;
  }

  .sc-xs-ml--172 {
    margin-left: -172px;
  }

  .sc-xs-ml--173 {
    margin-left: -173px;
  }

  .sc-xs-ml--174 {
    margin-left: -174px;
  }

  .sc-xs-ml--175 {
    margin-left: -175px;
  }

  .sc-xs-ml--176 {
    margin-left: -176px;
  }

  .sc-xs-ml--177 {
    margin-left: -177px;
  }

  .sc-xs-ml--178 {
    margin-left: -178px;
  }

  .sc-xs-ml--179 {
    margin-left: -179px;
  }

  .sc-xs-ml--180 {
    margin-left: -180px;
  }

  .sc-xs-ml--181 {
    margin-left: -181px;
  }

  .sc-xs-ml--182 {
    margin-left: -182px;
  }

  .sc-xs-ml--183 {
    margin-left: -183px;
  }

  .sc-xs-ml--184 {
    margin-left: -184px;
  }

  .sc-xs-ml--185 {
    margin-left: -185px;
  }

  .sc-xs-ml--186 {
    margin-left: -186px;
  }

  .sc-xs-ml--187 {
    margin-left: -187px;
  }

  .sc-xs-ml--188 {
    margin-left: -188px;
  }

  .sc-xs-ml--189 {
    margin-left: -189px;
  }

  .sc-xs-ml--190 {
    margin-left: -190px;
  }

  .sc-xs-ml--191 {
    margin-left: -191px;
  }

  .sc-xs-ml--192 {
    margin-left: -192px;
  }

  .sc-xs-ml--193 {
    margin-left: -193px;
  }

  .sc-xs-ml--194 {
    margin-left: -194px;
  }

  .sc-xs-ml--195 {
    margin-left: -195px;
  }

  .sc-xs-ml--196 {
    margin-left: -196px;
  }

  .sc-xs-ml--197 {
    margin-left: -197px;
  }

  .sc-xs-ml--198 {
    margin-left: -198px;
  }

  .sc-xs-ml--199 {
    margin-left: -199px;
  }

  .sc-xs-ml--200 {
    margin-left: -200px;
  }

  .sc-xs-mr--1 {
    margin-right: -1px;
  }

  .sc-xs-mr--2 {
    margin-right: -2px;
  }

  .sc-xs-mr--3 {
    margin-right: -3px;
  }

  .sc-xs-mr--4 {
    margin-right: -4px;
  }

  .sc-xs-mr--5 {
    margin-right: -5px;
  }

  .sc-xs-mr--6 {
    margin-right: -6px;
  }

  .sc-xs-mr--7 {
    margin-right: -7px;
  }

  .sc-xs-mr--8 {
    margin-right: -8px;
  }

  .sc-xs-mr--9 {
    margin-right: -9px;
  }

  .sc-xs-mr--10 {
    margin-right: -10px;
  }

  .sc-xs-mr--11 {
    margin-right: -11px;
  }

  .sc-xs-mr--12 {
    margin-right: -12px;
  }

  .sc-xs-mr--13 {
    margin-right: -13px;
  }

  .sc-xs-mr--14 {
    margin-right: -14px;
  }

  .sc-xs-mr--15 {
    margin-right: -15px;
  }

  .sc-xs-mr--16 {
    margin-right: -16px;
  }

  .sc-xs-mr--17 {
    margin-right: -17px;
  }

  .sc-xs-mr--18 {
    margin-right: -18px;
  }

  .sc-xs-mr--19 {
    margin-right: -19px;
  }

  .sc-xs-mr--20 {
    margin-right: -20px;
  }

  .sc-xs-mr--21 {
    margin-right: -21px;
  }

  .sc-xs-mr--22 {
    margin-right: -22px;
  }

  .sc-xs-mr--23 {
    margin-right: -23px;
  }

  .sc-xs-mr--24 {
    margin-right: -24px;
  }

  .sc-xs-mr--25 {
    margin-right: -25px;
  }

  .sc-xs-mr--26 {
    margin-right: -26px;
  }

  .sc-xs-mr--27 {
    margin-right: -27px;
  }

  .sc-xs-mr--28 {
    margin-right: -28px;
  }

  .sc-xs-mr--29 {
    margin-right: -29px;
  }

  .sc-xs-mr--30 {
    margin-right: -30px;
  }

  .sc-xs-mr--31 {
    margin-right: -31px;
  }

  .sc-xs-mr--32 {
    margin-right: -32px;
  }

  .sc-xs-mr--33 {
    margin-right: -33px;
  }

  .sc-xs-mr--34 {
    margin-right: -34px;
  }

  .sc-xs-mr--35 {
    margin-right: -35px;
  }

  .sc-xs-mr--36 {
    margin-right: -36px;
  }

  .sc-xs-mr--37 {
    margin-right: -37px;
  }

  .sc-xs-mr--38 {
    margin-right: -38px;
  }

  .sc-xs-mr--39 {
    margin-right: -39px;
  }

  .sc-xs-mr--40 {
    margin-right: -40px;
  }

  .sc-xs-mr--41 {
    margin-right: -41px;
  }

  .sc-xs-mr--42 {
    margin-right: -42px;
  }

  .sc-xs-mr--43 {
    margin-right: -43px;
  }

  .sc-xs-mr--44 {
    margin-right: -44px;
  }

  .sc-xs-mr--45 {
    margin-right: -45px;
  }

  .sc-xs-mr--46 {
    margin-right: -46px;
  }

  .sc-xs-mr--47 {
    margin-right: -47px;
  }

  .sc-xs-mr--48 {
    margin-right: -48px;
  }

  .sc-xs-mr--49 {
    margin-right: -49px;
  }

  .sc-xs-mr--50 {
    margin-right: -50px;
  }

  .sc-xs-mr--51 {
    margin-right: -51px;
  }

  .sc-xs-mr--52 {
    margin-right: -52px;
  }

  .sc-xs-mr--53 {
    margin-right: -53px;
  }

  .sc-xs-mr--54 {
    margin-right: -54px;
  }

  .sc-xs-mr--55 {
    margin-right: -55px;
  }

  .sc-xs-mr--56 {
    margin-right: -56px;
  }

  .sc-xs-mr--57 {
    margin-right: -57px;
  }

  .sc-xs-mr--58 {
    margin-right: -58px;
  }

  .sc-xs-mr--59 {
    margin-right: -59px;
  }

  .sc-xs-mr--60 {
    margin-right: -60px;
  }

  .sc-xs-mr--61 {
    margin-right: -61px;
  }

  .sc-xs-mr--62 {
    margin-right: -62px;
  }

  .sc-xs-mr--63 {
    margin-right: -63px;
  }

  .sc-xs-mr--64 {
    margin-right: -64px;
  }

  .sc-xs-mr--65 {
    margin-right: -65px;
  }

  .sc-xs-mr--66 {
    margin-right: -66px;
  }

  .sc-xs-mr--67 {
    margin-right: -67px;
  }

  .sc-xs-mr--68 {
    margin-right: -68px;
  }

  .sc-xs-mr--69 {
    margin-right: -69px;
  }

  .sc-xs-mr--70 {
    margin-right: -70px;
  }

  .sc-xs-mr--71 {
    margin-right: -71px;
  }

  .sc-xs-mr--72 {
    margin-right: -72px;
  }

  .sc-xs-mr--73 {
    margin-right: -73px;
  }

  .sc-xs-mr--74 {
    margin-right: -74px;
  }

  .sc-xs-mr--75 {
    margin-right: -75px;
  }

  .sc-xs-mr--76 {
    margin-right: -76px;
  }

  .sc-xs-mr--77 {
    margin-right: -77px;
  }

  .sc-xs-mr--78 {
    margin-right: -78px;
  }

  .sc-xs-mr--79 {
    margin-right: -79px;
  }

  .sc-xs-mr--80 {
    margin-right: -80px;
  }

  .sc-xs-mr--81 {
    margin-right: -81px;
  }

  .sc-xs-mr--82 {
    margin-right: -82px;
  }

  .sc-xs-mr--83 {
    margin-right: -83px;
  }

  .sc-xs-mr--84 {
    margin-right: -84px;
  }

  .sc-xs-mr--85 {
    margin-right: -85px;
  }

  .sc-xs-mr--86 {
    margin-right: -86px;
  }

  .sc-xs-mr--87 {
    margin-right: -87px;
  }

  .sc-xs-mr--88 {
    margin-right: -88px;
  }

  .sc-xs-mr--89 {
    margin-right: -89px;
  }

  .sc-xs-mr--90 {
    margin-right: -90px;
  }

  .sc-xs-mr--91 {
    margin-right: -91px;
  }

  .sc-xs-mr--92 {
    margin-right: -92px;
  }

  .sc-xs-mr--93 {
    margin-right: -93px;
  }

  .sc-xs-mr--94 {
    margin-right: -94px;
  }

  .sc-xs-mr--95 {
    margin-right: -95px;
  }

  .sc-xs-mr--96 {
    margin-right: -96px;
  }

  .sc-xs-mr--97 {
    margin-right: -97px;
  }

  .sc-xs-mr--98 {
    margin-right: -98px;
  }

  .sc-xs-mr--99 {
    margin-right: -99px;
  }

  .sc-xs-mr--100 {
    margin-right: -100px;
  }

  .sc-xs-mr--101 {
    margin-right: -101px;
  }

  .sc-xs-mr--102 {
    margin-right: -102px;
  }

  .sc-xs-mr--103 {
    margin-right: -103px;
  }

  .sc-xs-mr--104 {
    margin-right: -104px;
  }

  .sc-xs-mr--105 {
    margin-right: -105px;
  }

  .sc-xs-mr--106 {
    margin-right: -106px;
  }

  .sc-xs-mr--107 {
    margin-right: -107px;
  }

  .sc-xs-mr--108 {
    margin-right: -108px;
  }

  .sc-xs-mr--109 {
    margin-right: -109px;
  }

  .sc-xs-mr--110 {
    margin-right: -110px;
  }

  .sc-xs-mr--111 {
    margin-right: -111px;
  }

  .sc-xs-mr--112 {
    margin-right: -112px;
  }

  .sc-xs-mr--113 {
    margin-right: -113px;
  }

  .sc-xs-mr--114 {
    margin-right: -114px;
  }

  .sc-xs-mr--115 {
    margin-right: -115px;
  }

  .sc-xs-mr--116 {
    margin-right: -116px;
  }

  .sc-xs-mr--117 {
    margin-right: -117px;
  }

  .sc-xs-mr--118 {
    margin-right: -118px;
  }

  .sc-xs-mr--119 {
    margin-right: -119px;
  }

  .sc-xs-mr--120 {
    margin-right: -120px;
  }

  .sc-xs-mr--121 {
    margin-right: -121px;
  }

  .sc-xs-mr--122 {
    margin-right: -122px;
  }

  .sc-xs-mr--123 {
    margin-right: -123px;
  }

  .sc-xs-mr--124 {
    margin-right: -124px;
  }

  .sc-xs-mr--125 {
    margin-right: -125px;
  }

  .sc-xs-mr--126 {
    margin-right: -126px;
  }

  .sc-xs-mr--127 {
    margin-right: -127px;
  }

  .sc-xs-mr--128 {
    margin-right: -128px;
  }

  .sc-xs-mr--129 {
    margin-right: -129px;
  }

  .sc-xs-mr--130 {
    margin-right: -130px;
  }

  .sc-xs-mr--131 {
    margin-right: -131px;
  }

  .sc-xs-mr--132 {
    margin-right: -132px;
  }

  .sc-xs-mr--133 {
    margin-right: -133px;
  }

  .sc-xs-mr--134 {
    margin-right: -134px;
  }

  .sc-xs-mr--135 {
    margin-right: -135px;
  }

  .sc-xs-mr--136 {
    margin-right: -136px;
  }

  .sc-xs-mr--137 {
    margin-right: -137px;
  }

  .sc-xs-mr--138 {
    margin-right: -138px;
  }

  .sc-xs-mr--139 {
    margin-right: -139px;
  }

  .sc-xs-mr--140 {
    margin-right: -140px;
  }

  .sc-xs-mr--141 {
    margin-right: -141px;
  }

  .sc-xs-mr--142 {
    margin-right: -142px;
  }

  .sc-xs-mr--143 {
    margin-right: -143px;
  }

  .sc-xs-mr--144 {
    margin-right: -144px;
  }

  .sc-xs-mr--145 {
    margin-right: -145px;
  }

  .sc-xs-mr--146 {
    margin-right: -146px;
  }

  .sc-xs-mr--147 {
    margin-right: -147px;
  }

  .sc-xs-mr--148 {
    margin-right: -148px;
  }

  .sc-xs-mr--149 {
    margin-right: -149px;
  }

  .sc-xs-mr--150 {
    margin-right: -150px;
  }

  .sc-xs-mr--151 {
    margin-right: -151px;
  }

  .sc-xs-mr--152 {
    margin-right: -152px;
  }

  .sc-xs-mr--153 {
    margin-right: -153px;
  }

  .sc-xs-mr--154 {
    margin-right: -154px;
  }

  .sc-xs-mr--155 {
    margin-right: -155px;
  }

  .sc-xs-mr--156 {
    margin-right: -156px;
  }

  .sc-xs-mr--157 {
    margin-right: -157px;
  }

  .sc-xs-mr--158 {
    margin-right: -158px;
  }

  .sc-xs-mr--159 {
    margin-right: -159px;
  }

  .sc-xs-mr--160 {
    margin-right: -160px;
  }

  .sc-xs-mr--161 {
    margin-right: -161px;
  }

  .sc-xs-mr--162 {
    margin-right: -162px;
  }

  .sc-xs-mr--163 {
    margin-right: -163px;
  }

  .sc-xs-mr--164 {
    margin-right: -164px;
  }

  .sc-xs-mr--165 {
    margin-right: -165px;
  }

  .sc-xs-mr--166 {
    margin-right: -166px;
  }

  .sc-xs-mr--167 {
    margin-right: -167px;
  }

  .sc-xs-mr--168 {
    margin-right: -168px;
  }

  .sc-xs-mr--169 {
    margin-right: -169px;
  }

  .sc-xs-mr--170 {
    margin-right: -170px;
  }

  .sc-xs-mr--171 {
    margin-right: -171px;
  }

  .sc-xs-mr--172 {
    margin-right: -172px;
  }

  .sc-xs-mr--173 {
    margin-right: -173px;
  }

  .sc-xs-mr--174 {
    margin-right: -174px;
  }

  .sc-xs-mr--175 {
    margin-right: -175px;
  }

  .sc-xs-mr--176 {
    margin-right: -176px;
  }

  .sc-xs-mr--177 {
    margin-right: -177px;
  }

  .sc-xs-mr--178 {
    margin-right: -178px;
  }

  .sc-xs-mr--179 {
    margin-right: -179px;
  }

  .sc-xs-mr--180 {
    margin-right: -180px;
  }

  .sc-xs-mr--181 {
    margin-right: -181px;
  }

  .sc-xs-mr--182 {
    margin-right: -182px;
  }

  .sc-xs-mr--183 {
    margin-right: -183px;
  }

  .sc-xs-mr--184 {
    margin-right: -184px;
  }

  .sc-xs-mr--185 {
    margin-right: -185px;
  }

  .sc-xs-mr--186 {
    margin-right: -186px;
  }

  .sc-xs-mr--187 {
    margin-right: -187px;
  }

  .sc-xs-mr--188 {
    margin-right: -188px;
  }

  .sc-xs-mr--189 {
    margin-right: -189px;
  }

  .sc-xs-mr--190 {
    margin-right: -190px;
  }

  .sc-xs-mr--191 {
    margin-right: -191px;
  }

  .sc-xs-mr--192 {
    margin-right: -192px;
  }

  .sc-xs-mr--193 {
    margin-right: -193px;
  }

  .sc-xs-mr--194 {
    margin-right: -194px;
  }

  .sc-xs-mr--195 {
    margin-right: -195px;
  }

  .sc-xs-mr--196 {
    margin-right: -196px;
  }

  .sc-xs-mr--197 {
    margin-right: -197px;
  }

  .sc-xs-mr--198 {
    margin-right: -198px;
  }

  .sc-xs-mr--199 {
    margin-right: -199px;
  }

  .sc-xs-mr--200 {
    margin-right: -200px;
  }
}


/* ============== 03 header section ============== */

.sc-header-section,
.inner-header-style {
  position: absolute;
  width: 100%;
  z-index: 9;
  padding: 25px 0;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-header-section .container-fluid,
.inner-header-style .container-fluid {
  padding-left: 80px;
  padding-right: 80px;
}

.sc-header-section:not(.sc-header-sticky) .sticky-logo,
.inner-header-style:not(.sc-header-sticky) .sticky-logo {
  display: none;
}

.sc-header-section.sc-header-sticky,
.sc-header-sticky.inner-header-style {
  background: #fff;
  padding: 0;
  z-index: 99;
}

.sc-header-section.sc-header-sticky .default-logo,
.sc-header-sticky.inner-header-style .default-logo {
  display: none;
}

.sc-header-section.sc-header-sticky .sc-main-menu>ul>li>a,
.sc-header-sticky.inner-header-style .sc-main-menu>ul>li>a {
  color: #424242;
}

.sc-header-section.sc-header-sticky .sc-main-menu>ul>li>a:hover,
.sc-header-sticky.inner-header-style .sc-main-menu>ul>li>a:hover {
  color: #ef9334;
}

.sc-header-section.sc-header-sticky .sc-main-menu>ul>li .active,
.sc-header-sticky.inner-header-style .sc-main-menu>ul>li .active {
  color: #ef9334;
}

.sc-header-section.sc-header-sticky .sc-main-menu>ul>li .active::before,
.sc-header-sticky.inner-header-style .sc-main-menu>ul>li .active::before {
  color: #ef9334;
}

.sc-header-section.sc-header-sticky .header-cart-area .icon-list li:before,
.sc-header-sticky.inner-header-style .header-cart-area .icon-list li:before {
  position: absolute;
  content: "";
  top: 52%;
  transform: translateY(-50%);
  right: 0;
  background: #424242;
  height: 7px;
  width: 1px;
}

.sc-header-section.sc-header-sticky .header-cart-area .icon-list li a,
.sc-header-sticky.inner-header-style .header-cart-area .icon-list li a {
  color: #424242;
}

.sc-header-section.sc-header-sticky .header-cart-area .icon-list li a:hover,
.sc-header-sticky.inner-header-style .header-cart-area .icon-list li a:hover {
  color: #ef9334;
}

.sc-header-section.sc-header-sticky,
.sc-header-sticky.inner-header-style {
  position: fixed;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.08);
}

.sc-header-sticky .sc-main-menu>ul>li.menu-item-has-children a::before {
  color: #424242;
}

.sc-header-sticky div.header-list ul li a {
  color: #424242;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-sticky div.header-list ul li a:hover {
  color: #ef9334;
}

.sc-header-sticky .sticky-btn:before,
.sc-header-sticky .sticky-btn:after {
  background-color: #ef9334;
}

.sc-header-sticky .sticky-btn span {
  color: #ffffff;
}

.sc-main-menu>ul>li {
  margin-right: 45px;
}

.sc-main-menu>ul>li>a {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sc-main-menu>ul>li .active {
  color: #ef9334;
}

.sc-main-menu>ul>li .active::before {
  color: #ef9334;
}

.sc-main-menu>ul>li.menu-item-has-children>a::before {
  content: "\ea4e";
  font-family: remixicon;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 28px;
  right: -20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-main-menu>ul>li.menu-item-has-children>a.active::before,
.sc-main-menu>ul>li.menu-item-has-children>a:hover::before {
  color: #ef9334;
}

.sc-main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.sc-main-menu li {
  display: inline-block;
  position: relative;
}

.sc-main-menu li:last-child {
  margin-right: 0;
}

.sc-main-menu li a {
  color: #ffffff;
  font-size: 16px;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-main-menu li a:hover {
  color: #ef9334;
}

.sc-main-menu li .sub-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
  padding: 20px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  top: 120%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 200px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.sc-main-menu li .sub-menu-list::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 3px;
  background: #ef9334;
  content: "";
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-main-menu li .sub-menu-list li {
  display: block;
  padding-bottom: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.sc-main-menu li .sub-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-main-menu li .sub-menu-list li a {
  color: #666;
}

.sc-main-menu li .sub-menu-list li a.active,
.sc-main-menu li .sub-menu-list li a:hover {
  color: #ef9334;
}

.sc-main-menu li .sub-menu-list li::before {
  content: "";
  width: 0;
  height: 2px;
  background: #ef9334;
  position: absolute;
  top: 13px;
  left: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-main-menu li .sub-menu-list li:hover {
  padding-left: 20px;
}

.sc-main-menu li .sub-menu-list li:hover::before {
  width: 14px;
}

.sc-main-menu li:hover .sub-menu-list {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sc-main-menu li:hover .sub-menu-list::before {
  width: 100%;
}

.sc-main-menu>ul>li>a {
  text-transform: capitalize;
}

.header-btn-area .header-list ul li {
  display: inline-block;
}

.header-btn-area .header-list ul li a {
  color: #fff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.header-btn-area .header-list ul li a i {
  padding-right: 5px;
}

.header-btn-area .header-list ul li a:hover {
  /*color: #7215fa;*/
  color: #E82127;

}

.header-btn-area .header-list ul.dropdown-menu li {
  display: block;
}

.transparent-bg {
  background: #ffffff;
  padding: 15px 0 5px;
}

.sc-main-menu li .sub-menu-list {
  text-align: left;
}


.sc-header-section2.sc-header-sticky,
.sc-header-sticky.sc-header-section3 {
  padding: 0;
}

.sc-header-section2 .sc-main-menu,
.sc-header-section3 .sc-main-menu {
  text-align: center;
}

.sc-header-section2 .sc-main-menu .sub-menu-list,
.sc-header-section3 .sc-main-menu .sub-menu-list {
  text-align: left;
}

.sc-header-section2 .main-menu li a,
.sc-header-section3 .main-menu li a {
  color: rgba(66, 66, 66, 0.8);
}

.sc-header-section2 .main-menu li a.active,
.sc-header-section3 .main-menu li a.active,
.sc-header-section2 .main-menu li a:hover,
.sc-header-section3 .main-menu li a:hover {
  color: #ef9334;
}

.sc-header-section2 .sc-main-menu ul .menu-item-has-children a::before,
.sc-header-section3 .sc-main-menu ul .menu-item-has-children a::before {
  color: rgba(66, 66, 66, 0.8);
}

.sc-header-section2 .sc-main-menu>ul>li .active::before,
.sc-header-section3 .sc-main-menu>ul>li .active::before {
  color: #ef9334;
}

.sc-header-section2 .header-list ul li a,
.sc-header-section3 .header-list ul li a {
  color: #424242;
}

.sc-header-section2 .header-list ul li a:hover,
.sc-header-section3 .header-list ul li a:hover {
  color: #ef9334;
}

.sc-header-section3 .header-btn {
  position: relative;
  top: 5px;
}

.sc-header-section3 .header-list ul li a {
  color: #ffffff;
}

.sc-header-section3 .header-list ul li a:hover {
  color: #ef9334;
}

.sc-header-section4.sc-header-sticky {
  padding: 0;
}

.sc-header-section4 .sc-main-menu {
  text-align: center;
}

.sc-header-section4 .sc-main-menu .sub-menu-list {
  text-align: left;
}

.sc-header-section4 .main-menu li a {
  color: rgba(66, 66, 66, 0.8);
}

.sc-header-section4 .main-menu li a:hover {
  color: #ef9334;
}

.sc-header-section4 .sc-main-menu ul .menu-item-has-children a::before {
  color: rgba(66, 66, 66, 0.8);
}

.sc-header-section4 .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #ef9334;
}

.sc-header-section4 .header-list ul li a {
  color: #424242;
}

.sc-header-section4 .header-list ul li a:hover {
  color: #ef9334;
}

.sc-header-section5.sc-header-sticky {
  background: #fff;
  padding-top: 10px;
  z-index: 99;
}

.sc-header-section5.sc-header-sticky .default-logo {
  display: none;
}

.sc-header-section5.sc-header-sticky .sc-main-menu>ul>li>a {
  color: #424242;
}

.sc-header-section5.sc-header-sticky .sc-main-menu>ul>li>a:hover {
  color: #7a77ff;
}

.sc-header-section5.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active {
  color: #7a77ff;
}

.sc-header-section5.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #7a77ff;
}

.sc-header-section5.sc-header-sticky .header-cart-area .icon-list li:before {
  position: absolute;
  content: "";
  top: 52%;
  transform: translateY(-50%);
  right: 0;
  background: #424242;
  height: 7px;
  width: 1px;
}

.sc-header-section5.sc-header-sticky .header-cart-area .icon-list li a {
  color: #424242;
}

.sc-header-section5.sc-header-sticky .header-cart-area .icon-list li a:hover {
  color: #7a77ff;
}

.sc-header-section5.sc-header-sticky .header-list ul li a {
  color: #424242;
}

.sc-header-section5.sc-header-sticky .header-list ul li a:hover {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu>ul>li {
  margin-right: 45px;
}

.sc-header-section5 .sc-main-menu>ul>li>a {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sc-header-section5 .sc-main-menu>ul>li.current-menu-item .active {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu>ul>li.menu-item-has-children>a::before {
  content: "\ea4e";
  font-family: remixicon;
  font-weight: 400;
  color: #424242;
  font-size: 18px;
  position: absolute;
  top: 28px;
  right: -20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section5 .sc-main-menu>ul>li.menu-item-has-children>a:hover::before {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.sc-header-section5 .sc-main-menu li {
  display: inline-block;
  position: relative;
}

.sc-header-section5 .sc-main-menu li:last-child {
  margin-right: 0;
}

.sc-header-section5 .sc-main-menu li a {
  color: #424242;
  font-size: 16px;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section5 .sc-main-menu li a:hover {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
  padding: 20px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  top: 120%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 200px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.sc-header-section5 .sc-main-menu li .sub-menu-list::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 3px;
  background: #7a77ff;
  content: "";
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li {
  display: block;
  padding-bottom: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li a {
  color: #666;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li a:hover {
  color: #7a77ff;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li::before {
  content: "";
  width: 0;
  height: 2px;
  background: #7a77ff;
  position: absolute;
  top: 13px;
  left: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li:hover {
  padding-left: 20px;
}

.sc-header-section5 .sc-main-menu li .sub-menu-list li:hover::before {
  width: 14px;
}

.sc-header-section5 .sc-main-menu li:hover .sub-menu-list {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sc-header-section5 .sc-main-menu li:hover .sub-menu-list::before {
  width: 100%;
}

.sc-header-section5 .header-list ul li a {
  color: #424242;
}

.sc-header-section5 .header-list ul li a:hover {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky {
  background: #fff;
  padding-top: 10px;
  z-index: 99;
}

.sc-header-section6.sc-header-sticky .default-logo {
  display: none;
}

.sc-header-section6.sc-header-sticky .sc-main-menu>ul>li>a {
  color: #424242;
}

.sc-header-section6.sc-header-sticky .sc-main-menu>ul>li>a:hover {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky .header-cart-area .icon-list li:before {
  position: absolute;
  content: "";
  top: 52%;
  transform: translateY(-50%);
  right: 0;
  background: #424242;
  height: 7px;
  width: 1px;
}

.sc-header-section6.sc-header-sticky .header-cart-area .icon-list li a {
  color: #424242;
}

.sc-header-section6.sc-header-sticky .header-cart-area .icon-list li a:hover {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky .header-list ul li a {
  color: #424242;
}

.sc-header-section6.sc-header-sticky .header-list ul li a:hover {
  color: #7a77ff;
}

.sc-header-section6.sc-header-sticky .sc-main-menu>ul>li.menu-item-has-children>a::before {
  color: #424242;
}

.sc-header-section6 .sc-main-menu>ul>li {
  margin-right: 45px;
}

.sc-header-section6 .sc-main-menu>ul>li>a {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sc-header-section6 .sc-main-menu>ul>li.current-menu-item .active {
  color: #7a77ff;
}

.sc-header-section6 .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #7a77ff;
}

.sc-header-section6 .sc-main-menu>ul>li.menu-item-has-children>a::before {
  content: "\ea4e";
  font-family: remixicon;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 28px;
  right: -20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section6 .sc-main-menu>ul>li.menu-item-has-children>a:hover::before {
  color: #7a77ff;
}

.sc-header-section6 .sc-main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.sc-header-section6 .sc-main-menu li {
  display: inline-block;
  position: relative;
}

.sc-header-section6 .sc-main-menu li:last-child {
  margin-right: 0;
}

.sc-header-section6 .sc-main-menu li a {
  color: #ffffff;
  font-size: 16px;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section6 .sc-main-menu li a:hover {
  color: #7a77ff;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
  padding: 20px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  top: 120%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 200px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.sc-header-section6 .sc-main-menu li .sub-menu-list::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 3px;
  background: #7a77ff;
  content: "";
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li {
  display: block;
  padding-bottom: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li a {
  color: #666;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li a:hover {
  color: #7a77ff;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li::before {
  content: "";
  width: 0;
  height: 2px;
  background: #7a77ff;
  position: absolute;
  top: 13px;
  left: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li:hover {
  padding-left: 20px;
}

.sc-header-section6 .sc-main-menu li .sub-menu-list li:hover::before {
  width: 14px;
}

.sc-header-section6 .sc-main-menu li:hover .sub-menu-list {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sc-header-section6 .sc-main-menu li:hover .sub-menu-list::before {
  width: 100%;
}

.sc-header-section6 .header-list ul li a {
  color: #ffffff;
}

.sc-header-section6 .header-list ul li a:hover {
  color: #7a77ff;
}

.sc-header-section7.sc-header-sticky {
  background: #fff;
  padding: 0;
  z-index: 99;
}

.sc-header-section7.sc-header-sticky .default-logo {
  display: none;
}

.sc-header-section7.sc-header-sticky .sc-main-menu>ul>li>a {
  color: #424242;
}

.sc-header-section7.sc-header-sticky .sc-main-menu>ul>li>a:hover {
  color: #e81f41;
}

.sc-header-section7.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active {
  color: #e81f41;
}

.sc-header-section7.sc-header-sticky .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #e81f41;
}

.sc-header-section7.sc-header-sticky .header-cart-area .icon-list li:before {
  position: absolute;
  content: "";
  top: 52%;
  transform: translateY(-50%);
  right: 0;
  background: #424242;
  height: 7px;
  width: 1px;
}

.sc-header-section7.sc-header-sticky .header-cart-area .icon-list li a {
  color: #424242;
}

.sc-header-section7.sc-header-sticky .header-cart-area .icon-list li a:hover {
  color: #e81f41;
}

.sc-header-section7.sc-header-sticky .header-list ul li a {
  color: #424242;
}

.sc-header-section7.sc-header-sticky .header-list ul li a:hover {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu>ul>li {
  margin-right: 45px;
}

.sc-header-section7 .sc-main-menu>ul>li>a {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sc-header-section7 .sc-main-menu>ul>li.current-menu-item .active {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu>ul>li.current-menu-item .active::before {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu>ul>li.menu-item-has-children>a::before {
  content: "\ea4e";
  font-family: remixicon;
  font-weight: 400;
  color: #424242;
  font-size: 18px;
  position: absolute;
  top: 28px;
  right: -20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section7 .sc-main-menu>ul>li.menu-item-has-children>a:hover::before {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.sc-header-section7 .sc-main-menu li {
  display: inline-block;
  position: relative;
}

.sc-header-section7 .sc-main-menu li:last-child {
  margin-right: 0;
}

.sc-header-section7 .sc-main-menu li a {
  color: #424242;
  font-size: 16px;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section7 .sc-main-menu li a:hover {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #ffffff;
  padding: 20px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  top: 120%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 200px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.sc-header-section7 .sc-main-menu li .sub-menu-list::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 3px;
  background: #e81f41;
  content: "";
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li {
  display: block;
  padding-bottom: 5px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li a {
  color: #666;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li a:hover {
  color: #e81f41;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li::before {
  content: "";
  width: 0;
  height: 2px;
  background: #e81f41;
  position: absolute;
  top: 13px;
  left: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li:hover {
  padding-left: 20px;
}

.sc-header-section7 .sc-main-menu li .sub-menu-list li:hover::before {
  width: 14px;
}

.sc-header-section7 .sc-main-menu li:hover .sub-menu-list {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.sc-header-section7 .sc-main-menu li:hover .sub-menu-list::before {
  width: 100%;
}

.sc-header-section7 .sc-main-menu>ul>li.current-menu-item .active {
  color: #e81f41;
}

.sc-header-section7 .header-list ul li a {
  color: #424242;
}

.sc-header-section7 .header-list ul li a:hover {
  color: #e81f41;
}

.inner-header-style.sc-header-sticky .sc-main-menu>ul>li>a {
  padding-top: 25px;
  padding-bottom: 25px;
}

.inner-header-style.sc-header-sticky .sc-main-menu>ul>li.menu-item-has-children>a::before {
  top: 23px;
}

.inner-header-style .header-list ul li a {
  color: #ffffff;
}

.inner-header-style .header-list ul li a:hover {
  color: #ef9334;
}

.header-btn2 {
  position: relative;
  top: 5px;
}


/* ============== 04 banner section ============== */

.sc-banner-section-area {
  /*background: #7215fa;*/
  background: #E82127;
  position: relative;
}

.sc-banner-section-area .sc-banner-item .banner-header-content {
  max-width: 550px;
  margin-left: 31.5%;
}

.sc-banner-section-area .sc-banner-item .banner-header-content .banner-sub-title {
  padding-right: 80px;
}

.sc-banner-section-area .sc-banner-item .banner-header-content .banner-title {
  text-transform: capitalize;
}

.sc-banner-section-area .sc-banner-item .banner-image {
  position: relative;
  margin-right: -120px;
}

.sc-banner-section-area .sc-banner-item .banner-image .banner-img_1 {
  position: absolute;
  left: 15.5%;
  top: -75px;
}

.sc-banner-section-area .sc-banner-item .banner-image .banner-img_2 {
  bottom: -16%;
  position: absolute;
  right: 17.5%;
}

.sc-banner-section-area a.popup-video {
  color: #ffffff;
  position: relative;
  margin-left: 25px;
}

.sc-banner-section-area a.popup-video i {
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 25px;
  margin-right: 10px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-banner-section-area a.popup-video span {
  position: relative;
}

.sc-banner-section-area a.popup-video span:before {
  content: "";
  position: absolute;
  height: 1px;
  background: #ffffff;
  top: 25px;
  width: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-banner-section-area a.popup-video:hover {
  color: #ef9334;
}

.sc-banner-section-area a.popup-video:hover i {
  border-color: #ef9334;
}

.sc-banner-section-area a.popup-video:hover span:before {
  width: 100%;
  background-color: #ef9334;
}

.sc-banner-section-area2 {
  background: #fefff5;
  padding-bottom: 130px;
  padding-top: 225px;
}

.sc-banner-section-area2 .banner-header-content {
  max-width: 700px;
  margin: 0 auto;
}

.sc-banner-section-area2 .banner-header-content .secondary-btn {
  margin: auto;
}

.sc-banner-section-area2 .banner-image {
  position: relative;
  z-index: 3;
}

.sc-banner-section-area2 .banner-shape {
  position: absolute;
  left: 0;
  bottom: 90px;
}

.sc-banner-section-area2 .banner-shpae2 {
  left: auto;
  right: 0;
}

.sc-banner-section-area3 {
  background: #f8f8f8;
  position: relative;
}

.sc-banner-section-area3 .sc-banner-item .banner-header-content {
  margin-left: 19.5%;
}

.sc-banner-section-area3 .banner-circle-shape2 {
  /*background: #7215fa;*/
  background: #E82127;
  position: absolute;
  width: 780px;
  height: 780px;
  top: -20%;
  right: -5%;
  border-radius: 50%;
}

.sc-banner-section-area3 .banner_image {
  position: relative;
  z-index: 2;
  margin-left: 85px;
}

.sc-banner-section-area3 .banner_frame .frame_1 {
  position: absolute;
  left: 30%;
  top: 16%;
}

.sc-banner-section-area3 .banner_frame .frame_2 {
  position: absolute;
  bottom: 95px;
}

.sc-banner-section-area3 .banner_image {
  position: relative;
}

.sc-banner-section-area3 .banner_image .banner-1 {
  position: relative;
  z-index: 3;
  left: 12.5%;
}

.sc-banner-section-area3 .banner_image .banner-2 {
  position: absolute;
  left: 36%;
  top: 20px;
}


.sc-banner-section-area4 {
  background: #f8f8f8;
  position: relative;
}

.sc-banner-section-area4 .sc-banner-item .banner-header-content {
  margin-left: 19.5%;
}

.sc-banner-section-area4 .banner-circle-shape2 {
  /*background: #7215fa;*/
  background: #E82127;
  position: absolute;
  width: 780px;
  height: 780px;
  top: -20%;
  right: -5%;
  border-radius: 50%;
}

.sc-banner-section-area4 .banner_image {
  position: relative;
  z-index: 2;
  margin-left: 85px;
}

.sc-banner-section-area4 .banner_frame .frame_1 {
  position: absolute;
  left: 30%;
  top: 16%;
}

.sc-banner-section-area4 .banner_frame .frame_2 {
  position: absolute;
  bottom: 95px;
}

.sc-banner-section-area4 .banner_image {
  position: relative;
}

.sc-banner-section-area4 .banner_image .banner-1 {
  position: relative;
  z-index: 3;
  left: 12.5%;
}

.sc-banner-section-area4 .banner_image .banner-2 {
  position: absolute;
  left: 36%;
  top: 20px;
}

.sc-banner-section-area5 {
  background: #f5f9fb;
  position: relative;
}

.sc-banner-section-area5 .sc-banner-shapes .sc-shape-one {
  position: absolute;
  top: 0;
  left: 0;
}

.sc-banner-section-area5 .sc-banner-shapes .sc-shape-two {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sc-banner-section-area7 .banner-header-content {
  max-width: 500px;
}

.sc-banner-section-area7 .banner-header-content .banner-video-area .popup-video {
  background: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 18px;
  font-family: "Verdana", sans-serif;
  position: relative;
  z-index: 20;
}

.sc-banner-section-area7 .banner-header-content .banner-video-area i {
  background: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #eb1d36;
  width: 33px;
  height: 33px;
  line-height: 33px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

.banner-circle-shape {
  position: absolute;
  width: 880px;
  height: 800px;
  top: -20%;
  right: -5%;
  border-radius: 50%;
  background: #ef9334;
}

.bottom-skew-bg {
  position: relative;
}

.bottom-skew-bg:before {
  position: absolute;
  bottom: -130px;
  left: 0;
  width: 100%;
  height: 268px;
  content: "";
  transform: skew(92deg) scale(1.5);
  /*background: #7215fa;*/
  background: #E82127;
}

.sc-banner-section-area6 {
  background: url(../images/banner/banner-bg11.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sc-banner-section-area6 .banner-header-content {
  max-width: auto;
  margin-left: 38.5%;
}

.sc-banner-section-area6 .banner-header-content .banner-title {
  max-width: 450px;
}

.sc-banner-section-area6 .sc-banner-style-two .sc-shape-style-one {
  position: absolute;
  top: 0;
  left: 0;
}

.sc-banner-section-area6 .sc-banner-style-two .sc-shape-style-two {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sc-banner-section-area6 .sc-banner-style-two .sc-shape-style-three {
  position: absolute;
  bottom: 0;
  right: 0;
}

.border-brand {
  border-bottom: 1px solid rgba(53, 53, 53, 0.2);
  padding-bottom: 50px;
}

.border-brand .sc-brand-item img {
  margin: 0 auto;
  position: relative;
}

/* Banner Modal Video */
@keyframes modal-video {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }

  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

/* ============== 05 about section ============== */

.sc-about-area .sc-about-image {
  position: relative;
  margin-right: -120px;
  animation: shakeY 5s ease-in-out infinite;
}

.sc-about-area .sc-about-image .about-image {
  position: relative;
  z-index: 3;
}

.sc-about-area .sc-about-image .about-image_1 {
  position: absolute;
  bottom: -33%;
  right: 60px;
  z-index: 9;
}

.sc-about-area .sc-about-image .circle-shape {
  top: 36%;
  position: absolute;
  left: -42px;
  width: 395px;
  height: 395px;
  border-radius: 395px;
  display: inline-block;
  background: #ef9334;
}

.about-team-pages-area .sc-ab-circle {
  width: 480px;
  height: 480px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-team-pages-area .sc-ab-circle.purple {
  /*background: #7215fa;*/
  background: #E82127;
  left: 250px;
}

.about-team-pages-area .sc-ab-circle.yelow {
  background: #ef9334;
  right: 250px;
}

.sc-overlay-bg3,
.sc-overlay-bg2,
.sc-overlay-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/* ============== 06 canvas section ============== */

.sc-wishlist-canva,
.sc-product-offcanvas-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  z-index: 9999;
  background: #fff;
  padding: 30px 30px 30px;
  right: -450px;
  opacity: 0;
  visibility: visible;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-wishlist-canva .close-btn a i,
.sc-product-offcanvas-wrapper .close-btn a i {
  background: #efefef;
  width: 25px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  text-align: center;
  color: #424242;
  border-radius: 25px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-wishlist-canva .close-btn a i:hover,
.sc-product-offcanvas-wrapper .close-btn a i:hover {
  background: #ef9334;
  color: #fff;
}

.sc-product-offcanvas-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  z-index: 9999;
  background: #fff;
  padding: 30px 30px 30px;
  right: -450px;
  opacity: 0;
  visibility: visible;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-product-offcanvas-area .sc-offcanvas-header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 35px;
  margin-bottom: 30px;
}

.sc-product-offcanvas-area .sc-offcanvas-header .offcanvas-title .product-title {
  font-weight: 500;
  font-size: 20px;
}

.sc-product-offcanvas-area .offcanvas-icon a i {
  font-size: 20px;
  color: #454545;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #1e1e1e;
  border-radius: 40px;
  color: #fff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

.sc-product-offcanvas-area .offcanvas-icon a i:hover {
  background: #ef9334;
}

.sc-product-offcanvas-area .sc-offcanvas-icon-list {
  padding-top: 25px;
}

.sc-product-offcanvas-area .sc-offcanvas-icon-list ul li {
  display: inline-block;
  padding-right: 5px;
}

.sc-product-offcanvas-area .sc-offcanvas-icon-list ul li i {
  background: #101621;
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-product-offcanvas-area .sc-offcanvas-icon-list ul li i:hover {
  background: #ef9334;
}

.cart_expanded .sc-product-offcanvas-wrapper {
  right: 0;
}

.cart_expanded .sc-product-offcanvas-wrapper,
.cart_expanded .sc-overlay-bg {
  opacity: 1;
  visibility: visible;
}

.canvas_expanded .sc-product-offcanvas-area {
  right: 0;
}

.canvas_expanded .sc-product-offcanvas-area,
.canvas_expanded .sc-overlay-bg2 {
  opacity: 1;
  visibility: visible;
}

/* Customize Off-Canvas */

.offcanvas {
  padding: 35px;
}

.offcanvas .offcanvasMenu {
  color: #fff;
}

.offcanvas .offcanvas-header {
  border-bottom: 1px solid #e5e5e5;
}

.offcanvas-area .offcanvas-header {
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}

.offcanvas-area .offcanvas-body {
  padding: 0;
}

.offcanvas-area .offcanvas-body .nav-item .nav-link {
  color: #090b1a;
}

.offcanvas-area .offcanvas-body .nav-item .nav-link i {
  right: 20px;
  position: absolute;
  top: 8px;
}

.offcanvas-area .offcanvas-body .nav-item .nav-link:hover {
  color: #ef9334;
}


.offcanvas-area .offcanvas-body .nav-item .nav-link.active,
.offcanvas-area .offcanvas-body .nav-item .dropdown-menu .dropdown-item.active {
  color: #ef9334;
  background: transparent;
}

.offcanvas-area .offcanvas-body .nav-item .nav-link::after {
  display: none;
}

.offcanvas-area .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
  color: #090b1a;
}

.offcanvas-area .offcanvas-body .nav-item .dropdown-menu .dropdown-item:hover {
  color: #ef9334;
  background: none;
}

.offcanvas-area .offcanvasClose {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  background: #1e1e1e;
  border-radius: 40px;
  color: #fff;
  transition: 0.4s;
  z-index: 333;
  border: none;
}

.offcanvas-area .offcanvasClose:hover {
  background: #ef9334;
  border: transparent;
  outline: transparent;
}

.offcanvas-area .offcanvasClose i {
  color: #fff;
  position: relative;
  top: -2px;
}

.offcanvas-area li {
  display: block;
}

.offcanvas-area li a {
  border-bottom: 1px solid #e5e5e5;
}

.offcanvas-area li:last-child {
  border-bottom: none;
}

.offcanvas-area .dropdown-menu {
  border: none;
  padding: 0;
  margin-top: 0;
}

.offcanvas-area .dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.offcanvas-area .offcanvas-icon-list ul li {
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
}

.offcanvas-area .offcanvas-icon-list ul li i {
  background: #101621;
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.offcanvas-area .offcanvas-icon-list ul li i:hover {
  background: #ef9334;
}

.mobile-navbar-menu .nav-menu li {
  position: relative;
}

.mobile-navbar-menu .nav-menu li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: block;
  color: #090b1a;
  padding: 10px 0;
  line-height: 30px;
  width: 100%;
  font-family: "Russo One", sans-serif;
}

.mobile-navbar-menu .nav-menu li li a {
  padding-left: 25px;
}

.mobile-navbar-menu .nav-menu li li li a {
  padding-left: 50px;
}

.mobile-navbar-menu span.submenu-button {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 5px;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.mobile-navbar-menu span.submenu-button:before,
.mobile-navbar-menu span.submenu-button:after {
  position: absolute;
  top: 24px;
  right: 10px;
  width: 20px;
  height: 1px;
  display: block;
  background: #151625;
  z-index: 2;
  content: "";
  right: 12px;
  width: 12px;
}

.mobile-navbar-menu span.submenu-button:before {
  width: 1px;
  height: 12px;
  right: 17px;
  top: 18px;
}

.mobile-navbar-menu span.submenu-button em {
  display: block;
  position: absolute;
  right: 0;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-navbar-menu .hash-has-sub span.submenu-button {
  width: 100%;
  cursor: pointer;
  top: 0;
  height: 50px;
}

.mobile-navbar-menu .submenu-button.submenu-opened:before {
  display: none;
}

.mobile-navbar-menu ul ul li,
.mobile-navbar-menu li:hover>ul>li {
  height: auto;
}

.sc-category-tabs .carousel-nav-icon {
  height: 48px;
  width: 48px;
}

.sc-category-tabs .carousel-item .col,
.sc-category-tabs .carousel-item .col-sm,
.sc-category-tabs .carousel-item .col-md {
  margin: 8px;
  height: 300px;
  background-size: cover;
  background-position: center center;
}

/* ============== 07 service section ============== */

.sc-service-section-area .sc-service-content-box {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.03);
  padding: 40px 0;
  border-radius: 20px;
  position: relative;
  border: 1px solid #ef9334;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area .sc-service-content-box:after,
.sc-service-section-area .sc-service-content-box:before {
  position: absolute;
  background: #fff;
  z-index: 2;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  content: "";
  height: 100%;
  left: -20px;
  top: -20px;
  width: 100%;
}

.sc-service-section-area .sc-service-content-box svg,
.sc-service-section-area .sc-service-content-box h4 {
  position: relative;
  z-index: 3;
}

.sc-service-section-area .sc-service-content-box a {
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area .sc-service-content-box a:hover {
  color: #ef9334;
}

.sc-service-section-area .sc-service-content-box:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
}

.sc-service-section-area .sc-service-content-box:hover:after,
.sc-service-section-area .sc-service-content-box:hover::before {
  width: 0px;
  height: 0;
}

.sc-service-section-area .sc-service-content-box.gray-color:after,
.sc-service-section-area .sc-service-content-box.gray-color:before {
  background: #fbf8ff;
}

.sc-service-section-area.inner_service .sc-service-content-box:after,
.sc-service-section-area.inner_service .sc-service-content-box:before {
  background: #faf7fe;
}

.sc-service-section-area2 .service-content-box {
  position: relative;
}

.sc-service-section-area2 .service-content-box:before {
  content: "";
  position: absolute;
  height: 0;
  width: 265px;
  left: -145px;
  border-top: 1px dashed #17975a;
  top: 35px;
}

.sc-service-section-area2 .service-content-box.first-child:before {
  display: none;
}

.sc-service-section-area2 .service-content-box .service_icon {
  position: relative;
  z-index: 1;
  background: #17975a;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 20px;
}

.sc-service-section-area2 .service-content-box .service_title {
  margin-top: 20px;
}

.sc-service-section-area2 .service-content-box .service_title a {
  font-weight: 700;
  margin-top: 20px;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area2 .service-content-box .service_title a:hover {
  color: #ef9334;
}

.sc-service-section-area3 .sc-service-content-box {
  padding: 30px 25px 5px 25px;
  background: #ffffff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area3 .sc-service-content-box .service-icon {
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-service-section-area3 .sc-service-content-box .service-icon svg path {
  fill: #ef9334;
}

.sc-service-section-area3 .sc-service-content-box h4 {
  margin-bottom: 20px;
}

.sc-service-section-area3 .sc-service-content-box h4 a {
  color: #101621;
  margin-bottom: 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area3 .sc-service-content-box h4 a:hover {
  color: #ef9334;
}

.sc-service-section-area3 .sc-service-content-box:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
}

.sc-service-section-area3 .sc-service-content-box:hover .service-icon {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.sc-service-section-area4 .sc-service-content-box {
  position: relative;
  padding: 0 25px;
}

.sc-service-section-area4 .sc-service-content-box:before {
  position: absolute;
  content: "";
  top: 10px;
  left: -46px;
  width: 65px;
  height: 18px;
  background: url(../images/icons/work_shape.png);
}

.sc-service-section-area4 .sc-service-content-box.service_frame:first-child:before {
  display: none;
}

.sc-service-section-area4 .sc-service-content-box .service_icon {
  position: relative;
  margin-bottom: 55px;
}

.sc-service-section-area4 .sc-service-content-box .service_icon:before {
  position: absolute;
  top: -35px;
  left: 50%;
  content: "";
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background: url(../images/icons/work_frame.png);
  transform: translateX(-50%);
}

.sc-service-section-area4 .sc-service-content-box .service_icon svg {
  position: relative;
  z-index: 3;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-service-section-area4 .sc-service-content-box:hover svg {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}


.sc-service-section-area6 {
  background: url(../images/banner/step-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sc-service-section-area6 .sc-service-content-box {
  position: relative;
}

.sc-service-section-area6 .sc-service-content-box .sc-text-area {
  background: url(../images/feature/service_bg.png);
  background-size: cover;
  background-position: center bottom;
  padding: 32px 30px 12px;
  border-radius: 20px;
}

.sc-service-section-area6 .sc-service-content-box .sc-text-area .service-icon {
  background: linear-gradient(138.16deg, #3d3a92 15.52%, #141242 86.81%);
  width: 70px;
  height: 70px;
  margin-bottom: 30px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
}

.sc-service-section-area6 .sc-service-content-box .sc-text-area .title {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  color: #ffffff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area6 .sc-service-content-box .sc-text-area .title:hover {
  color: #7a77ff;
}

.sc-service-section-area6 .sc-service-content-box .sc-number {
  background: linear-gradient(154.11deg, #2c296d 10.09%, #08063e 93.27%);
  width: 34px;
  height: 34px;
  line-height: 32px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.sc-service-section-area6 .sc-service-content-box .sc-number .number {
  background: linear-gradient(180deg, #cdeb45 0%, rgba(205, 235, 69, 0) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 700;
}

.sc-service-section-area7 .sc-service-content-box {
  position: relative;
}

.sc-service-section-area7 .sc-service-content-box .sc-text-area {
  background: url(../images/feature/service_bg2.png);
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 32px 30px 12px;
  border-radius: 20px;
}

.sc-service-section-area7 .sc-service-content-box .sc-text-area .service-icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  margin-bottom: 25px;
  border-radius: 50%;
  text-align: center;
  background: linear-gradient(138.16deg, #e8e8ff 15.52%, #ffffff 86.81%);
}

.sc-service-section-area7 .sc-service-content-box .sc-text-area .title {
  color: #101621;
  margin-bottom: 20px;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  font-weight: 700;
}

.sc-service-section-area7 .sc-service-content-box .sc-text-area .title:hover {
  color: #7a77ff;
}

.sc-service-section-area7 .sc-service-content-box .sc-text-area .des {
  color: rgba(56, 54, 113, 0.8);
}

.sc-service-section-area7 .sc-service-content-box .sc-text-number {
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/feature/number-circle.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.sc-service-section-area7 .sc-service-content-box .sc-text-number .sc-number {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  color: #7a77ff;
  font-size: 14px;
  font-weight: 700;
}

.sc-service-section-area8 .sc-service-content-box {
  padding: 30px 25px 5px 25px;
  background: #ffffff;
  box-shadow: 30px 30px 60px rgba(75, 0, 35, 0.05);
  border-radius: 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area8 .sc-service-content-box .service-icon {
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-service-section-area8 .sc-service-content-box h4 {
  margin-bottom: 20px;
}

.sc-service-section-area8 .sc-service-content-box h4 a {
  color: #101621;
  margin-bottom: 20px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-service-section-area8 .sc-service-content-box h4 a:hover {
  color: #e81f41;
}


/* ============== 08 feature section ============== */

.sc-feature-section-area {
  position: relative;
}

.sc-feature-section-area .feature-content-box {
  display: flex;
}

.sc-feature-section-area .feature-content-box .feature-icon {
  background: #ef9334;
  min-width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  border-radius: 70px;
  margin-right: 30px;
  margin-top: 5px;
}

.sc-feature-section-area .feature-content-box .feature-icon svg {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-feature-section-area .feature-content-box .feature-icon:hover svg {
  -webkit-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}

.sc-feature-section-area .feature-content-box .sc-feature-text a {
  font-weight: 700;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-feature-section-area .feature-content-box .sc-feature-text a:hover {
  color: #ef9334;
}

.sc-feature-section-area .feature-image-area {
  position: relative;
  margin-right: -120px;
}

.sc-feature-section-area .feature-image-area .feature-img {
  position: relative;
  z-index: 2;
}

.sc-feature-section-area .feature-image-area .feature-img_1 {
  position: absolute;
  bottom: -20px;
  z-index: 22;
  left: -30px;
}

.sc-feature-section-area .feature-image-area .feature-shape {
  position: absolute;
  bottom: -123px;
  width: 230px;
  height: 260px;
  margin: 20px;
  clip-path: circle(45% at 50% 1%);
  /*background: #7215fa;*/
  background: #E82127;
  right: 40px;
}

.sc-feature-area2 .feature-content-box .feature-icon {
  background: #17975a;
}

.sc-feature-area2 .feature-image-area .feature-shape {
  background: #ef9334;
}

.sc-feature-section-area3 .feature-image-area {
  position: relative;
}

.sc-feature-section-area3 .feature-image-area .feature-img {
  position: relative;
  z-index: 3;
}

.sc-feature-section-area3 .feature-image-area .feature-img_1 {
  position: absolute;
  bottom: -55px;
  right: 55%;
  z-index: 9;
}

.sc-feature-section-area3 .feature-image-area .feature-circle-shape {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 450px;
  left: 31.5%;
  border-radius: 50%;
  background: #70ef34;
}

.sc-feature-section-area4 .sc-feature-image {
  position: relative;
}

.sc-feature-section-area4 .sc-feature-image .feature-image {
  position: relative;
  z-index: 9;
}

.sc-feature-section-area4 .sc-feature-image .feature-image_1 {
  position: absolute;
  bottom: -50px;
  left: 22%;
  z-index: 9;
}

.sc-feature-section-area4 .sc-feature-image .feature-circle-shape {
  position: absolute;
  width: 430px;
  height: 430px;
  border-radius: 50%;
  background: #ef9334;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 31.5%;
}

.sc-feature-section-area4 .feature_check ul li {
  color: #101621;
  font-weight: 500;
  font-size: 16px;
  font-family: "Verdana", sans-serif;
}

.sc-feature-section-area4 .feature_check ul li i {
  color: #ef9334;
  margin-right: 10px;
}

.sc-feature-section-area5 .sc-feature-image {
  position: relative;
}

.sc-feature-section-area5 .sc-feature-image .feature_img {
  position: relative;
  z-index: 3;
}

.sc-feature-section-area5 .sc-feature-image .image-bg-circle {
  position: absolute;
  left: 40%;
  transform: translate(-50%, -50%);
  top: 45%;
  width: 565px;
  height: 565px;
  border-radius: 50%;
  background: #e1cdff;
}

.sc-feature-section-area5 .feature_check ul li {
  color: #101621;
  font-weight: 500;
  font-size: 16px;
  font-family: "Verdana", sans-serif;
}

.sc-feature-section-area5 .feature_check ul li i {
  color: #ef9334;
  margin-right: 10px;
}


.sc-feature-section-area6 {
  position: relative;
  margin-top: -60px;
}

.sc-crupto-section-area {
  background: url(../images/banner/crypto.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sc-crupto-section-area .sc-crupto-item {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 15px 15px;
}

.sc-crupto-section-area .sc-crupto-item .sc-crupto-content-box .sc-crupto-icon {
  background: #f3f5ff;
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px;
}

.sc-crupto-section-area .sc-crupto-item .sc-crupto-content-box .sc-content .title {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
  color: #121933;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area .sc-crupto-item .sc-crupto-content-box .sc-content span {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: #353535;
}

.sc-crupto-section-area .sc-crupto-item .sc-red-color {
  font-weight: 600;
  font-size: 16px;
  color: #ff007a;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area .sc-crupto-item .sc-blue-color {
  font-weight: 600;
  font-size: 16px;
  color: #00c8a4;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area .sc-crupto-item-two {
  padding: 20px 15px 7px 15px;
}

.sc-crupto-section-area2 {
  background: url(../images/banner/crypto-two.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sc-crupto-section-area2 .sc-crupto-item {
  background: linear-gradient(135.39deg, #343363 2.02%, #0a0828 97.79%);
  border-radius: 20px;
  padding: 20px 15px 15px;
}

.sc-crupto-section-area2 .sc-crupto-item .sc-crupto-content-box .sc-crupto-icon {
  background: linear-gradient(142.4deg, #4843a7 13.93%, #0f0d34 86.43%);
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px;
}

.sc-crupto-section-area2 .sc-crupto-item .sc-crupto-content-box .sc-content .title {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area2 .sc-crupto-item .sc-crupto-content-box .sc-content span {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area2 .sc-crupto-item .sc-red-color {
  font-weight: 600;
  font-size: 16px;
  color: #ff007a;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area2 .sc-crupto-item .sc-blue-color {
  font-weight: 600;
  font-size: 16px;
  color: #00c8a4;
  font-family: "Inter", sans-serif;
}

.sc-crupto-section-area2 .sc-crupto-item-two {
  padding: 20px 15px 7px 15px;
}

.sc-crupto-content-two .crupto_check ul li i {
  color: #7a77ff;
}

.sc-crupto-content-four .crupto_check ul li i {
  color: #cdeb45;
}

.sc-crupto-style-three {
  background: url(../images/banner/crypto-three.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sc-crupto-style-five .crupto_check ul li i {
  color: #cdeb45;
}

.sc-apps-media-section-area {
  background: url(../images/banner/apps-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sc-apps-media-section-area .sc-media-content {
  max-width: 500px;
}

.sc-apps-media-section-area .sc-media-content .sc-media-form {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  position: relative;
}

.sc-apps-media-section-area .sc-media-content .sc-media-form input {
  padding: 25px 30px;
  color: #ffffff;
}

.sc-apps-media-section-area .sc-media-content .sc-media-form .sc-submit {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(0, 0, 1, 0.05);
  border-radius: 40px;
  border: none;
  font-weight: 600;
  padding: 13px 32px;
  font-size: 18px;
  transition: 0.4s;
}

.sc-apps-media-section-area .sc-media-content .sc-media-form .sc-submit:hover {
  background: #e81f41;
  color: #ffffff;
}

.sc-media-item .sc-media-list ul li {
  line-height: 2.6;
  font-family: "Verdana", sans-serif;
  color: #353535;
  font-weight: 500;
}

.sc-media-item .sc-media-list ul li i {
  background: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sc-section-bg {
  background: url(../images/banner/section-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ============== 09 client section ============== */

.sc-client-section-area {
  position: relative;
}

.sc-client-section-area .sc-client-item {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(9, 106, 98, 0.15);
  padding: 30px 20px 15px 30px;
  border: 1px solid #17975a;
}

.sc-client-section-area .sc-client-item .client-rating ul li {
  display: inline-block;
  color: #ef9334;
}

.sc-client-section-area .sc-client-item .author-text .client-title {
  font-weight: 600;
}

.sc-client-section-area .sc-client-item .author-text span {
  font-size: 14px;
}

.client-carousel-wrapper {
  position: relative;
}

.client-carousel-wrapper .react-multi-carousel-list {
  position: unset !important;
}

.client-carousel-wrapper .react-multi-carousel-item {
  padding: 10px;
}

.client-carousel-wrapper .commonArrow {
  position: absolute;
  z-index: 112;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  color: #101621;
  line-height: 16px;
  text-align: center;
  transition: .3s;
  border: none;
  box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.06);
  opacity: 0;
  visibility: hidden;
}

.client-carousel-wrapper .arrowRight {
  right: 0;
}

.client-carousel-wrapper .arrowLeft {
  left: 0;
}

.client-carousel-wrapper:hover .commonArrow {
  background: #ef9334;
  color: #fff;
  opacity: 1;
  visibility: visible;
}

.client-carousel-wrapper:hover .arrowRight {
  right: -3%;
}

.client-carousel-wrapper:hover .arrowLeft {
  left: -3%;
}

.client-carousel-wrapper:hover .arrowRight i {
  top: 1px;
}

.client-carousel-wrapper:hover .arrowLeft i {
  top: 1px;
}

/* Home 4 Testimonial  */

.sc-client-style-two {
  position: relative;
}

.sc-client-style-two .sc-client-item {
  background: #ffffff;
  border: 0.5px solid rgba(9, 106, 98, 0.15);
  border-radius: 20px;
  padding: 30px 20px 15px 30px;
  box-shadow: 0 0 20px rgb(107 107 107 / 8%);
}

.sc-client-style-two .sc-client-item .client-rating ul li {
  display: inline-block;
  color: #ef9334;
}

.sc-client-style-two .sc-client-item .author-text .client-title {
  font-weight: 600;
}

.sc-client-style-two .sc-client-item .author-text span {
  font-size: 14px;
}

.sc-client-style-two .react-multi-carousel-item {
  padding: 10px;
}

.sc-client-area {
  position: relative;
}

.sc-client-style-two .react-multi-carousel-list {
  position: unset !important;
}

.sc-client-area .commonArrow {
  position: absolute;
  z-index: 112;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  background: transparent;
  color: #101621;
  line-height: 27px;
  text-align: center;
  transition: .3s;
  border: none;
  font-size: 22px;
}

.sc-client-area .commonArrow.arrowRight:hover,
.sc-client-area .commonArrow.arrowLeft:hover {
  background-color: rgba(235, 29, 54, 0.8);
  color: #fff;
  opacity: 1;
  visibility: visible;
}

.sc-client-style-two .arrowRight {
  top: 10%;
  right: 20%;
}

.sc-client-style-two .arrowLeft {
  top: 10%;
  right: 23%;
}

/* ============== 10 counter section ============== */

.sc-counter-area {
  background: #101621;
}

.sc-counter-area .sc-counter-box {
  position: relative;
}

.sc-counter-area .sc-counter-box:before {
  position: absolute;
  content: "";
  height: 40px;
  right: 0;
  top: 55px;
  border-right: 1px solid rgba(255, 255, 255, 0.8);
}

.sc-counter-area .sc-counter-box.counter-border:before {
  display: none;
}

.sc-counter-area .sc-counter-box .sc-count {
  color: #ffffff;
  font-size: 65px;
  font-weight: 700;
  font-family: "Verdana", sans-serif;
}

.sc-counter-area .sc-counter-box .count-title {
  font-weight: 400;
}

.sc-counter-area .shape_image1,
.sc-counter-area .shape_image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sc-counter-area .shape_image1 {
  left: auto;
  right: -35px;
}

.sc-counter-area.style-two .sc-counter-box .sc-count {
  font-size: 40px;
}

/* ============== 11 price section ============== */

.sc-price-area .sc-heading-area .sub_title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Verdana", sans-serif;
}

.sc-price-area .sc-price-box {
  background: #ffffff;
  box-shadow: 0 0 15px rgba(235, 227, 227, 0.16);
  border-radius: 30px;
  padding: 50px 0px 30px 50px;
  position: relative;
  overflow: hidden;
}

.sc-price-area .sc-price-box .sc-primary-btn {
  margin-right: 50px;
}

.sc-price-area .sc-price-box .price-item .pric-title {
  text-transform: uppercase;
  font-weight: 700;
}

.sc-price-area .sc-price-box .price-item .price-active {
  color: #ef9334;
  font-size: 45px;
  font-weight: 700;
  border-bottom: 1px solid rgba(77, 76, 76, 0.2);
  margin-top: -15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-family: "Verdana", sans-serif;
}

.sc-price-area .sc-price-box .price-item .price {
  color: #424242;
  font-family: "Verdana", sans-serif;
}

.sc-price-area .sc-price-box .price-item .third_color {
  color: #17975a;
  font-family: "Verdana", sans-serif;
}

.sc-price-area .sc-price-box .price-item .month {
  font-size: 16px;
  font-weight: 400;
  color: #424242;
}

.sc-price-area .sc-price-box .perfect-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.sc-price-area .sc-price-box .price-list {
  margin-bottom: 20px;
}

.sc-price-area .sc-price-box .price-list .list li {
  color: #424242;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.5;
}

.sc-price-area .sc-price-box .price-list .list li i {
  color: #ef9334;
  margin-right: 10px;
}

.sc-price-area .sc-price-box .price-list .list li .check {
  color: #424242;
}

.sc-price-area .sc-price-box .price-list span {
  color: #101621;
  font-size: 16px;
  font-weight: 400;
}

.sc-price-area .sc-price-box .price_sale {
  display: table;
  position: absolute;
  text-align: center;
  top: 36px;
  right: -10px;
  z-index: 2;
  min-width: 100px;
  transform: rotate(90deg);
}

.sc-price-area .sc-price-box .price_sale:after,
.sc-price-area .sc-price-box .price_sale:before {
  border-style: solid;
  position: absolute;
  right: 13px;
  content: "";
  z-index: 1;
}

.sc-price-area .sc-price-box .price_sale:before {
  border-color: #ef9334 transparent transparent #ef9334;
  border-width: 14px 18px;
  top: 0;
}

.sc-price-area .sc-price-box .price_sale:after {
  border-color: transparent transparent #ef9334 #ef9334;
  border-width: 14px 18px;
  bottom: 0;
}

.sc-price-area .sc-price-box .price_sale span {
  position: relative;
  background: #ef9334;
  color: #fff;
  float: left;
  font-size: 10px;
  font-weight: 500;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  letter-spacing: 0.5px;
}

.sc-price-area.inner-price .sc-price-box {
  box-shadow: 0 0 40px rgba(0, 0, 1, 0.05);
}

.sc-price-area .pricing-color .pricing-monthly-btn {
  color: #ffffff;
}

.sc-price-area .pricing-color .pricing-yearly-btn {
  color: #ffffff;
}

.sc-price-area .pricing-color span {
  color: #ffffff;
}

.sc-price-area .pricing-color form input {
  background-color: #ffffff;
}

.sc-price-area .pricing-color form input:before {
  background-color: #17975a;
}


.sc-price-style .sc-heading-area .sub_title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Verdana", sans-serif;
}

.sc-price-style .sc-price-box {
  background: #ffffff;
  box-shadow: 30px 30px 60px rgba(75, 0, 35, 0.03);
  border-radius: 30px;
  padding: 50px 30px 30px 50px;
  position: relative;
  overflow: hidden;
}

.sc-price-style .sc-price-box .price-box {
  border-bottom: 1px solid rgba(232, 31, 65, 0.15);
  padding-bottom: 25px;
  margin-bottom: 15px;
}

.sc-price-style .sc-price-box .sc-price-image {
  margin-right: 15px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  background: #fcf7fe;
}

.sc-price-style .sc-price-box .sc-primary-btn {
  margin-right: 50px;
}

.sc-price-style .sc-price-box .sc-price {
  background: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  font-weight: 700;
  font-family: "Verdana", sans-serif;
}

.sc-price-style .sc-price-box .price-item .pric-title {
  font-size: 14px;
  color: rgba(53, 53, 53, 0.8);
  margin-bottom: 0;
}

.sc-price-style .sc-price-box .price-item .price {
  color: #424242;
  font-family: "Verdana", sans-serif;
}

.sc-price-style .sc-price-box .price-item .third_color {
  color: #17975a;
  font-family: "Verdana", sans-serif;
}

.sc-price-style .sc-price-box .price-item .month {
  font-size: 16px;
  font-weight: 400;
  color: #424242;
}

.sc-price-style .sc-price-box .perfect-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.sc-price-style .sc-price-box .price-list {
  margin-bottom: 20px;
}

.sc-price-style .sc-price-box .price-list img {
  margin-right: 5px;
}

.sc-price-style .sc-price-box .price-list .list li {
  color: #424242;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.5;
}

.sc-price-style .sc-price-box .price-list span {
  color: #101621;
  font-size: 16px;
  font-weight: 400;
}

.sc-price-style .sc-price-box .price_sale {
  display: table;
  position: absolute;
  text-align: center;
  top: 36px;
  right: -10px;
  z-index: 2;
  min-width: 100px;
  transform: rotate(90deg);
}

.sc-price-style .sc-price-box .price_sale:after,
.sc-price-style .sc-price-box .price_sale:before {
  border-style: solid;
  position: absolute;
  right: 13px;
  content: "";
  z-index: 1;
}

.sc-price-style .sc-price-box .price_sale:before {
  border-color: #e81f41 transparent transparent #e81f41;
  border-width: 14px 18px;
  top: 0;
}

.sc-price-style .sc-price-box .price_sale:after {
  border-color: transparent transparent #e81f41 #e81f41;
  border-width: 14px 18px;
  bottom: 0;
}

.sc-price-style .sc-price-box .price_sale span {
  position: relative;
  background: #e81f41;
  color: #fff;
  float: left;
  font-size: 10px;
  font-weight: 500;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  letter-spacing: 0.5px;
}

.sc-price-style.inner-price .sc-price-box {
  box-shadow: 0 0 40px rgba(0, 0, 1, 0.05);
}

.sc-price-style .pricing-color .pricing-monthly-btn {
  color: #ffffff;
}

.sc-price-style .pricing-color .pricing-yearly-btn {
  color: #ffffff;
}

.sc-price-style .pricing-color span {
  color: #ffffff;
}

.sc-price-style .pricing-color form input {
  background-color: #ffffff;
}

.sc-price-style .pricing-color form input:before {
  background-color: #17975a;
}

/* -- pricing selector style -- */

.pricing-selector-two {
  margin-bottom: 50px;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-selector-two form {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pricing-selector-two form button {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #101621;
  border: none;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.pricing-selector-two form button span {
  color: #101621;
  font-weight: 600;
}

.pricing-selector-two form input {
  appearance: none;
  width: 50px;
  height: 24px;
  border-radius: 15px;
  background-color: #e81f41;
  position: relative;
  transition: 0.3s;
  z-index: 22;
}

.pricing-selector-two form input:before {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #ffffff;
  transition: 0.3s;
}

.pricing-selector-two form input:checked::before {
  left: 30px;
}

.sc-gradiant-active {
  border-radius: 50px;
  font-weight: 700;
  display: block;
  text-align: center;
  font-size: 18px;
  transition: 0.4s;
  padding: 18px 50px;
  background-image: linear-gradient(98.31deg, #eb1d36 8.58%, #b844ff 90.93%);
}

.sc-gradiant-active span {
  color: #ffffff;
}

.sc-gradiant-active::before,
.sc-gradiant-active::after {
  background-color: 98.31deg, #eb1d36 8.58%, #b844ff 90.93%;
}

.pricing-selector {
  margin-bottom: 50px;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-selector form {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.pricing-selector form button {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #101621;
  border: none;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.pricing-selector form button span {
  color: #101621;
  font-weight: 600;
}

.pricing-selector form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 24px;
  border-radius: 15px;
  background-color: #101621;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.pricing-selector form input:before {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ef9334;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.pricing-selector form input:checked::before {
  left: 30px;
}

/* ============== 12 business section ============== */

.sc-business-section-area .business-image-area {
  position: relative;
}

.sc-business-section-area .business-image-area .business-img {
  position: relative;
  z-index: 3;
}

.sc-business-section-area .business-image-area .business-img_1 {
  position: absolute;
  left: 50px;
  top: -95px;
}

.sc-business-section-area .check-list li {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  font-family: "Verdana", sans-serif;
  display: flex;
}

.sc-business-section-area .check-list li i {
  position: relative;
  font-size: 18px;
  color: #17975a;
  margin-right: 20px;
}

.sc-business-section-area .check-list li span {
  display: block;
}

/* ============== 13 testimonial section ============== */

.sc-testimonial-area {
  position: relative;
}

.sc-testimonial-item {
  margin: 0 auto;
  max-width: 830px;
}

.sc-testimonial-area .sc-testimonial-item .quote-icon_1 {
  font-size: 60px;
  margin-bottom: -15px;
  display: block;
  color: #ef9334;
}

.sc-testimonial-area .sc-testimonial-item img {
  max-width: 65px;
  border-radius: 65px;
  margin: 0 auto 15px;
}

.sc-testimonial-area .sc-testimonial-item .description {
  font-size: 20px;
  margin-bottom: 32px;
}

.sc-testimonial-area .sc-testimonial-item .testimonial-image2 {
  float: left;
}

.sc-testimonial-area .sc-testimonial-item .testimonial-image2 img {
  margin-right: 10px;
}

.sc-testimonial-area .sc-testimonial-item a {
  font-family: "Varela", sans-serif;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-area .sc-testimonial-item a:hover {
  color: #ef9334;
}

.sc-testimonial-area .test_left_imgage {
  position: absolute;
  top: 20%;
  left: 10%;
}

.sc-testimonial-area .test_left_imgage1 {
  position: absolute;
  left: 0;
  bottom: 30%;
}

.sc-testimonial-area .test_left_imgage2 {
  position: absolute;
  left: 16%;
  bottom: 0;
}

.sc-testimonial-area .test_right_imgage {
  position: absolute;
  right: 10%;
  top: 20%;
}

.sc-testimonial-area .test_right_imgage1 {
  position: absolute;
  right: 0;
  bottom: 30%;
}

.sc-testimonial-area .test_right_imgage2 {
  position: absolute;
  right: 16%;
  bottom: 0;
}

.main-title {
  border-bottom: 1px solid #dddee1;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

/* Home Testimonial Navigation Customize */

.home-testimonial-wrapper .home-testimonial {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.home-testimonial-wrapper {
  position: relative;
}

.home-testimonial-wrapper .react-multi-carousel-list {
  position: unset !important;
}

.home-testimonial-wrapper .react-multi-carousel-item {
  padding: 10px;
}

.home-testimonial-wrapper .commonArrow {
  position: absolute;
  z-index: 112;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: transparent;
  color: rgba(77, 76, 76, 0.5);
  line-height: 16px;
  text-align: center;
  transition: .3s;
  border: none;
}

.home-testimonial-wrapper .commonArrow:hover {
  color: #101621;
}

.home-testimonial-wrapper .arrowRight {
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.home-testimonial-wrapper .arrowLeft {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Home5 Testimonial */

.home5-testimonial-wrapper {
  position: relative;
}

.home5-testimonial-wrapper .react-multi-carousel-list {
  position: unset !important;
}

.home5-testimonial-wrapper .commonArrow {
  position: absolute;
  z-index: 112;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: transparent;
  color: rgba(77, 76, 76, 0.5);
  line-height: 16px;
  text-align: center;
  transition: .3s;
  border: none;
}

.home5-testimonial-wrapper .arrowLeft {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.home5-testimonial-wrapper .arrowRight {
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.home5-testimonial-wrapper .arrowLeft i,
.home5-testimonial-wrapper .arrowRight i {
  color: #fff;
  font-size: 20px;
}

/* Home6 Testimonial  */

.home6-testimonial-wrapper {
  position: relative;
}

.home6-testimonial-wrapper .react-multi-carousel-list {
  position: unset !important;
}

.home6-testimonial-wrapper .commonArrow {
  position: absolute;
  z-index: 112;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: transparent;
  color: rgba(77, 76, 76, 0.5);
  line-height: 16px;
  text-align: center;
  transition: .3s;
  border: none;
}

.home6-testimonial-wrapper .arrowLeft {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.home6-testimonial-wrapper .arrowRight {
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.home6-testimonial-wrapper .arrowLeft i,
.home6-testimonial-wrapper .arrowRight i {
  color: #fff;
  font-size: 20px;
}


.sc-testimonial-style2 {
  position: relative;
  background: url(../images/banner/test-img.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sc-testimonial-style2 .slick-list {
  max-width: 860px;
  margin: 0 auto;
}

.sc-testimonial-style2 .sc-testimonial-item .quote-icon_1 {
  font-size: 60px;
  margin-bottom: -15px;
  display: block;
  color: #cdeb45;
}

.sc-testimonial-style2 .sc-testimonial-item img {
  max-width: 65px;
  border-radius: 65px;
  margin: 0 auto 15px;
}

.sc-testimonial-style2 .sc-testimonial-item .description {
  font-size: 20px;
  margin-bottom: 32px;
}

.sc-testimonial-style2 .sc-testimonial-item .testimonial-image2 {
  float: left;
}

.sc-testimonial-style2 .sc-testimonial-item .testimonial-image2 img {
  margin-right: 10px;
}

.sc-testimonial-style2 .sc-testimonial-item a {
  font-family: "Varela", sans-serif;
  color: #ffffff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style2 .sc-testimonial-item a:hover {
  color: #cdeb45;
}

.sc-testimonial-style2 .sc-testimonial-item .digination {
  font-size: 14px;
}

.sc-testimonial-style2 .sc-slick-slide .slick-arrow::before {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.sc-testimonial-style2 .test_left_imgage {
  position: absolute;
  top: 20%;
  left: 10%;
}

.sc-testimonial-style2 .test_left_imgage1 {
  position: absolute;
  left: 0;
  bottom: 30%;
}

.sc-testimonial-style2 .test_left_imgage2 {
  position: absolute;
  left: 16%;
  bottom: 0;
}

.sc-testimonial-style2 .test_right_imgage {
  position: absolute;
  right: 10%;
  top: 20%;
}

.sc-testimonial-style2 .test_right_imgage1 {
  position: absolute;
  right: 0;
  bottom: 30%;
}

.sc-testimonial-style2 .test_right_imgage2 {
  position: absolute;
  right: 16%;
  bottom: 0;
}

.sc-testimonial-style2 .sc-slick-slide .slick-arrow {
  border: none;
  padding: 0;
  font-size: 0;
  position: absolute;
  transform: translateY(-50%);
  z-index: 9;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style2 .sc-slick-slide .slick-arrow::before {
  content: "\ea60";
  position: absolute;
  top: 0;
  left: -5px;
  font-family: "remixicon";
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  z-index: 9;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style2 .sc-slick-slide .slick-arrow:hover:before {
  color: #ffffff;
}

.sc-testimonial-style2 .sc-slick-slide .next::before {
  content: "\ea6c";
}

.sc-testimonial-style2 .sc-slick-slide .prev {
  bottom: 0;
}

.sc-testimonial-style2 .sc-slick-slide .prev:before {
  top: 0;
  left: -35px;
}

.sc-testimonial-style3 {
  position: relative;
  background: url(../images/banner/test-img1.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sc-testimonial-style3 .slick-list {
  max-width: 860px;
  margin: 0 auto;
}

.sc-testimonial-style3 .sc-testimonial-item .quote-icon_1 {
  font-size: 60px;
  margin-bottom: -15px;
  display: block;
  color: #cdeb45;
}

.sc-testimonial-style3 .sc-testimonial-item img {
  max-width: 65px;
  border-radius: 65px;
  margin: 0 auto 15px;
}

.sc-testimonial-style3 .sc-testimonial-item .description {
  font-size: 20px;
  margin-bottom: 32px;
}

.sc-testimonial-style3 .sc-testimonial-item .testimonial-image2 {
  float: left;
}

.sc-testimonial-style3 .sc-testimonial-item .testimonial-image2 img {
  margin-right: 10px;
}

.sc-testimonial-style3 .sc-testimonial-item a {
  font-family: "Varela", sans-serif;
  color: #ffffff;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style3 .sc-testimonial-item a:hover {
  color: #cdeb45;
}

.sc-testimonial-style3 .sc-testimonial-item .digination {
  font-size: 14px;
}

.sc-testimonial-style3 .sc-slick-slide .slick-arrow::before {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
}

.sc-testimonial-style3 .test_left_imgage {
  position: absolute;
  top: 20%;
  left: 10%;
}

.sc-testimonial-style3 .test_left_imgage1 {
  position: absolute;
  left: 0;
  bottom: 30%;
}

.sc-testimonial-style3 .test_left_imgage2 {
  position: absolute;
  left: 16%;
  bottom: 0;
}

.sc-testimonial-style3 .test_right_imgage {
  position: absolute;
  right: 10%;
  top: 20%;
}

.sc-testimonial-style3 .test_right_imgage1 {
  position: absolute;
  right: 0;
  bottom: 30%;
}

.sc-testimonial-style3 .test_right_imgage2 {
  position: absolute;
  right: 16%;
  bottom: 0;
}

.sc-testimonial-style3 .sc-slick-slide .slick-arrow {
  border: none;
  padding: 0;
  font-size: 0;
  position: absolute;
  transform: translateY(-50%);
  z-index: 9;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style3 .sc-slick-slide .slick-arrow::before {
  content: "\ea60";
  position: absolute;
  top: 0;
  left: -5px;
  font-family: "remixicon";
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  z-index: 9;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-testimonial-style3 .sc-slick-slide .slick-arrow:hover:before {
  color: #ffffff;
}

.sc-testimonial-style3 .sc-slick-slide .next::before {
  content: "\ea6c";
}

.sc-testimonial-style3 .sc-slick-slide .prev {
  bottom: 0;
}

.sc-testimonial-style3 .sc-slick-slide .prev:before {
  top: 0;
  left: -35px;
}

.client4-carousel-wrapper {
  position: relative;
}

.sc-client-style-two .arrowRight {
  top: -100px;
  right: 0;
}

.sc-client-style-two .arrowLeft {
  top: -100px;
  right: 70px;
}

/* ============== 14 blog section ============== */

.blog-item .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}

.blog-item .blog-image img {
  position: relative;
  transform: scale(1);
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.blog-item .blog-image .date {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 60px;
  background: #101621;
  color: #fff;
  text-align: center;
  z-index: 2;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  padding-top: 7px;
}

.blog-item .blog-image .date span {
  display: block;
  line-height: 1.5;
  text-transform: uppercase;
}

.blog-item .blog-image:hover img {
  transform: scale(1.2);
}

.blog-item .blog-content {
  background: #ffffff;
}

.blog-item .blog-content .blog-list-menu {
  display: block;
  margin-bottom: -10px;
}

.blog-item .blog-content .blog-list-menu .blog-list {
  padding: 10px 15px;
  list-style: none;
  position: relative;
}

.blog-item .blog-content .blog-list-menu .blog-list::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  height: 1px;
  width: 50%;
  background: rgba(118, 118, 118, 0.3);
}

.blog-item .blog-content .blog-list-menu .blog-list li {
  display: inline-block;
  padding-right: 15px;
  margin-right: 10px;
  position: relative;
}

.blog-item .blog-content .blog-list-menu .blog-list li a {
  color: #767676;
}

.blog-item .blog-content .blog-list-menu .blog-list li a:hover {
  color: #ef9334;
}

.blog-item .blog-content .blog-list-menu .blog-list li::before {
  position: absolute;
  top: 16px;
  right: 0;
  content: "";
  height: 5px;
  width: 1px;
  background: #767676;
}

.blog-item .blog-content .blog-list-menu .blog-list li i {
  margin-right: 5px;
}

.blog-item .blog-content .blog-list-menu .blog-list li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.blog-item .blog-content .blog-list-menu .blog-list li:last-child::before {
  display: none;
}

.blog-item .blog-content .blog-list-menu .blog-list3::before {
  left: 34%;
  width: 65%;
}

.blog-item .blog-content .blog-list-menu .blog-list4::before {
  left: 67%;
  width: 65%;
}

.blog-item .blog-content .blog-title a {
  color: #101621;
  transition: 0.4s;
  line-height: 1.5;
}

.blog-item .blog-content .blog-title a:hover {
  color: #ef9334;
}

/* Latest Blog Carousel */

.sc-blog-area .latest-blog-carousel-wrapper .react-multi-carousel-list {
  position: unset !important;
}

.sc-blog-area .latest-blog-carousel {
  position: relative;
  margin-left: -30px;
}

.sc-blog-area .latest-blog-carousel {
  position: unset;
}

.sc-blog-area .latest-blog-carousel-wrapper .react-multiple-carousel__arrow {
  position: absolute;
}

.sc-blog-area .latest-blog-carousel-wrapper .react-multiple-carousel__arrow--left {
  left: calc(-3% + 1px)
}

.sc-blog-area .latest-blog-carousel-wrapper .react-multiple-carousel__arrow--right {
  right: calc(-3% + 1px)
}

.sc-blog-area .latest-blog-carousel-wrapper .react-multi-carousel-item {
  padding: 20px 0 20px 30px;
}

/* ============== 15 apps section ============== */

.sc-apps-section-area .apps-area {
  /*background: #7215fa;*/
  background: #E82127;
  border-radius: 30px;
  padding: 70px 100px 60px;
  position: relative;
  top: 120px;
  overflow: hidden;
}

.sc-apps-section-area .apps-area .apps-title {
  position: relative;
  z-index: 1;
}

.sc-apps-section-area .apps-area .apps-form {
  position: relative;
  right: 130px;
}

.sc-apps-section-area .apps-area .apps-form:before {
  position: absolute;
  content: "";
  top: 40px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
  width: 285px;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
}

.sc-apps-section-area .apps-area .apps-form input[type=email] {
  color: rgba(255, 255, 255, 0.8);
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_1 {
  position: absolute;
  left: 70px;
  top: 30px;
  max-width: 125px;
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_2 {
  position: absolute;
  right: 31%;
  max-width: 150px;
  top: 0;
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_3 {
  position: absolute;
  left: 0;
  bottom: -15px;
  max-width: 150px;
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_4 {
  position: absolute;
  left: 15%;
  bottom: 0;
  max-width: 135px;
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_5 {
  position: absolute;
  bottom: 35px;
  max-width: 40px;
  right: 100px;
}

.sc-apps-section-area .apps-area .apps_shape_image .shape-img_6 {
  position: absolute;
  top: -5px;
  max-width: 140px;
  right: -5px;
}

.sc-apps-section-area .apps-area2 {
  background: #ef9334;
}

.sc-apps-section-area .apps-area2:before {
  position: absolute;
  content: "";
  width: 130px;
  height: 130px;
  border-radius: 130px;
  background: #f2a95d;
  bottom: -15px;
  left: -10px;
}

.sc-apps-section-area2 {
  background: #17975a;
}

.sc-apps-section-area3 {
  background: #f8f8f8;
}

.sc-apps-section-area4 .apps-area {
  padding: 0 80px 0 100px;
  background: #ef9334;
}

.sc-apps-section-area4 .sc_apps_image {
  position: relative;
  top: 60px;
}

::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

/* ============== 16 footer section ============== */

.sc-footer-section {
  background: #101621;
}

.sc-footer-section p,
.sc-footer-section a {
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-section .padding-bottom {
  padding-bottom: 35px;
}

.sc-footer-section .footer-title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
}

.sc-footer-section .footer-about .about-icon {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-section .footer-about .about-icon li {
  display: inline-block;
  padding-right: 5px;
}

.sc-footer-section .footer-about .about-icon li i {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #424242;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  font-size: 18px;
}

.sc-footer-section .footer-about .about-icon li i:hover {
  background: #ef9334;
}

.sc-footer-section .footer-about .about-icon li:last-child {
  margin-right: 0;
}

.sc-footer-section .footer-menu-area .footer-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-section .footer-menu-area .footer-menu-list li {
  line-height: 30px;
  padding-bottom: 12px;
}

.sc-footer-section .footer-menu-area .footer-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-footer-section .footer-menu-area .footer-menu-list li a {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-footer-section .footer-menu-area .footer-menu-list li a:hover {
  color: #ef9334;
}

.sc-footer-section .footer-menu-area .footer-menu-list i {
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
}

.sc-footer-section .footer-menu-area .footer-menu-list .footer-map {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-section .footer-menu-area .footer-menu-list .footer-map span {
  display: block;
  padding-left: 30px;
  margin-top: -30px;
}

.sc-footer-section .footer-subscribe .footer-des {
  margin-bottom: 10px;
}

.sc-footer-section .footer-subscribe .subscribe-form {
  margin-bottom: 50px;
  position: relative;
}

.sc-footer-section .footer-subscribe .subscribe-form input {
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.sc-footer-section .footer-subscribe .subscribe-form button {
  background: #ffffff;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  padding: 0 20px;
  height: 40px;
  line-height: 0;
  color: #101621;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  border: 1px solid #fff;
  margin-top: 18px;
}

.sc-footer-section .footer-subscribe .subscribe-form button:hover {
  color: #ef9334;
}

.sc-footer-section .copyright-area .border-top {
  border-color: rgba(97, 97, 97, 0.9) !important;
}

.sc-footer-section .copyright-area .copyright-text {
  padding: 18px 0;
}

.sc-footer-section .copyright-area .copyright-text a,
.sc-footer-section .copyright-area .copyright-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.sc-footer-section .copyright-area .copyright-text a:hover {
  color: #ef9334;
}

.sc-footer-style-two {
  background: #101621;
}

.sc-footer-style-two p,
.sc-footer-style-two a {
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-two .padding-bottom {
  padding-bottom: 35px;
}

.sc-footer-style-two .footer-title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
}

.sc-footer-style-two .footer-about .about-icon {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-two .footer-about .about-icon li {
  display: inline-block;
  padding-right: 5px;
}

.sc-footer-style-two .footer-about .about-icon li i {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #424242;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  font-size: 18px;
}

.sc-footer-style-two .footer-about .about-icon li i:hover {
  background: #7a77ff;
}

.sc-footer-style-two .footer-about .about-icon li:last-child {
  margin-right: 0;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list li {
  line-height: 30px;
  padding-bottom: 12px;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list li a {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list li a:hover {
  color: #7a77ff;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list i {
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
}

.sc-footer-style-two .footer-menu-area .footer-menu-list .footer-map {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-two .footer-menu-area .footer-menu-list .footer-map span {
  display: block;
  padding-left: 30px;
  margin-top: -30px;
}

.sc-footer-style-two .footer-subscribe .footer-des {
  margin-bottom: 10px;
}

.sc-footer-style-two .footer-subscribe .subscribe-form {
  margin-bottom: 50px;
  position: relative;
}

.sc-footer-style-two .footer-subscribe .subscribe-form input {
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.sc-footer-style-two .footer-subscribe .subscribe-form button {
  background: #ffffff;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  padding: 0 20px;
  height: 40px;
  line-height: 0;
  color: #101621;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  border: 1px solid #fff;
  margin-top: 18px;
}

.sc-footer-style-two .footer-subscribe .subscribe-form button:hover {
  color: #ef9334;
}

.sc-footer-style-two .copyright-area .border-top {
  border-color: rgba(97, 97, 97, 0.9) !important;
}

.sc-footer-style-two .copyright-area .copyright-text {
  padding: 18px 0;
}

.sc-footer-style-two .copyright-area .copyright-text a,
.sc-footer-style-two .copyright-area .copyright-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.sc-footer-style-two .copyright-area .copyright-text a:hover {
  color: #7a77ff;
}


.sc-footer-style-three {
  background: #101621;
}

.sc-footer-style-three p,
.sc-footer-style-three a {
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-three .padding-bottom {
  padding-bottom: 35px;
}

.sc-footer-style-three .footer-title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
}

.sc-footer-style-three .footer-about .about-icon {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-three .footer-about .about-icon li {
  display: inline-block;
  padding-right: 5px;
}

.sc-footer-style-three .footer-about .about-icon li i {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #424242;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  font-size: 18px;
}

.sc-footer-style-three .footer-about .about-icon li i:hover {
  background: #cdeb45;
  color: #101621;
}

.sc-footer-style-three .footer-about .about-icon li:last-child {
  margin-right: 0;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list li {
  line-height: 30px;
  padding-bottom: 12px;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list li a {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list li a:hover {
  color: #cdeb45;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list i {
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
}

.sc-footer-style-three .footer-menu-area .footer-menu-list .footer-map {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-three .footer-menu-area .footer-menu-list .footer-map span {
  display: block;
  padding-left: 30px;
  margin-top: -30px;
}

.sc-footer-style-three .footer-subscribe .footer-des {
  margin-bottom: 10px;
}

.sc-footer-style-three .footer-subscribe .subscribe-form {
  margin-bottom: 50px;
  position: relative;
}

.sc-footer-style-three .footer-subscribe .subscribe-form input {
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.sc-footer-style-three .footer-subscribe .subscribe-form button {
  background: #ffffff;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  padding: 0 20px;
  height: 40px;
  line-height: 0;
  color: #101621;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  border: 1px solid #fff;
  margin-top: 18px;
}

.sc-footer-style-three .footer-subscribe .subscribe-form button:hover {
  color: #ef9334;
}

.sc-footer-style-three .copyright-area .border-top {
  border-color: rgba(97, 97, 97, 0.9) !important;
}

.sc-footer-style-three .copyright-area .copyright-text {
  padding: 18px 0;
}

.sc-footer-style-three .copyright-area .copyright-text a,
.sc-footer-style-three .copyright-area .copyright-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.sc-footer-style-three .copyright-area .copyright-text a:hover {
  color: #cdeb45;
}


.sc-footer-style-four {
  background: #101621;
}

.sc-footer-style-four p,
.sc-footer-style-four a {
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-four .padding-bottom {
  padding-bottom: 35px;
}

.sc-footer-style-four .footer-title {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
}

.sc-footer-style-four .footer-about .about-icon {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-four .footer-about .about-icon li {
  display: inline-block;
  padding-right: 5px;
}

.sc-footer-style-four .footer-about .about-icon li i {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #424242;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  font-size: 18px;
}

.sc-footer-style-four .footer-about .about-icon li i:hover {
  background: #e81f41;
  color: #ffffff;
}

.sc-footer-style-four .footer-about .about-icon li:last-child {
  margin-right: 0;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list li {
  line-height: 30px;
  padding-bottom: 12px;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list li:last-child {
  padding-bottom: 0;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list li a {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list li a:hover {
  color: #e81f41;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list i {
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
}

.sc-footer-style-four .footer-menu-area .footer-menu-list .footer-map {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.sc-footer-style-four .footer-menu-area .footer-menu-list .footer-map span {
  display: block;
  padding-left: 30px;
  margin-top: -30px;
}

.sc-footer-style-four .footer-subscribe .footer-des {
  margin-bottom: 10px;
}

.sc-footer-style-four .footer-subscribe .subscribe-form {
  margin-bottom: 50px;
  position: relative;
}

.sc-footer-style-four .footer-subscribe .subscribe-form input {
  height: 52px;
  width: 100%;
  padding: 0 20px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.sc-footer-style-four .footer-subscribe .subscribe-form button {
  background: #ffffff;
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  padding: 0 20px;
  height: 40px;
  line-height: 0;
  color: #101621;
  text-align: center;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  border: 1px solid #fff;
  margin-top: 18px;
}

.sc-footer-style-four .footer-subscribe .subscribe-form button:hover {
  color: #ef9334;
}

.sc-footer-style-four .copyright-area .border-top {
  border-color: rgba(97, 97, 97, 0.9) !important;
}

.sc-footer-style-four .copyright-area .copyright-text {
  padding: 18px 0;
}

.sc-footer-style-four .copyright-area .copyright-text a,
.sc-footer-style-four .copyright-area .copyright-text p {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.sc-footer-style-four .copyright-area .copyright-text a:hover {
  color: #e81f41;
}

/* ============== 17 breadcrumb page ============== */

.sc-breadcrumbs {
  position: relative;
  background-color: #E82127;
  background-image: url(../images/banner/inner_bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 205px 0 150px;
}

.sc-breadcrumbs .breadcrumbs-text {
  text-align: center;
}

.sc-breadcrumbs .breadcrumbs-text .page-title {
  margin-bottom: 12px;
  font-size: 40px;
  color: #ffffff;
}

.sc-breadcrumbs .breadcrumbs-text ul {
  padding: 0;
}

.sc-breadcrumbs .breadcrumbs-text ul li {
  color: #fff;
  display: inline-block;
  position: relative;
  padding-right: 20px;
  margin-right: 12px;
}

.sc-breadcrumbs .breadcrumbs-text ul li:before {
  content: "";
  width: 12px;
  height: 2px;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 14px;
  right: -4px;
}

.sc-breadcrumbs .breadcrumbs-text ul li:last-child::before {
  display: none;
}

.sc-breadcrumbs .breadcrumbs-text ul li a {
  color: rgba(255, 255, 255, 0.8);
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-breadcrumbs .breadcrumbs-text ul li a:hover {
  color: #ef9334;
}

.sc-breadcrumbs .breadcrumbs-text ul li:last-child {
  padding-right: 0;
  margin-right: 0;
}

/* ============== 18 blog Page ============== */

.sc-blog-pages-area .blog-item {
  position: relative;
}

.sc-blog-pages-area .blog-item .blog-image {
  border-radius: 30px;
}

.sc-blog-pages-area .blog-item .blog-content {
  padding: 15px 0 40px;
}

.sc-blog-pages-area .blog-item .blog-content .blog-list {
  padding: 0;
}

.sc-blog-pages-area .blog-item .blog-content .blog-list:before {
  display: none;
}

.sc-blog-pages-area .blog-item .blog-content .blog-list li {
  padding-right: 15px;
  margin-right: 6px;
}

.sc-blog-pages-area .blog-item .blog-content .blog-list li:before {
  width: 8px;
  background: #767676;
  top: 17px;
  height: 2px;
  right: -3px;
}

.sc-blog-pages-area .blog-item .blog-content2 {
  box-shadow: none;
  padding: 0;
}

.sc-blog-pages-area .blog-item .blog-content2 .blog_list2 {
  padding-top: 10px;
}

.sc-blog-pages-area .blog-item .readmore {
  font-size: 16px;
  color: #101621;
  font-weight: 500;
  font-family: "Verdana", sans-serif;
  text-transform: uppercase;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  position: relative;
}

.sc-blog-pages-area .blog-item .readmore::before {
  content: "";
  position: absolute;
  height: 1px;
  background: #424242;
  bottom: -5px;
  width: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.sc-blog-pages-area .blog-item .readmore:hover {
  color: #ef9334;
}

.sc-blog-pages-area .blog-item .readmore:hover::before {
  width: 100%;
  background: #ef9334;
}

.list_menu {
  margin-bottom: 0 !important;
}

.list_menu .blog-list li::before {
  display: none;
}

.list_menu .blog-list li i {
  /*background: #7215fa;*/
  background: #E82127;
  display: inline-block;
  color: #ffffff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}

.blog_date {
  position: absolute;
  right: 0;
  top: 0;
  background: #17975a;
  font-weight: 700;
  color: #ffffff;
  padding: 20px 35px;
  border-radius: 0 30px 0 0;
}

.spria-navigation ul li {
  display: inline-block;
  position: relative;
}

.spria-navigation ul li+li {
  margin-left: 4px;
}

.spria-navigation ul li:first-child {
  top: 2px;
}

.spria-navigation ul li i {
  position: relative;
  top: 0;
}

.spria-navigation ul li .arrow-line {
  position: relative;
  top: 2px;
}

.spria-navigation ul li a {
  display: block;
  color: #424242;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-family: "Russo One", sans-serif;
  text-transform: uppercase;
  transition: 0.4s;
}

.spria-navigation ul li a .icon-Vector {
  font-size: 13px;
}

.spria-navigation ul li a .icon-arrow_right {
  font-size: 13px;
}

.spria-navigation ul li a.active:not(.black-shape),
.spria-navigation ul li a:hover:not(.black-shape) {
  /*background: #7215fa;*/
  background: #E82127;
  color: #ffffff;
}

.details-content h2 {
  margin-top: -15px;
  display: block;
  margin-bottom: 20px;
}

.details-content .des {
  background: #16182d;
  color: #424242;
  font-size: 20px;
  padding: 40px 50px;
}

.details-title-item {
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(221, 222, 225, 0.7);
}

.details-title-item .details_btn h6 {
  margin-right: 30px;
}

.details-title-item .details-social-list h6 {
  font-weight: 700;
}

.details-title-item .details-social-list .list-icon {
  position: relative;
  bottom: 4px;
}

.details-title-item .details-social-list .list-icon ul li {
  display: inline-block;
  padding-left: 10px;
}

.details-title-item .details-social-list .list-icon ul li a {
  color: #424242;
  display: inline-block;
}

.details-title-item .details-social-list .list-icon ul li a i {
  position: relative;
  top: 0;
}

.details-title-item .details-social-list .list-icon ul li a:hover {
  color: #ef9334;
}

.details-form-box {
  background: #f8f8f8;
  border-radius: 20px;
  padding: 40px;
}

.details-form-box .form label {
  display: block;
}

.details-form-box .form textarea,
.details-form-box .form input {
  background: #ffffff;
  border: 1px solid rgba(114, 21, 250, 0.2);
  border-radius: 10px;
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 15px;
}

.details-form-box .form textarea {
  height: 160px;
}

.details-form-box ::placeholder {
  color: #424242;
  opacity: 1;
}

.details-form-box :-ms-input-placeholder {
  color: #424242;
}

.details-form-box ::-ms-input-placeholder {
  color: #424242;
}

.details-form-box h4 {
  display: block;
}

.details-author-box {
  margin-bottom: 30px;
}

.details-author-box .details-img {
  margin-right: 25px;
}

.details-author-box .title-box .author-text .title {
  margin-bottom: 5px;
  display: block;
  font-weight: 700;
}

.details-author-box .title-box .author-text span {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.details-author-box .title-box .author-text p {
  font-size: 15px;
}

.categories-area {
  background: #f5f5f5;
  border-radius: 20px;
  padding: 30px;
}

.categories-area .catergories-form {
  padding: 10px 15px;
  border: 1px solid rgb(62, 62, 62);
  border-radius: 5px;
  position: relative;
}

.categories-area .catergories-form input {
  color: #3e3e3e;
}

.categories-area .catergories-form button {
  padding: 0 13px;
  background: transparent;
  height: 51px;
  line-height: 51px;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  font-size: 18px;
}

.categories-area .catergories-form button i {
  color: #424242;
  position: relative;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  top: 0;
}

.categories-area .catergories-form button:hover i {
  color: #ef9334;
}

.categories-area .catergories-form ::placeholder {
  color: #424242;
  opacity: 1;
}

.categories-area .catergories-form :-ms-input-placeholder {
  color: #424242;
}

.categories-area .catergories-form ::-ms-input-placeholder {
  color: #424242;
}

.categories-area .categories-item .list li {
  color: #ffffff;
  font-weight: 500;
  line-height: 2.3;
}

.categories-area .categories-item .list li a {
  color: #424242;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.categories-area .categories-item .list li a:hover {
  color: #ef9334;
}

.categories-area .post-item .recent-content .recent-image img {
  border-radius: 15px;
}

.categories-area .post-item .recent-content .recent-text .calender-item {
  display: block;
}

.categories-area .post-item .recent-content .recent-text .calender-item span {
  font-size: 14px;
  font-weight: 500;
}

.categories-area .post-item .recent-content .recent-text .calender-item i {
  /*color: #7215fa;*/
  color: #E82127;
  margin-right: 5px;
}

.categories-area .post-item .recent-content .recent-text a {
  color: #101621;
  font-weight: 500;
}

.categories-area .post-item .recent-content .recent-text a:hover {
  color: #ef9334;
}

.categories-area .categories-tag .list li {
  display: inline-block;
  padding-right: 15px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.categories-area .categories-tag .list li a {
  color: #424242;
}

.categories-area .categories-tag .list li a:hover {
  color: #ef9334;
}

.details-btn .details-secondary,
.details-btn .details-primary {
  border: 1px solid transparent;
  color: #424242;
  font-size: 14px;
  transition: 0.4s;
}

.details-btn .details-secondary:hover,
.details-btn .details-primary:hover {
  color: #ef9334;
}

.details-btn .details-secondary {
  background: transparent;
  border: 1px solid #dddee1;
  color: #424242;
}

.details-btn .details-secondary:hover {
  /*background: #7215fa;*/
  background: #E82127;
  border-color: transparent;
  color: #ffffff;
}

/* ============== 19 blog-details Page ============== */

.sc-blog-details .blog-item {
  position: relative;
}

.sc-blog-details .blockquote {
  background: #f5f5f5;
  padding: 30px 50px;
  border-radius: 20px;
  margin: 5px 0 26px;
}

.sc-blog-details .blockquote .description {
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  font-family: "Verdana", sans-serif;
  margin-bottom: 20px;
  margin-top: 30px;
}

.sc-blog-details .blog-item .blog-content .blog-list-menu .blog-list::before {
  display: none;
}

.sc-blog-details .blog-item .blog-content .blog-list-menu .blog-list {
  padding-left: 0;
}

/* ============== 20 faq page ============== */

.sc-faq-pages-area .accordion-item {
  border: none;
  margin-bottom: 15px;
  padding: 8px 0;
  background: #f8f7f7;
  border-radius: 10px;
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button {
  font-size: 18px;
  color: #424242;
  font-weight: 500;
  position: relative;
  background: #f8f7f7;
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button:before {
  position: absolute;
  top: 15px;
  right: 25px;
  font-family: "remixicon";
  content: "\ea78";
  color: #101621;
  font-size: 20px;
  transform: rotate(-180deg);
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  color: #ef9334;
  box-shadow: none;
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  content: "\ea4e";
  color: #ef9334;
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.sc-faq-pages-area .accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.sc-faq-pages-area .accordion-item .accordion-body {
  padding-top: 0;
}

.sc-faq-pages-area .accordion-item .accordion-body strong {
  font-size: 16px;
  color: #424242;
  font-weight: 400;
}

.sc-crypto-faq-style {
  background: url(../images/banner/faq-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sc-crypto-faq-style .accordion-item {
  border: none;
  margin-bottom: 15px;
  padding: 8px 0;
  background: transparent !important;
  border-radius: 10px;
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button {
  font-size: 18px;
  color: #ffffff !important;
  font-weight: 500;
  position: relative;
  background: transparent !important;
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button:before {
  position: absolute;
  top: 15px;
  right: 25px;
  font-family: "remixicon";
  content: "\ea78";
  color: #ffffff !important;
  font-size: 20px;
  transform: rotate(-180deg);
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  color: #cdeb45 !important;
  box-shadow: none;
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  content: "\ea4e";
  color: #cdeb45 !important;
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.sc-crypto-faq-style .accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.sc-crypto-faq-style .accordion-item .accordion-body {
  padding-top: 0;
}

.sc-crypto-faq-style .accordion-item .accordion-body strong {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 400;
}


.sc-faq-style .accordion-item {
  border: none;
  padding: 8px 0;
  background: transparent;
  border-bottom: 1px solid rgba(66, 66, 66, 0.4);
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-radius: 0;
}

.sc-faq-style .accordion-item .accordion-header .accordion-button {
  font-size: 18px;
  color: #121933;
  font-weight: 700;
  position: relative;
  margin-bottom: 0;
  padding: 0;
  background: transparent;
}

.sc-faq-style .accordion-item .accordion-header .accordion-button:before {
  position: absolute;
  top: -3px;
  right: 0;
  font-family: "remixicon";
  content: "\ea78";
  color: #101621;
  font-size: 20px;
  transform: rotate(-180deg);
}

.sc-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  color: #7a77ff;
  box-shadow: none;
}

.sc-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  content: "\ea4e";
  color: #7a77ff;
}

.sc-faq-style .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
}

.sc-faq-style .accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.sc-faq-style .accordion-item .accordion-body {
  padding: 0;
}

.sc-faq-style .accordion-item .accordion-body strong {
  font-size: 16px;
  color: #424242;
  font-weight: 400;
}

/* ============== 21 contact page ============== */

.contact-page-service-box {
  display: flex;
  align-items: center;
}

.contact-page-service-box .contact_icon i {
  /*background: #7215fa;*/
  background: #E82127;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 20px;
  color: #ffffff;
  display: inline-block;
  font-size: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.contact-page-service-box .contact-service-box {
  margin-top: -25px;
}

.contact-page-service-box .contact-service-box ul li {
  color: #424242;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  font-family: "Verdana", sans-serif;
}

.contact-page-service-box .contact-service-box ul li a {
  color: #424242;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.contact-page-service-box .contact-service-box ul li a:hover {
  color: #ef9334;
}

.contact-page-service-box .contact-service-box ul li span {
  display: block;
}

.contact-box {
  background: #ffffff;
  padding: 40px 40px 30px;
  box-shadow: 0 0 25px rgba(110, 106, 106, 0.07);
}

.contact-box input.from-control {
  width: 100%;
  background: #f9f5ff;
  margin-bottom: 15px;
  padding: 15px 20px;
  border-radius: 10px;
  border: 1px solid rgba(114, 21, 250, 0.2);
}

.contact-box ::placeholder {
  color: #424242;
  opacity: 1;
}

.contact-box :-ms-input-placeholder {
  color: #424242;
}

.contact-box ::-ms-input-placeholder {
  color: #424242;
}

.contact-box textarea {
  width: 100%;
  background: #f9f5ff;
  padding: 20px 15px 50px 25px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgba(114, 21, 250, 0.2);
}

.contact-box h3 {
  display: block;
  margin-bottom: 30px;
}

.login-button .input-box {
  position: relative;
  top: 15px;
}

.login-button .input-box input[type=checkbox] {
  position: relative;
  top: -16px;
  right: 10px;
  border-radius: 3px;
}

/* ============== 22 team Page ============== */

.team-content-box {
  box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.05);
  padding: 20px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.team-content-box .team-image {
  position: relative;
  overflow: hidden;
}

.team-content-box .team-social-icon ul {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  width: 100%;
}

.team-content-box .team-social-icon ul li {
  display: inline-block;
  padding: 0 2px;
}

.team-content-box .team-social-icon ul li a {
  width: 30px;
  height: 30px;
  line-height: 25px;
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  background: #ffffff;
  color: #424242;
  transform: translateY(80px);
  opacity: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.team-content-box .team-social-icon ul li a:hover {
  background: #ef9334;
  color: #ffffff;
}

.team-content-box .team-social-icon ul li:nth-child(1) a {
  transition-delay: 0.1s;
}

.team-content-box .team-social-icon ul li:nth-child(2) a {
  transition-delay: 0.2s;
}

.team-content-box .team-social-icon ul li:nth-child(3) a {
  transition-delay: 0.3s;
}

.team-content-box .team-social-icon ul li:nth-child(4) a {
  transition-delay: 0.4s;
}

.team-content-box .team-author .team-title a {
  color: #101621;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.team-content-box .team-author .team-title a:hover {
  color: #ef9334;
}

.team-content-box .team-author span {
  color: #424242;
}

.team-content-box:hover {
  box-shadow: 20px 20px 60px rgba(0, 0, 0, 0.07);
}

.team-content-box:hover .team-social-icon a {
  opacity: 1;
  transform: translateY(0);
}

.content-area {
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(201, 193, 193, 0.3);
  padding: 40px;
}

.content-area .details-area .personal-info {
  margin-top: 25px;
  margin-bottom: 20px;
}

.content-area .details-area .personal-info li i {
  color: #ef9334;
  font-size: 20px;
  font-family: "Verdana", sans-serif;
  margin-right: 10px;
}

.content-area .details-area .personal-info li a {
  color: #424242;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}

.content-area .details-area .personal-info li a:hover {
  color: #ef9334;
}

.content-area .details-area .team-rating {
  margin-bottom: 35px;
}

.content-area .details-area .team-rating .rating-list li {
  display: inline-block;
  margin-right: 70px;
}

.content-area .details-area .team-rating .rating-list li i {
  color: #ef9334;
}

#myTabContent {
  position: relative;
}

#myTabContent .product-grid {
  position: relative;
}

#myTabContent .sc-product-inner {
  position: relative;
  float: left;
}

/* ============== 23 Login-SignUp Page ============== */

.sc-login-box {
  background: #ffffff;
  padding: 40px 50px 25px;
  max-width: 650px;
  margin: 0 auto;
  box-shadow: 0 0 25px rgb(110 106 106 / 7%);
}

.sc-login-box .sc-text a {
  color: #101621;
  transition: 0.4s;
}

.sc-login-box .sc-text a:hover {
  color: #ef9334;
}

.sc-login-box h4 {
  margin-bottom: 40px;
}

.sc-login-box .login-form label {
  display: block;
  margin-bottom: 5px;
}

.sc-login-box .login-form textarea,
.sc-login-box .login-form input {
  background: #f9f5ff;
  width: 100%;
  border: 1px solid rgba(114, 21, 250, 0.2);
  color: #101621;
  margin-bottom: 25px;
  height: 45px;
  padding: 0 15px;
  border-radius: 5px;
}

.sc-login-box .login-form textarea {
  height: 150px;
  padding: 10px 15px;
}

.sc-login-box ::placeholder {
  color: #424242;
  opacity: 1;
}

.sc-login-box :-ms-input-placeholder {
  color: #424242;
}

.sc-login-box ::-ms-input-placeholder {
  color: #424242;
}

.sc-login-box .check-box {
  margin-bottom: 20px;
}

.sc-login-box .check-box input {
  margin-right: 10px;
}

.sc-login-box .check-box .login-password a {
  color: #424242;
}

.sc-login-box .check-box .login-password a:hover {
  color: #ef9334;
}

.sc-login-box .login-email {
  position: relative;
}

.sc-login-box .login-email:after {
  content: "";
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(114, 21, 250, 0.2);
  top: 14px;
}

.sc-login-box .login-email p {
  display: inline-block;
  position: relative;
  z-index: 3;
  padding: 0 15px;
  background: #f9f5ff;
}

.sc-login-box .login-email a {
  color: #424242;
}

.sc-login-box .login-email a:hover {
  color: #ef9334;
}

.sc-login-box .logout-button .Google {
  margin-bottom: 10px;
}

.sc-login-section .submit-btn {
  display: block;
  padding: 12px 35px;
  margin-bottom: 25px;
  /*background: #7215fa;*/
  background: #E82127;
  border-radius: 5px;
  transition: 0.5s;
  position: relative;
}

.submit-btn svg path {
  fill: #fff;
}

.submit-btn .sc-facebook {
  top: -2px;
  position: relative;
}

.submit-btn:hover {
  background: #ef9334;
}

.submit-btn input[type="submit"] {
  color: #fff;
  position: relative;
  z-index: 3;
  width: auto;
}

.create {
  display: inline-block;
  margin-right: 30px;
}

.create a {
  margin-bottom: 0;
}

.login-form input {
  height: 55px !important;
}

.sc-register-box {
  padding: 40px 50px 50px;
}

.sc-register-box .create-title {
  font-size: 40px;
}

select {
  border: 1px solid rgba(114, 21, 250, 0.2);
  width: 100%;
  color: #424242;
  height: 55px;
  line-height: 55px;
  padding: 0 15px;
  border-radius: 5px;
  background: #f9f5ff;
}

.register-account .register-signin {
  position: relative;
  bottom: 6px;
  font-weight: 500;
}

.register-account .register-signin a {
  padding-left: 5px;
  text-decoration: underline;
  color: #ef9334;
  transition: 0.4s;
}

.register-account .register-signin a:hover {
  color: #ef9334;
}

/* ============== 24 responsive style ============== */

@media only screen and (max-width: 1650px) {
  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 28.5%;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 1520px) {
  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 24.5%;
  }

  .sc-banner-section-area .banner-circle-shape {
    width: 700px;
    height: 700px;
  }

  .sc-banner-section-area6 .banner-header-content {
    margin-left: 18.5%;
  }
}

@media only screen and (max-width: 1440px) {
  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 20.5%;
  }

  .sc-banner-section-area3 .banner-circle-shape2 {
    width: 650px;
    height: 650px;
  }

  .sc-banner-section-area3 .sc-banner-item .banner-header-content {
    margin-left: 16.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    top: 13px;
    left: -3px;
  }
}


/* Max Resolution 1366px */
@media only screen and (max-width: 1366px) {
  .sc-banner-section-area .sc-banner-item .banner-image .banner-img_2 {
    bottom: -25%;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 14.5%;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content .banner-title {
    font-size: 55px;
  }

  .image-area {
    padding-left: 55px;
  }

  .sc-feature-section-area .feature-image-area {
    margin-right: 0;
  }

  .sc-counter-area .shape_image {
    left: 20px;
  }

  .sc-counter-area .shape_image1 {
    right: 15px;
  }

  .sc-banner-section-area .banner-circle-shape {
    width: 525px;
    height: 525px;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: -16%;
  }

  .sc-feature-section-area4 .sc-feature-image .feature-circle-shape {
    left: 34.5%;
  }

  .sc-feature-section-area4 .sc-feature-item {
    padding-right: 80px;
  }

  .sc-heading-area .heading-title {
    font-size: 40px;
  }

  .sc-banner-section-area3 .banner-header-content .banner-title {
    font-size: 55px;
  }

  .sc-banner-section-area3 .banner-circle-shape2 {
    width: 540px;
    height: 540px;
  }

  .sc-feature-section-area5 .sc-feature-image .image-bg-circle {
    width: 450px;
    height: 450px;
  }

  .sc-header-section2 .container-fluid,
  .sc-header-section3 .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    top: 13px;
    left: -3px;
  }

  .sc-service-section-area8 .sc-service-content-box {
    padding: 30px 20px 5px 20px;
  }

  .sc-media-section-area .sc-media-item {
    padding-right: 0;
  }

  .sc-price-style .sc-price-box .sc-price {
    font-size: 25px;
  }

  .sc-header-section5 .sc-main-menu>ul>li,
  .sc-header-section6 .sc-main-menu>ul>li {
    margin-right: 40px;
  }

  .sc-header-section7 .sc-main-menu>ul>li {
    margin-right: 40px;
  }
}

/* Max Resolution 1199px */
@media only screen and (max-width: 1199px) {
  .sc-banner-section-area2 {
    padding-bottom: 60px;
  }

  .sc-form-inner {
    display: block !important;
  }

  .apps-form {
    right: 0 !important;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 7.5%;
  }

  .sc-counter-area .sc-counter-box .sc-count,
  .sc-about-area .sc-about-image .about-image_1 {
    left: 100px;
  }

  .sc-feature-section-area .feature-image-area .feature-shape {
    left: 40%;
  }

  .sc-apps-section-area .apps-area {
    padding: 70px 50px 60px;
  }

  .sc-banner-section-area .banner-circle-shape {
    width: 455px;
    height: 530px;
  }

  .sc-main-menu>ul>li {
    margin-right: 30px;
  }

  .image-area {
    padding-left: 30px;
  }

  .sc-apps-section-area .sc-primary-btn {
    padding-top: 40px;
  }

  .sc-header-section .container-fluid,
  .inner-header-style .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sc-feature-section-area .sc-feature-item,
  .sc-banner-section-area .sc-banner-item .banner-header-content .banner-sub-title {
    padding-right: 0;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    top: 82px;
    left: -5px;
  }

  .recent-image img {
    max-width: 90px;
  }

  .sc-heading-area .heading-title {
    font-size: 35px;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: -45%;
  }

  .sc-feature-section-area4 .sc-feature-image .feature-circle-shape,
  .sc-feature-section-area3 .feature-image-area .feature-circle-shape {
    left: 29.5%;
  }

  .sc-banner-section-area3 .sc-banner-item .banner-header-content {
    margin-left: 7.5%;
  }

  .sc-banner-section-area3 .banner-header-content .banner-title {
    font-size: 45px;
  }

  .sc-header-section2 .secondary-btn,
  .sc-header-section3 .secondary-btn {
    padding: 15px 30px;
  }

  .sc-banner-section-area7 .banner_image {
    padding-left: 0;
    margin-bottom: 30px;
  }

  .sc-service-section-area8 .sc-service-content-box {
    padding: 30px 10px 5px 10px;
  }

  .sc-service-section-area8 .sc-service-content-box h4 a {
    font-size: 18px;
  }

  .sc-banner-section-area7 .banner-header-content .banner-title {
    font-size: 60px;
  }

  .sc-media-section-area .sc-media-item .sc-media-list ul li {
    font-size: 15px;
  }

  .sc-media-section-two .sc-media-two {
    padding-left: 0;
  }

  .sc-price-style .sc-price-box {
    padding: 50px 15px 30px 30px;
  }

  .sc-price-style .sc-gradiant-active,
  .sc-price-style .sc-gradiant-btn {
    padding: 18px 40px;
  }

  .sc-client-style-two {
    position: relative;
  }

  .sc-client-style-two .sc-client-item {
    padding: 30px 15px 15px 25px;
  }

  .sc-yellow-btn-two {
    padding: 13px 40px;
  }

  .sc-header-section5 .sc-main-menu>ul>li,
  .sc-header-section6 .sc-main-menu>ul>li {
    margin-right: 25px;
  }

  .sc-header-section5 .header-btn,
  .sc-header-section6 .header-btn {
    padding-left: 15px;
  }

  .sc-service-section-area6 .sc-service-content-box .sc-text-area {
    padding: 30px 24px 10px;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-area .title,
  .sc-service-section-area6 .sc-service-content-box .sc-text-area .title {
    font-size: 18px;
  }

  .sc-crupto-style-three .sc-crupto-content-two {
    padding-right: 0;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-area {
    padding: 35px 20px 10px;
  }

  .sc-header-section7 .sc-main-menu>ul>li {
    margin-right: 25px;
  }

  .sc-header-section7 .header-btn {
    padding-left: 15px;
  }

  .sc-header-section7.sc-header-sticky {
    padding: 18px 0;
  }
}

@media only screen and (min-width: 992px) {
  .sc-product-offcanvas-area .mobile-navbar-menu {
    display: none;
  }
}

/* max-width: 991px */
@media only screen and (max-width: 991px) {
  .sc-feature-section-area .feature-image-area .feature-shape {
    left: 45%;
  }

  .sc-about-item {
    padding-left: 0;
  }

  .sc-header-section .container-fluid,
  .inner-header-style .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  .banner-circle-shape {
    width: 400px;
    height: 540px;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content {
    margin-left: 2.5%;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content .banner-title,
  .team-author h2 {
    font-size: 40px;
  }

  .header-btn-area .canvas {
    display: inline-block !important;
  }

  .sc-counter-area .sc-counter-box .sc-count {
    font-size: 45px;
  }

  .sc-counter-area .sc-counter-box .count-title {
    font-size: 16px;
  }

  .sc-counter-area .shape_image {
    left: -15px;
  }

  .sc-counter-area .shape_image1 {
    right: -15px;
  }

  .sc-testimonial-area .sc-testimonial-item .description {
    font-size: 18px;
  }

  .sc-client-section-area .client-carousel-wrapper:hover .arrowRight {
    right: -5%;
  }

  .sc-client-section-area .client-carousel-wrapper:hover .arrowLeft {
    left: -5%;
  }

  .client4-carousel-wrapper .commonArrow {
    display: none;
  }

  .sc-apps-section-area,
  .sc-apps-bottom {
    margin-top: -60px;
  }

  .sc-footer-section .footer-title {
    margin-bottom: 15px;
  }

  .sc-banner-section-area .sc-banner-item .banner-image .banner-img_2 {
    bottom: -40%;
  }

  .sc-banner-section-area .sc-banner-item .banner-image .banner-img_2 {
    right: 20.5%;
  }

  .sc-banner-section-area2 .banner-shape {
    bottom: -25px;
  }

  .sc-banner-section-area2 {
    padding-top: 160px;
  }

  .sc-service-section-area2 .service-content-box:before {
    left: 35px;
  }

  .sc-header-section2.sc-header-sticky,
  .sc-header-sticky.sc-header-section3 {
    padding: 14px 0;
  }

  .sc-apps-section-area4 .apps-area {
    padding: 70px 50px 0;
    display: block !important;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    display: none;
  }

  .style-two .sc-counter-box::before {
    display: none;
  }

  .sc-service-section-area4 .sc-service-content-box:before {
    display: none;
  }

  .sc-breadcrumbs {
    padding: 145px 0 70px;
  }

  .sc-feature-section-area5 .sc-feature-image .image-bg-circle {
    width: 520px;
    height: 520px;
  }

  .sc-banner-section-area3 .banner-circle-shape2 {
    width: 450px;
    height: 450px;
  }

  .sc-banner-section-area3 .banner_image {
    max-width: 350px;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: -30%;
  }

  .sc-banner-section-area3 .banner-header-content .banner-title {
    font-size: 35px;
  }

  .sc-select {
    margin-bottom: 30px;
  }

  .register-account {
    display: block !important;
  }

  .register-form p {
    padding-top: 10px;
  }

  .sc-register-box {
    padding: 20px 50px 30px;
  }

  .sc-login-section {
    padding-bottom: 20px;
    padding-top: 80px;
  }

  .sc-banner-section-area6 .banner-header-content {
    margin-left: 5.5%;
  }

  .sc-crupto-style-two .sc-crupto-content-two {
    position: relative;
    margin-top: -95px;
  }

  .sc-feature-section-area6 .feature-image-area {
    text-align: start !important;
  }

  .sc-header-section6.sc-header-sticky,
  .sc-header-section5.sc-header-sticky {
    padding: 15px 0 5px;
  }

  .image-wrap {
    display: none;
  }
}

/* max-width: 767px */
@media only screen and (max-width: 767px) {

  .apps-area,
  .details-content-area .details-title-item {
    display: block !important;
  }

  .details-content-area .details-title-item .details_btn {
    margin-bottom: 40px;
  }

  .sc-about-area .sc-about-image .about-image_1 {
    left: 55px;
  }

  .banner-circle-shape {
    width: 350px;
    height: 350px;
  }

  .sc-client-section-area .client-carousel-wrapper:hover .arrowRight {
    right: -6%;
  }

  .sc-client-section-area .client-carousel-wrapper:hover .arrowLeft {
    left: -6%;
  }

  .sc-counter-area .sc-counter-box:before {
    right: -10px;
  }

  .sc-banner-section-area3 .banner-circle-shape2,
  .sc-service-section-area2 .service-content-box:before,
  .sc-banner-section-area2 .banner-shape,
  .sc-banner-section-area .banner-image,
  .sc-testimonial-area .test_left_imgage,
  .sc-testimonial-area .test_left_imgage1,
  .sc-testimonial-area .test_left_imgage2,
  .sc-testimonial-area .test_right_imgage,
  .sc-testimonial-area .test_right_imgage1,
  .sc-testimonial-area .test_right_imgage2 {
    display: none;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    display: block;
    top: 27px;
    left: -4px;
  }

  .sc-apps-section-area .apps-area {
    padding: 40px 30px 20px;
    top: 70px;
  }

  .sc-price-area .sc-price-box {
    padding: 25px 0px 30px 30px;
  }

  .sc-faq-pages-area h2,
  .apps-title .heading-title,
  .sc-counter-area .sc-counter-box .sc-count,
  .sc-price-area .sc-price-box .price-item .price-active {
    font-size: 40px;
  }

  .sc-breadcrumbs .breadcrumbs-text .page-title {
    font-size: 45px;
  }

  .banner-header-content .description {
    padding: 0 15px;
  }

  .sc-feature-section-area5 .feature_img {
    max-width: 400px;
  }

  .sc-feature-section-area5 .sc-feature-image .image-bg-circle {
    width: 400px;
    height: 400px;
  }

  .sc-feature-section-area4 .sc-feature-item {
    padding-right: 0;
  }

  .sc-feature-section-area3 .feature-image-area .feature-img_1 {
    right: 5%;
  }

  .sc-feature-section-area4 .sc-feature-image .feature-circle-shape,
  .sc-feature-section-area3 .feature-image-area .feature-circle-shape {
    left: 41.5%;
  }

  .sc-banner-section-area .banner-circle-shape {
    width: 350px;
    height: 350px;
  }

  .banner-header-content h1 {
    font-size: 50px;
  }

  .blog-content .blog_date {
    padding: 15px 25px;
  }

  .about-team-pages-area .sc-ab-circle.purple {
    left: 130px;
    width: 410px;
    height: 410px;
  }

  .about-team-pages-area .sc-ab-circle.yelow {
    right: 130px;
    width: 410px;
    height: 410px;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: 0;
  }

  .sc-header-section3 .header-list ul li a {
    color: #101621;
  }

  .sc-login-box {
    max-width: 530px;
  }

  .sc-banner-section-area7 .banner-header-content {
    max-width: 100%;
  }

  .sc-banner-section-area7 .banner-header-content .banner-title {
    font-size: 35px;
  }

  .sc-client-style-two .sc-slick-slide .slick-arrow {
    display: none !important;
  }

  .sc-faq-style .accordion-item .accordion-header .accordion-button {
    font-size: 16px;
  }

}

/* Max Resolution 575px */
@media only screen and (max-width: 575px) {
  .sc-client-section-area .client-carousel-wrapper .commonArrow {
    display: none;
  }

  .team-author h2 {
    font-size: 35px;
  }

  .sc-blog-details .blockquote .description,
  .team-author h3 {
    font-size: 20px;
  }

  .content-area .details-area .team-rating .rating-list li {
    margin-right: 40px;
  }

  .image-area {
    padding-left: 0;
  }

  .sc-heading-area h2 {
    font-size: 30px;
  }

  .details-author-box,
  .sc-header-section .sc-header-left-wraper,
  .inner-header-style .sc-header-left-wraper {
    display: block !important;
  }

  .sc-feature-section-area .sc-heading-area {
    margin-bottom: 20px;
  }

  .sc-header-section.sc-header-sticky,
  .sc-header-sticky.inner-header-style {
    padding: 30px 0 23px;
  }


  .header-btn-area {
    padding: 10px 0;
  }

  .banner-header-content {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .sc-counter-area .shape_image,
  .sc-about-area .sc-about-image .circle-shape {
    left: 30px;
  }

  .sc-about-area .sc-about-image .about-image {
    max-width: 450px;
  }

  .sc-feature-section-area .feature-image-area .feature-shape {
    left: 35%;
  }

  .sc-about-area .sc-about-image .about-image_1,
  .sc-feature-section-area .feature-image-area .feature-img_1 {
    left: 0;
  }

  .sc-counter-area .shape_image1 {
    right: 30px;
  }

  .sc-counter-area .sc-counter-box:before {
    display: none;
  }

  .sc-header-section.sc-header-sticky,
  .sc-header-sticky.inner-header-style {
    padding: 15px 0;
  }

  .sc-banner-section-area {
    padding-bottom: 0;
    padding-top: 190px;
  }

  .banner-header-content h1 {
    padding: 0 12px;
  }

  .sc-banner-section-area3 .container-fluid {
    margin-top: 50px;
  }

  .sc-feature-section-area5 .feature_img {
    max-width: 350px;
    padding-left: 60px;
  }

  .sc-feature-section-area5 .sc-feature-image .image-bg-circle {
    width: 350px;
    height: 350px;
  }

  .sc-feature-section-area4 .sc-feature-image .feature-circle-shape,
  .sc-feature-section-area3 .feature-image-area .feature-circle-shape {
    width: 280px;
    height: 280px;
    left: 53.5%;
    top: 80%;
  }

  .sc-service-section-area7 .sc-service-content-box .sc-text-number {
    display: none
  }

  .about-team-pages-area .sc-ab-circle.purple,
  .about-team-pages-area .sc-ab-circle.yelow {
    display: none;
  }

  .details-author-box .details-img img {
    max-width: 80px;
    margin-bottom: 20px;
  }

  .sc-header-section2 .sc-main-logo,
  .sc-header-section3 .sc-main-logo {
    margin-bottom: 25px;
  }

  .sc-login-section .container {
    padding: 0 10px;
  }

  .register-form .page-title {
    font-size: 32px;
  }

  .register-form p,
  .register-signin {
    font-size: 15px;
  }

  .sc-login-box {
    max-width: 445px;
  }

  .sc-login-section .check-box {
    display: block !important;
  }

  .sc-banner-section-area6 .banner-header-content {
    margin-top: 35px;
  }

  .sc-crupto-section-area2 .sc-crupto-item .sc-shape {
    width: 100%;
  }

  .sc-crupto-section-area .sc-crupto-item .sc-crupto-img {
    width: 100%;
  }

  .sc-banner-section-area5 .banner-header-content h1,
  .sc-banner-section-area6 .banner-header-content .banner-title {
    padding: 0;
  }

  .sc-yellow-btn-two {
    padding: 9px 30px;
  }

  .sc-header-section5 .header-btn-area,
  .sc-header-section6 .header-btn-area {
    padding-top: 15px;
  }

  .sc-header-section7 .logo-area {
    margin-bottom: 20px;
  }

  .gradiant-btn {
    font-size: 15px;
    padding: 15px 40px;
  }
}

/* Max Resolution 480px */
@media only screen and (max-width: 480px) {

  .categories-area .post-item .recent-content .recent-text h5,
  .blog-content .description {
    font-size: 15px;
  }

  .sc-login-box {
    padding: 40px 25px 25px;
  }

  .sc-apps-section-area4 .apps-btn-area,
  .sc-feature-section-area .feature-content-box,
  .details-content-area .details_btn,
  .details-content-area .title-box {
    display: block !important;
  }

  .details-content-area .title-box .details-btn {
    padding-top: 25px;
  }

  .details-title-item .details_btn h6 {
    margin-bottom: 25px;
  }

  .details-title-item .details-social-list .list-icon ul li {
    padding-left: 0;
  }

  .details-title-item .details-social-list h6 {
    padding-right: 15px;
  }

  .sc-faq-pages-area h2 {
    font-size: 35px;
  }

  .banner-header-content h1 {
    font-size: 30px;
  }

  .apps-title .heading-title {
    font-size: 32px;
  }

  .blog-content .blog-title {
    font-size: 20px;
  }

  .content-area .details-area .team-rating .rating-list li {
    margin-right: 0;
  }

  .accordion-item .accordion-header .accordion-button,
  .check-list li {
    font-size: 16px;
  }

  .sc-heading-area .heading-title {
    font-size: 24px;
  }

  .sc-about-area .sc-about-image .about-image {
    max-width: 300px;
  }

  .sc-about-area .sc-about-image .about-image_1 {
    left: -120px;
  }

  .sc-about-area .sc-about-image .about-image_1 {
    bottom: -45%;
  }

  .sc-about-area .sc-about-image .circle-shape {
    left: 15px;
    width: 280px;
    height: 280px;
  }

  .sc-feature-section-area .feature-image-area .feature-shape {
    left: 10%;
  }

  .sc-counter-area .shape_image1 {
    right: 0;
  }

  .spria-navigation ul li a {
    padding: 5px 12px;
  }

  .spria-navigation ul li .arrow {
    padding: 4px 9px 4px;
  }

  .sc-apps-section-area .apps-area .apps_shape_image .shape-img_6 {
    max-width: 100px;
  }

  .feature-content-box .sc-feature-text .feature-title {
    padding-top: 20px;
  }

  .sc-counter-area .shape_image,
  .sc-business-section-area .business-image-area .business-img_1 {
    left: 10px;
  }

  .sc-apps-section-area4 .apps-btn-area .apps-btn {
    margin-bottom: 10px;
  }

  .sc-faq-pages-area .accordion-item .accordion-header .accordion-button {
    font-size: 16px;
  }

  .sc-product-offcanvas-area {
    width: 300px;
  }

  .offcanvas-end {
    width: 250px;
  }

  .sc-feature-section-area5 .sc-sub-title {
    font-size: 18px;
  }

  .sc-feature-section-area5 .sc-heading-area .heading-title {
    font-size: 21px;
  }

  .sc-feature-section-area3 .feature-image-area,
  .sc-feature-section-area4 .sc-feature-image {
    margin-bottom: 60px;
  }

  .sc-banner-section-area .sc-banner-item .banner-header-content .banner-title {
    font-size: 35px;
  }

  .sc-faq-pages-area .accordion-item .accordion-header .accordion-button:before {
    right: 5px;
  }

  .contact-page-service-box .contact-service-box ul li {
    font-size: 18px;
  }

  .spria-navigation ul li a {
    width: 35px;
    height: 35px;
    line-height: 30px;
    border-radius: 50%;
  }

  .sc-feature-section-area5 .sc-feature-image .image-bg-circle {
    width: 230px;
    height: 230px;
  }

  .sc-feature-section-area5 .feature_img {
    max-width: 290px;
    padding-left: 60px;
  }

  .sc-feature-section-area5 {
    padding-top: 90px;
  }

  .sc-banner-section-area3 .banner_image {
    margin-left: -45px;
  }

  .sc-banner-section-area3 .banner_image {
    max-width: 270px;
  }

  .sc-login-box {
    max-width: 345px;
  }

  .sc-banner-section-area5 .banner-btn-area,
  .sc-crupto-style-three .crupto-btn-area,
  .sc-banner-section-area6 .banner-btn-area,
  .sc-banner-section-area7 .banner-video-area {
    display: block !important;
  }

  .sc-banner-section-area7 .banner-header-content .banner-title {
    font-size: 30px;
  }

  .sc-banner-section-area7 .banner-button {
    padding: 18px 25px;
    font-size: 16px;
    margin-right: 10px;
  }

  .sc-banner-section-area7 .banner-header-content .banner-video-area .popup-video {
    display: inline-block;
    margin-top: 28px;
  }

  .sc-banner-section-area7 {
    padding-bottom: 40px;
  }

  .sc-price-style .sc-gradiant-active,
  .sc-price-style .sc-gradiant-btn {
    padding: 15px 40px;
    font-size: 16px;
  }

  .sc-apps-media-section-area .sc-media-content .sc-media-form .sc-submit {
    padding: 13px 20px;
    font-size: 16px;
  }

  .sc-crupto-style-three .crupto-btn-area .sc-crupto-btm {
    margin-bottom: 15px;
  }

  .sc-price-style .sc-price-box {
    padding: 50px 15px 30px 20px;
  }

  .sc-crupto-style-two .sc-crupto-content-two {
    margin-top: -30px;
  }

  .sc-login-section .container {
    padding: 0 10px;
  }

  .sc-login-box {
    padding: 40px 25px 25px;
  }

  .sc-login-box {
    max-width: 345px;
  }
}

.wrapper { 
  display: flex; 
  align-content: center; 
  justify-content: space-between; 
  margin: 1em 0; 
} 

canvas { 
  border: 2px solid crimson; 
  border-radius: 20px; 
} 

button#reload-button { 
  font-size: 26px; 
  width: 2.6em; 
  background-color: #E82127; 
  cursor: pointer; 
  border: none; 
  border-radius: 0.4em; 
  color: #ffffff; 
} 

button#reload-button:hover { 
  background-color: #ef9334; 
} 

input[type='text'] { 
  font-family: 'Roboto Mono', monospace; 
  font-size: 1rem; 
  width: 100%; 
  padding: 16px; 
  border: 2px solid crimson; 
  border-radius: 20px; 
} 

button#submit-button { 
  width: 100%; 
  color: #ffffff; 
  font-size: 1.5em; 
  border: none; 
  margin-top: 1em; 
  padding: 0.8em 0; 
  border-radius: 0.4em; 
  background-color: green; 
  cursor: pointer; 
} 

button#submit-button:hover { 
  background-color: rgb(53, 175, 53); 
}

.text-red{
  color: #E82127; 
}

/* Max Resulation: 374px */
@media only screen and (max-width: 374px) {

  .sc-banner-section-area6 .banner-btn-area .sc-yellow-btm,
  .sc-crupto-style-three .crupto-btn-area .sc-yellow-white-btn,
  .sc-banner-section-area5 .banner-btn-area .yellow-btn2 {
    margin-right: 0;
    margin-bottom: 15px;
  }
}